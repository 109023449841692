import React from 'react';
import { AppState } from 'store';
import { answersAction } from 'modules/answers/answers.action';
import { useDispatch, useSelector } from 'react-redux';
import AssignmentsRules from './AssignmentsRules';

const AssignmentsRulesContainer = () => {
  const dispatch = useDispatch();
  const getTaskDetail = (id: string, groupId: number, isSaved: boolean) => {
    dispatch(answersAction.getDetailTaskForStudent({ id, groupId, isSaved }));
  };
  const answersState = useSelector((state: AppState) => state.pages.answers);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  return <AssignmentsRules getTaskDetail={getTaskDetail} answersState={answersState} currentGroup={currentGroup} />;
};

export default AssignmentsRulesContainer;
