import React from 'react';
import { Row } from 'antd';
import TableResultBasicTask from 'components/TableResultBasicTask/TableResultBasicTask';
import TableResultControlTask from 'components/TableResultControlTask/TableResultControlTask';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import styles from './style.module.scss';
import './style.css';

const AssignmentsResultOverview = () => {
  const { resultAssignment } = useSelector((state: AppState) => state.pages.answers);

  const basicScore = resultAssignment.basicResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const eventScore = resultAssignment.eventResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  return (
    <div className="groupsAdm">
      <p className={styles.bodySection}>栽培問題回答に対する評価</p>
      <TableResultBasicTask maxScore={resultAssignment.maxScore} basicScore={basicScore} eventScore={eventScore} />
      <p className={styles.bodySection}>制御問題回答に対する評価</p>
      <TableResultControlTask
        maxScore={resultAssignment.maxScore}
        morningScore={resultAssignment.controlResult?.morningScore}
        afternoonScore={resultAssignment.controlResult?.afternoonScore}
        beforeNightScore={resultAssignment.controlResult?.beforeNightScore}
        midnightScore={resultAssignment.controlResult?.midnightScore}
      />
      <Row justify="start" className={styles.bodySectionComment}>
        <p>講師コメント</p>
      </Row>
      <div className={styles.commentWrapper}>
        {resultAssignment.comments?.map((cmt) => (
          <p key={cmt.teacherId}>
            <b>講師名: </b> {cmt.teacherName} <b>- 日付:</b> {cmt.time} - <i>{cmt.content}</i>
          </p>
        ))}
      </div>
    </div>
  );
};

export default AssignmentsResultOverview;
