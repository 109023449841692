import React from 'react';
import styles from './style.module.scss';

type Props = {
  size?: number;
  color?: string;
};

const RightAnswerIcon = ({ size = 28, color }: Props) => (
  <div className={`${styles.iconComponent} size${size}`}>
    <svg width={size} height={size} viewBox="0 0 28 28" fill={color}>
      <path d="M14 0.163983C6.37997 0.163983 0.199966 6.34398 0.199966 13.934C0.199966 21.554 6.37997 27.734 14 27.734C21.59 27.734 27.77 21.554 27.77 13.934C27.77 6.34398 21.59 0.163983 14 0.163983ZM14 3.88398C19.55 3.88398 24.05 8.38398 24.05 13.934C24.05 19.514 19.55 24.014 14 24.014C8.41997 24.014 3.91997 19.514 3.91997 13.934C3.91997 8.38398 8.41997 3.88398 14 3.88398Z" />
    </svg>
  </div>
);
export default RightAnswerIcon;
