import { getMaxScoreControl } from 'libs/helpers/functions';
import React from 'react';
import { TMaxScore } from 'types/assignments';
import styles from './style.module.scss';

type Props = {
  maxScore?: TMaxScore;
  morningScore?: number;
  afternoonScore?: number;
  beforeNightScore?: number;
  midnightScore?: number;
};

const TableResultControlTask = ({
  maxScore,
  morningScore = 0,
  afternoonScore = 0,
  beforeNightScore = 0,
  midnightScore = 0,
}: Props) => (
  <table className={styles.tableResult}>
    <thead>
      <tr>
        <th>合計</th>
        <th>早朝管理</th>
        <th>日中管理</th>
        <th>前夜半管理</th>
        <th>夜間管理</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>
          {getMaxScoreControl(maxScore)}
          点満点
        </th>
        <th>{maxScore?.morning ?? 0}点満点</th>
        <th>{maxScore?.afternoon ?? 0}点満点</th>
        <th>{maxScore?.beforeNight ?? 0}点満点</th>
        <th>{maxScore?.midnight ?? 0}点満点</th>
      </tr>
    </thead>
    <tbody>
      <tr className={styles.rowScore}>
        <td>{(morningScore + afternoonScore + beforeNightScore + midnightScore).toFixed(1)}</td>
        <td>{morningScore}</td>
        <td>{afternoonScore}</td>
        <td>{beforeNightScore}</td>
        <td>{midnightScore}</td>
      </tr>
    </tbody>
  </table>
);

export default TableResultControlTask;
