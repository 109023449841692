import React, { useEffect, useState } from 'react';
import { Button, Row, Select } from 'antd';
import LineChart from 'components/LineChart/LineChart';
import { Link } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { convertData, convertRangeData, convertCorrectData, replacePathParams } from 'libs/helpers/functions';
import { aliasValue } from 'constants/date';
import { getAssignmentName, getDataSimulationsFromLocalStorage } from 'libs/helpers/localStorageFn';
import { TChartDataAll, TDataSimulations } from 'types/results';
import { configLineChart } from 'components/LineChart/lineChartConfig';
import { DATA_SELECT, DATA_TYPE_CALCULATED_ANSWER, DATA_TYPE_STUDENT_ANSWER } from 'constants/common';
import styles from './style.module.scss';

type Props = {
  assignmentId: string;
  dataSimulations: TDataSimulations | null;
  onBackMainPage: () => void;
};
const DEFAULT_VALUE_SELECT = 'C1';
const AssignmentsResultSimulation = ({ assignmentId, dataSimulations, onBackMainPage }: Props) => {
  const dataToSimulations = getDataSimulationsFromLocalStorage() || dataSimulations;
  let dataCalculateInit;
  let dataCorrectInit;

  if (dataToSimulations.studentSimulation) {
    const convertCalculate = convertData(
      dataToSimulations.studentSimulation.calculatedData,
      DEFAULT_VALUE_SELECT,
      true,
      DATA_TYPE_CALCULATED_ANSWER,
    );
    dataCalculateInit = [
      ...convertCalculate.filter((e) => e.Date.split(' ')[0] === '10'),
      ...convertCalculate.filter((e) => e.Date.split(' ')[0] === '11'),
      ...convertCalculate.filter((e) => e.Date.split(' ')[0] === '12'),
      ...convertCalculate.filter((e) => e.Date.split(' ')[0] === '1'),
      ...convertCalculate.filter((e) => e.Date.split(' ')[0] === '2'),
      ...convertCalculate.filter((e) => e.Date.split(' ')[0] === '3'),
      ...convertCalculate.filter((e) => e.Date.split(' ')[0] === '4'),
    ];
  }
  if (dataToSimulations.correctSimulations) {
    const convertDataCorrect = convertCorrectData(dataToSimulations.correctSimulations, DEFAULT_VALUE_SELECT);
    dataCorrectInit = [
      ...convertDataCorrect.filter((e) => e.Date.split(' ')[0] === '10'),
      ...convertDataCorrect.filter((e) => e.Date.split(' ')[0] === '11'),
      ...convertDataCorrect.filter((e) => e.Date.split(' ')[0] === '12'),
      ...convertDataCorrect.filter((e) => e.Date.split(' ')[0] === '1'),
      ...convertDataCorrect.filter((e) => e.Date.split(' ')[0] === '2'),
      ...convertDataCorrect.filter((e) => e.Date.split(' ')[0] === '3'),
      ...convertDataCorrect.filter((e) => e.Date.split(' ')[0] === '4'),
    ];
  }
  const [selectValue, setSelectValue] = useState(DEFAULT_VALUE_SELECT);
  const [dataChart, setDataChart] = useState<TChartDataAll>({
    dataLine: [...(dataCalculateInit ?? []), ...(dataCorrectInit ?? [])],
    minData: [],
    maxData: [],
  });

  const handleChange = (als: string) => {
    setSelectValue(als);
    if (als === aliasValue.C1 || als === aliasValue.C2 || als === aliasValue.C3) {
      const dataCalculate = convertData(
        dataToSimulations.studentSimulation.calculatedData,
        als,
        true,
        DATA_TYPE_CALCULATED_ANSWER,
      );

      const dataCorrect = convertCorrectData(dataToSimulations.correctSimulations, als);

      setDataChart({
        minData: [],
        maxData: [],
        dataLine: [...dataCalculate, ...dataCorrect],
      });
    } else {
      const dataMinValue = convertRangeData(dataToSimulations.correctSimulations, als, 'min');
      const dataMaxValue = convertRangeData(dataToSimulations.correctSimulations, als, 'max');
      const dataCorrect = convertCorrectData(dataToSimulations.correctSimulations, als);
      const dataStudentAnswer = convertData(
        dataToSimulations.studentSimulation.studentAnswers,
        als,
        false,
        DATA_TYPE_STUDENT_ANSWER,
      );

      setDataChart({
        dataLine: [...dataCorrect, ...dataStudentAnswer],
        minData: dataMinValue,
        maxData: dataMaxValue,
      });
    }
  };

  useEffect(() => {
    if (!dataToSimulations || !dataToSimulations.correctSimulations || !dataToSimulations.studentSimulation) {
      onBackMainPage();
    }
  }, []);

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <div className={styles.title}>
          <Row>
            <p>制御シミュレーション結果</p>
            <p className={styles.titleTime}>{getAssignmentName()}</p>
          </Row>
          <Row justify="end">
            <Link to={replacePathParams(EPath.U10, { id: assignmentId })}>
              <Button className={styles.titleButton}>総合評価</Button>
            </Link>
          </Row>
          <Row>
            <Select defaultValue="C1" onChange={handleChange} className={styles.titleSelect} options={DATA_SELECT} />
          </Row>
        </div>

        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <LineChart
              desc3="許容範囲外"
              className={styles.linePlot}
              configChart={configLineChart(dataChart)}
              alias={selectValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentsResultSimulation;
