import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import RoutePublic from 'routes/RoutePublic';
import RoutePrivate from 'routes/RoutePrivate';
import { Route, Switch } from 'react-router';
import NotFound from 'routes/NotFound';
import { History } from 'history';
import { RootProps } from 'types/common';
import MainHeader from 'components/MainHeader/MainHeader';
import MainFooter from 'components/MainFooter/MainFooter';
import LeftMenu from 'components/LeftMenu/LeftMenu';
import { routers } from 'constants/router';
import MessageNotification from 'components/MessageNotification/MessageNotification';
import Loading from 'pages/Ui/Loading';
import { getCurrentFromLS, getRefreshToken } from 'config/server';
import { authModule } from 'modules/user/user.reducer';
import { AppState } from 'store';
import { ERole } from 'constants/enums/common';
import styles from './style.module.scss';

const App: React.FC<RootProps> = ({ history }: { history: History }) => {
  const dispatch = useDispatch();
  const userLS = getCurrentFromLS();
  const refreshToken = getRefreshToken();
  const { currentUser, isAuthenticated } = useSelector((state: AppState) => state.pages.auth);
  useEffect(() => {
    if (userLS.id && refreshToken) {
      dispatch(
        authModule.actions.saveUser({
          isAuthenticated: true,
          currentUser: userLS,
        }),
      );
    }
  }, []);

  return (
    <ConnectedRouter history={history}>
      <div className={styles.mainWarp}>
        <MainHeader />
        <div className={styles.contentWrap}>
          <Switch>
            {routers &&
              Object.values(routers)
                .filter(
                  (router) =>
                    !router.role.length || router.role.includes(userLS.role || currentUser.role || ERole.Student),
                )
                .map((router) =>
                  router.public ? (
                    <RoutePublic
                      key={router.path}
                      exact={router.exact}
                      path={router.path}
                      component={() => router.component}
                    />
                  ) : (
                    <RoutePrivate
                      key={router.path}
                      exact={router.exact}
                      path={router.path}
                      isAuthenticated={(!!refreshToken && !!userLS.id) || isAuthenticated}
                      component={() => router.component}
                      roleUser={userLS.role ?? currentUser.role}
                      rolesRouter={router.role}
                    />
                  ),
                )}
            <Route component={NotFound} />
          </Switch>
        </div>
        <MainFooter />
      </div>
      <MessageNotification />
      <Loading />
      <LeftMenu />
    </ConnectedRouter>
  );
};

export default App;
