import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import templateAction from 'modules/template/template.action';
import { AppState } from 'store';
import { templateModule, TTemplateState } from 'modules/template/template.reducer';
import AdmTempControlSimulation from './AdmTempControlSimulation';
import { TStep5State } from './AdmTempControlSimulation.state';

const AdmTempControlSimulationContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const updateTemplate = useCallback(
    (
      data: TStep5State,
      id: number,
      groupId: number,
      buttonFooterNext: boolean = false,
      buttonFooterPre: boolean = false,
    ) => {
      dispatch(templateAction.updateTemplateStep5({ data, id, groupId, buttonFooterNext, buttonFooterPre }));
    },
    [],
  );
  const saveTemplate = (data: TTemplateState) => {
    dispatch(templateModule.actions.saveTemplate(data));
  };
  return (
    <AdmTempControlSimulation
      updateTemplate={updateTemplate}
      saveTemplate={saveTemplate}
      template={template}
      currentGroup={currentGroup}
    />
  );
};

export default AdmTempControlSimulationContainer;
