import userAction from 'modules/user/user.action';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TForgotPasswordBody } from 'types/auth';
import ForgotPassword from './ForgotPassword';

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch();

  const forgotPassword = (data: TForgotPasswordBody) => dispatch(userAction.forgotPassword(data));

  return <ForgotPassword forgotPassword={forgotPassword} />;
};

export default ForgotPasswordContainer;
