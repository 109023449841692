import { EPath } from 'constants/routes';
import { convertCorrectData, convertData, replacePathParams } from 'libs/helpers/functions';
import { answersAction } from 'modules/answers/answers.action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AssignmentsResultOverviewAdm from 'pages/AssignmentsResultOverviewAdm/AssignmentsResultOverviewAdm';
import { AppState } from 'store';
import assignmentsAction from 'modules/assignments/assignments.action';
import AssignmentsResultControlAdm from 'pages/AssignmentsResultControlAdm/AssignmentsResultControlAdm';
import AssignmentsResultCultivateAdm from 'pages/AssignmentsResultCultivateAdm/AssignmentsResultCultivateAdm';
import { DATA_TYPE_CALCULATED_ANSWER } from 'constants/common';
import { TChartData } from 'types/results';
import AssignmentResultStudents, { TArrayPathNavLinkU8 } from './AssignmentResultStudents';

const AssignmentResultStudentsContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id, sid } = useParams<{ id: string; sid: string }>();
  const getResultAssignment = () => {
    dispatch(answersAction.getResultAssignmentOneStudent({ id, sid }));
  };
  const getResultsAssignment = (data: { id: string; groupId: number }) =>
    dispatch(assignmentsAction.getResultsAssignment(data));
  const { resultAssignment } = useSelector((state: AppState) => state.pages.answers);
  const { resultsAssignment } = useSelector((state: AppState) => state.pages.assignments);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);

  const { isShowLoading } = useSelector((state: AppState) => state.pages.ui);
  const DEFAULT_VALUE = 'C1';
  let dataCalculateInit: TChartData[] = [];
  let dataCorrectInit: TChartData[] = [];
  if (resultAssignment.controlResult?.studentAnswers) {
    dataCalculateInit = convertData(
      resultAssignment.controlResult?.studentAnswers,
      DEFAULT_VALUE,
      false,
      DATA_TYPE_CALCULATED_ANSWER,
    );
    dataCorrectInit = convertCorrectData(resultAssignment.controlResult?.correctAnswers ?? [], DEFAULT_VALUE);
  }

  const arrayPathU8: TArrayPathNavLinkU8[] = [
    {
      to: replacePathParams(EPath.A8_3, { id, sid }),
      title: '総合評価',
      component: (
        <AssignmentsResultOverviewAdm
          resultAssignment={resultAssignment}
          currentUser={currentUser}
          assignmentId={id}
          studentId={sid}
          getResultAssignment={getResultAssignment}
        />
      ),
    },
    {
      to: replacePathParams(EPath.A8_1, { id, sid }),
      title: '栽培問題',
      component: <AssignmentsResultControlAdm resultAssignment={resultAssignment} />,
    },
    {
      to: replacePathParams(EPath.A8_2, { id, sid }),
      title: '制御問題',
      component: (
        <AssignmentsResultCultivateAdm
          resultAssignment={resultAssignment}
          isShowLoading={isShowLoading}
          dataCalculateInit={dataCalculateInit}
          dataCorrectInit={dataCorrectInit}
        />
      ),
    },
  ];

  useEffect(() => {
    const { pathname } = location;
    if (pathname === replacePathParams(EPath.A8, { id, sid })) {
      history.replace(replacePathParams(EPath.A8_3, { id, sid }));
    } else if (!arrayPathU8.map((p) => p.to).includes(pathname)) {
      history.replace(EPath.A1);
    }
  }, []);

  useEffect(() => {
    if (id && sid && currentGroup.id) {
      getResultsAssignment({ id, groupId: currentGroup.id });
      getResultAssignment();
    }
  }, [id, sid]);

  return (
    <AssignmentResultStudents
      arrayPathU8={arrayPathU8}
      isShowLoading={isShowLoading}
      assignmentId={id}
      studentId={sid}
      resultsAssignment={resultsAssignment}
    />
  );
};

export default AssignmentResultStudentsContainer;
