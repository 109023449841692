import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import controlResultsAction from 'modules/controlResults/controlResults.action';
import { controlResultsModule } from 'modules/controlResults/controlResults.reducer';
import AdmControlResultDetail from './AdmControlResultDetail';

const AdmControlResultDetailContainer = () => {
  const dispatch = useDispatch();
  const controlResultsState = useSelector((state: AppState) => state.pages.controlResults);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const { id } = useParams<{ id: string }>();
  const uploadFileControlResults = (file: FormData) => {
    dispatch(controlResultsAction.uploadControlResultsFile(file));
  };

  const createControlResults = (data: { name?: string; fname?: string; groupId: number }) => {
    dispatch(controlResultsAction.createControlResults(data));
  };
  const updateControlResults = (data: { ctrRsId: number; name?: string; fname?: string; groupId: number }) => {
    dispatch(controlResultsAction.updateControlResults(data));
  };
  const getDetailControlResults = (data: { ctrRsId: number; groupId: number }) => {
    dispatch(controlResultsAction.getControlResultsDetail(data));
  };

  const updateDataFileUpload = () => {
    dispatch(controlResultsModule.actions.setDataFileUpload({}));
  };
  return (
    <AdmControlResultDetail
      id={id}
      currentGroup={currentGroup}
      controlResultsState={controlResultsState}
      createControlResults={createControlResults}
      getDetailControlResults={getDetailControlResults}
      updateControlResults={updateControlResults}
      uploadFileControlResults={uploadFileControlResults}
      updateDataFileUpload={updateDataFileUpload}
    />
  );
};

export default AdmControlResultDetailContainer;
