import { EPath } from 'constants/routes';

export type TLeftMenu = {
  text: string;
  link: string;
};

export const studentMenu: TLeftMenu[] = [
  {
    text: 'TOP',
    link: EPath.U3,
  },
];

export const teacherMenu: TLeftMenu[] = [
  {
    text: 'TOP',
    link: EPath.A1,
  },
  {
    text: 'ユーザー管理',
    link: EPath.A2,
  },
  {
    text: '研修テンプレート一覧',
    link: EPath.A3,
  },
  {
    text: '研修一覧',
    link: EPath.A5,
  },
  {
    text: '模範データ管理',
    link: EPath.A9_1,
  },
];
export const adminMenu: TLeftMenu[] = [
  {
    text: 'TOP',
    link: EPath.A1,
  },
  {
    text: 'ユーザー管理',
    link: EPath.A2,
  },
  {
    text: '研修テンプレート一覧',
    link: EPath.A3,
  },
  {
    text: '研修一覧',
    link: EPath.A5,
  },
  {
    text: '模範データ管理',
    link: EPath.A9_1,
  },
  {
    text: 'グループ管理',
    link: EPath.S3_1,
  },
];
