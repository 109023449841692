import { DEFAULT_LIMIT_PAGINATION } from 'constants/pagination';
import { TOptionsQuery } from 'types/common';
import { controlResultsRepositories } from 'repositories/controlResults';
import { TControlResultsDetail } from 'types/controlResults';

const DEFAULT_GROUPS_LIMIT = DEFAULT_LIMIT_PAGINATION;
const DEFAULT_GROUPS_PAGE = 1;

const getControlResults = async (params: TOptionsQuery<TControlResultsDetail>) => {
  const res = await controlResultsRepositories.getControlResults({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_GROUPS_LIMIT,
      page: params.page ?? DEFAULT_GROUPS_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getControlResultsDetail = async (data: { ctrRsId: number; groupId: number }) => {
  const { ctrRsId, groupId } = data;
  const res = await controlResultsRepositories.getDetailControlResults({
    condition: { groupId },
    pathParameters: { id: ctrRsId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const createControlResults = async (data: { name?: string; fname?: string; groupId: number }) => {
  const { groupId, ...body } = data;
  const res = await controlResultsRepositories.createControlResults({
    body,
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateControlResults = async (data: { ctrRsId: number; name?: string; fname?: string; groupId: number }) => {
  const { ctrRsId, name, fname, groupId } = data;
  const res = await controlResultsRepositories.updateControlResults({
    body: { name, fname },
    pathParameters: { id: ctrRsId },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const deleteControlResults = async (data: { ctrRsId: number; groupId: number }) => {
  const { ctrRsId, groupId } = data;
  const res = await controlResultsRepositories.deleteControlResults({
    pathParameters: { id: ctrRsId },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return ctrRsId;
};

const uploadControlResultsFile = async (file: FormData) => {
  const res = await controlResultsRepositories.uploadControlResults({
    body: file,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const downloadControlResultsFile = async (data: { ctrRsId: number; groupId: number }) => {
  const { ctrRsId, groupId } = data;
  const res = await controlResultsRepositories.downloadControlResults({
    pathParameters: { id: ctrRsId },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};
export const controlResultsServices = {
  getControlResults,
  getControlResultsDetail,
  uploadControlResultsFile,
  createControlResults,
  updateControlResults,
  deleteControlResults,
  downloadControlResultsFile,
};
