import templateAction from 'modules/template/template.action';
import { templateModule, TTemplateState } from 'modules/template/template.reducer';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import AdmTempQuestion from './AdmTempQuestion';
import { TStep4State } from './AdmTempQuestion.state';

const AdmTempQuestionContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const updateTemplate = useCallback(
    (
      data: TStep4State,
      id: number,
      groupId: number,
      buttonFooterNext: boolean = false,
      buttonFooterPre: boolean = false,
    ) => {
      dispatch(templateAction.updateTemplateStep4({ data, id, groupId, buttonFooterNext, buttonFooterPre }));
    },
    [],
  );
  const saveTemplate = (data: TTemplateState) => {
    dispatch(templateModule.actions.saveTemplate(data));
  };
  return (
    <AdmTempQuestion
      updateTemplate={updateTemplate}
      template={template}
      saveTemplate={saveTemplate}
      currentGroup={currentGroup}
    />
  );
};

export default AdmTempQuestionContainer;
