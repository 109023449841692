import { TStep2 } from 'types/template';

export type TStep2State = {
  step2: TStep2[];
};

export const STAGE_NAME = {
  STAGE_PLANTING: '定植期～主枝摘心期（10月）',
  START_TO_HARVEST: '収穫開始期・前期（11・12月）',
  BETWEEN_TO_HARVEST: '収穫中期（1・2月）',
  AFTER_TO_HARVEST: '収穫後期（3・4月）',
};

export const OPTION_SCORE = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

export const OPTION_STAGE_NAME = [
  {
    value: STAGE_NAME.STAGE_PLANTING,
    label: STAGE_NAME.STAGE_PLANTING,
  },
  {
    value: STAGE_NAME.START_TO_HARVEST,
    label: STAGE_NAME.START_TO_HARVEST,
  },
  {
    value: STAGE_NAME.BETWEEN_TO_HARVEST,
    label: STAGE_NAME.BETWEEN_TO_HARVEST,
  },
  {
    value: STAGE_NAME.AFTER_TO_HARVEST,
    label: STAGE_NAME.AFTER_TO_HARVEST,
  },
];

export const QUESTION = {
  QUESTION_1: '温度管理方法の適切な考え方を選択してください。',
  QUESTION_2: '炭酸ガス濃度目標値を選択してください。',
  QUESTION_3: '温度管理方法の適切ではない考え方を選択してください。',
  QUESTION_4: '炭酸ガス濃度目標値を選択してください。',
  QUESTION_5: '11月・12月の１株あたりのかん水量目安として適切なものを選択してください。',
  QUESTION_6: '厳寒期の温度管理として適切なものを選択してください。',
  QUESTION_7: '炭酸ガス濃度目標値を選択してください。',
  QUESTION_8: '湿度管理について最も適切な考え方を選択してください。',
  QUESTION_9: '1月・2月の１株あたりのかん水量目安として適切なものを選択してください。',
  QUESTION_10: '温度管理として適切ではないものを選択してください。',
  QUESTION_11: '４月以降の炭酸ガス濃度目標値を選択してください。',
  QUESTION_12: '3月・4月の１株あたりのかん水量目安として適切なものを選択してください。',
};

export const QUESTION_BASIC = [
  '温度管理方法の適切な考え方を選択してください。',
  '炭酸ガス濃度目標値を選択してください。',
  '温度管理方法の適切ではない考え方を選択してください。',
  '11月・12月の１株あたりのかん水量目安として適切なものを選択してください。',
  '厳寒期の温度管理として適切なものを選択してください。',
  '湿度管理について最も適切な考え方を選択してください。',
  '1月・2月の１株あたりのかん水量目安として適切なものを選択してください。',
  '温度管理として適切ではないものを選択してください。',
  '４月以降の炭酸ガス濃度目標値を選択してください。',
  '3月・4月の１株あたりのかん水量目安として適切なものを選択してください。',
];

export const INITIAL_DATA: TStep2[] = [
  {
    id: '1',
    stageName: STAGE_NAME.STAGE_PLANTING,
    question: QUESTION.QUESTION_1,
    options: [
      {
        index: 1,
        value:
          '日中は22～23℃を目安に換気につとめ、夜間は天窓、サイドを全開にし、可能な限り夜温を下げ、最低夜温10℃を保つ。',
        isCorrect: false,
      },
      {
        index: 2,
        value:
          '日中は22～23℃を目安に換気につとめ、夜間は天窓、サイドを全閉にし、可能な限り夜温を上げ、最低夜温15℃を保つ。',
        isCorrect: false,
      },
      {
        index: 3,
        value:
          '日中は27～28℃を目安に換気につとめ、夜間は天窓、サイドを全開にし、可能な限り夜温を下げ、最低夜温10℃を保つ。',
        isCorrect: true,
      },
      {
        index: 4,
        value:
          '日中は27～28℃を目安に換気につとめ、夜間は天窓、サイドを全閉にし、可能な限り夜温を上げ、最低夜温15℃を保つ。',
        isCorrect: false,
      },
    ],
    comment:
      '根張り期の日中は27～28℃を目安に換気につとめ、夜間は天窓、サイドを全開にし、可能な限り夜温を下げて株の充実をはかる。※夜温が高く、かん水が多いと徒長する。主枝摘心期では着果すると心が細りやすいので、前夜半の温度を極力下げて最低夜温は10℃を保つ。同時にかん水量もやや多くする。もし前夜半の温度が高いと軟弱徒長となり、尻太になりやすい。',
    score: 5,
  },
  {
    id: '2',
    stageName: STAGE_NAME.STAGE_PLANTING,
    question: QUESTION.QUESTION_2,
    options: [
      {
        index: 1,
        value: '400ppm',
        isCorrect: false,
      },
      {
        index: 2,
        value: '450ppm',
        isCorrect: true,
      },
      {
        index: 3,
        value: '500ppm',
        isCorrect: false,
      },
      {
        index: 4,
        value: '550ppm',
        isCorrect: false,
      },
    ],
    comment:
      'ハウス内の炭酸ガス濃度が350ppmを下回る頃から施用を開始し、ハウス内炭酸ガス濃度が450ppm程度になるように保つ。',
    score: 5,
  },
  {
    id: '3',
    stageName: STAGE_NAME.START_TO_HARVEST,
    question: QUESTION.QUESTION_3,
    options: [
      {
        index: 1,
        value: '早朝加温は1時間に2℃以上温度が上がらないように緩やかに行う。',
        isCorrect: false,
      },
      {
        index: 2,
        value: '日中時間帯は25℃をできるだけ維持する。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '転流時間帯は日の入2時間前から徐々に温度を下げる。',
        isCorrect: true,
      },
      {
        index: 4,
        value: '１１月中旬までに加温機の準備をしておき、いつでも作動出来る状態にする。',
        isCorrect: false,
      },
    ],
    comment:
      '早朝の急激な温度変化は葉の気孔を閉じさせ光合成速度が落ちてしまうため、早朝加温は1時間に2℃以上上がらないように緩やかに行う。日中光合成を最大化させるために天窓の開度を調整して光合成適温である25℃をできるだけ維持させる。転流促進のため、日の入30分前から温度を一気に下げる。時刻ごとの目標温度は、4時10.5℃、6時13.5℃、8時16.5℃、10時23.0℃、12時25.0℃、14時24.0℃、16時21.0℃、18時以降12.0℃である。',
    score: 5,
  },
  {
    id: '4',
    stageName: STAGE_NAME.START_TO_HARVEST,
    question: QUESTION.QUESTION_4,
    options: [
      {
        index: 1,
        value: '400ppm～450ppm',
        isCorrect: false,
      },
      {
        index: 2,
        value: '450ppm～550ppm',
        isCorrect: false,
      },
      {
        index: 3,
        value: '550ppm～650ppm',
        isCorrect: true,
      },
      {
        index: 4,
        value: '650ppm～750ppm',
        isCorrect: false,
      },
    ],
    comment:
      '気温の低下に伴い、ハウスを閉め始める11月中旬頃から、炭酸ガス濃度を550ppm～650ppmに上げていく。.5℃、6時13.5℃、8時16.5℃、10時23.0℃、12時25.0℃、14時24.0℃、16時21.0℃、18時以降12.0℃である。',
    score: 5,
  },
  {
    id: '5',
    stageName: STAGE_NAME.START_TO_HARVEST,
    question: QUESTION.QUESTION_5,
    options: [
      {
        index: 1,
        value:
          '11月の１株あたりのかん水量目安は2.0L/日（0.13L/MJ。）12月の１株あたりのかん水量目安は2.5L/日（0.17L/MJ）。',
        isCorrect: true,
      },
      {
        index: 2,
        value:
          '11月の１株あたりのかん水量目安は2.5L/日（0.16L/MJ）。12月の１株あたりのかん水量目安は3.0L/日（0.20L/MJ）。',
        isCorrect: false,
      },
      {
        index: 3,
        value:
          '11月の１株あたりのかん水量目安は3.0L/日（0.19L/MJ）。12月の１株あたりのかん水量目安は3.5L/日（0.23L/MJ）。',
        isCorrect: false,
      },
      {
        index: 4,
        value:
          '11月の１株あたりのかん水量目安は3.0L/日（0.22L/MJ）。12月の１株あたりのかん水量目安は3.5L/日（0.26L/MJ）。',
        isCorrect: false,
      },
    ],
    comment:
      '果実が肥大する11月の1株あたりのかん水量の目安は0.13L/MJ（２L/日）。日射量が増加し雌花率と収穫量が増加する12月のかん水量の目安は0.17L/MJ（2.5L/日）。なお、かん水は土壌ｐF値や、葉の大きさ、着果数などを参考に、生育に応じて調整する。',
    score: 5,
  },
  {
    id: '6',
    stageName: STAGE_NAME.BETWEEN_TO_HARVEST,
    question: QUESTION.QUESTION_6,
    options: [
      {
        index: 1,
        value: '午前（13時まで）27℃。午後25℃。夜間12～13℃。',
        isCorrect: false,
      },
      {
        index: 2,
        value: '午前（13時まで）27℃。午後25℃。夜間10～11℃。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '午前（13時まで）20℃。午後18℃。夜間12～13℃。',
        isCorrect: true,
      },
      {
        index: 4,
        value: '午前（13時まで）20℃。午後18℃。夜間10～11℃。',
        isCorrect: false,
      },
    ],
    comment:
      '午前（13時まで）20℃。午後18℃。夜間12～13℃。夜間の温度については、果実の肥大を見て決定する。２月中旬以降、外気温が上がり始めた頃、早めに換気し厳寒期に落ちこんだ草姿の回復をはかる。',
    score: 5,
  },
  {
    id: '7',
    stageName: STAGE_NAME.BETWEEN_TO_HARVEST,
    question: QUESTION.QUESTION_7,
    options: [
      {
        index: 1,
        value: '600ppm～650ppm',
        isCorrect: true,
      },
      {
        index: 2,
        value: '650ppm～700ppm',
        isCorrect: false,
      },
      {
        index: 3,
        value: '700ppm～750ppm',
        isCorrect: false,
      },
      {
        index: 4,
        value: '750ppm～800ppm',
        isCorrect: false,
      },
    ],
    comment: '光合成を促進させ収穫量を増加させるために炭酸ガス濃度を750ppm~800ppmに上げていく。',
    score: 5,
  },
  {
    id: '8',
    stageName: STAGE_NAME.BETWEEN_TO_HARVEST,
    question: QUESTION.QUESTION_8,
    options: [
      {
        index: 1,
        value: 'ハウス内湿度は適正値になりやすいため、何もしない。',
        isCorrect: false,
      },
      {
        index: 2,
        value: 'ハウス内湿度が下がりやすいため、通路かん水を行い湿度保持につとめる。',
        isCorrect: true,
      },
      {
        index: 3,
        value: 'ハウス内湿度が上がりやすいため、除湿を行い湿度低減につとめる。',
        isCorrect: false,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '加温機の稼働時間が長くなりハウス内湿度が下がるので通路かん水を行い、ハウス内湿度の保持につとめる。午前中70％以上、午後60％で正常生育となり、午前中70％以下の乾燥、午後40％以下の乾燥状態で心止まり気味となる。',
    score: 5,
  },
  {
    id: '9',
    stageName: STAGE_NAME.BETWEEN_TO_HARVEST,
    question: QUESTION.QUESTION_9,
    options: [
      {
        index: 1,
        value: '1月・２月の１株あたりのかん水量目安は2.0L/日（0.13L/MJ）。',
        isCorrect: false,
      },
      {
        index: 2,
        value: '1月・２月の１株あたりのかん水量目安は2.5L/日（0.17L/MJ）。',
        isCorrect: true,
      },
      {
        index: 3,
        value: '1月・２月の１株あたりのかん水量目安は3.0L/日（0.21L/MJ）。',
        isCorrect: false,
      },
      {
        index: 4,
        value: '1月・２月の１株あたりのかん水量目安は3.5L/日（0.25L/MJ）。',
        isCorrect: false,
      },
    ],
    comment:
      '加温機の稼働時間が長くなりハウス内湿度が下がるので通路かん水を行い、ハウス内湿度の保持につとめる。午前中70％以上、午後60％で正常生育となり、午前中70％以下の乾燥、午後40％以下の乾燥状態で心止まり気味となる。',
    score: 5,
  },
  {
    id: '10',
    stageName: STAGE_NAME.AFTER_TO_HARVEST,
    question: QUESTION.QUESTION_10,
    options: [
      {
        index: 1,
        value: '外気温が8℃以上の予報の時は、天窓を20%程度開け、２重カーテンを全開にする。',
        isCorrect: false,
      },
      {
        index: 2,
        value: '外気温が12℃以上あるときは、天窓と2重カーテンを全閉にする。',
        isCorrect: true,
      },
      {
        index: 3,
        value: '日中時間帯は天窓をこまめに調整しながら25℃をキープする。',
        isCorrect: false,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '外気温が12℃あるときは天窓と２重カーテンを全開にするため、選択肢２の全閉にするは適切ではありません。外気温に応じて天窓の開度を調整し、外気のハウス内への流入速度を調整します。日中時間帯は光合成適温の25℃になるように調整します。',
    score: 5,
  },
  {
    id: '11',
    stageName: STAGE_NAME.AFTER_TO_HARVEST,
    question: QUESTION.QUESTION_11,
    options: [
      {
        index: 1,
        value: '400ppmを下回らない程度',
        isCorrect: true,
      },
      {
        index: 2,
        value: '500ppmを下回らない程度',
        isCorrect: false,
      },
      {
        index: 3,
        value: '600ppmを下回らない程度',
        isCorrect: false,
      },
      {
        index: 4,
        value: '700ppmを下回らない程度',
        isCorrect: false,
      },
    ],
    comment:
      '4月以降は天窓が開いており、炭酸ガスを施用してもハウス外に逃げてしまうため、炭酸ガス濃度は外気と同等の400ppmを下回らない程度で施用する。',
    score: 5,
  },
  {
    id: '12',
    stageName: STAGE_NAME.AFTER_TO_HARVEST,
    question: QUESTION.QUESTION_12,
    options: [
      {
        index: 1,
        value:
          '3月の１株あたりのかん水量目安は2～3L/日（0.16L/MJ）。4月の１株あたりのかん水量目安は3～4L/日（0.18L/MJ）。',
        isCorrect: true,
      },
      {
        index: 2,
        value:
          '3月の１株あたりのかん水量目安は3～4L/日（0.20L/MJ）。4月の１株あたりのかん水量目安は4～5L/日（0.22L/MJ）。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '3月のかん水量目安は2L/日（0.24L/MJ）。4月のかん水量目安は2.5L/日（0.26L/MJ）。',
        isCorrect: false,
      },
      {
        index: 4,
        value: '3月のかん水量目安は2L/日（0.28L/MJ）。4月のかん水量目安は2.5L/日（0.30L/MJ）。',
        isCorrect: false,
      },
    ],
    comment:
      '日射量がさらに増加し収穫量も増加する3月が0.16L/MJ（２～3L/日）。そして4～6月が0.18L/MJ（3～4L/日）。なお、かん水は土壌ｐF値や、葉の大きさ、着果数などを参考に、生育に応じて調整する。',
    score: 5,
  },
];
