import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Typography, Button, Select } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { setStatusShowInsModal, getAssignmentName } from 'libs/helpers/localStorageFn';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import LineChart from 'components/LineChart/LineChart';
import ConfirmModal from 'components/CommonModal/ConfirmModal';
import NotiModal from 'components/CommonModal/NotiModal';
import { EPath } from 'constants/routes';
import { convertData, replacePathParams } from 'libs/helpers/functions';
import { TAnswerControlSubmit, TControlAnswer } from 'types/assignments';
import { TStudentSimulation } from 'types/results';
import { DATA_SELECT_SIMULATION, DATA_SELECT_USER, getChartConfig } from './AssignmentsControlGraph.state';
import styles from './style.module.scss';

type TAnswerStudentCheck = {
  alias: string;
  factorial: string;
  data: TControlAnswer[];
};
type Props = {
  handleClickPre: () => void;
  handleSubmitAnswerControlTask: (data: TAnswerControlSubmit) => void;
  answersState: TControlAnswer[];
  assignmentId: string;
  dataStudentAnswer?: TStudentSimulation;
  studentAnswerCheckNotDoing: TAnswerStudentCheck[];
};
const AssignmentsControlGraph = ({
  handleClickPre,
  handleSubmitAnswerControlTask,
  answersState,
  assignmentId,
  dataStudentAnswer,
  studentAnswerCheckNotDoing,
}: Props) => {
  const [modal, setModal] = useState<'warn' | 'confirm' | ''>('');
  const [option, setOption] = useState({
    calculatedData: 'C1',
    studentAnswer: 'M1',
  });

  const isAnswer = ['未回答', '回答済'];
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const isDone = answersState
    .filter((i) => i.alias === 'M1' || i.alias === 'M4' || i.alias === 'M6')
    .every((item) =>
      [item.afternoonData, item.beforeNightData, item.midnightData, item.morningData].every((i) => i !== 0),
    );

  const handleSetModal = useCallback(() => {
    if (isDone) {
      setModal('confirm');
    } else {
      setModal('warn');
    }
  }, []);

  const handleBackPage = (month: string) => {
    history.push(replacePathParams(EPath.U8_1_2, { id }), { month: Number(month.slice(0, 2)) });
  };

  useEffect(() => {
    handleSubmitAnswerControlTask({
      id: assignmentId,
      simulation: true,
      answers: answersState ?? [],
    });
  }, []);

  const onSubmitAnswer = () => {
    setModal('');
    setStatusShowInsModal('control', 'on');
    handleSubmitAnswerControlTask({
      id: assignmentId,
      simulation: false,
      answers: answersState ?? [],
    });
  };

  const handleClickNext = () => {
    // Logic back phase 1
    // if (isDone && isConfirm && isConfirm.length > 0) {
    //   history.push(replacePathParams(EPath.U9, { id }));
    // } else if (isDone) {
    //   onSubmitAnswer();
    // } else {
    //   setModal('warn');
    // }
    if (isDone) {
      onSubmitAnswer();
    } else {
      setModal('warn');
    }
  };

  const handleChange = useCallback(
    (value: string, studentAnswer = false) => {
      if (!studentAnswer) {
        setOption({
          ...option,
          calculatedData: value,
        });
      } else {
        setOption({
          ...option,
          studentAnswer: value,
        });
      }
    },
    [option],
  );

  const allData = (calculatedData: string, studentAnswers: string) => [
    ...convertData(dataStudentAnswer?.calculatedData ?? [], calculatedData, true, 'calculatedData'),
    ...convertData(studentAnswerCheckNotDoing ?? [], studentAnswers, false, 'studentSimulation'),
  ];

  const dataChartConfig = useMemo(() => {
    const data = allData(option.calculatedData, option.studentAnswer);

    const unanswered: string[] = data.map((item) => {
      if (item.check === false) {
        return item.Date;
      }
      return '';
    });

    return getChartConfig(data, unanswered);
  }, [dataStudentAnswer, option.calculatedData, option.studentAnswer]);

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row className={styles.assignmentsQuestionWrap__marginBottom}>
          <Col span={12}>
            <Row>
              <p>制御シミュレーション結果</p>
              <p className={styles.title}>{getAssignmentName()}</p>
            </Row>
          </Col>
          <Col span={12}>
            <Row className={styles.contentRight}>
              <Link to={replacePathParams(EPath.U11, { id: assignmentId })}>
                <Typography.Text underline>栽培ルール確認</Typography.Text>
              </Link>
            </Row>
            <Row className={styles.contentRightButton}>
              <Link to={replacePathParams(EPath.U12, { id: assignmentId })}>
                <Button className={styles.buttonLeft}>栽培問題課題回答確認</Button>
              </Link>{' '}
              <Button className={styles.buttonModalPre} onClick={handleSetModal}>
                回答確定
              </Button>
            </Row>
          </Col>
        </Row>
        <NotiModal
          title="未回答問題があります。"
          message={
            <p>
              未回答の問題があるため、回答を確定することができません。
              <br />
              未回答問題に回答してください。
            </p>
          }
          isButtonPre
          isSubButton
          textButton="戻る"
          open={modal === 'warn'}
          openModal={() => setModal('')}
        />
        <ConfirmModal
          title="確認"
          message={
            <p>
              回答を確定しますか？
              <br />
              回答を確定後回答を変更できません。
            </p>
          }
          textConfirm
          open={modal === 'confirm'}
          onClose={() => setModal('')}
          onConfirm={onSubmitAnswer}
        />
        <div className={styles.description}>
          <p>この画面はこれまでの回答を基にシミュレーションされたグラフです。</p>
          <br />
          <p>
            グラフを見ておかしいとこがあれば、「早朝」や「日中」などのボタンをクリックすることで、回答を見直すことができます。
          </p>
          <p>グラフを見ておかしいところがなければ、右上の「回答確定」ボタンをクリックしてください。</p>
          <p>模範データの比較画面に移動します。</p>
          <p>模範データとの比較画面に一度移動すると再回答はできません。</p>
        </div>
        <br />
        <Row align="middle" className={styles.titleChart}>
          <Col span={6}>
            <span>環境センサー</span>
            <Select
              className={styles.widthSelect}
              defaultValue={option.calculatedData}
              bordered
              options={DATA_SELECT_SIMULATION}
              onChange={(e): void => handleChange(e)}
            />
          </Col>
          <Col span={6}>
            <span>制御設定</span>
            <Select
              className={styles.widthSelect}
              defaultValue={option.studentAnswer}
              bordered
              options={DATA_SELECT_USER}
              onChange={(e): void => handleChange(e, true)}
            />
          </Col>
        </Row>
        <LineChart
          configChart={dataChartConfig}
          isAnswer={isAnswer}
          handleBackPage={handleBackPage}
          alias={option.calculatedData}
          fixedDesc
        />
      </div>
      <ButtonFooter handleClickPre={handleClickPre} handleClickNext={handleClickNext} />
    </>
  );
};
export default AssignmentsControlGraph;
