import { TOptionsQueryExtra } from 'types/common';
import { TUserDetail } from 'types/groups';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  getUsers: ac<TOptionsQueryExtra<TUserDetail>>('getUsers'),
  getTeachers: ac<TOptionsQueryExtra<TUserDetail>>('getTeachers'),
  getStudents: ac<TOptionsQueryExtra<TUserDetail>>('getStudents'),
  createUsers: ac<TUserDetail & { groupId: number }>('createUsers'),
  updateUsers: ac<{ user: TUserDetail; groupId: number }>('updateUsers'),
  deleteUsers: ac<{ id: number; groupId: number }>('deleteUsers'),
};
