import { answersAction } from 'modules/answers/answers.action';
import React from 'react';
import { useDispatch } from 'react-redux';
import AssignmentsAnswerAfterOverview from './AssignmentsAnswerAfterOverview';

const AssignmentsAnswerAfterOverviewContainer = () => {
  const dispatch = useDispatch();

  const getTaskDetail = (id: string, groupId: number, isSaved: boolean) => {
    dispatch(answersAction.getDetailTaskForStudent({ id, isSaved, groupId }));
  };
  const getResultAssignment = (data: { id: string; sid: string }) => {
    dispatch(answersAction.getResultAssignmentOneStudent(data));
  };
  return <AssignmentsAnswerAfterOverview getTaskDetail={getTaskDetail} getResultAssignment={getResultAssignment} />;
};

export default AssignmentsAnswerAfterOverviewContainer;
