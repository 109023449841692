import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import templatesAction from 'modules/templates/templates.action';
import { TOptionsQueryExtra } from 'types/common';
import { TTemplateDetail } from 'types/templates';
import { templatesModule } from 'modules/templates/templates.reducer';
import TemplatesAdm from './TemplatesAdm';

const TemplatesContainer = () => {
  const dispatch = useDispatch();
  const templatesState = useSelector((state: AppState) => state.pages.templates);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const uiState = useSelector((state: AppState) => state.pages.ui);
  const getTemplatesFinished = (params: TOptionsQueryExtra<TTemplateDetail>) =>
    dispatch(templatesAction.getTemplates({ ...params, sortBy: 'id:desc' }));
  const getAllTemplatesUnFinished = (groupId: number) =>
    dispatch(templatesAction.getAllTemplates({ isCompleted: false, groupId }));
  const deleteTemplate = (data: { templateId: string; groupId: number }) =>
    dispatch(templatesAction.deleteTemplate(data));

  const clearErr = () => {
    dispatch(templatesModule.actions.setError(false));
  };
  return (
    <TemplatesAdm
      templatesState={templatesState}
      uiState={uiState}
      getTemplatesFinished={getTemplatesFinished}
      getAllTemplatesUnFinished={getAllTemplatesUnFinished}
      deleteTemplate={deleteTemplate}
      currentGroup={currentGroup}
      clearErr={clearErr}
    />
  );
};

export default TemplatesContainer;
