import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import AssignmentsControlQuestion from './AssignmentsControlQuestion';
import { answersAction } from '../../modules/answers/answers.action';
import { AppState } from '../../store';
import { TCtrlAnswer } from './AssignmentsControlQuestion.state';
import { TControlAnswer } from '../../types/assignments';

const AssignmentsControlQuestionContainer = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const getTaskDetail = (id: string, groupId: number) => {
    dispatch(answersAction.getDetailTaskForStudent({ id, isSaved: false, groupId }));
  };
  const targetMonth = useLocation<{ month: number }>();
  const checkFalse = (data: any) => data !== null && data !== undefined;
  const saveCtrlAnswers = (rawAnswers: TCtrlAnswer[]) => {
    const answers: TControlAnswer[] = rawAnswers.map((it) => {
      const dataAnswer = {
        alias: it.alias,
        factorial: it.factorial,
        morningData: checkFalse(it.morningData) ? parseFloat(it.morningData || '0') : null,
        afternoonData: checkFalse(it.noonData) ? parseFloat(it.noonData || '0') : null,
        beforeNightData: checkFalse(it.afternoonData) ? parseFloat(it.afternoonData || '0') : null,
        midnightData: checkFalse(it.nightData) ? parseFloat(it.nightData || '0') : null,
        unit: it.unit,
        month: it.month,
      };
      return dataAnswer;
    });
    dispatch(answersAction.saveControlAnswers({ id, answers }));
  };

  const answersState = useSelector((state: AppState) => state.pages.answers);
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);

  const getResults = (id: string, sid: string) => {
    dispatch(answersAction.getResultAssignmentOneStudent({ id, sid }));
  };
  return (
    <AssignmentsControlQuestion
      assignmentId={id}
      getTaskDetail={getTaskDetail}
      answersState={answersState}
      saveCtrlAnswers={saveCtrlAnswers}
      targetMonth={targetMonth.state ?? 10}
      currentGroup={currentGroup}
      getResults={getResults}
      currentUser={currentUser}
    />
  );
};

export default AssignmentsControlQuestionContainer;
