import React, { useEffect } from 'react';
import { Divider, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import TableResultBasicTask from 'components/TableResultBasicTask/TableResultBasicTask';
import TableResultDetail from 'components/TableResultDetail/TableResultDetail';
import styles from './style.module.scss';

type Props = {
  getTaskDetail: (id: string, groupId: number, isSaved: boolean) => void;
  getResultAssignment: (data: { id: string; sid: string }) => void;
};

const AssignmentsAnswerAfterOverview = ({ getTaskDetail, getResultAssignment }: Props) => {
  const history = useHistory();
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const { detailTask, resultAssignment } = useSelector((state: AppState) => state.pages.answers);
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);

  const { id }: { id: string } = useParams();
  useEffect(() => {
    if (id && currentGroup.id) getTaskDetail(id, currentGroup.id, false);
  }, []);

  useEffect(() => {
    if (currentUser.id) {
      getResultAssignment({
        id,
        sid: currentUser.id.toString(),
      });
    }
  }, []);

  const handelClickBack = () => {
    history.goBack();
  };

  const basicScore = resultAssignment.basicResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const eventScore = resultAssignment.eventResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const basicTaskResults = resultAssignment.basicResults?.map((e, i) => ({
    ...e,
    key: i,
  }));
  const eventTaskResults = resultAssignment.eventResults?.map((e, i) => ({
    ...e,
    key: i,
  }));
  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <div className={styles.title}>
          <Row>
            <p>栽培問題</p>
            <p className={styles.titleTime}>{detailTask.assignmentName}</p>
          </Row>
          <Row>
            <p className={styles.titleResult}>採点結果と回答解説</p>
          </Row>
        </div>

        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <Row>
              <Divider className={styles.divider} />
              <TableResultBasicTask
                maxScore={resultAssignment.maxScore}
                basicScore={basicScore}
                eventScore={eventScore}
              />
            </Row>
            <Row>
              <Row>
                <p className={styles.title}>解答解説</p>
                <p className={`${styles.title} ${styles.titleTable}`}>基本問題</p>
              </Row>
              <Divider className={styles.divider} />
              <TableResultDetail dataTable={basicTaskResults ?? []} title1="解答解説" title2="基本問題" />
            </Row>
            <Row>
              <Row>
                <p className={styles.title}>解答解説</p>
                <p className={`${styles.title} ${styles.titleTable}`}>イベント問題</p>
              </Row>
              <Divider className={styles.divider} />
              <TableResultDetail dataTable={eventTaskResults ?? []} title1="解答解説" title2="イベント問題" />
            </Row>
          </div>
        </div>
      </div>
      <ButtonFooter handleClickPre={handelClickBack} />
    </>
  );
};

export default AssignmentsAnswerAfterOverview;
