// Common
export const MESSAGE_ERROR_DEFAULT = 'データの更新に失敗しました。';
export const MESSAGE_SUCCESS_DEFAULT = 'データの更新が完了しました。';

// Auth
export const MESSAGE_CHANGE_PASSWORD_SUCCESS = 'パスワードの変更が完了しました。';
export const MESSAGE_FORGOT_PASSWORD_SUCCESS =
  '入力いただいたメールアドレス宛てへメールを送信しました。ご確認ください。';
export const MESSAGE_LOGIN_SUCCESS = 'ログインしました。';
export const MESSAGE_LOGOUT_SUCCESS = 'ログアウトしました。';

// Template
export const MESSAGE_ERROR_ADD_QUESTION_BASIC = '問題は最大12問まで登録できます。';
export const MESSAGE_ERROR_ADD_QUESTION_EVENT = '問題は最大11問まで登録できます。';
// Upload
export const MESSAGE_ERROR_FILE_TOO_LARGE = 'アップロードするファイルサイズが大きすぎます。';
