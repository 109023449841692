import { TStep6 } from 'types/template';

export type TStep6State = {
  step6: TStep6[];
};
export const data: TStep6[] = [
  {
    factorial: '天窓',
    alias: '換気温度',
    properRange: 2.0,
    lowerLimit: 0,
    upperLimit: 10,
    stepValue: 1.0,
    unit: '℃',
  },
  {
    factorial: '天窓',
    alias: '換気開度',
    properRange: 5.0,
    lowerLimit: 0,
    upperLimit: 10,
    stepValue: 1.0,
    unit: '%',
  },
  {
    factorial: '重油炊き暖房',
    alias: '暖房温度',
    properRange: 2.0,
    lowerLimit: 0,
    upperLimit: 10,
    stepValue: 1.0,
    unit: '℃',
  },
  {
    factorial: '湿度管理',
    alias: '目標湿度',
    properRange: 10.0,
    lowerLimit: 0,
    upperLimit: 20,
    stepValue: 5.0,
    unit: '%',
  },
  {
    factorial: '保温カーテン',
    alias: '保温温度',
    properRange: 2.0,
    lowerLimit: 0,
    upperLimit: 10,
    stepValue: 1.0,
    unit: '℃',
  },
  {
    factorial: '保温カーテン',
    alias: '保温感度',
    properRange: 5.0,
    lowerLimit: 0,
    upperLimit: 10,
    stepValue: 1.0,
    unit: '℃ / 100%',
  },
  {
    factorial: '炭酸ガス',
    alias: 'CO2濃度設定',
    properRange: 200.0,
    lowerLimit: 0,
    upperLimit: 500,
    stepValue: 50.0,
    unit: 'ppm',
  },
  {
    factorial: '循環扇（ダクトファン）',
    alias: '循環扇タイマー',
    properRange: 0,
    lowerLimit: 0,
    upperLimit: 10,
    stepValue: 1.0,
    unit: '',
  },
  {
    factorial: 'かん水装置',
    alias: '１株あたりの１日かん水量',
    properRange: 0.3,
    lowerLimit: 0,
    upperLimit: 10,
    stepValue: 0.1,
    unit: 'L',
  },
];
