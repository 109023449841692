import { TOptionsQuery } from 'types/common';
import { TControlResultsDetail } from 'types/controlResults';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  getControlResults: ac<TOptionsQuery<TControlResultsDetail>>('getControlResults'),
  getControlResultsDetail: ac<{ ctrRsId: number; groupId: number }>('getControlResultsDetail'),
  createControlResults: ac<{ name?: string; fname?: string; groupId: number }>('createControlResults'),
  updateControlResults: ac<{ ctrRsId: number; name?: string; fname?: string; groupId: number }>('updateControlResults'),
  deleteControlResults: ac<{ ctrRsId: number; groupId: number }>('deleteControlResults'),
  uploadControlResultsFile: ac<FormData>('uploadControlResultsFile'),
  downloadControlResultsFile: ac<{ ctrRsId: number; groupId: number }>('downloadControlResultsFile'),
};
