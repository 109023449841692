import React from 'react';
import { Form } from 'antd';
import type { FormInstance } from 'antd/es/form';

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<{ index: number }> = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRow;
