import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getTemplate: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/templates',
};
const getTemplateById: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/templates/:id',
};
const getAllTemplates: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates/all',
};
const createTemplate: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/templates',
};
const updateTemplate: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/templates/:id',
};
const deleteTemplate: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/templates/:id',
};
const uploadCsvFile: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates/:id/file',
};
const downloadCsvFile: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates/:id/file',
};
export const templateRepositories = {
  getTemplate: apiClient.getFn(getTemplate),
  getAllTemplates: apiClient.getFn(getAllTemplates),
  getTemplateById: apiClient.getFn(getTemplateById),
  createTemplate: apiClient.postFn(createTemplate),
  updateTemplate: apiClient.patchFn(updateTemplate),
  deleteTemplate: apiClient.deleteFn(deleteTemplate),
  uploadCsvFile: apiClient.postFn(uploadCsvFile),
  downloadCsvFile: apiClient.getFn(downloadCsvFile),
};
