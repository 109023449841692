import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const uploadImage: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/images',
};

export const uploadRepositories = {
  uploadImage: apiClient.postFn(uploadImage),
};
