import React from 'react';
import { Row } from 'antd';
import { getAssignmentName } from 'libs/helpers/localStorageFn';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import styles from './style.module.scss';

type Props = {
  handleNextPage: () => void;
  handleBackPage: () => void;
};
const AssignmentsStartRules = ({ handleBackPage, handleNextPage }: Props) => (
  <>
    <div className={styles.assignmentsRfpWrap}>
      <Row className={styles.titleWrapper}>
        <p>栽培ルール</p>
        <p className={styles.titleTime}>{getAssignmentName()}</p>
      </Row>
      <div className={styles.contentWrap}>
        <h2>ここからは栽培ルールの説明です</h2>
      </div>
    </div>
    <ButtonFooter handleClickNext={handleNextPage} handleClickPre={handleBackPage} />;
  </>
);

export default AssignmentsStartRules;
