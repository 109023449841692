import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { groupsClassServices } from 'services/groupsClass';
import { AxiosResponse, TResponsePagination } from 'libs/axios';
import { messageModule } from 'modules/message/message.reducer';
import { TGroupsClassDetail } from 'types/groupsClass';
import { push } from 'react-router-redux';
import { EPath } from 'constants/routes';
import { groupsClassModule } from './groupsClass.reducer';
import actions from './groupsClass.action';

export const getGroupsClassEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getGroupsClass),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsClassModule.actions.loading(),
        asyncFns: from(groupsClassServices.getGroups(payload)),
        next: (u: TResponsePagination<TGroupsClassDetail>) => [groupsClassModule.actions.getListGroups(u)],
        complete: groupsClassModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getGroupsClassModalEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getGroupsClassModal),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsClassModule.actions.loading(),
        asyncFns: from(groupsClassServices.getGroups(payload)),
        next: (u: TResponsePagination<TGroupsClassDetail>) => [groupsClassModule.actions.getListGroupsModal(u)],
        complete: groupsClassModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createGroupsClassEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.createGroupsClass),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsClassModule.actions.loading(),
        asyncFns: from(groupsClassServices.createGroups(payload)),
        next: (u: TGroupsClassDetail) => [
          groupsClassModule.actions.saveListGroups(u),
          push(EPath.S3_1),
          messageModule.actions.setSuccess(''),
        ],
        complete: groupsClassModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateGroupsClassEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateGroupsClass),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsClassModule.actions.loading(),
        asyncFns: from(groupsClassServices.updateGroups(payload)),
        next: (u: TGroupsClassDetail) => [
          groupsClassModule.actions.updateListGroups(u),
          push(EPath.S3_1),
          messageModule.actions.setSuccess(''),
        ],
        complete: groupsClassModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const deleteGroupsClassEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.deleteGroupsClass),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsClassModule.actions.loading(),
        asyncFns: from(groupsClassServices.deleteGroups(payload)),
        next: (u: number) => [groupsClassModule.actions.removeGroups(u), messageModule.actions.setSuccess('')],
        complete: groupsClassModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getDetailGroupClassEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getDetailGroupClass),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsClassModule.actions.loading(),
        asyncFns: from(groupsClassServices.getDetailGroup(payload)),
        next: (u: TGroupsClassDetail) => [groupsClassModule.actions.saveDetailGroups(u)],
        complete: groupsClassModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
