export const configBulletChart = (score: number = 10, title: string) => {
  let a = '';
  let text = '';
  if (score >= 50) {
    a = 'blue';
    text = `${title}良`;
  } else if (score >= 25 && score < 50) {
    a = 'yellow';
    text = `${title}注`;
  } else {
    a = ' red';
    text = `${title}危`;
  }
  const data = [
    {
      title: text,
      ranges: [40, 70, 100],
      measures: [score],
      target: 100,
    },
  ];
  const configScore = {
    data,
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'target',
    xField: 'title',
    color: {
      range: ['#dbd2d2'],
      measure: a,
      target: '#39a3f4',
    },
    xAxis: {
      line: null,
    },
    yAxis: false,
    label: {
      target: true,
    },
    legend: false,
  };
  return { configScore };
};
