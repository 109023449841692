import React from 'react';
import { Link } from 'react-router-dom';
import { TAnswerAQuestion } from 'types/assignments';
import { replacePathParams } from 'libs/helpers/functions';
import { EPath } from 'constants/routes';
import styles from './style.module.scss';

type Props = {
  dataQuestion: Array<TAnswerAQuestion>;
  quesId?: string;
  id?: string;
  type: 'basic' | 'event';
};
const NavQuestion = ({ dataQuestion, quesId, type, id }: Props) => {
  const styleStatus = (stepData: TAnswerAQuestion) => {
    if (stepData.questionId === quesId) {
      return styles.current;
    }
    if (stepData.selectedOption === 0) {
      return styles.missing;
    }
    if (stepData.selectedOption === null) {
      return '';
    }
    return styles.active;
  };
  const url = type === 'basic' ? EPath.U7_BASIC : EPath.U7_EVENT;

  return (
    <ul className={styles.stepWrap}>
      {dataQuestion &&
        dataQuestion.map((step) => (
          <Link
            key={step.questionId}
            to={replacePathParams(url, {
              id: id ?? '0',
              quesId: step.questionId ?? '',
            })}
          >
            <li className={`${styles.step} ${styleStatus(step)}`}> </li>
          </Link>
        ))}
    </ul>
  );
};

export default NavQuestion;
