import groupsClassAction from 'modules/groupsClass/groupsClass.action';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { TGroupsClassDetail } from 'types/groupsClass';
import GroupsDetails from './GroupsDetails';

const GroupsDetailsContainer = () => {
  const dispatch = useDispatch();
  const groupsClassState = useSelector((state: AppState) => state.pages.groupsClass);
  const { id } = useParams<{ id: string }>();
  const handleSubmit = (data: TGroupsClassDetail) => {
    if (id) {
      return dispatch(groupsClassAction.updateGroupsClass({ ...data, id: Number(id) }));
    }
    return dispatch(groupsClassAction.createGroupsClass(data));
  };

  const getDetailgroup = () => {
    if (id) {
      dispatch(groupsClassAction.getDetailGroupClass(Number(id)));
    }
  };

  return (
    <GroupsDetails
      handleSubmit={handleSubmit}
      getDetailgroup={getDetailgroup}
      idGroup={id}
      groupsClassState={groupsClassState}
    />
  );
};

export default GroupsDetailsContainer;
