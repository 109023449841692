export const EPath = {
  root: '/',
  login: '/login',
  forgotPassword: '/password/forgot',
  resetPassword: '/password/reset',
  changePassword: '/password/change',
  A1: '/',
  A2: '/adm/users',
  A3: '/adm/templates',
  A4_1_Create: '/adm/templates/information',
  A4_1: '/adm/templates/information/:id',
  A4_2: '/adm/templates/cultivation/basics/:id',
  A4_3: '/adm/templates/cultivation/events/:id',
  A4_4: '/adm/templates/control/questions/:id',
  A4_5: '/adm/templates/control/simulation/:id',
  A4_6_NEW: '/adm/templates/cultivation/add/:id',
  A4_6: '/adm/templates/cultivation/simulation/:id',
  A5: '/adm/assignments',
  A6_1: '/adm/assignment/:id',
  A6_2: '/adm/assignments/add',
  A7: '/adm/result/:id',
  // id: id of assignment, sid: id of student
  A8: '/adm/assignments/:id/results/:sid',
  A8_1: '/adm/assignments/:id/results/:sid/control',
  A8_2: '/adm/assignments/:id/results/:sid/cultivate',
  A8_3: '/adm/assignments/:id/results/:sid/overview',
  A9_1: '/adm/control_results',
  A9_2: '/adm/control_results/add',
  A9_3: '/adm/control_results/:id/edit',
  // Student
  U3: '/',
  U5_1: '/assignments/:id/start',
  U5_2: '/assignments/:id/explanation',
  U6_1: '/assignments/:id/rule',
  U6_2: '/assignments/:id/information',
  U7_BASIC: '/assignments/:id/cultivation/basic/:quesId',
  U7_EVENT: '/assignments/:id/cultivation/event/:quesId',
  U7_RESULTS: '/assignments/:id/cultivation/results',
  U8_1_2: '/assignments/:id/control/questions',
  U8_3: '/assignments/:id/control/graph',
  U9: '/assignments/:id/control/result',
  U10: '/assignments/:id/score',
  U10_1: '/assignments/:id/score/total',
  U10_2: '/assignments/:id/score/cultivation',
  U10_3: '/assignments/:id/score/control',
  U11: '/assignments/:id/information/answering',
  U12: '/assignments/:id/cultivation/results/answering',
  // Adminstrator
  S1_TOP: '/adm',
  S3_1: '/adm/groups',
  S3_2: '/adm/groups/add',
  S3_3: '/adm/groups/:id/edit',
};
