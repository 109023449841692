import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Select, DatePicker, Table, Space, Checkbox } from 'antd';
import { defaultValidateMessages } from 'constants/validate/message';
import { TOptionsQuery } from 'types/common';
import { TGroupsState } from 'modules/groups/groups.reducer';
import { TUiState } from 'modules/ui/ui.reducer';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { TTrainingDetail } from 'types/assignments';
import { TAssignmentsState } from 'modules/assignments/assignments.reducer';
import { TTemplatesState } from 'modules/templates/templates.reducer';
import { convertArrayStringToNumber, getListUserDetailByIds } from 'libs/helpers/functions';
import { DEFAULT_FORMAT_DATE, disabledDateBeforeToday } from 'constants/date';
import { Link } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { TUserDetail } from 'types/groups';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import moment from 'moment';
import type { Dayjs } from 'dayjs';
import { TGroupsClassDetail } from 'types/groupsClass';
import PreviewUsersTraining from './PreviewUsersTraining';
import { columnsTableUsers } from './AssignmentDetailAdm.state';
import styles from './style.module.scss';

const { RangePicker } = DatePicker;

type Props = {
  getStudents: (params: TOptionsQuery) => void;
  getTeachers: (params: TOptionsQuery) => void;
  getAllUsers: (groupId: number) => void;
  groupsState: TGroupsState;
  uiState: TUiState;
  createTraining: (t: TTrainingDetail & { groupId: number }) => void;
  trainingId: string;
  assignmentsState: TAssignmentsState;
  getTraining: (data: { id: string; groupId: number }) => void;
  updateTraining: (t: TTrainingDetail & { groupId: number }) => void;
  getAllTemplatesFinished: (groupId: number) => void;
  templatesState: TTemplatesState;
  currentGroup: TGroupsClassDetail;
};

const AssignmentDetailAdm = ({
  getStudents,
  getTeachers,
  groupsState,
  uiState,
  createTraining,
  trainingId,
  assignmentsState,
  updateTraining,
  getTraining,
  getAllTemplatesFinished,
  templatesState,
  getAllUsers,
  currentGroup,
}: Props) => {
  const [form] = Form.useForm();
  const [selectedStudentKeys, setSelectedStudentKeys] = useState<React.Key[] | undefined>([]);
  const [selectedTeacherKeys, setSelectedTeacherKeys] = useState<React.Key[] | undefined>([]);

  const { usersByRole, users } = groupsState;
  const { isShowLoading } = uiState;
  const { trainingDetail } = assignmentsState;
  const { templatesAll } = templatesState;

  useEffect(() => {
    if (trainingId && getTraining && currentGroup.id) {
      getTraining({ id: trainingId, groupId: currentGroup.id });
    }
    if (currentGroup.id) {
      getTeachers({ groupId: currentGroup.id });
      getStudents({ groupId: currentGroup.id });
      getAllUsers(currentGroup.id);
      getAllTemplatesFinished(currentGroup.id);
    }
  }, [currentGroup.id]);

  useEffect(() => {
    if (trainingId && trainingDetail.id) {
      setSelectedStudentKeys(trainingDetail.studentIds ?? []);
      setSelectedTeacherKeys(trainingDetail.teacherIds ?? []);
      form.setFieldsValue({
        ...trainingDetail,
        date: [moment(trainingDetail.startDate), moment(trainingDetail.endDate)],
      });
    }
  }, [trainingDetail]);
  // STUDENT SELECT
  const onSelectStudent = (record: TUserDetail, isChecked: boolean) => {
    if (isChecked && record.id) {
      const newListStudentKeys = [...(selectedStudentKeys ?? []), record.id];
      setSelectedStudentKeys(newListStudentKeys);
    } else {
      const remainListStudentKeys = selectedStudentKeys?.filter((e) => e !== record.id);
      setSelectedStudentKeys(remainListStudentKeys);
    }
  };

  const onSelectAllStudent = (isSelectAll: boolean, listRecord: TUserDetail[], listRecordChange: TUserDetail[]) => {
    const allStudentKeys = listRecord.filter((e) => e !== undefined).map((r) => r.id ?? 0);
    if (isSelectAll) {
      const newListStudentKeys = [...(selectedStudentKeys ?? [])];
      allStudentKeys.forEach((e) => {
        if (!selectedStudentKeys?.includes(e)) {
          newListStudentKeys.push(e);
        }
      });
      setSelectedStudentKeys(newListStudentKeys);
    } else {
      const listStudentKeyChange = listRecordChange.map((e) => e.id);
      const listStudentKeysRemain = selectedStudentKeys?.filter((r) => !listStudentKeyChange.includes(Number(r)));
      setSelectedStudentKeys(listStudentKeysRemain);
    }
  };
  //  TEACHER SELECT
  const onSelectTeacher = (record: TUserDetail, isChecked: boolean) => {
    if (isChecked && record.id) {
      const newListTeacherKeys = [...(selectedTeacherKeys ?? []), record.id];
      setSelectedTeacherKeys(newListTeacherKeys);
    } else {
      const remainListTeacherKeys = selectedTeacherKeys?.filter((e) => e !== record.id);
      setSelectedTeacherKeys(remainListTeacherKeys);
    }
  };

  const onSelectAllTeacher = (isSelectAll: boolean, listRecord: TUserDetail[], listRecordChange: TUserDetail[]) => {
    const allTeacherKeys = listRecord.filter((e) => e !== undefined).map((r) => r.id ?? 0);
    if (isSelectAll) {
      const newListTeacherKeys = [...(selectedTeacherKeys ?? [])];
      allTeacherKeys.forEach((e) => {
        if (!selectedTeacherKeys?.includes(e)) {
          newListTeacherKeys.push(e);
        }
      });
      setSelectedTeacherKeys(newListTeacherKeys);
    } else {
      const listTeacherKeyChange = listRecordChange.map((e) => e.id);
      const listTeacherKeysRemain = selectedTeacherKeys?.filter((r) => !listTeacherKeyChange.includes(Number(r)));
      setSelectedTeacherKeys(listTeacherKeysRemain);
    }
  };

  const onChangePageStudent = (page: number, limit: number) => {
    if (currentGroup.id) getStudents({ page, limit, groupId: currentGroup.id });
  };

  const onChangePageTeacher = (page: number, limit: number) => {
    if (currentGroup.id) getTeachers({ page, limit, groupId: currentGroup.id });
  };

  const onFinish = (values: TTrainingDetail & { date: Array<Dayjs> }) => {
    const newValues = {
      name: values.name,
      templateId: values.templateId,
      startDate: values.date[0].format(DEFAULT_FORMAT_DATE),
      endDate: values.date[1].format(DEFAULT_FORMAT_DATE),
      studentIds: convertArrayStringToNumber(selectedStudentKeys),
      teacherIds: convertArrayStringToNumber(selectedTeacherKeys),
      enabledDemonstrationMode: values.enabledDemonstrationMode ?? false,
    };
    if (createTraining && !trainingId) {
      createTraining({ ...newValues, groupId: currentGroup.id ?? 0 });
    }
    if (updateTraining && trainingId) {
      updateTraining({ id: trainingDetail.id, ...newValues, groupId: currentGroup.id ?? 0 });
    }
  };

  const disabledSubmit = isShowLoading || !selectedStudentKeys?.length || (!!trainingId && !trainingDetail.id);

  return (
    <div className="groupsAdm">
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>{trainingId ? '研修詳細・編集' : '研修登録'}</p>
            </div>
          }
          loading={isShowLoading}
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.topButtonGroup}>
                <Button className={styles.topButton}>
                  <Link to={EPath.A5}>研修一覧に戻る</Link>
                </Button>
              </Space>
              <Form
                autoComplete="off"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                validateMessages={defaultValidateMessages}
                disabled={isShowLoading}
              >
                <Form.Item>
                  <Space direction="vertical" align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>研修名</p>
                    <Form.Item name="name" rules={[{ required: true, whitespace: true }]} noStyle>
                      <Input className={styles.assignmentItemWidth} placeholder="example" />
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item>
                  <Space direction="vertical" align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>実施期間</p>
                    <Form.Item name="date" rules={[{ type: 'array' as const, required: true }]} noStyle>
                      <RangePicker
                        locale={locale}
                        format={DEFAULT_FORMAT_DATE}
                        disabledDate={disabledDateBeforeToday}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>

                <Form.Item>
                  <Space direction="vertical" align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>研修テンプレート</p>
                    <p className={styles.assignmentTitle}>※研修の設問や試算の係数の設定テンプレートです。</p>
                    <Form.Item
                      name="templateId"
                      rules={[{ required: true, message: 'この項目は必須項目です。' }]}
                      noStyle
                    >
                      <Select className={styles.assignmentItemWidth}>
                        {templatesAll.map((t) => (
                          <Select.Option key={t.id} value={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space>
                </Form.Item>

                <Form.Item>
                  <Space direction="vertical" align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>
                      研修デモンストレーションモードを有効にする。
                      <br />
                      ※この設定を有効にすると、正解の表示後に設問の回答を修正することができます。
                    </p>
                    <Form.Item name="enabledDemonstrationMode" noStyle valuePropName="checked">
                      <Checkbox>有効にする</Checkbox>
                    </Form.Item>
                  </Space>
                </Form.Item>
                <div className={styles.body}>
                  <p>
                    研修講師の設定 <br />
                    ※研修を担当するユーザーを選択してください。
                  </p>
                  <Table
                    rowSelection={{
                      selectedRowKeys: selectedTeacherKeys,
                      onSelect: onSelectTeacher,
                      onSelectAll: onSelectAllTeacher,
                    }}
                    dataSource={usersByRole.teachers.results}
                    columns={columnsTableUsers}
                    rowKey="id"
                    pagination={false}
                    scroll={{ y: 200 }}
                  />
                  <div className={styles.pagination}>
                    <PaginationCustom
                      current={usersByRole.teachers.pagination.page}
                      total={usersByRole.teachers.pagination.totalResults}
                      pageSize={usersByRole.teachers.pagination.limit}
                      disabled={isShowLoading}
                      onChange={onChangePageTeacher}
                      showQuickJumper={false}
                      showSizeChanger={false}
                      showLessItems={false}
                      showTotal={false}
                      hideOnSinglePage
                    />
                  </div>
                  <p>※選択中の受講者一覧（上で選択したユーザーの一覧です）</p>
                  <PreviewUsersTraining selectedUsers={getListUserDetailByIds(selectedTeacherKeys, users)} />

                  <p>
                    研修受講者の設定
                    <br />
                    ※研修を受講するユーザーを選択してください。
                  </p>
                  <Table
                    rowSelection={{
                      selectedRowKeys: selectedStudentKeys,
                      // onChange: onSelectStudent,
                      onSelect: onSelectStudent,
                      onSelectAll: onSelectAllStudent,
                    }}
                    dataSource={usersByRole.students.results}
                    columns={columnsTableUsers}
                    rowKey="id"
                    pagination={false}
                    scroll={{ y: 200 }}
                  />
                  <div className={styles.pagination}>
                    <PaginationCustom
                      current={usersByRole.students.pagination.page}
                      total={usersByRole.students.pagination.totalResults}
                      pageSize={usersByRole.students.pagination.limit}
                      disabled={isShowLoading}
                      onChange={onChangePageStudent}
                      showQuickJumper={false}
                      showSizeChanger={false}
                      showLessItems={false}
                      showTotal={false}
                      hideOnSinglePage
                    />
                  </div>
                  <PreviewUsersTraining selectedUsers={getListUserDetailByIds(selectedStudentKeys, users)} />
                </div>
                <Form.Item noStyle>
                  <button disabled={disabledSubmit} type="submit" className={styles.confirmButton}>
                    保存する
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AssignmentDetailAdm;
