import React from 'react';
import TemplateInfoContainer from 'pages/TemplateInfo/TemplateInfo.container';
import AdmTempBasicContainer from 'pages/AdmTempBasic/AdmTempBasic.container';
import AdmTempEventContainer from 'pages/AdmTempEvent/AdmTempEvent.container';
import AdmTempQuestionContainer from 'pages/AdmTempQuestion/AdmTempQuestion.container';
import AdmTempControlSimulationContainer from 'pages/AdmTempControlSimulation/AdmTempControlSimulation.container';
import AdmTempCultivateSimulationContainer from 'pages/AdmTempCultivateSimulation/AdmTempCultivateSimulation.container';
import AssignmentsBasicQuestionContainer from 'pages/AssignmentsBasicQuestion/AssignmentsBasicQuestion.container';
import AssignmentsEventQuestionContainer from 'pages/AssignmentsEventQuestion/AssignmentsEventQuestion.container';
import AdmSampleDataSimulationContainer from 'pages/AdmSampleDataSimulation/AdmSampleDataSimulation.container';
import AssignmentsControlQuestionContainer from 'pages/AssignmentsControlQuestion/AssignmentsControlQuestion.container';
import AssignmentsControlGraphContainer from 'pages/AssignmentsControlGraph.tsx/AssignmentsControlGraph.container';
import AssignmentsRfpContainer from 'pages/AssignmentsRfp/AssignmentsRfp.container';
import LoginContainer from 'pages/Login/Login.container';
import ResetPasswordContainer from 'pages/ResetPassword/ResetPassword.container';
import TopContainer from 'pages/Top/Top.container';
import TopAdmContainer from 'pages/TopAdm/TopAdm.container';
import AssignmentsStartContainer from 'pages/AssignmentsStart/AssignmentsStart.container';
import GroupsAdmContainer from 'pages/GroupsAdm/GroupsAdm.container';
import TemplatesAdmContainer from 'pages/TemplatesAdm/TemplatesAdm.container';
import AssignmentsAdmContainer from 'pages/AssignmentsAdm/AssignmentsAdm.container';
import ChangePasswordContainer from 'pages/ChangePassword/ChangePassword.container';
import AssignmentDetailAdmContainer from 'pages/AssignmentDetailAdm/AssignmentDetailAdm.container';
import AssignmentsResultAdmContainer from 'pages/AssignmentsResultAdm/AssignmentsResultAdm.container';
import AssignmentsRfpAboutToolContainer from 'pages/AssignmentsRfpAboutTool/AssignmentsRfpAboutTool.container';
import AssignmentResultStudentsContainer from 'pages/AssignmentResultStudentsU8/AssignmentResultStudents.container';
import AdmControlResultContainer from 'pages/AdmControlResult/AdmControlResult.container';
import AdmControlResultDetailContainer from 'pages/AdmControlResultDetail/AdmControlResultDetail.container';
// import { TRoleUser } from 'types/auth';
import AssignmentsAnswerOverviewContainer from 'pages/AssignmentsAnswerOverview/AssignmentsAnswerOverview.container';
import AssignmentsRulesContainer from 'pages/AssignmentsRules/AssignmentsRules.container';
import AssignmentsAnswerAfterOverviewContainer from 'pages/AssignmentsAnswerAfterOverview/AssignmentsAnswerAfterOverview.container';
import AssignmentsResultSimulationContainer from 'pages/AssignmentsResultSimulation/AssignmentsResultSimulation.container';
import AssignmentsStartRulesContainer from 'pages/AssignmentsStartRules/AssignmentsStartRules.container';
import ForgotPasswordContainer from 'pages/ForgotPassword/ForgotPassword.container';
import AssignmentsResultStudentOverviewContainer from 'pages/AssignmentsResultStudentOverview/AssignmentsResultStudentOverview.container';
import GroupsManageContainer from 'pages/Adminstrator/GroupsManage/GroupsManage.container';
import GroupsDetailsContainer from 'pages/Adminstrator/GroupsDetails/GroupsDetails.container';
import { ERole } from 'constants/enums/common';
import { EPath } from './routes';
import { IndexedObject } from './types';

type TRouterConfig = {
  name: string;
  component: JSX.Element;
  path: string;
  exact: boolean;
  public: boolean;
  role: ERole[];
  isShowFooter: boolean;
  isShowLeftMenu: boolean;
};

export const routers: IndexedObject<TRouterConfig> = {
  U1: {
    name: 'U1',
    component: <LoginContainer />,
    path: EPath.login,
    exact: true,
    public: true,
    role: [ERole.Student, ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: false,
  },
  forgotPassword: {
    name: 'forgotPassword',
    component: <ForgotPasswordContainer />,
    path: EPath.forgotPassword,
    exact: true,
    public: true,
    role: [ERole.Student, ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: false,
  },
  resetPassword: {
    name: 'resetPassword',
    component: <ResetPasswordContainer />,
    path: EPath.resetPassword,
    exact: true,
    public: true,
    role: [ERole.Student, ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: false,
  },
  U2_1: {
    name: 'U2_1',
    component: <ChangePasswordContainer />,
    path: EPath.changePassword,
    exact: true,
    public: false,
    role: [ERole.Student, ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U3: {
    name: 'U3',
    component: <TopContainer />,
    path: EPath.U3,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U5_1: {
    name: 'U5_1',
    component: <AssignmentsRfpAboutToolContainer />,
    path: EPath.U5_1,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U5_2: {
    name: 'U5_2',
    component: <AssignmentsRfpContainer />,
    path: EPath.U5_2,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U6_1: {
    name: 'U6_1',
    component: <AssignmentsStartRulesContainer />,
    path: EPath.U6_1,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U6_2: {
    name: 'U6_2',
    component: <AssignmentsStartContainer />,
    path: EPath.U6_2,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_BASIC: {
    name: 'U7_basic',
    component: <AssignmentsBasicQuestionContainer />,
    path: EPath.U7_BASIC,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_EVENT: {
    name: 'U7_event',
    component: <AssignmentsEventQuestionContainer />,
    path: EPath.U7_EVENT,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_RESULTS: {
    name: 'U7_7_results',
    component: <AssignmentsAnswerOverviewContainer />,
    path: EPath.U7_RESULTS,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U8_CONTROL: {
    name: 'U8_control_tasks',
    component: <AssignmentsControlQuestionContainer />,
    path: EPath.U8_1_2,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U8_3: {
    name: 'U8_3',
    component: <AssignmentsControlGraphContainer />,
    path: EPath.U8_3,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U9: {
    name: 'U9',
    component: <AssignmentsResultSimulationContainer />,
    path: EPath.U9,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U10: {
    name: 'U10',
    component: <AssignmentsResultStudentOverviewContainer />,
    path: EPath.U10,
    exact: false,
    public: false,
    role: [ERole.Student],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U11: {
    name: 'U11',
    component: <AssignmentsRulesContainer />,
    path: EPath.U11,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U12: {
    name: 'U12',
    component: <AssignmentsAnswerAfterOverviewContainer />,
    path: EPath.U12,
    exact: true,
    public: false,
    role: [ERole.Student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A1: {
    name: 'A1',
    component: <TopAdmContainer />,
    path: EPath.A1,
    exact: true,
    public: true,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A2: {
    name: 'A2',
    component: <GroupsAdmContainer />,
    path: EPath.A2,
    exact: true,
    public: false,
    role: [],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A3: {
    name: 'A3',
    component: <TemplatesAdmContainer />,
    path: EPath.A3,
    exact: true,
    public: false,
    role: [],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A4_1_Create: {
    name: 'A4_1_Create',
    component: <TemplateInfoContainer />,
    path: EPath.A4_1_Create,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_1: {
    name: 'A4_1',
    component: <TemplateInfoContainer />,
    path: EPath.A4_1,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_2: {
    name: 'A4_2',
    component: <AdmTempBasicContainer />,
    path: EPath.A4_2,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_3: {
    name: 'A4_3',
    component: <AdmTempEventContainer />,
    path: EPath.A4_3,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_4: {
    name: 'A4_4',
    component: <AdmTempQuestionContainer />,
    path: EPath.A4_4,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_5: {
    name: 'A4_5',
    component: <AdmTempControlSimulationContainer />,
    path: EPath.A4_5,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_6_NEW: {
    name: 'A4_6_NEW',
    component: <AdmSampleDataSimulationContainer />,
    path: EPath.A4_6_NEW,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_6: {
    name: 'A4_6',
    component: <AdmTempCultivateSimulationContainer />,
    path: EPath.A4_6,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A5: {
    name: 'A5',
    component: <AssignmentsAdmContainer />,
    path: EPath.A5,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A6_1: {
    name: 'A6_1',
    component: <AssignmentDetailAdmContainer />,
    path: EPath.A6_1,
    exact: true,
    public: false,
    role: [],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A6_2: {
    name: 'A6_2',
    component: <AssignmentDetailAdmContainer />,
    path: EPath.A6_2,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A7: {
    name: 'A7',
    component: <AssignmentsResultAdmContainer />,
    path: EPath.A7,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A8: {
    name: 'A8',
    component: <AssignmentResultStudentsContainer />,
    path: EPath.A8,
    exact: false,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A9_1: {
    name: 'A9_1',
    component: <AdmControlResultContainer />,
    path: EPath.A9_1,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A9_2: {
    name: 'A9_2',
    component: <AdmControlResultDetailContainer />,
    path: EPath.A9_2,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A9_3: {
    name: 'A9_3',
    component: <AdmControlResultDetailContainer />,
    path: EPath.A9_3,
    exact: true,
    public: false,
    role: [ERole.Teacher, ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  // Adminstrator
  S1_TOP: {
    name: 'S1_TOP',
    component: <TopAdmContainer />,
    path: EPath.S1_TOP,
    exact: true,
    public: false,
    role: [ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S3_1: {
    name: 'S3_1',
    component: <GroupsManageContainer />,
    path: EPath.S3_1,
    exact: true,
    public: false,
    role: [ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S3_2: {
    name: 'S3_2',
    component: <GroupsDetailsContainer />,
    path: EPath.S3_2,
    exact: true,
    public: false,
    role: [ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S3_3: {
    name: 'S3_3',
    component: <GroupsDetailsContainer />,
    path: EPath.S3_3,
    exact: true,
    public: false,
    role: [ERole.Admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
};
