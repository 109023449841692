import React from 'react';
import { TUserDetail } from 'types/groups';

export type EditableCellProps = {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'select' | 'text';
  record: string;
  index: number;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

export const optionsValue = [
  {
    name: '生徒',
    value: 'student',
  },
  {
    name: '講師',
    value: 'teacher',
  },
];

export const isEditingUser = (record: TUserDetail, editingKey: number) => record.id === editingKey;

export type TypeCreateUser = {
  name: string;
  email: string;
  role: string;
  password: string;
};

export type TypeUpdateUser = {
  data: TUserDetail;
  id: number;
};

export type TOpenModal = '' | 'add' | 'delete' | 'noti-create';
