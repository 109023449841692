import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { navStep } from 'constants/nav';
import EditableRow from 'components/EditTableColRow/EditableRow';
import { ColumnTypes, TStep4 } from 'types/template';
import { TTemplateState } from 'modules/template/template.reducer';
import EditTemplate from 'hook/EditTemplate';
import EditableCell from 'components/EditTableColRow/EditableCellProps';
import { Link } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { TGroupsClassDetail } from 'types/groupsClass';
import useGetTemplateLs from 'hook/useGetTemplateLs';
import { data, TStep4State } from './AdmTempQuestion.state';
import styles from './style.module.scss';

type TProps = {
  template: TTemplateState;
  currentGroup: TGroupsClassDetail;
  updateTemplate: (
    data: TStep4State,
    id: number,
    groupId: number,
    buttonFooterNext?: boolean,
    buttonFooterPre?: boolean,
  ) => void;
  saveTemplate: (data: TTemplateState) => void;
};
const AdmTempQuestion = ({ updateTemplate, template, currentGroup, saveTemplate }: TProps) => {
  const [dataSource, setDataSource] = useState<TStep4[] | []>([]);
  const [dataTemp] = useGetTemplateLs();

  EditTemplate();

  useEffect(() => {
    if (template.id && template.step4 && template.step4.length > 0) {
      setDataSource([...template.step4]);
    } else {
      setDataSource(data);
    }
  }, [template]);

  useEffect(() => {
    if (Object.keys(dataTemp).length > 0) {
      saveTemplate(dataTemp);
    }
  }, []);
  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: '月',
      dataIndex: 'month',
      align: 'center',
      width: '5%',
    },
    {
      title: '問題文',
      dataIndex: 'question',
      width: '35%',
      editable: true,
      align: 'center',
    },
    {
      title: '晴れ日数',
      dataIndex: 'sunnyDay',
      editable: true,
      align: 'center',
      width: '10%',
    },
    {
      title: '曇り日数',
      dataIndex: 'cloudyDay',
      align: 'center',
      editable: true,
      width: '10%',
    },
    {
      title: '雨日数',
      dataIndex: 'rainyDay',
      align: 'center',
      editable: true,
      width: '10%',
    },
    {
      title: '平均気温',
      dataIndex: 'avgTemp',
      align: 'center',
      editable: true,
      width: '10%',
    },
    {
      title: '最高気温',
      dataIndex: 'maxTemp',
      align: 'center',
      editable: true,
      width: '10%',
    },
    {
      title: '最低気温',
      dataIndex: 'minTemp',
      align: 'center',
      editable: true,
      width: '10%',
    },
  ];

  const handleSave = (row: TStep4) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.month === item.month);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setDataSource(newData);
  };

  const components = {
    body: {
      cell: EditableCell,
      row: EditableRow,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TStep4) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onClickNextStep = () => {
    const data: TStep4State = {
      step4: dataSource,
    };
    updateTemplate(data, template.id, currentGroup.id ?? 0, true, false);
  };

  const onClickPreStep = (buttonFooter: boolean = false) => {
    const data: TStep4State = {
      step4: dataSource,
    };
    updateTemplate(data, template.id, currentGroup.id ?? 0, buttonFooter, true);
  };

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
            </Row>
            <Row className={styles.assignmentsQuestionWrap__marginTop}>
              <Link to={EPath.A3}>
                <Button>テンプレート一覧に戻る</Button>
              </Link>
            </Row>
          </Col>
        </Row>
        <NavStep data={navStep} />
        <div className={styles.contentTitle}>制御問題の設定</div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.form}>
              <p>制御問題とは、月ごとの出題する各機器制御設定の問題です。</p>
              <p>月ごとの気象情報・問題文を設定できます。</p>
              <p>問題を解くための参考情報として月ごとの気象情報を生徒画面に表示します。</p>
              <br />
              <Form layout="vertical">
                <Form.Item name="items">
                  <Table
                    className={styles.contentWrap__table}
                    components={components}
                    columns={columns as ColumnTypes}
                    dataSource={dataSource}
                    pagination={false}
                    bordered
                    rowClassName={() => 'editable-row'}
                    rowKey="month"
                  />
                </Form.Item>
              </Form>
              <br />
            </div>
          </div>
        </div>
      </div>
      <ButtonFooter handleClickNext={onClickNextStep} handleClickPre={onClickPreStep} />
    </>
  );
};

export default AdmTempQuestion;
