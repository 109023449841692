import React, { useState } from 'react';
import { Row, Carousel } from 'antd';
import { getPublicUrlImage } from 'libs/helpers/functions';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import { getAssignmentName } from 'libs/helpers/localStorageFn';
import styles from './style.module.scss';

type Props = {
  handleNextPage: () => void;
  handleBackPage: () => void;
};
const AssignmentsRfp = ({ handleBackPage, handleNextPage }: Props) => {
  const [questionDesc, setQuestionDesc] = useState(false);
  const [lastSlide, setLastSlide] = useState(false);
  const onChange = (index: number) => {
    if (index === 9) {
      setLastSlide(true);
    }
    if (index < 2) {
      setQuestionDesc(false);
    } else {
      setQuestionDesc(true);
    }
  };

  const onNextPage = () => {
    if (lastSlide) {
      handleNextPage();
    }
  };
  return (
    <>
      <div className={styles.assignmentsRfpWrap}>
        <div className={styles.titleContainer}>
          <Row className={styles.titleWrapper}>
            {questionDesc ? (
              <div className={styles.titleContent}>
                <p>ツール説明</p>
                <p className={styles.titleTime}>{getAssignmentName()}</p>
              </div>
            ) : (
              <div className={styles.titleContent}>
                <p>ツール説明</p>
                <p className={styles.titleTime}>{getAssignmentName()}</p>
              </div>
            )}
          </Row>
          <Row>
            {questionDesc ? (
              <p className={styles.description}>
                制御シミュレーションの流れを最後まで読み、画面下の「進む」ボタンを押しましょう。
              </p>
            ) : (
              <p className={styles.description}>
                制御シミュレーションの流れを最後まで読み、画面下の「進む」ボタンを押しましょう。
              </p>
            )}
          </Row>
        </div>
        <div className={styles.contentWrap}>
          <Carousel arrows afterChange={onChange} className={styles.slider}>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションの目的</h3>
              <ul>
                <li>制御シミュレーションを通して</li>
                <li>①環境制御盤の基礎知識を学ぶこと</li>
                <li>②環境制御設定と環境センサー値の関係性を学ぶこと</li>
                <li>③篤農家の制御の考え方を学ぶこと</li>
                <li>を目的としています。</li>
              </ul>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションの流れ</h3>
              <ul>
                <li>①栽培基本情報を確認します。</li>
                <li>②栽培基本問題に回答します。（環境制御に関する基本的な問題です。）</li>
                <li>③栽培イベント問題に回答します。（環境制御に関する突発的出来事への対応方法の問題です。）</li>
                <li>④採点結果・解説の確認をします。</li>
                <li>⑤制御問題に回答します。</li>
                <li>⑥制御シミュレーション結果を確認しながら制御問題の回答を見直します。</li>
                <li>⑦模範データとの比較による回答結果を行います。</li>
                <li>⑧総合評価を確認します。</li>
              </ul>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションツールの流れ</h3>
              <ul>
                <li>①栽培基本情報を確認します。</li>
              </ul>
              <div className={styles.sliderImage}>
                <img src={getPublicUrlImage('slider_03.png')} alt="" />
              </div>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションツールの流れ</h3>
              <ul>
                <li>②栽培基本問題に回答します。（環境制御に関する基本的な問題です）</li>
              </ul>
              <div className={styles.sliderImage}>
                <img src={getPublicUrlImage('slider_04.png')} alt="" />
              </div>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションツールの流れ</h3>
              <ul>
                <li>③栽培イベント問題に回答します。（環境制御に関する突発的出来事への対応方法の問題です。）</li>
              </ul>
              <div className={styles.sliderImage}>
                <img src={getPublicUrlImage('slider_05.png')} alt="" />
              </div>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションツールの流れ</h3>
              <ul>
                <li>④採点結果・解説の確認をします。</li>
              </ul>
              <div className={styles.sliderImage}>
                <img src={getPublicUrlImage('slider_06.png')} alt="" />
              </div>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションツールの流れ</h3>
              <ul>
                <li>⑤制御問題に回答します。</li>
              </ul>
              <div className={styles.sliderImage}>
                <img src={getPublicUrlImage('slider_07.png')} alt="" />
              </div>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションツールの流れ</h3>
              <ul>
                <li>⑥制御シミュレーション結果を確認しながら制御問題の回答を見直します。</li>
              </ul>
              <div className={styles.sliderImage}>
                <img src={getPublicUrlImage('slider_08.png')} alt="" />
              </div>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションツールの流れ</h3>
              <ul>
                <li>⑦模範データとの比較による回答結果を行います。</li>
              </ul>
              <div className={styles.sliderImage}>
                <img src={getPublicUrlImage('slider_09.png')} alt="" />
              </div>
            </div>
            <div className={styles.sliderItem}>
              <h3>制御シミュレーションツールの流れ</h3>
              <ul>
                <li>⑧総合評価を確認します。</li>
              </ul>
              <div className={styles.sliderImage}>
                <img src={getPublicUrlImage('slider_10.png')} alt="" />
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <ButtonFooter handleClickPre={handleBackPage} handleClickNext={onNextPage} disabledNextBtn={!lastSlide} />
    </>
  );
};

export default AssignmentsRfp;
