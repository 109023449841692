import { TStep2 } from 'types/template';

export type TStep3State = {
  step3: TStep2[];
};

export const STAGE_NAME = {
  START_TO_HARVEST: '収穫開始期・前期（11・12月）',
  BETWEEN_TO_HARVEST: '収穫中期（1・2月）',
  AFTER_TO_HARVEST: '収穫後期（3・4月）',
};

export const OPTION_STAGE_NAME = [
  {
    value: STAGE_NAME.START_TO_HARVEST,
    label: STAGE_NAME.START_TO_HARVEST,
  },
  {
    value: STAGE_NAME.BETWEEN_TO_HARVEST,
    label: STAGE_NAME.BETWEEN_TO_HARVEST,
  },
  {
    value: STAGE_NAME.AFTER_TO_HARVEST,
    label: STAGE_NAME.AFTER_TO_HARVEST,
  },
];

export const QUESTION = {
  QUESTION_1: 'べと病の防除方法の考え方で適切ではないものを選択してください。',
  QUESTION_2: '灰色かび病と菌核病の防除方法の考え方で適切ではないものを選択してください。',
  QUESTION_3: 'ミナミキイロアザミウマ（黄化えそ病を伝染する害虫）の防除方法の考え方で適切なものを選択してください。',
  QUESTION_4: '日焼け症（ケロイド果）の予防方法の考え方で適切ではないものを選択してください。',
  QUESTION_5:
    '天候不良（雨）によりハウスの密閉状態が続き、相対湿度９5％が７２時間以上続く見込みのときの最初に考えられる対応を選択してください。',
  QUESTION_6: '日中時間帯に強風が長時間吹くとき（特に東からの強風が多いとき）の適切な対応方法を選択してださい。',
  QUESTION_7:
    '植物状態が過度な芯止まり傾向（樹勢強い・生殖生長寄り）にあるときの適切ではない対応方法を選択してください。',
  QUESTION_8: '植物状態が過度な徒長傾向（樹勢強い・栄養生長寄り）にあるときの適切ではない対応方法を選択してください。',
  QUESTION_9: '植物状態が過度な軟弱傾向（樹勢弱い・生殖生長寄り）にあるときの適切ではない対応方法を選択してください。',
  QUESTION_10:
    '植物状態が過度な軟弱徒長傾向（樹勢弱い・栄養生長寄り）にあるときの適切ではない対応方法を選択してください。',
  QUESTION_11: 'うどんこ病の防除方法の考え方で適切ではないものを選択してください。',
};

export const QUESTION_EVENT = [
  'べと病の防除方法の考え方で適切ではないものを選択してください。',
  '灰色かび病と菌核病の防除方法の考え方で適切ではないものを選択してください。',
  'ミナミキイロアザミウマ（黄化えそ病を伝染する害虫）の防除方法の考え方で適切なものを選択してください。',
  '日焼け症（ケロイド果）の予防方法の考え方で適切ではないものを選択してください。',
  '天候不良（雨）によりハウスの密閉状態が続き、相対湿度９5％が７２時間以上続く見込みのときの最初に考えられる対応を選択してください。',
  '日中時間帯に強風が長時間吹くとき（特に東からの強風が多いとき）の適切な対応方法を選択してださい。',
  '植物状態が過度な芯止まり傾向（樹勢強い・生殖生長寄り）にあるときの適切ではない対応方法を選択してください。',
  '植物状態が過度な徒長傾向（樹勢強い・栄養生長寄り）にあるときの適切ではない対応方法を選択してください。',
  '植物状態が過度な軟弱傾向（樹勢弱い・生殖生長寄り）にあるときの適切ではない対応方法を選択してください。',
  '植物状態が過度な軟弱徒長傾向（樹勢弱い・栄養生長寄り）にあるときの適切ではない対応方法を選択してください。',
  'うどんこ病の防除方法の考え方で適切ではないものを選択してください。',
];

export const INITIAL_DATA: TStep2[] = [
  {
    id: '1',
    stageName: STAGE_NAME.START_TO_HARVEST,
    question: QUESTION.QUESTION_1,
    options: [
      {
        index: 1,
        value: '曇雨天日などには加温機の暖房が切れても、送風による除湿を行い葉の濡れを防止する。',
        isCorrect: false,
      },
      {
        index: 2,
        value:
          'べと病菌は湿度の影響が大きく、葉に結露が生じていると急激に蔓延することがあるので、適正な温度管理を行う。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '冷たい外気がハウス内に一気に吹き込むと、葉に結露し、発病することがあるのでカーテン操作に注意する。',
        isCorrect: true,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '冷たい外気がハウス内に一気に吹き込むと葉に結露し、発病することがあるため、カーテン操作ではなく天窓操作に注意する必要があり、選択肢３は適切ではない。選択肢１、２は適切な対応である。',
    score: 5,
  },
  {
    id: '2',
    stageName: STAGE_NAME.START_TO_HARVEST,
    question: QUESTION.QUESTION_2,
    options: [
      {
        index: 1,
        value: '葉露を常時上げるような状態であれば換気をなるべく控える。',
        isCorrect: true,
      },
      {
        index: 2,
        value: '温度が高い場合には天窓、カーテンを閉め急がない。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '曇雨天時には、加温または加湿機の送風を作動させ除湿する。',
        isCorrect: false,
      },
      {
        index: 4,
        value: '日中もハウス内に霧がたち込めたり、結露したりする場合には、加湿機の送風だけ作動させて、結露を防止する。',
        isCorrect: false,
      },
    ],
    comment:
      '葉露を常時上げるような状態であれば換気を積極的に行う必要があるため、選択肢１が適切ではない。選択肢２、３、４は適切な対応である。また、灰色かび病と菌核病は低温多湿になりやすい11月、12月だけではなく、梅雨の６月にも発生しやすい。',
    score: 5,
  },
  {
    id: '3',
    stageName: STAGE_NAME.START_TO_HARVEST,
    question: QUESTION.QUESTION_3,
    options: [
      {
        index: 1,
        value: '施設の天窓および換気窓への防虫ネットの展張は、温湿度管理の効率が低下するため、行わない。',
        isCorrect: false,
      },
      {
        index: 2,
        value: '芽かき、摘葉などで発生した残さは、保湿効果を高めるために、通路や畝上に置く。',
        isCorrect: false,
      },
      {
        index: 3,
        value:
          '黄化えそ病の疑いのある株を発見した場合、抜き取りを行うと、周辺の株に抜き取った株分のかん水、肥料等が吸収されて生育が狂うため、抜き取りは実施しない。',
        isCorrect: false,
      },
      {
        index: 4,
        value:
          '効果的な薬剤防除を実施するためには、葉の表裏に十分な量の薬液を散布する必要があるため、ハウス内湿度が上昇する。',
        isCorrect: true,
      },
    ],
    comment:
      '害虫対策の基本は、ハウスに入れない・ハウスの中で増やさない・ハウスから出さないことである。まず、防虫ネットでハウス内への侵入を防止する。摘葉した残さや罹病株は、ハウス内に放置すると、害虫が増殖する温床となったり、ウイルス病の感染源となるため、ハウス外へ持ち出し処分する。その際に、持ち出した残さから近隣の生産者のほ場へ害虫等が移動しないよう、密閉可能な袋にいれるなどの対策を実施する。薬剤の散布は、農薬登録の範囲内で十分な量を散布する。',
    score: 5,
  },
  {
    id: '4',
    stageName: STAGE_NAME.START_TO_HARVEST,
    question: QUESTION.QUESTION_4,
    options: [
      {
        index: 1,
        value: '急激な換気や強風により果実表面付近の湿度が急激に低下した場合に発生する。',
        isCorrect: false,
      },
      {
        index: 2,
        value: 'こまめな換気操作と合わせて、早朝加温あるいは加温機の送風などにより果実の結露を軽減する。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '曇天日が続き、その翌日以降が風のある晴天（乾燥状態）で発生することが多い。',
        isCorrect: false,
      },
      {
        index: 4,
        value: '夕方に強い西日を受けると発生することが多い。',
        isCorrect: true,
      },
    ],
    comment:
      '詳しいメカニズムは不明だが、果実が急激に乾燥すると発生する。早朝、果実が結露した状態で、急激な換気を行うと発生することが多いため、カーテンを数回に分けて少しずつ開けるなどする。',
    score: 5,
  },
  {
    id: '5',
    stageName: STAGE_NAME.BETWEEN_TO_HARVEST,
    question: QUESTION.QUESTION_5,
    options: [
      {
        index: 1,
        value: '高湿度による病害発生を防ぐために、カーテンを１５分開ける。',
        isCorrect: false,
      },
      {
        index: 2,
        value: '高湿度による病害発生を防ぐために、暖房機を１５分稼働させる。',
        isCorrect: true,
      },
      {
        index: 3,
        value: '高湿度による病害発生を防ぐために、窓を１５分開ける。',
        isCorrect: false,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '高湿度が長時間続くと病害発生リスクが高まるため一時でも湿度を下げることが重要である。密閉時で１０℃のときに暖房機を稼働させ温度を１℃上げると湿度が約５％下がる。それでも湿度が下がらないときは天窓を少し開けること等も考えられるが、冷やされた外気がハウス内に急に入り込むため注意である。',
    score: 5,
  },
  {
    id: '6',
    stageName: STAGE_NAME.BETWEEN_TO_HARVEST,
    question: QUESTION.QUESTION_6,
    options: [
      {
        index: 1,
        value: '東の天窓を大きく開ける。',
        isCorrect: false,
      },
      {
        index: 2,
        value: '東の天窓を少し開ける。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '西の天窓を少し開ける。',
        isCorrect: true,
      },
      {
        index: 4,
        value: '天窓をすべて閉める。',
        isCorrect: false,
      },
    ],
    comment:
      '強風が吹いているときに天窓を大きく開けていると、一瞬に多くの冷たい外気がハウス内に入り込み急激に温度変化をし植物へ悪影響を及ぼす。また、すべての天窓を完全に閉めてしまうと、ハウス内の空気が滞留し環境ムラが生まれやすくなる。そのため強風が吹いてくる反対側の窓を少しだけ開けることが考えられる。統合環境制御盤と風光風速センサーを用いて自動制御できるものもある。',
    score: 5,
  },
  {
    id: '7',
    stageName: STAGE_NAME.AFTER_TO_HARVEST,
    question: QUESTION.QUESTION_7,
    options: [
      {
        index: 1,
        value: '日中・転流・夜間時間帯の温度を下げる。',
        isCorrect: false,
      },
      {
        index: 2,
        value: 'かん水量を減らす。',
        isCorrect: true,
      },
      {
        index: 3,
        value: '日中・転流時間帯に湿度を保持する。',
        isCorrect: false,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '１日平均温度を低くすると樹勢が弱い方向へ進み、日中・夜間温度差が小さいと植物にストレスがかからず栄養生長へ進む。また、かん水量を増やすことで植物にストレスがかからず栄養生長寄りへ進む。また、湿度を保持することでストレスがかからず栄養生長寄りへ進む。そのため選択肢２は適切ではない。栽培ステージと植物状態目標を考慮して管理する。',
    score: 5,
  },
  {
    id: '8',
    stageName: STAGE_NAME.AFTER_TO_HARVEST,
    question: QUESTION.QUESTION_8,
    options: [
      {
        index: 1,
        value: '全時間帯の温度を上げる。',
        isCorrect: true,
      },
      {
        index: 2,
        value: 'かん水量を減らす。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '全時間帯乾燥させる。',
        isCorrect: false,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '１日平均温度を低くすると樹勢が弱い方向へ進む。そのため選択肢１は適切ではない。日中・夜間温度差が大きいと植物にストレスがかかり生殖生長へ進む。また、かん水量を減らすことで植物にストレスがかか生殖生長寄りへ進む。また、乾燥させることでストレスがかかり生殖生長寄りへ進む。栽培ステージと植物状態目標を考慮して管理する。',
    score: 5,
  },
  {
    id: '9',
    stageName: STAGE_NAME.AFTER_TO_HARVEST,
    question: QUESTION.QUESTION_9,
    options: [
      {
        index: 1,
        value: '日中時間帯の温度を下げ、朝方・転流・夜間時間帯の温度を上げる。',
        isCorrect: false,
      },
      {
        index: 2,
        value: 'かん水量を増やす。',
        isCorrect: false,
      },
      {
        index: 3,
        value: '全時間帯の湿度を乾燥させる。',
        isCorrect: true,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '１日平均温度を高くすると樹勢が強い方向へ進み、日中・夜間温度差が小さいと植物にストレスがかからず栄養生長へ進む。また、かん水量を増やすことで植物にストレスがかからず栄養生長へ進む。また、湿度を保持することでストレスがかからず栄養生長寄りへ進む。そのため選択肢３は適切ではない。栽培ステージと植物状態目標を考慮して管理する。',
    score: 5,
  },
  {
    id: '10',
    stageName: STAGE_NAME.AFTER_TO_HARVEST,
    question: QUESTION.QUESTION_10,
    options: [
      {
        index: 1,
        value: '日中・転流・夜間時間帯の温度を下げる。',
        isCorrect: false,
      },
      {
        index: 2,
        value: 'かん水量を減らす。',
        isCorrect: true,
      },
      {
        index: 3,
        value: '日中・転流・夜間時間帯に湿度を保持する。',
        isCorrect: false,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '１日平均温度を高くすると樹勢が強い方向へ進み、日中・夜間温度差が大きいと植物にストレスがかかり生殖生長へ進む。また、かん水量を減らすことで植物にストレスがかかり生殖生長へと進むが樹勢も弱くなりやすいため、樹勢を弱らせないことを優先させかん水量は増やす。そのため選択肢２は適切ではない。また、乾燥させることでストレスがかかり生殖生長寄りへ進む。栽培ステージと植物状態目標を考慮して管理する。',
    score: 5,
  },
  {
    id: '11',
    stageName: STAGE_NAME.AFTER_TO_HARVEST,
    question: QUESTION.QUESTION_11,
    options: [
      {
        index: 1,
        value: 'うどんこ病菌は湿度の影響が大きく、乾燥すると急激に蔓延することがあるので、適正な湿度管理を行う。',
        isCorrect: false,
      },
      {
        index: 2,
        value:
          '加温機の周辺など乾燥しやすい場所に局所的に発生することがある。防除の効率化を図るため、うどんこ病が発生している付近のみ薬剤散布を行う。',
        isCorrect: true,
      },
      {
        index: 3,
        value:
          'うどんこ病防除に効果のあるイオウくん煙剤を使用する際には、循環扇を併用して、薬剤がハウス内に広く分散するようにする。',
        isCorrect: false,
      },
      {
        index: 4,
        value: 'なし',
        isCorrect: false,
      },
    ],
    comment:
      '病害の防除は予防的な対応が基本となるため、発生箇所以外にも薬剤散布を行う。イオウくん煙剤は、被覆資材の劣化を早めるため、使用する際には、被覆資材の更新計画などにも反映させる。',
    score: 5,
  },
];
