import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { AppState } from 'store';
import AssignmentsResultSimulation from './AssignmentsResultSimulation';

const AssignmentsResultSimulationContainer = () => {
  const { id } = useParams<{ id: string }>();
  const { dataSimulations } = useSelector((state: AppState) => state.pages.answers);
  const history = useHistory();
  const onBackMainPage = () => history.push(EPath.U3);
  return (
    <AssignmentsResultSimulation assignmentId={id} dataSimulations={dataSimulations} onBackMainPage={onBackMainPage} />
  );
};

export default AssignmentsResultSimulationContainer;
