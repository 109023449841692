import { getTemplateCopyFromLs } from 'libs/helpers/localStorageFn';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { TTemplateState } from 'modules/template/template.reducer';

const useGetTemplateLs = () => {
  const template = useSelector((state: AppState) => state.pages.template);

  const templateLocal = getTemplateCopyFromLs();

  const haveTemplate = Object.keys(template).length > 0 || Object.keys(templateLocal).length > 0;
  const dataTemp: TTemplateState = haveTemplate ? templateLocal : {};
  return [dataTemp];
};

export default useGetTemplateLs;
