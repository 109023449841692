/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, Select, SelectProps } from 'antd';
import type { InputRef } from 'antd';
import { logger } from 'libs/logger';
import { EditableContext } from 'components/EditTableColRow/EditableRow';
import { TStep6 } from 'types/template';
import styles from './style.module.scss';

const EditableCellStep6: React.FC<{
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: TStep6;
  title: string;
  handleSave: (record: TStep6) => void;
}> = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const [options] = useState<SelectProps['options']>([]);
  const inputRef = useRef<InputRef>(null);
  const inputNumberRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      inputNumberRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (form) form.setFieldsValue({ [dataIndex]: record[dataIndex as keyof TStep6] });
  };

  const save = async () => {
    try {
      const values = form && (await form.validateFields());
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      logger.log('message', errInfo);
    }
  };

  let childNode = children;
  let inputNode = <></>;

  useEffect(() => {
    if (record?.stepValue) {
      for (let i = record.lowerLimit; i <= record.upperLimit; i += record.stepValue) {
        if (record.stepValue === 0.1) {
          options?.push({
            value: (Math.round(i * 10) / 10).toFixed(1),
            label: (Math.round(i * 10) / 10).toFixed(1),
          });
        } else {
          options?.push({
            value: i,
            label: i,
          });
        }
      }
    }
  }, [options, record]);

  switch (dataIndex) {
    case 'properRange':
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <Select defaultValue={record.properRange} options={options} onChange={save} />
        </Form.Item>
      );
      break;
    default:
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <Input style={{ width: '100%' }} ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      );
  }

  if (editable) {
    childNode = editing ? (
      inputNode
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit} onKeyDown={toggleEdit} role="button" tabIndex={0}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCellStep6;
