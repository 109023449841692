import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { navStep } from 'constants/nav';
import { ColumnTypes, TStep6 } from 'types/template';
import EditableRow from 'components/EditTableColRow/EditableRow';
import { TTemplateState } from 'modules/template/template.reducer';
import EditTemplate from 'hook/EditTemplate';
import { EPath } from 'constants/routes';
import { Link } from 'react-router-dom';
import { omit } from 'lodash';
import { TGroupsClassDetail } from 'types/groupsClass';
import useGetTemplateLs from 'hook/useGetTemplateLs';
import { setTemplateCopyToLs } from 'libs/helpers/localStorageFn';
import { data, TStep6State } from './AdmTempCultivateSimulation.state';
import styles from './style.module.scss';
import EditableCellStep6 from './EditTableCellStep6';

type TProps = {
  template: TTemplateState;
  currentGroup: TGroupsClassDetail;
  updateTemplate: (
    data: TStep6State,
    id: number,
    groupId: number,
    buttonFooterNext?: boolean,
    buttonFooterPre?: boolean,
  ) => void;
  saveTemplate: (data: TTemplateState) => void;
  clearTemplateState: () => void;
};

const AdmTempCultivateSimulation = ({
  updateTemplate,
  template,
  currentGroup,
  saveTemplate,
  clearTemplateState,
}: TProps) => {
  const [dataSource, setDataSource] = useState<TStep6[] | []>(data);
  const [dataTemp] = useGetTemplateLs();

  EditTemplate();

  useEffect(() => {
    if (template.id && template.step6 && template.step6.length > 0) {
      setDataSource([
        ...template.step6.map((item) => ({
          ...item,
          alias: data[Number(item.alias.split('')[1]) - 1]?.alias,
          properRange:
            ([...(template.step6 ?? [])][Number(item.alias.split('')[1]) - 1]?.upperLimit +
              [...(template.step6 ?? [])][Number(item.alias.split('')[1]) - 1]?.lowerLimit) /
            2,
          unit: data[Number(item.alias.split('')[1]) - 1]?.unit,
        })),
      ]);
    } else {
      setDataSource(data);
    }
  }, [template]);

  useEffect(() => {
    if (Object.keys(dataTemp).length > 0) {
      saveTemplate(dataTemp);
    }
  }, []);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: '制御機器',
      dataIndex: 'factorial',
      width: '10%',
      align: 'center',
    },
    {
      title: '設定項目',
      dataIndex: 'alias',
      width: '10%',
      align: 'center',
    },
    {
      title: '適正範囲',
      dataIndex: 'properRange',
      width: '10%',
      align: 'center',
      editable: true,
    },
    {
      title: '幅',
      dataIndex: 'width',
      width: '10%',
      align: 'center',
      render: () => <>上下限</>,
    },
    {
      title: '単位',
      dataIndex: 'unit',
      width: '10%',
      align: 'center',
      render: (_: any, record: TStep6) => <>{record.unit}</>,
    },
  ];

  const handleSave = (row: TStep6) => {
    const newData = dataSource.map((data) => (data.factorial === row.factorial ? row : data));

    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCellStep6,
    },
  };

  const columns = defaultColumns.map((col) => ({
    ...col,
    onCell: (record: TStep6) => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave,
    }),
  }));

  const handleClickNext = (buttonFooterNext: boolean = true) => {
    const newData = dataSource.map((item, index) =>
      omit(
        {
          ...item,
          alias: `M${index + 1}`,
          lowerLimit: item.properRange ?? item.lowerLimit,
          upperLimit: item.properRange ?? item.upperLimit,
        },
        ['properRange'],
        ['stepValue'],
        ['unit'],
      ),
    );
    const data: TStep6State = {
      step6: newData,
    };
    updateTemplate(data, template.id, currentGroup.id ?? 0, buttonFooterNext);
    setTemplateCopyToLs({});
    clearTemplateState();
  };

  const handleClickPre = (buttonFooterNext: boolean = false) => {
    const newData = dataSource.map((item, index) =>
      omit(
        {
          ...item,
          alias: `M${index + 1}`,
          lowerLimit: item.properRange ?? item.lowerLimit,
          upperLimit: item.properRange ?? item.upperLimit,
        },
        ['properRange'],
        ['stepValue'],
        ['unit'],
      ),
    );

    const data: TStep6State = {
      step6: newData,
    };
    updateTemplate(data, template.id, currentGroup.id ?? 0, buttonFooterNext, true);
  };

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
            </Row>
            <Row className={styles.assignmentsQuestionWrap__marginTop}>
              <Link to={EPath.A3}>
                <Button>テンプレート一覧に戻る</Button>
              </Link>
            </Row>
          </Col>
        </Row>
        <NavStep data={navStep} />
        <div className={styles.contentTitle}>模範データ閾値の設定</div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.form}>
              <p>
                講師が講師画面で制御設定値の適正範囲を設定できます。生徒が解答した設定値が適正範囲外の場合、採点結果が減点されます。
              </p>
              <br />
              <Table
                className={styles.contentWrap__table}
                columns={columns as ColumnTypes}
                dataSource={dataSource}
                pagination={false}
                components={components}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
      <ButtonFooter handleClickFinish={handleClickNext} handleClickPre={handleClickPre} />
    </>
  );
};

export default AdmTempCultivateSimulation;
