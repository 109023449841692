import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

export const postLogin: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/login',
};

const postLogout: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/logout',
};

export const refreshTokens: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/refresh-access-token',
};

const changePassword: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/change-password',
};

const forgotPassword: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/forgot-password',
};

const resetPassword: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/reset-password',
};

const getAuth: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/get-auth',
};

export const authRepositories = {
  postLogin: apiClient.postFn(postLogin),
  postLogout: apiClient.postFn(postLogout),
  refreshTokens: apiClient.postFn(refreshTokens),
  changePassword: apiClient.postFn(changePassword),
  forgotPassword: apiClient.postFn(forgotPassword),
  resetPassword: apiClient.postFn(resetPassword),
  getAuth: apiClient.postFn(getAuth),
};

export const authRequestCustomCongfig = {
  postLogin,
  postLogout,
  refreshTokens,
  changePassword,
  forgotPassword,
  getAuth,
};
