/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Upload, Modal, Space, UploadFile, UploadProps, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MainButton from 'components/MainButton/MainButton';
import { RcFile, UploadListType } from 'antd/lib/upload/interface';
import { AppState } from 'store';
import { DownloadOutlined } from '@ant-design/icons';
import { messageModule } from 'modules/message/message.reducer';
import { customValidateMessages } from 'constants/validate/message';
import styles from './style.module.scss';

const UploadCsvModal: React.FC<{
  open: boolean;
  closeModal: () => void;
  groupId: number;
  id: number;
  step: number;
  uploadFile?: (data: { file: FormData; id: number; groupId: number; step: number }) => void;
}> = ({ open, closeModal, uploadFile, groupId, id, step }) => {
  const dispatch = useDispatch();
  const showMessage = () => {
    dispatch(messageModule.actions.setError(customValidateMessages.provideOneFile));
  };
  const { uploaded } = useSelector((state: AppState) => state.pages.upload);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList);

  useEffect(() => {
    if (uploaded) {
      closeModal();
    }
  }, [uploaded]);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
  };

  const handleUpload = (fileList: UploadFile[]) => {
    if (!fileList.length) {
      showMessage();
      return;
    }
    const isValidJsonFile = fileList[0].type === 'text/csv';
    if (!isValidJsonFile) {
      message.error('Invalid file type');
    }
    const isLt500KB = fileList[0]?.size ? fileList[0]?.size / 1024 < 500 : undefined;
    if (!isLt500KB) {
      message.error('File too large');
    }
    if (isValidJsonFile && uploadFile) {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('file', file.originFileObj as RcFile);
      });
      uploadFile({ file: formData, id, groupId, step });
      closeModal();
    }
  };

  return (
    <Modal
      title="ファイルのアップロード"
      centered
      open={open}
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
      footer={null}
    >
      <Upload.Dragger maxCount={1} {...props} onChange={handleChange} listType={'picture' as UploadListType}>
        <p className="ant-upload-drag-icon">
          <img src="/images/icon-wrapper-h.png" alt="icon-wrapper-h" />
        </p>
        <p className="ant-upload-text">ここにファイルをドラッグ＆ドロップ</p>
        <p className="ant-upload-hint">またはここをクリックし、ファイルを指定してください。</p>
      </Upload.Dragger>
      <div className={styles.uploadDesc}>
        <p className={styles.textDesc}>
          ・CSVの文字コードは「UTF-8(BOM付き)」としてください。
          <br />
          ・１行目は栽培ステージなどの項目名を設定してください。
          <br />
          ・各項目は、カンマ区切り、ダブルクォート括りとしてください。
          <br />
          ・改行コードは、「CR+LF」（\r\n）を設定してください。
          <br />
          ・項目内にダブルクォーテーションを設定する場合、ダブルクォーテーションを２つ並べてください。
        </p>
      </div>
      <div className={styles.buttonUpload}>
        <Space>
          <MainButton
            text={
              <>
                <DownloadOutlined className={styles.iconDownload} />
                このファイルをアップロード
              </>
            }
            onClick={() => {
              handleUpload(fileList);
            }}
          />
        </Space>
      </div>
    </Modal>
  );
};

export default UploadCsvModal;
