import assignmentsAction from 'modules/assignments/assignments.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import Top from './Top';

const TopContainer = () => {
  const dispatch = useDispatch();
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const getAllAssignment = (id: number) => dispatch(assignmentsAction.getTrainings({ groupId: id }));
  const assignmentState = useSelector((state: AppState) => state.pages.assignments);
  return <Top getAllAssignment={getAllAssignment} assignmentState={assignmentState} currentGroup={currentGroup} />;
};
export default TopContainer;
