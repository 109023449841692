import { Card, Form, Input, Row } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import { checkPassword, checkRePassword } from 'constants/validate';
import { customValidateMessages, defaultValidateMessages } from 'constants/validate/message';
import React, { useState } from 'react';
import { TChangePasswordBody } from 'types/auth';
import styles from './style.module.scss';

type FormChangePassWord = {
  oldPassword: string;
  newPassword: string;
  reNewPassword: string;
};

type Props = {
  changePassword: (data: TChangePasswordBody) => void;
};

const ChangePassword = ({ changePassword }: Props) => {
  const [form] = Form.useForm();
  const [passwordState, setPasswordState] = useState('');

  const onChangeValues = (values: FormChangePassWord) => {
    const { newPassword } = values;
    if (newPassword) {
      setPasswordState(newPassword);
    }
  };
  const handleChangePassword = (values: FormChangePassWord) => {
    const { oldPassword, newPassword } = values;
    const data: TChangePasswordBody = {
      oldPassword,
      newPassword,
    };
    changePassword(data);
    form.resetFields();
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Card type="inner" title="パスワードの変更" className={styles.cardWrap}>
        <p className={styles.subTitle}>現在のパスワードと新しいパスワードを入力してください。</p>
        <div className={styles.formWarp}>
          <Form
            layout="vertical"
            name="update_user_form"
            className="update_user_form"
            onFinish={handleChangePassword}
            form={form}
            validateMessages={defaultValidateMessages}
            onValuesChange={onChangeValues}
          >
            <Form.Item name="oldPassword" label="現在のパスワード" rules={[{ required: true, whitespace: true }]}>
              <Input.Password type="password" placeholder="Current password" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="新しいパスワード"
              rules={[
                { required: true, whitespace: true },
                { pattern: checkPassword, message: customValidateMessages.validatePassword },
              ]}
            >
              <Input.Password type="password" placeholder="New password" />
            </Form.Item>
            <Form.Item
              name="reNewPassword"
              label="新しいパスワード（確認用）"
              rules={[
                { required: true, whitespace: true },
                { pattern: checkRePassword(passwordState), message: customValidateMessages.rePasswordNotMatch },
              ]}
            >
              <Input.Password type="password" placeholder="Renew password" />
            </Form.Item>
            <Form.Item>
              <MainButton text="変更" htmlType="submit" />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Row>
  );
};

export default ChangePassword;
