import React, { useEffect, useState } from 'react';
import { Row, Divider, Select } from 'antd';
import { TResultAssignmentForOneStudent } from 'types/assignments';
import LineChart from 'components/LineChart/LineChart';
import BulletChart from 'components/BulletChart/BulletChart';
import TableResultControlTask from 'components/TableResultControlTask/TableResultControlTask';
import { DATA_SELECT, DATA_TYPE_CALCULATED_ANSWER, DATA_TYPE_STUDENT_ANSWER } from 'constants/common';
import { configLineChart } from 'components/LineChart/lineChartConfig';
import { TChartDataAll } from 'types/results';
import { aliasValue } from 'constants/date';
import { convertCorrectData, convertData, convertRangeData } from 'libs/helpers/functions';
import { configBulletChart } from './AssignmentsResultControl.state';
import styles from './style.module.scss';
import './style.css';

type Props = {
  resultAssignment: TResultAssignmentForOneStudent;
  dataCalculateInit: any;
  dataCorrectInit: any;
  isShowLoading: boolean;
};
const DEFAULT_SELECT_VALUE = 'C1';
const AssignmentsResultControl = ({ isShowLoading, resultAssignment, dataCorrectInit, dataCalculateInit }: Props) => {
  const [dataChart, setDataChart] = useState<TChartDataAll>({
    maxData: [],
    dataLine: [],
    minData: [],
  });
  const [selectValue, setSelectValue] = useState(DEFAULT_SELECT_VALUE);

  useEffect(() => {
    if (!isShowLoading) {
      setDataChart({
        maxData: [],
        dataLine: [...dataCalculateInit, ...dataCorrectInit],
        minData: [],
      });
    }
  }, [isShowLoading]);

  const handleChange = (als: string) => {
    setSelectValue(als);
    if (als === aliasValue.C1 || als === aliasValue.C2 || als === aliasValue.C3) {
      const dataCalculate = convertData(
        resultAssignment.controlResult?.studentAnswers,
        als,
        false,
        DATA_TYPE_CALCULATED_ANSWER,
      );
      const dataCorrect = convertCorrectData(resultAssignment.controlResult?.correctAnswers ?? [], als);
      setDataChart({
        minData: [],
        maxData: [],
        dataLine: [...dataCalculate, ...dataCorrect],
      });
    } else {
      const dataMinValue = convertRangeData(resultAssignment.controlResult?.correctAnswers ?? [], als, 'min');
      const dataMaxValue = convertRangeData(resultAssignment.controlResult?.correctAnswers ?? [], als, 'max');
      const dataCorrect = convertCorrectData(resultAssignment.controlResult?.correctAnswers ?? [], als);
      const dataStudentAnswer = convertData(
        resultAssignment.controlResult?.studentAnswers,
        als,
        false,
        DATA_TYPE_STUDENT_ANSWER,
      );
      setDataChart({
        dataLine: [...dataCorrect, ...dataStudentAnswer],
        minData: dataMinValue,
        maxData: dataMaxValue,
      });
    }
  };

  return (
    <div className="groupsAdm">
      <p className={styles.bodySection}>採点結果</p>
      <Divider className={styles.divider} />
      <TableResultControlTask
        maxScore={resultAssignment.maxScore}
        morningScore={resultAssignment.controlResult?.morningScore}
        afternoonScore={resultAssignment.controlResult?.afternoonScore}
        beforeNightScore={resultAssignment.controlResult?.beforeNightScore}
        midnightScore={resultAssignment.controlResult?.midnightScore}
      />
      <p className={styles.bodySection}>回答解説</p>
      <Divider className={styles.divider} />
      <BulletChart
        configChart={configBulletChart(resultAssignment.controlResult?.morningScore, '   早朝時間帯    ').configScore}
      />
      <BulletChart
        configChart={configBulletChart(resultAssignment.controlResult?.afternoonScore, '   日中時間帯    ').configScore}
      />
      <BulletChart
        configChart={
          configBulletChart(resultAssignment.controlResult?.beforeNightScore, '前夜半時間帯    ').configScore
        }
      />
      <BulletChart
        configChart={configBulletChart(resultAssignment.controlResult?.midnightScore, '  夜間時間帯    ').configScore}
      />
      <Row className={styles.progressControlWrapper}>
        <p>環境センサー</p>
        <Select
          defaultValue={DEFAULT_SELECT_VALUE}
          onChange={handleChange}
          options={DATA_SELECT}
          className={styles.titleSelect}
        />
      </Row>
      <LineChart alias={selectValue} configChart={configLineChart(dataChart)} />
    </div>
  );
};

export default AssignmentsResultControl;
