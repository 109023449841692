import { TOptionsQuery, TOptionsQueryExtra } from 'types/common';
import { TTrainingDetail } from 'types/assignments';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  getTrainings: ac<TOptionsQueryExtra<TTrainingDetail>>('getTrainings'),
  getTraining: ac<{ id: string; groupId: number; createTrainingResult?: boolean }>('getTraining'),
  updateTraining: ac<TTrainingDetail & { groupId: number }>('updateTraining'),
  createTraining: ac<TTrainingDetail & { groupId: number }>('createTraining'),
  deleteTraining: ac<{ id: number; groupId: number }>('deleteTraining'),
  getTrainingsWorking: ac<TOptionsQuery<TTrainingDetail>>('getTrainingsWorking'),
  getTrainingsFinish: ac<TOptionsQuery<TTrainingDetail>>('getTrainingsFinish'),
  getResultsAssignment: ac<{ id: string; groupId: number }>('getResultsAssignment'),
};
