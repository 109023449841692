import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationDefault } from 'constants/pagination';
import { TResponsePagination } from 'libs/axios';
import { TOptionsQuery, TPagination } from 'types/common';
import { TControlResultsDetail } from 'types/controlResults';
import { TUserDetail } from 'types/groups';

type TShowModal = 'groups' | 'delete' | '';
export type TDataFileUpload = { fname?: string; originalName?: string };

export type TControlResultsState = {
  loading: boolean;
  error: boolean;
  showModal: TShowModal;
  message: string;
  controlResults: Array<TControlResultsDetail>;
  controlResultsDetail: TControlResultsDetail;
  pagination: TPagination;
  dataFileUpload: TDataFileUpload;
};

const initialState: TControlResultsState = {
  loading: false,
  error: false,
  showModal: '',
  message: '',
  controlResults: [],
  controlResultsDetail: {},
  pagination: paginationDefault(),
  dataFileUpload: {},
};

const _module = createSlice({
  name: 'controlResults',
  initialState,
  reducers: {
    loading(state: TControlResultsState) {
      return {
        ...state,
        loading: true,
      };
    },
    stopLoading(state) {
      return {
        ...state,
        loading: false,
      };
    },
    setError(state: TControlResultsState, action: PayloadAction<boolean>) {
      return {
        ...state,
        error: action.payload,
      };
    },
    setModal: (state: TControlResultsState, action: PayloadAction<TShowModal>) => ({
      ...state,
      showModal: action.payload,
    }),

    createSuccess(state, action: PayloadAction<TShowModal>) {
      return {
        ...state,
        showModalNoti: action.payload,
      };
    },
    clearSuccess(state) {
      return {
        ...state,
        showModalNoti: '',
      };
    },
    getListControlResults: (
      state: TControlResultsState,
      action: PayloadAction<TResponsePagination<TControlResultsDetail>>,
    ) => {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        controlResults: results,
        pagination,
      };
    },
    removeControlResults: (state: TControlResultsState, action: PayloadAction<number>) => {
      const newListControlResults = state.controlResults.filter((g: TControlResultsDetail) => g.id !== action.payload);
      return {
        ...state,
        controlResults: newListControlResults,
      };
    },
    saveControlResultsDetail: (state: TControlResultsState, action: PayloadAction<TControlResultsDetail>) => ({
      ...state,
      controlResultsDetail: action.payload,
    }),
    saveListControlResults: (state: TControlResultsState, action: PayloadAction<TControlResultsDetail>) => ({
      ...state,
      controlResults: [...state.controlResults, action.payload],
    }),
    setDataFileUpload: (state: TControlResultsState, action: PayloadAction<TDataFileUpload>) => ({
      ...state,
      dataFileUpload: action.payload,
    }),
  },
});

export const controlResultsModule = _module;
