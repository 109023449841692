import React, { useEffect, useRef, useState } from 'react';
import { Button, Row } from 'antd';
import TableResultBasicTask from 'components/TableResultBasicTask/TableResultBasicTask';
import TextArea from 'antd/lib/input/TextArea';
import { TResultAssignmentForOneStudent } from 'types/assignments';
import { useDispatch } from 'react-redux';
import { TUserDetail } from 'types/groups';
import TableResultControlTask from 'components/TableResultControlTask/TableResultControlTask';
import { answersAction } from 'modules/answers/answers.action';
import styles from './style.module.scss';
import './style.css';

type Props = {
  resultAssignment: TResultAssignmentForOneStudent;
  assignmentId: string;
  studentId: string;
  currentUser: TUserDetail;
  getResultAssignment: () => void;
};
const AssignmentsResultOverviewAdm = ({
  getResultAssignment,
  currentUser,
  resultAssignment,
  assignmentId,
  studentId,
}: Props) => {
  const dispatch = useDispatch();
  const [disableComment, setDisableComment] = useState(true);
  const [comment, setComment] = useState('');
  const refInput = useRef<HTMLInputElement>(null);
  const oldComment =
    resultAssignment && resultAssignment.comments && !!resultAssignment.comments.length
      ? resultAssignment.comments?.find((e) => e.teacherId === currentUser.id)?.content
      : '';

  const basicScore = resultAssignment.basicResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const eventScore = resultAssignment.eventResults?.reduce((score, current) => score + (current.score ?? 0), 0);

  useEffect(() => {
    if (oldComment) {
      setComment(oldComment);
    }
  }, [resultAssignment]);

  const handleShowComment = () => {
    setDisableComment((prev) => !prev);
  };
  useEffect(() => {
    getResultAssignment();
  }, []);
  useEffect(() => {
    if (!disableComment && refInput && refInput.current) {
      refInput.current.focus();
    }
  }, [disableComment, refInput]);

  const onBlurInput = () => {
    setDisableComment(true);
    if (comment !== oldComment) {
      dispatch(
        answersAction.updateCommentAssignment({
          id: Number(assignmentId),
          sid: Number(studentId),
          comment,
        }),
      );
    }
  };

  return (
    <>
      <p className={styles.bodySection}>栽培問題回答に対する評価</p>
      <TableResultBasicTask maxScore={resultAssignment.maxScore} basicScore={basicScore} eventScore={eventScore} />
      <p className={styles.bodySection}>制御問題回答に対する評価</p>
      <TableResultControlTask
        maxScore={resultAssignment.maxScore}
        morningScore={resultAssignment.controlResult?.morningScore}
        afternoonScore={resultAssignment.controlResult?.afternoonScore}
        beforeNightScore={resultAssignment.controlResult?.beforeNightScore}
        midnightScore={resultAssignment.controlResult?.midnightScore}
      />
      <Row justify="space-between" className={styles.bodySectionComment}>
        <p>講師コメント</p>
        <Button onClick={handleShowComment} className={styles.topButton}>
          {disableComment ? '編集する' : '保存する'}
        </Button>
      </Row>
      <TextArea
        ref={refInput}
        readOnly={disableComment}
        value={comment}
        onChange={(e) => setComment(e.target.value ?? '')}
        rows={8}
        onBlur={onBlurInput}
      />
    </>
  );
};

export default AssignmentsResultOverviewAdm;
