import React, { useEffect } from 'react';
import { Button, Card, Form, Space, Table } from 'antd';
import { EPath } from 'constants/routes';
import { Link } from 'react-router-dom';
import { TUiState } from 'modules/ui/ui.reducer';
import { TAssignmentsState } from 'modules/assignments/assignments.reducer';
import { TResultsScoreOfStudent } from 'types/assignments';
import MainButton from 'components/MainButton/MainButton';
import { replacePathParams } from 'libs/helpers/functions';
import { TGroupsClassDetail } from 'types/groupsClass';
import type { ColumnsType } from 'antd/es/table';
import styles from './style.module.scss';

type Props = {
  uiState: TUiState;
  trainingId: string;
  assignmentsState: TAssignmentsState;
  getResultsAssignment: (data: { id: string; groupId: number }) => void;
  currentGroup: TGroupsClassDetail;
};

const AssignmentsResultAdm = ({ uiState, trainingId, assignmentsState, currentGroup, getResultsAssignment }: Props) => {
  const { isShowLoading } = uiState;
  const { resultsAssignment } = assignmentsState;

  useEffect(() => {
    if (trainingId && getResultsAssignment && currentGroup.id) {
      getResultsAssignment({ id: trainingId, groupId: currentGroup.id });
    }
  }, []);

  const columnsTableResultsAssignment: ColumnsType<TResultsScoreOfStudent> = [
    {
      key: 'studentName',
      title: 'ユーザー名',
      width: '14%',
      render: (_: any, record: TResultsScoreOfStudent) => record.studentName,
    },
    {
      key: 'completedDate',
      title: '提出日時',
      dataIndex: 'completedDate',
      width: '14%',
    },
    {
      key: 'cultivationTotalScore',
      title: '栽培問題 合計（240満点）',
      width: '8%',
      render: (_: any, record: TResultsScoreOfStudent) => Number(record.basicScore) + Number(record.eventScore) || null,
      align: 'center',
      sorter: (a, b) =>
        Number(Number(a.basicScore) + Number(a.eventScore)) - Number(Number(b.basicScore) + Number(b.eventScore)),
    },
    {
      key: 'basicScore',
      title: '栽培問題 基本問題（150満点）',
      dataIndex: 'basicScore',
      width: '8%',
      align: 'center',
      sorter: (a, b) => Number(a.basicScore) - Number(b.basicScore),
    },
    {
      key: 'eventScore',
      title: '栽培問題 ｲﾍﾞﾝﾄ問題（90満点）',
      dataIndex: 'eventScore',
      width: '8%',
      align: 'center',
      sorter: (a, b) => Number(a.eventScore) - Number(b.eventScore),
    },
    {
      key: 'controlTotalScore',
      title: '制御問題 合計（400満点）',
      width: '8%',
      render: (_: any, record: TResultsScoreOfStudent) =>
        (
          Number(record.morningScore) +
          Number(record.afternoonScore) +
          Number(record.beforeNightScore) +
          Number(record.midnightScore)
        ).toFixed(1) || null,
      align: 'center',
      sorter: (a, b) =>
        Number(
          Number(a.morningScore) + Number(a.afternoonScore) + Number(a.beforeNightScore) + Number(a.midnightScore),
        ) -
        Number(
          Number(b.morningScore) + Number(b.afternoonScore) + Number(b.beforeNightScore) + Number(b.midnightScore),
        ),
    },
    {
      key: 'morningScore',
      title: '制御問題 早朝管理（100満点）',
      dataIndex: 'morningScore',
      width: '8%',
      align: 'center',
      sorter: (a, b) => Number(a.morningScore) - Number(b.morningScore),
    },
    {
      key: 'afternoonScore',
      title: '制御問題 日中管理（100満点）',
      dataIndex: 'afternoonScore',
      width: '8%',
      align: 'center',
      sorter: (a, b) => Number(a.afternoonScore) - Number(b.afternoonScore),
    },
    {
      key: 'beforeNightScore',
      title: '制御問題 転流管理（100満点）',
      dataIndex: 'beforeNightScore',
      width: '8%',
      align: 'center',
      sorter: (a, b) => Number(a.beforeNightScore) - Number(b.beforeNightScore),
    },
    {
      key: 'midnightScore',
      title: '制御問題 夜間管理（100満点）',
      dataIndex: 'midnightScore',
      width: '8%',
      align: 'center',
      sorter: (a, b) => Number(a.midnightScore) - Number(b.midnightScore),
    },
    {
      key: 'actions',
      title: '',
      width: '8%',
      align: 'center',
      render: (_: any, record: TResultsScoreOfStudent) =>
        resultsAssignment.id && record.studentId ? (
          <Space align="center" className={styles.groupButton}>
            <Link
              to={replacePathParams(EPath.A8_1, {
                id: resultsAssignment.id.toString() ?? '',
                sid: record.studentId.toString() ?? '',
              })}
            >
              <MainButton text="詳細" />
            </Link>
          </Space>
        ) : (
          <MainButton disabled text="詳細" />
        ),
    },
  ];

  return (
    <div className="groupsAdm">
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>研修一覧に戻る</p>
            </div>
          }
          loading={isShowLoading}
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.topButtonGroup}>
                <Button className={styles.topButton}>
                  <Link to={EPath.A5}>研修一覧に戻る</Link>
                </Button>
              </Space>
              <Form layout="vertical" name="assignment-results">
                <Form.Item>
                  <Space align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>研修名</p>
                    <p className={styles.assignmentTitle}>{resultsAssignment.name}</p>
                  </Space>
                </Form.Item>
                <Form.Item>
                  <Space align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>研修テンプレート</p>
                    <p className={styles.assignmentTitle}>{resultsAssignment.templateName}</p>
                  </Space>
                </Form.Item>
                <Form.Item>
                  <Space align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>開始日</p>
                    <p className={styles.assignmentTitle}>{resultsAssignment.startDate}</p>
                  </Space>
                </Form.Item>
                <Form.Item>
                  <Space align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>終了日</p>
                    <p className={styles.assignmentTitle}>{resultsAssignment.endDate}</p>
                  </Space>
                </Form.Item>
              </Form>
              <div className={styles.body}>
                <Table
                  loading={isShowLoading}
                  columns={columnsTableResultsAssignment}
                  dataSource={resultsAssignment.results}
                  pagination={false}
                  rowKey={(record) => record.studentId ?? Math.random()}
                  showSorterTooltip={false}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AssignmentsResultAdm;
