import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TStep2, TUpdateTemplate } from 'types/template';

export type TTemplateState = TUpdateTemplate & {
  id: number;
  name: string;
  isLinkedWithTraining: boolean;
};

const initialState: TTemplateState = {
  id: 0,
  itemName: '',
  name: '',
  controlResult: 0,
  isLinkedWithTraining: false,
};

const _module = createSlice({
  name: 'template',
  initialState,
  reducers: {
    getTemplate(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    saveTemplate(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateStep2(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step2: action.payload.step2,
      };
    },
    updateStep3(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step3: action.payload.step3,
      };
    },
    updateStep4(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step4: action.payload.step4,
      };
    },
    updateStep5(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step5: action.payload.step5,
      };
    },
    updateStep6(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step6: action.payload.step6,
      };
    },
    updateStepChooseControlResult(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        controlResult: action.payload.controlResult,
      };
    },
    updateImage(state: TTemplateState, action: PayloadAction<string>) {
      return {
        ...state,
        picture: action.payload,
      };
    },
    saveIdTemplate(state: TTemplateState, action: PayloadAction<number>) {
      return {
        ...state,
        id: action.payload,
      };
    },
    clearState() {
      return { ...initialState };
    },
    updateQuestionStep2(state: TTemplateState, action: PayloadAction<TStep2[]>) {
      return {
        ...state,
        step2: action.payload,
      };
    },
    updateQuestionStep3(state: TTemplateState, action: PayloadAction<TStep2[]>) {
      return {
        ...state,
        step3: action.payload,
      };
    },
    setIsLinkedWithTraining(state: TTemplateState, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLinkedWithTraining: action.payload,
      };
    },
  },
});

export const templateModule = _module;
