export const defaultValidateMessages = {
  required: 'This field is required!',
  whitespace: 'This field is required!',
  types: {
    email: 'メールアドレスが正しい形式ではありません。',
  },
};

export const customValidateMessages = {
  validatePassword: 'Password must be at least 8 characters and must contain at least one letter and one number!',
  rePasswordNotMatch: 'Re-entered password does not match!',
  provideOneFile: 'ファイルを添付してください。',
};
