import { TNewTemplate } from 'types/template';
import { templateRepositories } from 'repositories/template';
import { TTemplateState } from 'modules/template/template.reducer';
import { TStep2State } from 'pages/AdmTempBasic/AdmTempBasic.state';
import { TStep3State } from 'pages/AdmTempEvent/AdmTempEvent.state';
import { TStep4State } from 'pages/AdmTempQuestion/AdmTempQuestion.state';
import { TStep5State } from 'pages/AdmTempControlSimulation/AdmTempControlSimulation.state';
import { TStep6State } from 'pages/AdmTempCultivateSimulation/AdmTempCultivateSimulation.state';

const createTemplate = async (data: TNewTemplate & { groupId: number }): Promise<TTemplateState> => {
  const { groupId, ...body } = data;
  const res = await templateRepositories.createTemplate({
    body,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  if (!res.result) {
    return {
      id: 0,
      name: '',
      itemName: '',
      isLinkedWithTraining: false,
    };
  }

  return {
    id: res.data.id,
    name: res.data.name,
    itemName: res.data.itemName,
    isLinkedWithTraining: false,
  };
};
const updateTemplate = async (data: TNewTemplate, id: number, groupId: number) => {
  const res = await templateRepositories.updateTemplate({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate2 = async (data: TStep2State, id: number, groupId: number) => {
  const res = await templateRepositories.updateTemplate({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate3 = async (data: TStep3State, id: number, groupId: number) => {
  const res = await templateRepositories.updateTemplate({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate4 = async (data: TStep4State, id: number, groupId: number) => {
  const res = await templateRepositories.updateTemplate({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate5 = async (data: TStep5State, id: number, groupId: number) => {
  const res = await templateRepositories.updateTemplate({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate6 = async (data: TStep6State, id: string, groupId: number) => {
  const res = await templateRepositories.updateTemplate({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplateChooseControlResult = async (data: { controlResult: number }, id: string, groupId: number) => {
  const res = await templateRepositories.updateTemplate({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};
const getTemplateById = async (data: { id: string; groupId: number }) => {
  const { id, groupId } = data;
  const res = await templateRepositories.getTemplateById({
    pathParameters: {
      id,
    },
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const uploadCsvQuestionFile = async (data: { file: FormData; id: number; groupId: number; step: number }) => {
  const { file, id, groupId, step } = data;
  const res = await templateRepositories.uploadCsvFile({
    body: file,
    pathParameters: { id },
    condition: { groupId, step },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const downloadCsvQuestionFile = async (data: { id: number; groupId: number; step: number }) => {
  const { id, groupId, step } = data;
  const res = await templateRepositories.downloadCsvFile({
    pathParameters: { id },
    condition: { groupId, step },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const templateServices = {
  createTemplate,
  updateTemplate,
  updateTemplate2,
  updateTemplate3,
  updateTemplate4,
  updateTemplate5,
  updateTemplate6,
  getTemplateById,
  updateTemplateChooseControlResult,
  uploadCsvQuestionFile,
  downloadCsvQuestionFile,
};
