import { ColumnTypes, TStep5 } from 'types/template';

export type DataType = TStep5 & { correctionValue: string };

export const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
  {
    title: '補正値名',
    dataIndex: 'correctionValue',
    width: '20%',
    align: 'center',
  },
  {
    title: '関連する設備名',
    dataIndex: 'alias',
    width: '20%',
    align: 'center',
  },
  {
    title: '補正値の説明',
    dataIndex: 'effectName',
    align: 'center',
    width: '50%',
  },
  {
    title: '補正値',
    dataIndex: 'factor',
    width: '10%',
    editable: true,
    align: 'center',
  },
];

export const data: DataType[] = [
  {
    correctionValue: '日中前夜半時間帯温度',
    alias: '天窓',
    effectName: '数値を１つ大きくすると日中・前夜半時間帯の温度が１℃上がります。',
    factor: 3,
  },
  {
    correctionValue: '換気開度効果',
    alias: '天窓',
    effectName:
      '数値を１つ大きくすると、生徒が日中・前夜半時間帯で換気開度を25%に設定した場合温度が１℃さらに下がります。また生徒が換気開度を5％に設定した場合は温度が0.2℃さらに下がります。',
    factor: 0.2,
  },
  {
    correctionValue: '空気循環効果',
    alias: '循環扇',
    effectName:
      '数値を１つ大きくすると、日中・前夜半時間帯で生徒が循環扇タイマー設定がONに設定した場合に温度が１℃さらに下がります。',
    factor: 1,
  },
  {
    correctionValue: '湿度上限　早朝時間帯',
    alias: 'なし',
    effectName: '早朝時間帯の湿度上限値です。',
    factor: 100,
  },
  {
    correctionValue: '湿度上限　日中時間帯',
    alias: 'なし',
    effectName: '日中時間帯の湿度上限値です。',
    factor: 100,
  },
  {
    correctionValue: '湿度上限 前夜半時間帯',
    alias: 'なし',
    effectName: '前夜半時間帯の湿度上限値です。',
    factor: 100,
  },
  { correctionValue: '湿度上限　夜間時間帯', alias: 'なし', effectName: '夜間時間帯の湿度上限値です。', factor: 100 },
  { correctionValue: '湿度下限　早朝時間帯', alias: 'なし', effectName: '早朝時間帯の湿度下限値です。', factor: 60 },
  { correctionValue: '湿度下限　日中時間帯', alias: 'なし', effectName: '日中時間帯の湿度下限値です。', factor: 40 },
  {
    correctionValue: '湿度下限 前夜半時間帯',
    alias: 'なし',
    effectName: '前夜半時間帯の湿度下限値です。',
    factor: 60,
  },
  {
    correctionValue: '湿度下限　夜間時間帯',
    alias: 'なし',
    effectName: '夜間時間帯の湿度下限値です。',
    factor: 80,
  },
  {
    correctionValue: 'CO2濃度上限　早朝時間帯',
    alias: '炭酸ガス発生機',
    effectName: '早朝時間帯のCO2濃度上限値です。',
    factor: 1500,
  },
  {
    correctionValue: 'CO2濃度上限　日中時間帯',
    alias: '炭酸ガス発生機',
    effectName: '日中時間帯のCO2濃度上限値です。',
    factor: 1500,
  },
  {
    correctionValue: 'CO2濃度上限　転流時間帯',
    alias: '炭酸ガス発生機',
    effectName: '前夜半時間帯のCO2濃度上限値です。',
    factor: 1500,
  },
  {
    correctionValue: 'CO2濃度上限 夜間時間帯',
    alias: '炭酸ガス発生機',
    effectName: '夜間時間帯のCO2濃度上限値です。',
    factor: 2500,
  },
  {
    correctionValue: 'CO2濃度下限 早朝時間帯',
    alias: '炭酸ガス発生機',
    effectName: '早朝時間帯のCO2濃度下限値です。',
    factor: 250,
  },
  {
    correctionValue: 'CO2濃度下限 日中時間帯',
    alias: '炭酸ガス発生機',
    effectName: '日中時間帯のCO2濃度下限値です。',
    factor: 250,
  },
  {
    correctionValue: 'CO2濃度下限 前夜半時間帯',
    alias: '炭酸ガス発生機',
    effectName: '前夜半時間帯のCO2濃度下限値です。',
    factor: 250,
  },
  {
    correctionValue: 'CO2濃度下限 夜間時間帯',
    alias: '炭酸ガス発生機',
    effectName: '夜間時間帯のCO2濃度下限値です。',
    factor: 800,
  },
];

export const aliasData: string[] = [
  'K1',
  'K2',
  'K3',
  'L1',
  'L2',
  'L3',
  'L4',
  'L5',
  'L6',
  'L7',
  'L8',
  'L9',
  'L10',
  'L11',
  'L12',
  'L13',
  'L14',
  'L15',
  'L16',
  'L17',
  'L18',
];
export const correctionValue: string[] = [
  '日中転流時間帯温度',
  '換気感度効果',
  '空気循環効果',
  '湿度上限　早朝時間帯',
  '湿度上限　日中時間帯',
  '湿度上限　転流時間帯',
  '湿度上限　夜間時間帯',
  '湿度下限　早朝時間帯',
  '湿度下限　日中時間帯',
  '湿度下限　転流時間帯',
  '湿度下限　夜間時間帯',
  'CO2濃度上限　早朝時間帯',
  'CO2濃度上限　日中時間帯',
  'CO2濃度上限　転流時間帯',
  'CO2濃度上限 夜間時間帯',
  'CO2濃度下限　早朝時間帯',
  'CO2濃度下限　日中時間帯',
  'CO2濃度下限　転流時間帯',
  'CO2濃度下限　夜間時間帯',
];
export const ALIAS: string[] = [
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
  '暖房機',
];
export type TStep5State = {
  step5: TStep5[];
};
