import React, { useEffect, useState } from 'react';
import { Button, Card, Pagination, Space, Table } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MainButton from 'components/MainButton/MainButton';
import { TOptionsQuery } from 'types/common';
import { TTemplateDetail } from 'types/templates';
import { parseSearchParams, createQueryUrl, replacePathParams } from 'libs/helpers/functions';
import ConfirmModal from 'components/CommonModal/ConfirmModal';
import NotiModal from 'components/CommonModal/NotiModal';
import { TControlResultsState } from 'modules/controlResults/controlResults.reducer';
import { TGroupsClassDetail } from 'types/groupsClass';
import { EPath } from 'constants/routes';
import { TControlResultsDetail } from 'types/controlResults';
import styles from './style.module.scss';
import './style.css';

type Props = {
  getControlResults: (params: TOptionsQuery) => void;
  currentGroup: TGroupsClassDetail;
  controlResultsState: TControlResultsState;
  deleteControlResults: (data: { ctrRsId: number; groupId: number }) => void;
  clearErr: () => void;
  downloadControlResults: (data: { ctrRsId: number; groupId: number }) => void;
};
const AdmControlResult = ({
  getControlResults,
  currentGroup,
  deleteControlResults,
  clearErr,
  controlResultsState,
  downloadControlResults,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const urlQueryParams: TOptionsQuery<TTemplateDetail> = parseSearchParams(location.search);
  const { controlResults, loading, error, pagination } = controlResultsState;
  const [modal, setModal] = useState<'delete' | 'notice' | ''>('');
  const [ctrRs, setCtrRs] = useState<number>(0);

  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };
  const goDetailPage = (id: string) => {
    history.push(replacePathParams(EPath.A9_3, { id }));
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: '模範データ名',
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: '登録日',
      dataIndex: 'createdAt',
      width: '40%',
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_: any, record: TControlResultsDetail) => (
        <Space className={styles.buttonOperation} align="center">
          <MainButton
            onClick={() => downloadControlResults({ ctrRsId: record.id ?? 0, groupId: currentGroup.id ?? 0 })}
            text="エクスポート"
          />
          <MainButton onClick={() => goDetailPage(String(record.id))} text="更新" />
          <MainButton
            subButton
            text="削除"
            onClick={() => {
              setCtrRs(record.id ?? 0);
              setModal('delete');
            }}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (currentGroup.id) getControlResults({ ...urlQueryParams, groupId: currentGroup.id });
  }, [currentGroup.id, location]);
  return (
    <div className="groupsAdm">
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>模範データ管理</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.buttonTopGroup}>
                <Link to={EPath.A1}>
                  <Button className={styles.buttonAddTemplate}>TOPに戻る</Button>
                </Link>
              </Space>
              <Space className={styles.buttonTopGroup}>
                <Link to={EPath.A9_2}>
                  <Button className={styles.buttonAddTemplate}>模範データを追加する</Button>
                </Link>
              </Space>
              <Table
                bordered
                columns={columns}
                dataSource={controlResults}
                pagination={false}
                loading={loading}
                rowKey="id"
              />
            </div>
            <div className={styles.bottom}>
              <Pagination
                showQuickJumper
                current={pagination.page}
                total={pagination.totalResults}
                pageSize={pagination.limit}
                disabled={false}
                onChange={onChangePage}
              />
            </div>
          </div>
        </Card>
      </div>
      <ConfirmModal
        title="確認"
        message="模範データを削除してもよろしいでしょうか？"
        open={modal === 'delete'}
        onClose={() => setModal('')}
        onConfirm={() => {
          if (currentGroup.id) deleteControlResults({ ctrRsId: ctrRs, groupId: currentGroup.id });
          setTimeout(() => setModal('notice'), 1);
        }}
      />
      {error && (
        <NotiModal
          title="エラー"
          message="この模範データは研修テンプレートで使用されているため削除はできません。"
          open={modal === 'notice'}
          openModal={() => {
            clearErr();
            setModal('');
          }}
          textButton="OK"
        />
      )}
    </div>
  );
};

export default AdmControlResult;
