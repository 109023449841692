import React from 'react';
import MainButton from 'components/MainButton/MainButton';
import styles from './style.module.scss';

const ButtonFooter: React.FC<{
  textBtnPrev?: string;
  textBtnNext?: string;
  textBtnFinish?: string;
  handleClickPre?: () => void;
  handleClickNext?: (buttonFooter?: boolean) => void;
  handleClickFinish?: (buttonFooter?: boolean) => void;
  disabledNextBtn?: boolean;
}> = ({
  handleClickPre,
  handleClickNext,
  handleClickFinish,
  textBtnPrev = '戻る',
  textBtnNext = '進む',
  textBtnFinish = '完了',
  disabledNextBtn = false,
}) => {
  const onClickPre = () => {
    if (handleClickPre) {
      handleClickPre();
    }
  };

  const onClickNext = () => {
    if (handleClickNext) {
      handleClickNext(true);
    }
  };

  const onClickFinish = () => {
    if (handleClickFinish) {
      handleClickFinish(true);
    }
  };

  return (
    <div className={styles.buttonFooter}>
      {handleClickPre && (
        <div className={styles.buttonWrap}>
          <MainButton subButton text={textBtnPrev} onClick={onClickPre} />
        </div>
      )}
      {handleClickNext && (
        <div className={`${styles.buttonWrap} ${disabledNextBtn ? styles.disabled : ''}`}>
          <MainButton text={textBtnNext} onClick={onClickNext} />
        </div>
      )}
      {handleClickFinish && (
        <div className={styles.buttonFinish}>
          <MainButton text={textBtnFinish} onClick={onClickFinish} />
        </div>
      )}
    </div>
  );
};

export default ButtonFooter;
