import { Button, Result } from 'antd';
import { EPath } from 'constants/routes';
import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();
  const goToHome = () => {
    history.push(EPath.root);
  };
  return (
    <>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={goToHome}>
            Back Home
          </Button>
        }
      />
      ;
    </>
  );
};

export default NotFound;
