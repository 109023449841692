import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getControlResults: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'controlResults',
};
const createControlResults: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'controlResults',
};

const updateControlResults: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'controlResults/:id',
};

const deleteControlResults: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'controlResults/:id',
};

const getDetailControlResults: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'controlResults/:id',
};

const uploadControlResults: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'controlResults/upload',
};

export const downloadControlResults: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/controlResults/:id/file',
};

export const controlResultsRepositories = {
  getControlResults: apiClient.getFn(getControlResults),
  getDetailControlResults: apiClient.getFn(getDetailControlResults),
  createControlResults: apiClient.postFn(createControlResults),
  updateControlResults: apiClient.patchFn(updateControlResults),
  deleteControlResults: apiClient.deleteFn(deleteControlResults),
  uploadControlResults: apiClient.postFn(uploadControlResults),
  downloadControlResults: apiClient.getFn(downloadControlResults),
};
