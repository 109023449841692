import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { paginationDefault } from 'constants/pagination';
import { TResponsePagination } from 'libs/axios';
import { TOptionsQuery, TPagination } from 'types/common';
import { TUserDetail } from 'types/groups';

type TShowNotiModal = 'create' | 'update' | 'delete' | '';
export type TGroupsState = {
  loading: boolean;
  users: TUserDetail[];
  error: boolean;
  showModalNoti: TShowNotiModal;
  message: string;
  pagination: TPagination;
  usersByRole: {
    students: {
      pagination: TPagination;
      results: TUserDetail[];
    };
    teachers: {
      pagination: TPagination;
      results: TUserDetail[];
    };
  };
};

const initialState: TGroupsState = {
  loading: false,
  users: [],
  error: false,
  showModalNoti: '',
  message: '',
  pagination: paginationDefault(),
  usersByRole: {
    students: {
      pagination: paginationDefault(),
      results: [],
    },
    teachers: {
      pagination: paginationDefault(),
      results: [],
    },
  },
};

const _module = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    loading(state: TGroupsState) {
      return {
        ...state,
        loading: true,
      };
    },
    stopLoading(state) {
      return {
        ...state,
        loading: false,
      };
    },
    setParams(state: TGroupsState, action: PayloadAction<TOptionsQuery<TUserDetail>>) {
      return {
        ...state,
        params: action.payload,
      };
    },
    createSuccess(state, action: PayloadAction<TShowNotiModal>) {
      return {
        ...state,
        showModalNoti: action.payload,
      };
    },
    clearSuccess(state) {
      return {
        ...state,
        showModalNoti: '',
      };
    },
    saveUsers(state: TGroupsState, action: PayloadAction<TUserDetail>) {
      const newListUsers = current(state.users).map((u) => (u.id === action.payload.id ? action.payload : u));
      return {
        ...state,
        users: newListUsers,
      };
    },
    getListUser: (state: TGroupsState, action: PayloadAction<TResponsePagination<TUserDetail>>) => {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        users: results,
        pagination,
      };
    },
    clearError() {
      return initialState;
    },
    addUser(state, action: PayloadAction<TUserDetail>) {
      state.users.push(action.payload);
    },
    removeUser(state: TGroupsState, action: PayloadAction<number>) {
      const newListUsers = current(state.users).filter((u) => u.id !== action.payload);
      return {
        ...state,
        users: newListUsers,
      };
    },
    getListStudents: (state: TGroupsState, action: PayloadAction<TResponsePagination<TUserDetail>>) => {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        usersByRole: {
          ...state.usersByRole,
          students: {
            results,
            pagination,
          },
        },
      };
    },
    getListTeachers: (state: TGroupsState, action: PayloadAction<TResponsePagination<TUserDetail>>) => {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        usersByRole: {
          ...state.usersByRole,
          teachers: {
            results,
            pagination,
          },
        },
      };
    },
  },
});

export const groupsModule = _module;
