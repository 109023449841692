import { getCurrentFromLS, saveCurrentUserToLS } from 'config/server';
import { push } from 'connected-react-router';
import {
  MESSAGE_CHANGE_PASSWORD_SUCCESS,
  MESSAGE_FORGOT_PASSWORD_SUCCESS,
  MESSAGE_LOGIN_SUCCESS,
  MESSAGE_LOGOUT_SUCCESS,
} from 'constants/notifications/message';
import { EPath } from 'constants/routes';
import { AxiosResponse } from 'libs/axios';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import _ from 'lodash';
import { messageModule } from 'modules/message/message.reducer';
import { uiModule } from 'modules/ui/ui.reducer';
import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { authServices } from 'services/auth';
import { AnyAction } from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { ERole } from 'constants/enums/common';
import actions from './user.action';
import { authModule, TAuth, TAuthWithToken } from './user.reducer';

export const userLogin: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.login),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(authServices.login(payload)),
        next: (v: TAuthWithToken) => {
          let directUrl;
          if (v.currentUser.role === ERole.Admin) {
            directUrl = EPath.S1_TOP;
          } else if (v.currentUser.role === ERole.Teacher) {
            directUrl = EPath.A1;
          } else {
            directUrl = EPath.U3;
          }
          return [authModule.actions.save(v), messageModule.actions.setSuccess(MESSAGE_LOGIN_SUCCESS), push(directUrl)];
        },
        complete: [uiModule.actions.hideLoading()],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const userLogout: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.logout),
    map(() =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(authServices.logout()),
        next: () => [
          push(EPath.login),
          messageModule.actions.setSuccess(MESSAGE_LOGOUT_SUCCESS),
          authModule.actions.logout(),
        ],
        complete: [uiModule.actions.hideLoading()],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const userChangePassword: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.changePassword),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(authServices.changePassword(payload)),
        next: () => messageModule.actions.setSuccess(MESSAGE_CHANGE_PASSWORD_SUCCESS),
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const forgotPassword: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.forgotPassword),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(authServices.forgotPassword(payload)),
        next: () => messageModule.actions.setSuccess(MESSAGE_FORGOT_PASSWORD_SUCCESS),
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const resetPassword: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.resetPassword),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(authServices.resetPassword(payload)),
        next: () => [messageModule.actions.setSuccess(MESSAGE_CHANGE_PASSWORD_SUCCESS), push(EPath.login)],
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getAuth: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getAuth),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFns: from(authServices.getAuth(payload)),
        next: (v: TAuth) => {
          const userLS = getCurrentFromLS();
          if (!_.isEqual(v, userLS)) {
            saveCurrentUserToLS(v.currentUser);
          }
          return [];
        },
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
