import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { groupsServices } from 'services/groups';
import { TUserDetail } from 'types/groups';
import { TResponsePagination, AxiosResponse } from 'libs/axios';
import { messageModule } from 'modules/message/message.reducer';
import actions from './groups.action';
import { groupsModule } from './groups.reducer';

export const getUsersEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getUsers),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsModule.actions.loading(),
        asyncFns: from(groupsServices.getUsers(payload)),
        next: (u: TResponsePagination<TUserDetail>) => [
          groupsModule.actions.getListUser(u),
          groupsModule.actions.saveUsers(payload),
          groupsModule.actions.setParams(payload),
        ],
        complete: groupsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createUsersEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$, state$) =>
  action$.pipe(
    ofAction(actions.createUsers),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsModule.actions.loading(),
        asyncFns: from(groupsServices.createUsers(payload)),
        next: () => {
          const { params } = state$.value.pages.groups;
          return [actions.getUsers(params), groupsModule.actions.createSuccess('create')];
        },
        complete: groupsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateUsersEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateUsers),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsModule.actions.loading(),
        asyncFns: from(groupsServices.updateUsers(payload)),
        next: (u) => [groupsModule.actions.saveUsers(u), groupsModule.actions.createSuccess('update')],
        complete: groupsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const deleteUsersEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.deleteUsers),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsModule.actions.loading(),
        asyncFns: from(groupsServices.deleteUsers(payload)),
        next: (u) => [groupsModule.actions.removeUser(u), groupsModule.actions.createSuccess('delete')],
        complete: groupsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getStudentsEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getStudents),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsModule.actions.loading(),
        asyncFns: from(groupsServices.getUsers(payload)),
        next: (u: TResponsePagination<TUserDetail>) => [groupsModule.actions.getListStudents(u)],
        complete: groupsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getTeachersEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTeachers),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: groupsModule.actions.loading(),
        asyncFns: from(groupsServices.getUsers(payload)),
        next: (u: TResponsePagination<TUserDetail>) => [groupsModule.actions.getListTeachers(u)],
        complete: groupsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
