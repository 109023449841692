export const columnsTableUsers = [
  {
    key: 'name',
    title: '氏名',
    dataIndex: 'name',
    width: '45%',
  },
  {
    key: 'email',
    title: 'メールアドレス',
    dataIndex: 'email',
    width: '45%',
  },
];
