import { EPath } from 'constants/routes';
import { replacePathParams } from 'libs/helpers/functions';
import { answersAction } from 'modules/answers/answers.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppState } from 'store';
import AssignmentsRfpAboutTool from './AssignmentsRfpAboutTool';

const AssignmentsRfpAboutToolContainer = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const getTaskDetail = (groupId: number, id: string, isSaved: boolean) => {
    dispatch(answersAction.getDetailTaskForStudent({ groupId, id, isSaved }));
  };
  const answersState = useSelector((state: AppState) => state.pages.answers);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const handleNextPage = () => history.push(replacePathParams(EPath.U5_2, { id }));
  return (
    <AssignmentsRfpAboutTool
      handleNextPage={handleNextPage}
      getTaskDetail={getTaskDetail}
      answersState={answersState}
      idAssignment={id}
      currentGroup={currentGroup}
    />
  );
};

export default AssignmentsRfpAboutToolContainer;
