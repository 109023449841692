import React from 'react';
import { Row, Card, Form, Checkbox, Input } from 'antd';
import { TUser } from 'types/auth';
import { defaultValidateMessages } from 'constants/validate/message';
import { Link } from 'react-router-dom';
import { EPath } from 'constants/routes';
import MainButton from '../../components/MainButton/MainButton';
import styles from './style.module.scss';

const Login: React.FC<{ login: (data: TUser) => void }> = ({ login }) => {
  const handelSubmit = (values: { email: string; password: string }) => {
    login(values);
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Card type="inner" title="ログイン" className={styles.cardWrap}>
        <div className={styles.formWarp}>
          <Form layout="vertical" onFinish={handelSubmit} validateMessages={defaultValidateMessages}>
            <Form.Item
              label="メールアドレス"
              name="email"
              rules={[
                { required: true, whitespace: true },
                { type: 'email', message: defaultValidateMessages.types.email },
              ]}
            >
              <Input name="email" placeholder="Enter your email" />
            </Form.Item>
            <Form.Item label="パスワード" name="password">
              <Input.Password name="password" placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Checkbox>
                ログイン状態を保存
                <Link to={EPath.resetPassword} className={styles.forgotPw}>
                  パスワードをお忘れの方
                </Link>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <MainButton htmlType="submit" text="ログイン" />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Row>
  );
};

export default Login;
