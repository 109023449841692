import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getUsers: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'users',
};
const createUsers: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'users',
};

const updateUsers: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'users/:id',
};

const deleteUsers: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'users/:id',
};

export const groupsRepositories = {
  getUsers: apiClient.getFn(getUsers),
  createUsers: apiClient.postFn(createUsers),
  updateUsers: apiClient.patchFn(updateUsers),
  deleteUsers: apiClient.deleteFn(deleteUsers),
};
