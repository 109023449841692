import { TNavSteps } from 'types/common';

export const navStep: TNavSteps = [
  { name: 'STEP1', label: '栽培基本ルール', link: '/adm/templates/information/:id' },
  { name: 'STEP2', label: '栽培基本問題', link: '/adm/templates/cultivation/basics/:id' },
  { name: 'STEP3', label: '栽培イベント問題', link: '/adm/templates/cultivation/events/:id' },
  { name: 'STEP4', label: '制御問題', link: '/adm/templates/control/questions/:id' },
  { name: 'STEP5', label: '制御シミュレーション補正値', link: '/adm/templates/control/simulation/:id' },
  { name: 'STEP6', label: '模範データ選択', link: '/adm/templates/cultivation/add/:id' },
  { name: 'STEP7', label: '模範データ閾値', link: '/adm/templates/cultivation/simulation/:id' },
];

export const dataNavLinkStage = [
  {
    to: '/adm/z/assignments/3/questions',
    title: '総合評価',
  },
  {
    to: '/adm/z/assignments/1/questions',
    title: '栽培問題',
  },
  {
    to: '/adm/z/assignments/2/questions',
    title: '制御問題',
  },
];
