import React from 'react';
import { IndexedObject } from 'constants/types';
import { Row } from 'antd';
import { Bullet } from '@ant-design/plots';
import styles from './style.module.scss';

type Props = {
  configChart: IndexedObject;
};

const BulletChart = ({ configChart }: Props) => (
  <Row justify="center">
    <Bullet className={styles.progressPlot} {...(configChart as any)} />
  </Row>
);

export default BulletChart;
