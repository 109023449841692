/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, Select } from 'antd';
import type { InputRef } from 'antd';
import { logger } from 'libs/logger';
import { EditableContext } from 'components/EditTableColRow/EditableRow';
import { TStep2, TStages } from 'types/template';
import { omit } from 'lodash';
import { OPTION_SCORE, OPTION_STAGE_NAME, STAGE_NAME } from './AdmTempBasic.state';
import styles from './style.module.scss';

const EditableCellStep2: React.FC<{
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: TStep2;
  title: string;
  handleSave: (record: TStep2) => void;
  stages: TStages[];
}> = ({ editable, children, dataIndex, record, handleSave, stages, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const inputNumberRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext);

  const defaultOption = 'なし';

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      inputNumberRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (form)
      form.setFieldsValue({
        [dataIndex]: record[dataIndex as keyof TStep2],
        option1: record.options[0].value,
        option2: record.options[1].value,
        option3: record.options[2].value,
        option4: record.options[3].value,
        correctAnswer: record.options.find((item) => item.isCorrect)?.index,
      });
  };

  const save = async () => {
    try {
      const values = form && (await form.validateFields());
      toggleEdit();
      handleSave({
        ...record,
        ...omit(values, ['option1', 'option2', 'option3', 'option4', 'correctAnswer']),
        options: record.options.map((item, index) => {
          const newValues = values[`option${index + 1}`];
          return {
            ...item,
            value: newValues === undefined ? item.value : newValues || defaultOption,
            isCorrect: values.correctAnswer ? index + 1 === Number(values.correctAnswer) : item.isCorrect,
          };
        }),
        score: values.score ? Number(values.score) : record.score,
      });
    } catch (errInfo) {
      logger.log('message', errInfo);
    }
  };

  let childNode = children;
  let inputNode = <></>;

  switch (dataIndex) {
    case 'correctAnswer':
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <Select
            defaultValue={record.options.findIndex((o) => o.isCorrect) + 1}
            options={record.options.map((o, index) => ({
              value: (index + 1).toString(),
              label: (index + 1).toString(),
            }))}
            onChange={save}
          />
        </Form.Item>
      );
      break;
    case 'score':
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <Select
            defaultValue={record.options.findIndex((o) => o.isCorrect) + 1}
            options={OPTION_SCORE}
            onChange={save}
          />
        </Form.Item>
      );
      break;
    case 'stageName':
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <Select
            defaultValue={STAGE_NAME.STAGE_PLANTING}
            style={{ width: '100%' }}
            bordered
            options={stages?.length > 0 ? stages?.map((s) => ({ label: s.name, value: s.name })) : OPTION_STAGE_NAME}
            onChange={save}
          />
        </Form.Item>
      );
      break;
    default:
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 10 }}
            className={styles.widthMax}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      );
  }

  if (editable) {
    childNode = editing ? (
      inputNode
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit} onKeyDown={toggleEdit} role="button" tabIndex={0}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCellStep2;
