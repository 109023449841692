import { uploadRepositories } from 'repositories/upload';

const uploadImage = async (id: number, data: FormData) => {
  const res = await uploadRepositories.uploadImage({
    body: data,
    condition: {
      templateId: id,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const uploadServices = {
  uploadImage,
};
