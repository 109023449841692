import React from 'react';
import { answersAction } from 'modules/answers/answers.action';
import { answersModule } from 'modules/answers/answers.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { TAnswerAQuestion, TAnswerSubmit } from 'types/assignments';
import AssignmentsBasicQuestion from './AssignmentsBasicQuestion';
import { TQuesParams } from './AssignmentsBasicQuestion.state';

const AssignmentsBasicQuestionContainer = () => {
  const dispatch = useDispatch();
  const getQuestionList = (dataId: TQuesParams) => {
    dispatch(answersAction.getQuestion(dataId));
  };
  const getTaskDetail = (id: string, groupId: number, isSaved: boolean) => {
    dispatch(answersAction.getDetailTaskForStudent({ id, isSaved, groupId }));
  };
  const updateBasicAnswer = (data: Array<TAnswerAQuestion>) => {
    dispatch(answersModule.actions.saveBasicAnswer(data));
  };
  const answersState = useSelector((state: AppState) => state.pages.answers);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const submitAnswerBasicTask = (data: TAnswerSubmit) => {
    dispatch(answersAction.submitAnswerBasicTask(data));
  };
  const getResults = (id: string, sid: string) => {
    dispatch(answersAction.getResultAssignmentOneStudent({ id, sid }));
  };
  return (
    <AssignmentsBasicQuestion
      updateBasicAnswer={updateBasicAnswer}
      getQuestionList={getQuestionList}
      answersState={answersState}
      submitAnswerBasicTask={submitAnswerBasicTask}
      currentGroup={currentGroup}
      getTaskDetail={getTaskDetail}
      getResults={getResults}
    />
  );
};

export default AssignmentsBasicQuestionContainer;
