import React, { useCallback } from 'react';
import templateAction from 'modules/template/template.action';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { templateModule, TTemplateState } from 'modules/template/template.reducer';
import AdmTempEvent from './AdmTempEvent';
import { TStep3State } from './AdmTempEvent.state';

const AdmTempEventContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const updateTemplate = useCallback(
    (
      data: TStep3State,
      id: number,
      groupId: number,
      buttonFooterNext: boolean = false,
      buttonFooterPre: boolean = false,
    ) => {
      dispatch(templateAction.updateTemplateStep3({ data, id, groupId, buttonFooterNext, buttonFooterPre }));
    },
    [],
  );
  const saveTemplate = (data: TTemplateState) => {
    dispatch(templateModule.actions.saveTemplate(data));
  };

  const uploadCsvQuestionFile = (data: { file: FormData; id: number; groupId: number; step: number }) => {
    dispatch(templateAction.uploadCsvQuestionFile(data));
  };
  const downloadCsvQuestionFile = (data: { id: number; groupId: number; step: number }) => {
    dispatch(templateAction.downloadCsvQuestionFile(data));
  };
  return (
    <AdmTempEvent
      template={template}
      updateTemplate={updateTemplate}
      saveTemplate={saveTemplate}
      currentGroup={currentGroup}
      uploadCsvQuestionFile={uploadCsvQuestionFile}
      downloadCsvQuestionFile={downloadCsvQuestionFile}
    />
  );
};

export default AdmTempEventContainer;
