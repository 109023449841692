import React from 'react';
import { Line } from '@ant-design/plots';
import { Col, Row } from 'antd';
import { aliasValue } from 'constants/date';
import CircleIcon from 'components/Icons/CircleIcon';
import { checkUnitLineChart } from 'libs/helpers/functions';
import { DESC_CALCULATED_DATA, DESC_CORRECT_ANSWERS, DESC_STUDENT_SETTINGS } from 'constants/common';
import styles from './style.module.scss';

const LineChart: React.FC<{
  configChart?: any;
  className?: string;
  desc3?: string;
  isAnswer?: string[];
  alias?: string;
  fixedDesc?: boolean;
  handleBackPage?: (month: string) => void;
}> = ({ configChart, className, desc3, isAnswer, handleBackPage, alias, fixedDesc }) => {
  const unitData = checkUnitLineChart(alias);
  let description;
  if (alias === aliasValue.C1 || alias === aliasValue.C2 || alias === aliasValue.C3) {
    if (fixedDesc) {
      description = {
        desc1: DESC_CALCULATED_DATA,
        desc2: DESC_STUDENT_SETTINGS,
      };
    } else {
      description = {
        desc1: DESC_CORRECT_ANSWERS,
        desc2: DESC_CALCULATED_DATA,
      };
    }
  } else {
    description = {
      desc1: DESC_CORRECT_ANSWERS,
      desc2: DESC_STUDENT_SETTINGS,
    };
  }
  return (
    <>
      <Row align="middle">
        <Col span={2} className={styles.unitWrapper}>
          <p className={styles.marginLeft}>{unitData.name}</p>
          <p className={styles.marginLeft}>{unitData.value}</p>
        </Col>
        <Col span={22}>
          <Line
            className={className}
            {...configChart}
            onReady={(plot) => {
              plot.on('axis-label:click', (evt: any) => {
                if (handleBackPage) {
                  handleBackPage(evt.gEvent.target.attrs.text);
                }
              });
            }}
          />
          {alias !== aliasValue.C1 && alias !== aliasValue.C2 && alias !== aliasValue.C3 && desc3 && (
            <>
              <Row align="middle" className={styles.redColor}>
                <div className={styles.circle}> </div> <span>{desc3}</span>
              </Row>
            </>
          )}
          {isAnswer && (
            <>
              <Row align="middle" className={styles.redColor}>
                <span className={styles.unanswered}>{isAnswer[0]}</span>
                <span className={styles.answered}>{isAnswer[1]}</span>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <div className={styles.descriptionPlotWrapper}>
        <Row align="middle" className={styles.redColor}>
          <CircleIcon /> <span>{description.desc1}</span>
        </Row>
        <Row align="middle" className={styles.blueColor}>
          <CircleIcon /> <span>{description.desc2}</span>
        </Row>
      </div>
    </>
  );
};

export default LineChart;
