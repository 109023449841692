import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TAnswerAQuestion,
  TControlAnswer,
  TDetailTask,
  TQuestion,
  TResultAssignmentForOneStudent,
} from 'types/assignments';
import {
  saveBasicAnswerToLocalStorage,
  saveEventAnswerToLocalStorage,
  saveControlAnswersToLocalStorage,
  getControlAnswersFromLS,
  saveDataSimulationsToLocalStorage,
  setEnabledEditMode,
} from 'libs/helpers/localStorageFn';
import { TDataSimulations } from 'types/results';

export type TAnswersState = {
  detailTask: TDetailTask;
  basicAnswer: Array<TAnswerAQuestion> | null;
  eventAnswer: Array<TAnswerAQuestion> | null;
  controlAnswers: Array<TControlAnswer> | null;
  currentQuestion: TQuestion;
  resultAssignment: TResultAssignmentForOneStudent;
  dataSimulations: TDataSimulations | null;
  enabledDemonstrationMode: boolean;
};

const initialState: TAnswersState = {
  detailTask: {},
  basicAnswer: null,
  eventAnswer: null,
  controlAnswers: [],
  currentQuestion: {},
  resultAssignment: {},
  dataSimulations: null,
  enabledDemonstrationMode: false,
};

const _module = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    saveTaskDetail(state: TAnswersState, action: PayloadAction<TDetailTask>) {
      setEnabledEditMode(action.payload.enabledDemonstrationMode ?? false);
      return {
        ...state,
        detailTask: action.payload,
        controlAnswers: getControlAnswersFromLS(action.payload.id as number),
      };
    },
    saveBasicAnswer(state: TAnswersState, action: PayloadAction<Array<TAnswerAQuestion>>) {
      saveBasicAnswerToLocalStorage(action.payload);
      return {
        ...state,
        basicAnswer: action.payload,
      };
    },
    saveEventAnswer(state: TAnswersState, action: PayloadAction<Array<TAnswerAQuestion>>) {
      saveEventAnswerToLocalStorage(action.payload);
      return {
        ...state,
        eventAnswer: action.payload,
      };
    },
    saveCurrentQuestion(state: TAnswersState, action: PayloadAction<TQuestion>) {
      return {
        ...state,
        currentQuestion: action.payload,
      };
    },
    saveResultAssignment(state: TAnswersState, action: PayloadAction<TResultAssignmentForOneStudent>) {
      return {
        ...state,
        resultAssignment: action.payload,
      };
    },
    saveControlAnswers(state: TAnswersState, action: PayloadAction<{ id: string; answers: TControlAnswer[] }>) {
      saveControlAnswersToLocalStorage(action.payload);
      return {
        ...state,
        controlAnswers: action.payload.answers,
      };
    },
    saveCommentAssignment(state: TAnswersState, action: PayloadAction<string>) {
      return {
        ...state,
        resultAssignment: {
          ...state.resultAssignment,
          comments: [{ content: action.payload }],
        },
      };
    },
    saveDataSimulations(state: TAnswersState, action: PayloadAction<TDataSimulations>) {
      if (action.payload.correctSimulations) {
        saveDataSimulationsToLocalStorage(action.payload);
      }
      return {
        ...state,
        dataSimulations: action.payload,
        enabledDemonstrationMode: action.payload.enabledDemonstrationMode ?? false,
      };
    },
    clearDataBasicAnswer(state: TAnswersState) {
      return {
        ...state,
        basicAnswer: null,
        eventAnswer: null,
      };
    },
    setEnabledDemonstrationMode(state: TAnswersState, action: PayloadAction<boolean>) {
      return {
        ...state,
        enabledDemonstrationMode: action.payload,
      };
    },
    clearResultBasicTask(state: TAnswersState) {
      return {
        ...state,
        resultAssignment: { ...state.resultAssignment, basicResults: [], eventResults: [] },
      };
    },
  },
});

export const answersModule = _module;
