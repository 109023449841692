import React from 'react';
import styles from './style.module.scss';

type Props = {
  size?: number;
};

const CircleIcon = ({ size = 10 }: Props) => (
  <div className={`${styles.iconComponent} size${size}`}>
    <svg width={size} height={size} viewBox="0 0 10 10" fill="none">
      <circle cx="5" cy="5" r="3.5" strokeWidth="3" />
    </svg>
  </div>
);
export default CircleIcon;
