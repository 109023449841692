import templateAction from 'modules/template/template.action';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import { templateModule } from 'modules/template/template.reducer';
import useGetTemplateLs from './useGetTemplateLs';

const EditTemplate = () => {
  const { id } = useParams<{ id: string }>();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const [dataTemp] = useGetTemplateLs();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(dataTemp).length > 0 && currentGroup.id) {
      templateModule.actions.saveTemplate(dataTemp);
      dispatch(templateAction.getTemplateAndSaveId({ id, groupId: currentGroup.id }));
      return;
    }
    if (id && id !== `${template.id}` && currentGroup.id) {
      dispatch(templateAction.getTemplateId({ id, groupId: currentGroup.id }));
    }
  }, [id, currentGroup]);
};

export default EditTemplate;
