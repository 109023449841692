import groupsClassAction from 'modules/groupsClass/groupsClass.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { EPath } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { replacePathParams } from 'libs/helpers/functions';
import { TGroupsClassDetail } from 'types/groupsClass';
import { TOptionsQuery } from 'types/common';
import GroupsManage from './GroupsManage';

const GroupsManageContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const groupsClassState = useSelector((state: AppState) => state.pages.groupsClass);

  const deleteGroupsClass = (id: number) => {
    dispatch(groupsClassAction.deleteGroupsClass(id));
  };
  const goGroupsDetailPage = (groupId: number) =>
    history.push(replacePathParams(EPath.S3_3, { id: groupId.toString() }));

  const getGroupsClass = (params: TOptionsQuery<TGroupsClassDetail>) => {
    dispatch(groupsClassAction.getGroupsClass(params));
  };
  return (
    <GroupsManage
      groupsClassState={groupsClassState}
      deleteGroupsClass={deleteGroupsClass}
      goGroupsDetailPage={goGroupsDetailPage}
      getGroupsClass={getGroupsClass}
    />
  );
};

export default GroupsManageContainer;
