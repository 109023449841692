import { combineReducers } from 'redux';
import { authModule } from './user/user.reducer';
import { uiModule } from './ui/ui.reducer';
import { githubModule } from './github/github.reducer';
import { groupsModule } from './groups/groups.reducer';
import { messageModule } from './message/message.reducer';
import { templatesModule } from './templates/templates.reducer';
import { templateModule } from './template/template.reducer';
import { assignmentsModule } from './assignments/assignments.reducer';
import { answersModule } from './answers/answers.reducer';
import { uploadModule } from './upload/upload.reducer';
import { groupsClassModule } from './groupsClass/groupsClass.reducer';
import { controlResultsModule } from './controlResults/controlResults.reducer';

const reducers = {
  ui: uiModule.reducer,
  auth: authModule.reducer,
  github: githubModule.reducer,
  message: messageModule.reducer,
  groups: groupsModule.reducer,
  templates: templatesModule.reducer,
  template: templateModule.reducer,
  assignments: assignmentsModule.reducer,
  answers: answersModule.reducer,
  upload: uploadModule.reducer,
  groupsClass: groupsClassModule.reducer,
  controlResults: controlResultsModule.reducer,
};

export default combineReducers(reducers);
