import React from 'react';
import { TResultAssignmentForOneStudent } from 'types/assignments';
import TableResultDetail from 'components/TableResultDetail/TableResultDetail';
import TableResultBasicTask from 'components/TableResultBasicTask/TableResultBasicTask';
import styles from './style.module.scss';
import './style.css';

type Props = {
  resultAssignment: TResultAssignmentForOneStudent;
};
const AssignmentsResultControlAdm = ({ resultAssignment }: Props) => {
  const basicScore = resultAssignment.basicResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const eventScore = resultAssignment.eventResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const basicTaskResults = resultAssignment.basicResults?.map((e, i) => ({
    ...e,
    key: i,
  }));
  const eventTaskResults = resultAssignment.eventResults?.map((e, i) => ({
    ...e,
    key: i,
  }));
  return (
    <>
      <p className={styles.bodySection}>採点結果</p>
      <hr />
      <TableResultBasicTask maxScore={resultAssignment.maxScore} basicScore={basicScore} eventScore={eventScore} />

      <TableResultDetail dataTable={basicTaskResults ?? []} title1="解答解説" title2="基本問題" />

      <TableResultDetail dataTable={eventTaskResults ?? []} title1="解答解説" title2="イベント問題" />
    </>
  );
};

export default AssignmentsResultControlAdm;
