/* eslint-disable complexity */
import axios, { AxiosError, AxiosInstance } from 'axios';
import { checkUserInfo, getAccessToken, getRefreshToken, setRefreshToken, setToken } from 'config/server';
import { HttpStatusCode } from 'constants/enums';
import { EPath } from 'constants/routes';
import { IndexedObject } from 'constants/types';
import { postLogin, refreshTokens } from 'repositories/auth';
import { authServices } from 'services/auth';

type AxiosResponse = {
  result: boolean;
  data: IndexedObject;
};

export const throwError = (error: AxiosError<IndexedObject>): Promise<AxiosResponse> => {
  const responseError: AxiosResponse = { result: false, data: error.response?.data ?? {} };
  throw responseError;
};

export class AxiosHelper {
  private static instance: AxiosHelper;

  _axios: AxiosInstance;

  private constructor(param: any) {
    this._axios = axios.create(param);

    this._axios.interceptors.request.use(
      (config) => {
        const token = getAccessToken();

        const newConfig = { ...config };
        if (token && newConfig.headers) {
          newConfig.headers.authorization = `Bearer ${token}`;
        }
        return newConfig;
      },
      (error) => Promise.reject(error),
    );

    this._axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        const refreshToken = getRefreshToken();
        // Error when obtaining Refresh Token
        if (originalConfig.url === refreshTokens.url) {
          if (window.location.pathname !== EPath.login) {
            window.location.replace(EPath.login);
          }
          return Promise.reject(err);
        }
        if (originalConfig.url !== postLogin.url && err.response) {
          // Access Token was expired
          if (err.response.status === HttpStatusCode.UNAUTHORIZED && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
              const res = await authServices.refreshTokens(refreshToken);
              const newToken = res.data.access.token;
              const newRefreshToken = res.data.refresh.token;

              setToken(newToken);
              setRefreshToken(newRefreshToken);

              return await this._axios({
                ...originalConfig,
                headers: {
                  ...originalConfig.headers,
                },
              });
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
          if ((err.response.status === HttpStatusCode.UNAUTHORIZED && originalConfig._retry) || !checkUserInfo) {
            window.location.replace(EPath.login);
          }
        }
        return Promise.reject(err);
      },
    );
  }

  public static getInstance(param: any): AxiosHelper {
    if (!AxiosHelper.instance) {
      AxiosHelper.instance = new AxiosHelper(param);
    }

    return AxiosHelper.instance;
  }

  public get Axios(): AxiosInstance {
    return this._axios;
  }
}
