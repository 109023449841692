import React from 'react';
import { parseSearchParams } from 'libs/helpers/functions';
import { useLocation } from 'react-router-dom';
import groupsAction from 'modules/groups/groups.action';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TUserDetail } from 'types/groups';
import { TOptionsQuery, TOptionsQueryExtra } from 'types/common';
import GroupsAdm from './GroupsAdm';

const GroupsAdmContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const urlQueryParams: TOptionsQuery<TUserDetail> = parseSearchParams(location.search);
  const groupsState = useSelector((state: AppState) => state.pages.groups);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const getUsers = (params: TOptionsQueryExtra<TUserDetail>) =>
    dispatch(groupsAction.getUsers({ ...params, sortBy: 'id:desc' }));

  const setUsers = (data: { user: TUserDetail; groupId: number }) => dispatch(groupsAction.updateUsers(data));
  const deleteUsers = (data: { id: number; groupId: number }) => dispatch(groupsAction.deleteUsers(data));
  return (
    <GroupsAdm
      getUsers={getUsers}
      groupsState={groupsState}
      setUsers={setUsers}
      location={location}
      urlQueryParams={urlQueryParams}
      deleteUsers={deleteUsers}
      currentGroup={currentGroup}
    />
  );
};

export default GroupsAdmContainer;
