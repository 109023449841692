import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationDefault } from 'constants/pagination';
import { TResponsePagination } from 'libs/axios';
import { TPagination } from 'types/common';
import { TTemplateDetail } from 'types/templates';

export type TTemplatesState = {
  templates: TTemplateDetail[];
  pagination: TPagination;
  templatesAll: TTemplateDetail[];
  error: boolean;
};

const initialState: TTemplatesState = {
  templates: [],
  pagination: paginationDefault(),
  templatesAll: [],
  error: false,
};

const _module = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    saveTemplates(state: TTemplatesState, action: PayloadAction<TResponsePagination<TTemplateDetail>>) {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        templates: results,
        pagination,
      };
    },
    saveTemplatesAll(state: TTemplatesState, action: PayloadAction<TTemplateDetail[]>) {
      return {
        ...state,
        templatesAll: action.payload,
      };
    },
    deleteTemplate(state: TTemplatesState, action: PayloadAction<string>) {
      return {
        ...state,
        templates: state.templates.filter((u) => u.id !== action.payload),
      };
    },

    setError(state: TTemplatesState, action: PayloadAction<boolean>) {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
});

export const templatesModule = _module;
