import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { answersAction } from 'modules/answers/answers.action';
import { answersModule } from 'modules/answers/answers.reducer';
import { AppState } from 'store';
import AssignmentsStart from './AssignmentsStart';

const AssignmentsStartContainer = () => {
  const dispatch = useDispatch();
  const getTaskDetail = (id: string, groupId: number, isSaved: boolean) => {
    dispatch(answersAction.getDetailTaskForStudent({ id, isSaved, groupId }));
  };
  const clearResult = () => {
    dispatch(answersModule.actions.clearResultBasicTask());
  };
  const answersState = useSelector((state: AppState) => state.pages.answers);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  return (
    <AssignmentsStart
      getTaskDetail={getTaskDetail}
      clearResult={clearResult}
      answersState={answersState}
      currentGroup={currentGroup}
    />
  );
};

export default AssignmentsStartContainer;
