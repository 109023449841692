import React from 'react';
import { Row } from 'antd';
import styles from './style.module.scss';

const NavQuestionStage: React.FC<{ monthData: number[]; currentMonth: number; onClick: (m: number) => void }> = ({
  currentMonth,
  onClick,
  monthData,
}) => {
  const handleClick = (month: number) => {
    onClick(month);
  };

  return (
    <Row>
      <ul className={styles.stepWrap}>
        {monthData
          .map((m) => ({ month: m, label: `${m}月` }))
          .map((step) => (
            <li
              key={step.month}
              className={`${styles.step} ${step.month === currentMonth ? styles.active : ''}`}
              onClick={() => handleClick(step.month)}
              onKeyUp={() => {}}
            >
              {step.label}
            </li>
          ))}
      </ul>
    </Row>
  );
};

export default NavQuestionStage;
