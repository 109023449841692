import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

export const DEFAULT_FORMAT_DATE = 'YYYY-MM-DD';

export const disabledDateBeforeToday: RangePickerProps['disabledDate'] = (current) =>
  // Can not select days before today
  current && current < dayjs().startOf('day');

export const stageStep = [
  { name: 'STEP1', label: '定植期～主枝摘心期', value: '定植期～主枝摘心期（10月）', link: '1' },
  { name: 'STEP2', label: '収穫開始期・前期', value: '収穫開始期・前期（11・12月）', link: '2' },
  { name: 'STEP3', label: '収穫中期', value: '収穫中期（1・2月）', link: '3' },
  { name: 'STEP4', label: '収穫後期', value: '収穫後期（3・4月）', link: '4' },
];

export enum aliasValue {
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M5 = 'M5',
  M6 = 'M6',
  M7 = 'M7',
  M8 = 'M8',
  M9 = 'M9',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
}
