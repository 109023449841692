import { UploadFile } from 'antd';
import { RcFile } from 'antd/lib/upload';
import templateAction from 'modules/template/template.action';
import uploadAction from 'modules/upload/upload.action';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TNewTemplate } from 'types/template';
import templatesAction from 'modules/templates/templates.action';
import { templateModule } from 'modules/template/template.reducer';
import TemplateInfo from './TemplateInfo';

const TemplateInfoContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { templatesAll } = useSelector((state: AppState) => state.pages.templates);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const updateTemplate = (
    fileList: UploadFile[],
    data: TNewTemplate,
    id: number,
    buttonFooterNext: boolean,
    image: string,
    groupId: number,
  ) => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file.originFileObj as RcFile);
    });

    dispatch(templateAction.updateTemplateStep1({ formData, data, id, buttonFooterNext, image, groupId }));
  };

  const updateImage = (id: number, data: FormData) => {
    dispatch(uploadAction.uploadImage({ id, data }));
  };

  const createTemplate = useCallback(
    (fileList: UploadFile[], payload: TNewTemplate) => {
      const formData = new FormData();

      fileList.forEach((file) => {
        formData.append('file', file.originFileObj as RcFile);
      });

      dispatch(templateAction.createTemplate({ formData, data: payload, groupId: currentGroup.id ?? 0 }));
    },
    [dispatch],
  );

  const createTemplateWithoutImage = useCallback(
    (payload: TNewTemplate) => {
      dispatch(templateAction.createTemplateWithoutImage({ data: payload, groupId: currentGroup.id ?? 0 }));
    },
    [dispatch],
  );

  const getAllTemplates = (data: { groupId: number }) => {
    dispatch(templatesAction.getAllTemplates(data));
  };

  const getDetailTemplate = (data: { groupId: number; id: string }) => {
    dispatch(templateAction.getTemplateId(data));
  };

  const resetTemplate = () => {
    dispatch(templateModule.actions.clearState());
  };
  const setIsLinkedTraining = () => {
    dispatch(templateModule.actions.setIsLinkedWithTraining(false));
  };
  return (
    <TemplateInfo
      createTemplate={createTemplate}
      createTemplateWithoutImage={createTemplateWithoutImage}
      updateTemplate={updateTemplate}
      template={template}
      updateImage={updateImage}
      currentGroup={currentGroup}
      templatesAll={templatesAll}
      getAllTemplates={getAllTemplates}
      getDetailTemplate={getDetailTemplate}
      resetTemplate={resetTemplate}
      setIsLinkedTraining={setIsLinkedTraining}
    />
  );
};

export default TemplateInfoContainer;
