import React, { ReactNode } from 'react';
import { Button, Modal, Space } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import styles from './style.module.scss';

const NotiModal: React.FC<{
  title: string;
  message: string | ReactNode;
  open: boolean;
  openModal: () => void;
  isSubButton?: boolean;
  textButton: string;
  isButtonPre?: boolean;
  textButtonPre?: string;
}> = ({ title, open, openModal, message, isSubButton = false, textButton, isButtonPre, textButtonPre }) => (
  <Modal
    title={title}
    centered
    open={open}
    onOk={openModal}
    onCancel={openModal}
    width={498}
    bodyStyle={{
      padding: '16px 40px',
    }}
    footer={
      <div>
        <Space align="center" className={isButtonPre ? styles.footer_modal : ''}>
          {textButtonPre && (
            <Button className={isButtonPre ? styles.buttonPre : styles.buttonCancel} size="large" onClick={openModal}>
              {textButtonPre}
            </Button>
          )}
          <MainButton subButton={isSubButton} text={textButton} onClick={openModal} />
        </Space>
      </div>
    }
  >
    {message}
  </Modal>
);

export default NotiModal;
