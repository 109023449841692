import React from 'react';
import styles from './style.module.scss';

type Props = {
  size?: number;
};

const WrongAnswerIcon = ({ size = 14 }: Props) => (
  <div className={`${styles.iconComponent} size${size}`}>
    <svg width={size} height={size} viewBox="0 0 14 14" fill="#4F4F4F">
      <path d="M11.406 0.0839829L13.656 2.27398L9.18598 6.74398L13.596 11.184L11.406 13.404L6.93598 8.99398L2.52598 13.374L0.335981 11.154L4.68598 6.74398L0.305981 2.33398L2.52598 0.0839829L6.93598 4.46398L11.406 0.0839829Z" />
      <svg width={size} height={size} viewBox="0 0 14 14">
        <path d="M11.406 0.0839829L13.656 2.27398L9.18598 6.74398L13.596 11.184L11.406 13.404L6.93598 8.99398L2.52598 13.374L0.335981 11.154L4.68598 6.74398L0.305981 2.33398L2.52598 0.0839829L6.93598 4.46398L11.406 0.0839829Z" />
      </svg>
    </svg>
  </div>
);
export default WrongAnswerIcon;
