import { TPagination } from 'types/common';

export const DEFAULT_LIMIT_PAGINATION = 10;
export const paginationDefault = (limit?: number): TPagination => ({
  page: 1,
  limit: limit ?? DEFAULT_LIMIT_PAGINATION,
  totalPages: 1,
  totalResults: 0,
});

export const pageSizeOptions = [10, 20, 30, 50, 100];
