import React, { ReactElement } from 'react';
import { Link, NavLink, Route, Switch, useHistory } from 'react-router-dom';
import { Button, Row, Select, Space, Col, Typography } from 'antd';
import { EPath } from 'constants/routes';
import { TResultsTraining } from 'types/assignments';
import { replacePathParams } from 'libs/helpers/functions';
import styles from './style.module.scss';

const { Option } = Select;

export type TArrayPathNavLinkU8 = {
  to: string;
  title: string;
  component: ReactElement;
};

type Props = {
  isShowLoading: boolean;
  arrayPathU8: TArrayPathNavLinkU8[];
  assignmentId: string;
  studentId: string;
  resultsAssignment: TResultsTraining;
};

const AssignmentResultStudents = ({
  isShowLoading,
  arrayPathU8,
  assignmentId,
  studentId,
  resultsAssignment,
}: Props) => {
  const history = useHistory();
  const onChangeStudent = (studentId: string) => {
    history.push(replacePathParams(EPath.A8_3, { id: assignmentId, sid: studentId }));
  };

  return (
    <div className="groupsAdm">
      <div className={styles.groups}>
        <Row justify="space-between">
          <Col>
            <Row>
              <p className={styles.groupsTitle}>制御シミュレーション結果</p>
              <p>{resultsAssignment.name}</p>
            </Row>
          </Col>
          <Col>
            <Typography.Text underline>栽培ルール確認</Typography.Text>
          </Col>
        </Row>

        <div className={styles.controlGroup}>
          <div className={styles.controlLeft}>
            <Space className={`${styles.topGroup} ${styles.topGroupButton}`}>
              <Link to={replacePathParams(EPath.A7, { id: assignmentId })}>
                <Button className={styles.topButton}>研修回答結果一覧</Button>
              </Link>
            </Space>
            <Space className={styles.topGroup} direction="horizontal">
              <Select
                defaultValue={studentId}
                className={styles.selectUserResult}
                loading={isShowLoading}
                onChange={onChangeStudent}
              >
                {resultsAssignment.results?.map((r) => (
                  <Option key={r.studentId} value={r.studentId?.toString()}>
                    {r.studentName}
                  </Option>
                ))}
              </Select>
            </Space>
          </div>
          <Space className={styles.topGroup}>
            <Link to={EPath.A1}>
              <Button className={styles.topButton}>TOPにもどる</Button>
            </Link>
          </Space>
        </div>

        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.navGroupButton}>
              {arrayPathU8.map((l) => (
                <NavLink key={l.to} to={`${l.to}`} className={styles.linkButton} activeClassName={styles.activeLink}>
                  <div>{l.title}</div>
                </NavLink>
              ))}
            </div>
            <div className={styles.body}>
              <Switch>
                {arrayPathU8.map((r) => (
                  <Route exact path={r.to} key={r.title}>
                    {r.component}
                  </Route>
                ))}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentResultStudents;
