import React, { useEffect } from 'react';
import Task from 'components/Task/Task';
import MainButton from 'components/MainButton/MainButton';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { replacePathParams, splitNameAndTime } from 'libs/helpers/functions';
import { clearAnswerBasicTaskInLocalStorage } from 'libs/helpers/localStorageFn';
import { TTrainingDetail } from 'types/assignments';
import { TAssignmentsState } from 'modules/assignments/assignments.reducer';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';

type DataType = {
  key: string;
  title: string;
  data1: string;
  data2: string;
  data3: string;
  updatedAt: string;
};

type DataType2 = {
  key: string;
  name: string;
  updatedAt: string;
};

type Props = {
  getAllAssignment: (id: number) => void;
  assignmentState: TAssignmentsState;
  currentGroup: TGroupsClassDetail;
};
const Home = ({ getAllAssignment, assignmentState, currentGroup }: Props) => {
  const history = useHistory();
  useEffect(() => {
    if (currentGroup && currentGroup.id) {
      getAllAssignment(currentGroup.id);
    }
  }, [currentGroup]);
  useEffect(() => {
    clearAnswerBasicTaskInLocalStorage();
  }, []);
  const assignmentUncomplete = assignmentState.trainings
    .filter((t) => !t.isCompleted)
    .map((e) => ({
      ...e,
      key: e.id,
      name: splitNameAndTime(e.name ?? '').name,
      updatedAt: splitNameAndTime(e.name ?? '').updatedAt,
    }))
    .reverse();

  const assignmentComplete = assignmentState.trainings
    .filter((t) => t.isCompleted)
    .map((e) => ({ ...e, key: e.id }))
    .map((e) => ({
      ...e,
      key: e.id,
      name: splitNameAndTime(e.name ?? '').name,
      totalScore: parseFloat(e.result?.totalBasicScore) + parseFloat(e.result?.totalControlScore.toFixed(1)),
      totalBasicScore: e.result?.totalBasicScore,
      totalControlScore: e.result?.totalControlScore.toFixed(1),
      updatedAt: e.result?.updatedAt,
    }))
    .reverse();

  const handleTodoAssignment = (e: TTrainingDetail) => {
    if (!e.result && e.id) {
      history.push(replacePathParams(EPath.U5_1, { id: e.id.toString() }));
    } else {
      history.push(replacePathParams(EPath.U7_RESULTS, { id: e.id?.toString() ?? '0' }));
    }
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'タイトル',
      dataIndex: 'name',
      key: 'title',
      render: (text) => text,
      width: '30%',
    },
    {
      title: '総合点数',
      dataIndex: 'totalScore',
      key: 'totalScore',
      width: '10%',
    },
    {
      title: '栽培問題点数',
      dataIndex: 'totalBasicScore',
      key: 'totalBasicScore',
      width: '10%',
    },
    {
      title: '制御問題点数',
      dataIndex: 'totalControlScore',
      key: 'totalControlScore',
      width: '10%',
    },
    {
      title: '更新日',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '30%',
    },
    {
      render: (e) => (
        <MainButton text="結果の確認" onClick={() => history.push(replacePathParams(EPath.U10, { id: e.id }))} />
      ),
      width: '10%',
    },
  ];

  const columns2: ColumnsType<DataType2> = [
    {
      title: 'タイトル',
      dataIndex: 'name',
      key: 'title',
      width: '60%',
    },
    {
      title: '更新日',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '30%',
    },
    {
      render: (e) => <MainButton text="課題を開く" onClick={() => handleTodoAssignment(e)} />,
      width: '10%',
    },
  ];

  return (
    <div className={styles.topWrap}>
      <h2>課題一覧</h2>
      <Task columns={columns2} data={assignmentUncomplete} title="課題作業中" />
      <Task columns={columns} data={assignmentComplete} title="課題提出済み" />
    </div>
  );
};

export default Home;
