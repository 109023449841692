import { DATA_TYPE_CALCULATED_ANSWER } from 'constants/common';
import { convertCorrectData, convertData } from 'libs/helpers/functions';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import AssignmentsResultControl from './AssignmentsResultControl';

const DEFAULT_VALUE = 'C1';
const AssignmentsResultControlContainer = () => {
  const { resultAssignment } = useSelector((state: AppState) => state.pages.answers);
  const { isShowLoading } = useSelector((state: AppState) => state.pages.ui);
  let dataCalculateInit: any = [];
  if (resultAssignment.controlResult?.studentAnswers) {
    dataCalculateInit = convertData(
      resultAssignment.controlResult?.studentAnswers,
      DEFAULT_VALUE,
      false,
      DATA_TYPE_CALCULATED_ANSWER,
    );
  }
  const dataCorrectInit = convertCorrectData(resultAssignment.controlResult?.correctAnswers ?? [], DEFAULT_VALUE);

  return (
    <AssignmentsResultControl
      resultAssignment={resultAssignment}
      dataCalculateInit={dataCalculateInit}
      dataCorrectInit={dataCorrectInit}
      isShowLoading={isShowLoading}
    />
  );
};

export default AssignmentsResultControlContainer;
