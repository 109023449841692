import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replacePathParams } from 'libs/helpers/functions';
import AssignmentsResultOverviewContainer from 'pages/AssignmentsResultOverview/AssignmentsResultOverview.container';
import { EPath } from 'constants/routes';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppState } from 'store';
import { answersAction } from 'modules/answers/answers.action';
import AssignmentsResultCultivateContainer from 'pages/AssignmentsResultCultivate/AssignmentsResultCultivate.container';
import AssignmentsResultControlContainer from 'pages/AssignmentsResultControl/AssignmentsResultControl.container';
import AssignmentsResultStudentOverview from './AssignmentsResultStudentOverview';
import { TArrayPathNavLinkU10 } from './AssignmentsResultStudentOverview.state';

const AssignmentsResultStudentOverviewContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);
  const { resultAssignment, detailTask } = useSelector((state: AppState) => state.pages.answers);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const { id } = useParams<{ id: string }>();
  const arrayPathU10: TArrayPathNavLinkU10[] = [
    {
      to: replacePathParams(EPath.U10_1, { id }),
      title: '総合評価',
      component: <AssignmentsResultOverviewContainer />,
    },
    {
      to: replacePathParams(EPath.U10_2, { id }),
      title: '栽培問題',
      component: <AssignmentsResultCultivateContainer />,
    },
    {
      to: replacePathParams(EPath.U10_3, { id }),
      title: '制御問題',
      component: <AssignmentsResultControlContainer />,
    },
  ];
  const getTaskDetail = (id: string, groupId: number, isSaved: boolean) => {
    dispatch(answersAction.getDetailTaskForStudent({ id, isSaved, groupId }));
  };

  const onBackBasicTask = () => {
    if (detailTask.basicQuestions && detailTask.basicQuestions[0].id) {
      history.push(replacePathParams(EPath.U7_BASIC, { id, quesId: String(detailTask.basicQuestions[0].id) }));
    }
  };

  useEffect(() => {
    if (currentGroup.id) getTaskDetail(id, currentGroup.id, false);
  }, []);
  useEffect(() => {
    if (currentUser.id) dispatch(answersAction.getResultAssignmentOneStudent({ id, sid: currentUser.id.toString() }));
  }, []);
  useEffect(() => {
    const { pathname } = location;
    if (pathname === replacePathParams(EPath.U10, { id })) {
      history.replace(replacePathParams(EPath.U10_1, { id }));
    } else if (!arrayPathU10.map((p) => p.to).includes(pathname)) {
      history.replace(EPath.U3);
    }
  }, []);
  return (
    <AssignmentsResultStudentOverview
      arrayPathU10={arrayPathU10}
      resultAssignment={resultAssignment}
      assignmentId={id}
      onBackBasicTask={onBackBasicTask}
    />
  );
};

export default AssignmentsResultStudentOverviewContainer;
