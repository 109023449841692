import React, { useEffect, useState } from 'react';
import { Button, Card, MenuProps, Pagination, Space } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MainButton from 'components/MainButton/MainButton';
import EditTable from 'components/EditTable/EditTable';
import PullDown from 'components/Pulldown/PullDown';
import { TUiState } from 'modules/ui/ui.reducer';
import { TOptionsQuery, TOptionsQueryExtra } from 'types/common';
import { TTemplatesState } from 'modules/templates/templates.reducer';
import { TTemplateDetail } from 'types/templates';
import { parseSearchParams, createQueryUrl, replacePathParams } from 'libs/helpers/functions';
import ConfirmModal from 'components/CommonModal/ConfirmModal';
import { EPath } from 'constants/routes';
import { TGroupsClassDetail } from 'types/groupsClass';
import NotiModal from 'components/CommonModal/NotiModal';
import styles from './style.module.scss';
import './style.css';

type Props = {
  getTemplatesFinished: (params: TOptionsQueryExtra<TTemplateDetail>) => void;
  getAllTemplatesUnFinished: (groupId: number) => void;
  deleteTemplate: (data: { templateId: string; groupId: number }) => void;
  templatesState: TTemplatesState;
  uiState: TUiState;
  currentGroup: TGroupsClassDetail;
  clearErr: () => void;
};
const GroupsAdm = ({
  getTemplatesFinished,
  deleteTemplate,
  templatesState,
  uiState,
  getAllTemplatesUnFinished,
  currentGroup,
  clearErr,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const urlQueryParams: TOptionsQuery<TTemplateDetail> = parseSearchParams(location.search);

  const { templates, pagination, templatesAll, error } = templatesState;
  const { isShowLoading } = uiState;
  const [modal, setModal] = useState<string>('');
  const menuTemplatesAll = templatesAll.map((item) => ({ key: item.id, label: item.name }));

  useEffect(() => {
    if (getTemplatesFinished && currentGroup.id) {
      getTemplatesFinished({ ...urlQueryParams, groupId: currentGroup.id, isCompleted: true });
    }
    if (getAllTemplatesUnFinished && currentGroup.id) {
      getAllTemplatesUnFinished(currentGroup.id);
    }
  }, [location, currentGroup]);

  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  const handleDelete = () => {
    deleteTemplate({ templateId: modal, groupId: currentGroup.id ?? 0 });
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    history.push(replacePathParams(EPath.A4_1, { id: e.key }));
  };

  const menuProps = {
    items: menuTemplatesAll,
    onClick: handleMenuClick,
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
      editable: false,
    },
    {
      title: 'テンプレート名',
      dataIndex: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedAt',
      width: '40%',
      editable: true,
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_: any, record: TTemplateDetail) => (
        <Space className={styles.buttonOperation} align="center">
          <Link to={replacePathParams(EPath.A4_1, { id: record.id ?? '' })}>
            <MainButton text="閲覧・編集" />
          </Link>
          <MainButton
            subButton
            text="削除"
            onClick={() => {
              setModal(record.id ?? '');
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="groupsAdm">
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>研修テンプレート一覧</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.buttonTopGroup}>
                <Link to={EPath.A4_1_Create}>
                  <Button className={styles.buttonAddTemplate}>新しいテンプレートを作成する</Button>
                </Link>
                <PullDown menuProps={menuProps} title="未完成のテンプレートを続きから作成する" />
              </Space>
              <EditTable loading={isShowLoading} data={templates} mergedColumns={columns} />
            </div>
            <div className={styles.bottom}>
              <Pagination
                showQuickJumper
                current={pagination.page}
                total={pagination.totalResults}
                pageSize={pagination.limit}
                disabled={isShowLoading}
                onChange={onChangePage}
              />
            </div>
          </div>
        </Card>
      </div>
      <ConfirmModal
        title="確認"
        message="本当に削除しますか？"
        open={!!modal}
        onClose={() => setModal('')}
        onConfirm={handleDelete}
      />
      <NotiModal
        title="エラー"
        message="この研修テンプレートは研修で使用されているため削除はできません。"
        open={error}
        openModal={() => {
          clearErr();
        }}
        textButton="OK"
      />
    </div>
  );
};

export default GroupsAdm;
