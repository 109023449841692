import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import controlResultsAction from 'modules/controlResults/controlResults.action';
import templateAction from 'modules/template/template.action';
import { TOptionsQuery } from 'types/common';
import { AppState } from 'store';
import { templateModule, TTemplateState } from 'modules/template/template.reducer';
import AdmSampleDataSimulation from './AdmSampleDataSimulation';

const AdmSampleDataSimulationContainer = () => {
  const dispatch = useDispatch();
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const template = useSelector((state: AppState) => state.pages.template);
  const { controlResults } = useSelector((state: AppState) => state.pages.controlResults);
  const getAllControlResults = (params: TOptionsQuery) => {
    dispatch(controlResultsAction.getControlResults(params));
  };

  const updateTemplate = (
    data: { controlResult: number },
    id: number,
    groupId: number,
    buttonFooterNext: boolean = false,
    buttonFooterPre: boolean = false,
  ) => {
    dispatch(
      templateAction.updateTemplateStepChooseControlResult({ data, id, groupId, buttonFooterNext, buttonFooterPre }),
    );
  };
  const saveTemplate = (data: TTemplateState) => {
    dispatch(templateModule.actions.saveTemplate(data));
  };
  return (
    <AdmSampleDataSimulation
      getAllControlResults={getAllControlResults}
      controlResults={controlResults}
      currentGroup={currentGroup}
      updateTemplate={updateTemplate}
      template={template}
      saveTemplate={saveTemplate}
    />
  );
};
export default AdmSampleDataSimulationContainer;
