import { TStep2State } from 'pages/AdmTempBasic/AdmTempBasic.state';
import { TStep3State } from 'pages/AdmTempEvent/AdmTempEvent.state';
import { TStep4State } from 'pages/AdmTempQuestion/AdmTempQuestion.state';
import { TStep5State } from 'pages/AdmTempControlSimulation/AdmTempControlSimulation.state';
import { TStep6State } from 'pages/AdmTempCultivateSimulation/AdmTempCultivateSimulation.state';
import { TNewTemplate } from 'types/template';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  getTemplateId: ac<{ id: string; groupId: number }>('getTemplate'),
  getTemplateAndSaveId: ac<{ id: string; groupId: number }>('getTemplateAndSaveId'),
  createTemplate: ac<{ formData: FormData; data: TNewTemplate; groupId: number }>('createTemplate'),
  createTemplateWithoutImage: ac<{ data: TNewTemplate; groupId: number }>('createTemplateWithoutImage'),
  updateTemplateStep1: ac<{
    formData: FormData;
    data: TNewTemplate;
    id: number;
    buttonFooterNext: boolean;
    buttonFooterPre?: boolean;
    image: string;
    groupId: number;
  }>('updateTemplateStep1'),
  updateTemplateStep2: ac<{
    data: TStep2State;
    id: number;
    buttonFooterNext: boolean;
    buttonFooterPre: boolean;
    groupId: number;
  }>('updateTemplateStep2'),
  updateTemplateStep3: ac<{
    data: TStep3State;
    id: number;
    buttonFooterNext: boolean;
    buttonFooterPre: boolean;
    groupId: number;
  }>('updateTemplateStep3'),
  updateTemplateStep4: ac<{
    data: TStep4State;
    id: number;
    buttonFooterNext: boolean;
    buttonFooterPre: boolean;
    groupId: number;
  }>('updateTemplateStep4'),
  updateTemplateStep5: ac<{
    data: TStep5State;
    id: number;
    buttonFooterNext: boolean;
    buttonFooterPre: boolean;
    groupId: number;
  }>('updateTemplateStep5'),
  updateTemplateStep6: ac<{
    data: TStep6State;
    id: number;
    buttonFooterNext: boolean;
    buttonFooterPre: boolean;
    groupId: number;
  }>('updateTemplateStep6'),
  clearTemplate: ac('clearTemplate'),
  updateTemplateStepChooseControlResult: ac<{
    data: { controlResult: number };
    id: number;
    buttonFooterNext: boolean;
    buttonFooterPre: boolean;
    groupId: number;
  }>('updateTemplateStepChooseControlResult'),
  uploadCsvQuestionFile: ac<{ file: FormData; id: number; groupId: number; step: number }>('uploadCsvQuestionFile'),
  downloadCsvQuestionFile: ac<{ id: number; groupId: number; step: number }>('downloadCsvQuestionFile'),
};
