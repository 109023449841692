import React from 'react';
import styles from './style.module.scss';

type Props = {
  size?: number;
};

const ArrowDownIcon = ({ size = 10 }: Props) => (
  <div className={`${styles.iconComponent} size${size}`}>
    <svg width={10} height={10} viewBox="0 0 10 10" fill="#fff">
      <path d="M4.52908 7.16172L1.53158 3.73547C1.17783 3.33234 1.46533 2.69922 2.0022 2.69922H7.9972C8.11735 2.69912 8.23499 2.73365 8.33602 2.79868C8.43705 2.86372 8.5172 2.95649 8.56686 3.0659C8.61652 3.17531 8.63359 3.29672 8.61602 3.41558C8.59846 3.53444 8.54701 3.64572 8.46783 3.73609L5.47033 7.16109C5.41166 7.22822 5.33932 7.28203 5.25815 7.31889C5.17697 7.35576 5.08885 7.37483 4.9997 7.37483C4.91055 7.37483 4.82243 7.35576 4.74126 7.31889C4.66009 7.28203 4.58774 7.22822 4.52908 7.16109V7.16172Z" />
    </svg>
  </div>
);
export default ArrowDownIcon;
