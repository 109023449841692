import { combineEpics } from 'redux-observable';
import { showLeftMenu } from './ui/ui.epic';
import { userLogin, userLogout, userChangePassword, forgotPassword, getAuth, resetPassword } from './user/user.epic';
import { fetchRepositories } from './github/github.epic';
import {
  createUsersEpic,
  deleteUsersEpic,
  getStudentsEpic,
  getTeachersEpic,
  getUsersEpic,
  updateUsersEpic,
} from './groups/groups.epic';
import { deleteTemplateEpic, getTemplatesEpic, getAllTemplatesEpic } from './templates/templates.epic';
import {
  createTemplateEpic,
  createTemplateWithoutImageEpic,
  downloadCsvQuestionFileEpic,
  getTemplateAndSaveIdEpic,
  getTemplateByIdEpic,
  updateTemplateStep1,
  updateTemplateStep2,
  updateTemplateStep3,
  updateTemplateStep4,
  updateTemplateStep5,
  updateTemplateStep6,
  updateTemplateStepChooseControlResult,
  uploadCsvQuestionFileEpic,
} from './template/template.epic';
import {
  createTrainingEpic,
  deleteTrainingEpic,
  getResultsAssignmentEpic,
  getTrainingEpic,
  getTrainingsEpic,
  getTrainingsFinishEpic,
  getTrainingsWorkingEpic,
  updateTrainingEpic,
} from './assignments/assignments.epic';
import {
  getDetailTask,
  getQuestion,
  getResultAssignment,
  saveControlAnswers,
  submitStudentAnswerBasicTask,
  updateCommentAssignmentEpic,
  submitStudentAnswerControlTask,
} from './answers/answers.epic';
import { clearState, uploadImage } from './upload/upload.epic';
import {
  createGroupsClassEpic,
  deleteGroupsClassEpic,
  getDetailGroupClassEpic,
  getGroupsClassEpic,
  getGroupsClassModalEpic,
  updateGroupsClassEpic,
} from './groupsClass/groupsClass.epic';
import {
  createControlResultsEpic,
  deleteControlResultsEpic,
  downloadControlResultsEpic,
  getControlResultsDetailEpic,
  getControlResultsEpic,
  updateControlResultsEpic,
  uploadControlResultsFileEpic,
} from './controlResults/controlResults.epic';

const combineUserEpics = [userLogout, userLogin, userChangePassword, forgotPassword, getAuth, resetPassword];

const combineGroupEpics = [
  getUsersEpic,
  createUsersEpic,
  updateUsersEpic,
  deleteUsersEpic,
  getStudentsEpic,
  getTeachersEpic,
];
const combineTemplatesEpics = [getTemplatesEpic, getAllTemplatesEpic, deleteTemplateEpic];

const combineTemplateEpics = [
  getTemplateByIdEpic,
  getTemplateAndSaveIdEpic,
  createTemplateEpic,
  createTemplateWithoutImageEpic,
  updateTemplateStep1,
  updateTemplateStep2,
  updateTemplateStep3,
  updateTemplateStep4,
  updateTemplateStep5,
  updateTemplateStep6,
  updateTemplateStepChooseControlResult,
  uploadCsvQuestionFileEpic,
  downloadCsvQuestionFileEpic,
];

const combineAssignmentsEpics = [
  getTrainingsEpic,
  getTrainingEpic,
  createTrainingEpic,
  updateTrainingEpic,
  deleteTrainingEpic,
  getTrainingsWorkingEpic,
  getTrainingsFinishEpic,
  getResultsAssignmentEpic,
];

const combineAnswersEpics = [
  getDetailTask,
  getQuestion,
  submitStudentAnswerBasicTask,
  getResultAssignment,
  saveControlAnswers,
  updateCommentAssignmentEpic,
  submitStudentAnswerControlTask,
];

const combineGroupsClassEpics = [
  getGroupsClassEpic,
  getGroupsClassModalEpic,
  deleteGroupsClassEpic,
  createGroupsClassEpic,
  getDetailGroupClassEpic,
  updateGroupsClassEpic,
];
const combineUploadEpics = [uploadImage, clearState];

const combineControlResultsEpics = [
  getControlResultsEpic,
  createControlResultsEpic,
  updateControlResultsEpic,
  uploadControlResultsFileEpic,
  deleteControlResultsEpic,
  getControlResultsDetailEpic,
  downloadControlResultsEpic,
];

export default combineEpics(
  fetchRepositories,
  showLeftMenu,
  ...combineUserEpics,
  ...combineGroupEpics,
  ...combineTemplatesEpics,
  ...combineTemplateEpics,
  ...combineAssignmentsEpics,
  ...combineAnswersEpics,
  ...combineUploadEpics,
  ...combineGroupsClassEpics,
  ...combineControlResultsEpics,
);
