import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import uiAction from 'modules/ui/ui.action';
import { useHistory } from 'react-router-dom';
import { ERole } from 'constants/enums/common';
import styles from './style.module.scss';
import { adminMenu, studentMenu, teacherMenu, TLeftMenu } from './LeftMenu.state';

const LeftMenu: React.FC = () => {
  const [menus, setMenus] = useState<TLeftMenu[]>([]);
  const { isShowLeftMenu } = useSelector((state: AppState) => state.pages.ui);
  const { role } = useSelector((state: AppState) => state.pages.auth.currentUser);
  const history = useHistory();

  const dispatch = useDispatch();
  const myRef = useRef<any>();

  useEffect(() => {
    if (role && role === ERole.Admin) {
      setMenus(adminMenu);
    } else if (role && role === ERole.Teacher) {
      setMenus(teacherMenu);
    } else {
      setMenus(studentMenu);
    }
  }, [role]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = (e: any) => {
    if (!myRef?.current?.contains(e.target) && isShowLeftMenu) {
      setShowLeftMenu();
    }
  };

  const setShowLeftMenu = useCallback(() => {
    dispatch(uiAction.showLeftMenu());
  }, [dispatch]);

  const changeUrl = (link: string) => {
    history.push(link);
    dispatch(uiAction.showLeftMenu());
  };

  return isShowLeftMenu ? (
    <div className={styles.leftMenuWrap}>
      <div className={styles.leftMenuWrap__content} ref={myRef}>
        <div className={styles.top}>
          <div
            className={styles.hamburgerLines}
            onKeyDown={setShowLeftMenu}
            onClick={setShowLeftMenu}
            role="button"
            tabIndex={0}
          >
            <span className={styles.line} />
            <span className={styles.line} />
            <span className={styles.line} />
          </div>
          <div>
            <img src="/images/logo.svg" alt="NSM_Logo_white 1" />
          </div>
        </div>
        <div className={styles.bottom}>
          <ul>
            {menus &&
              menus.map((menu) => (
                <li key={menu.text}>
                  <div
                    onClick={() => changeUrl(menu.link)}
                    onKeyDown={() => changeUrl(menu.link)}
                    role="button"
                    tabIndex={0}
                  >
                    {menu.text}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default LeftMenu;
