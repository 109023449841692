import userAction from 'modules/user/user.action';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TResetPasswordBody } from 'types/auth';
import ResetPassword from './ResetPassword';

const ResetPasswordContainer = () => {
  const dispatch = useDispatch();

  const resetPassword = (data: TResetPasswordBody) => dispatch(userAction.resetPassword(data));

  return <ResetPassword resetPassword={resetPassword} />;
};

export default ResetPasswordContainer;
