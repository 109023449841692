import { ERole } from 'constants/enums/common';
import assignmentsAction from 'modules/assignments/assignments.action';
import groupsAction from 'modules/groups/groups.action';
import templatesAction from 'modules/templates/templates.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import { TTrainingDetail } from 'types/assignments';
import { TOptionsQuery } from 'types/common';
import AssignmentDetailAdm from './AssignmentDetailAdm';

const AssignmentDetailAdmContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const groupsState = useSelector((state: AppState) => state.pages.groups);
  const uiState = useSelector((state: AppState) => state.pages.ui);
  const assignmentsState = useSelector((state: AppState) => state.pages.assignments);
  const templatesState = useSelector((state: AppState) => state.pages.templates);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const getTraining = (data: { id: string; groupId: number }) => dispatch(assignmentsAction.getTraining(data));
  const getAllTemplatesFinished = (groupId: number) =>
    dispatch(templatesAction.getAllTemplates({ isCompleted: true, groupId }));

  const getAllUsers = (groupId: number) =>
    dispatch(
      groupsAction.getUsers({
        page: 1,
        limit: 10000,
        groupId,
      }),
    );
  const getStudents = (params: TOptionsQuery) =>
    dispatch(
      groupsAction.getStudents({
        ...params,
        role: ERole.Student,
      }),
    );
  const getTeachers = (params: TOptionsQuery) =>
    dispatch(
      groupsAction.getTeachers({
        ...params,
        role: ERole.Teacher,
      }),
    );

  const createTraining = (t: TTrainingDetail & { groupId: number }) => dispatch(assignmentsAction.createTraining(t));
  const updateTraining = (t: TTrainingDetail & { groupId: number }) => dispatch(assignmentsAction.updateTraining(t));

  return (
    <AssignmentDetailAdm
      groupsState={groupsState}
      uiState={uiState}
      assignmentsState={assignmentsState}
      getStudents={getStudents}
      getTeachers={getTeachers}
      createTraining={createTraining}
      trainingId={id}
      getTraining={getTraining}
      updateTraining={updateTraining}
      getAllTemplatesFinished={getAllTemplatesFinished}
      templatesState={templatesState}
      getAllUsers={getAllUsers}
      currentGroup={currentGroup}
    />
  );
};

export default AssignmentDetailAdmContainer;
