import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SelectProps } from 'antd';
import { TControlAnswer } from '../../types/assignments';

const months = [10, 11, 12, 1, 2, 3, 4];
export const firstMonth = months[0];
export const lastMonth = months[months.length - 1];

export const stages = [
  { name: 'Stage1', label: '定植期～主枝摘心期', isActive: true, index: 1 },
  { name: 'Stage2', label: '収穫開始期・前期', isActive: false, index: 2 },
  { name: 'Stage3', label: '収穫中期', isActive: false, index: 3 },
  { name: 'Stage4', label: '収穫後期', isActive: false, index: 4 },
];

export type DataTypeWeather = {
  key: React.Key;
  weather: string;
  sunny: number;
  cloudy: number;
  rain: number;
};
export type DataTypeTemperature = {
  key: React.Key;
  kind: string;
  highest: number;
  average: number;
  lowest: number;
};
export type DataTypeTime = {
  key: React.Key;
  timeZone: string;
  morning: string;
  noon: string;
  afternoon: string;
  night: string;
};
export type InitialDataType = {
  weatherData: Array<DataTypeWeather>;
  tempData: Array<DataTypeTemperature>;
  question: string;
  timeData: Array<DataTypeTime>;
};
export type TableSettingItem = {
  alias: string;
  factorial: string;
  morningOpts: SelectProps['options'];
  noonOpts: SelectProps['options'];
  afternoonOpts: SelectProps['options'];
  nightOpts: SelectProps['options'];
  unit: string;
};
export type TCtrlAnswer = {
  alias: string;
  factorial: string;
  morningData?: string | null;
  noonData?: string | null;
  afternoonData?: string | null;
  nightData?: string | null;
  unit?: string;
  month: number;
};

export const convertToTCtrlAnswer = (controlAnswers: TControlAnswer[]) =>
  controlAnswers.map((it) => {
    const morningData = it.morningData !== null ? it.morningData?.toString() : null;
    const noonData = it.afternoonData !== null ? it.afternoonData?.toString() : null;
    const afternoonData = it.beforeNightData !== null ? it.beforeNightData?.toString() : null;
    const nightData = it.midnightData !== null ? it.midnightData?.toString() : null;

    return {
      alias: it.alias,
      factorial: it.factorial,
      morningData,
      noonData,
      afternoonData,
      nightData,
      unit: it.unit,
      month: it.month,
    };
  });

export const columnWeather: ColumnsType<DataTypeWeather> = [
  { title: '天気', dataIndex: 'weather', align: 'center' },
  { title: '晴れ', dataIndex: 'sunny', align: 'center' },
  { title: '曇り', dataIndex: 'cloudy', align: 'center' },
  { title: '雨', dataIndex: 'rain', align: 'center' },
];
export const columnTemperature: ColumnsType<DataTypeTemperature> = [
  { title: '種類', dataIndex: 'kind', align: 'center' },
  { title: '最高', dataIndex: 'highest', align: 'center' },
  { title: '平均', dataIndex: 'average', align: 'center' },
  { title: '最低', dataIndex: 'lowest', align: 'center' },
];

export const timeSample = {
  key: 10,
  timeZone: '開始時刻',
  morning: '04:10',
  noon: '06:10',
  afternoon: '17:33',
  night: '20:33',
};
export const timeMappedByMonth = new Map<number, DataTypeTime>([
  [10, { key: 10, timeZone: '開始時刻', morning: '04:10', noon: '06:10', afternoon: '17:33', night: '20:33' }],
  [11, { key: 11, timeZone: '開始時刻', morning: '04:37', noon: '06:37', afternoon: '17:03', night: '20:03' }],
  [12, { key: 12, timeZone: '開始時刻', morning: '05:02', noon: '07:02', afternoon: '16:59', night: '19:59' }],
  [1, { key: 1, timeZone: '開始時刻', morning: '05:10', noon: '07:10', afternoon: '17:20', night: '20:20' }],
  [2, { key: 2, timeZone: '開始時刻', morning: '04:50', noon: '06:50', afternoon: '17:50', night: '20:50' }],
  [3, { key: 3, timeZone: '開始時刻', morning: '04:17', noon: '06:17', afternoon: '18:14', night: '21:14' }],
  [4, { key: 4, timeZone: '開始時刻', morning: '03:36', noon: '05:36', afternoon: '18:37', night: '21:37' }],
]);
export const columnTime: ColumnsType<DataTypeTime> = [
  { title: '時間帯', dataIndex: 'timeZone', align: 'center' },
  { title: '早朝', dataIndex: 'morning', align: 'center' },
  { title: '日中', dataIndex: 'noon', align: 'center' },
  { title: '前夜半', dataIndex: 'afternoon', align: 'center' },
  { title: '夜間', dataIndex: 'night', align: 'center' },
];

const round = (num: number, decimal: number, checkFloat: boolean) => {
  if (num === 0) return 0;
  const factor = 10 ** decimal;
  return checkFloat ? (Math.round(num * factor) / factor).toFixed(1) : Math.round(num * factor) / factor;
};

const makeOptions = (min: number, max: number, step: number) => {
  const opts: SelectProps['options'] = [];
  let checkFloat = false;
  if (!Number.isNaN(step) && !Number.isInteger(step)) {
    checkFloat = true;
  }
  for (let i = min; i <= max; i += step) {
    const tmp = round(i, 1, checkFloat);
    opts.push({ value: tmp, label: tmp });
  }

  return opts;
};
const tempSkylightOpts = makeOptions(5, 40, 0.5);
const rateSkylightOpts = makeOptions(0, 100, 1);
const heatTempOpts = makeOptions(0, 30, 0.5);
const dehOpts = makeOptions(50, 100, 1);
const insTempOpts = makeOptions(0, 35, 0.5);
const senTempOpts = makeOptions(1, 3, 1);
const co2Opts = makeOptions(0, 2500, 50);
const timerOpts = [
  { value: 1, label: 'ON' },
  { value: 0, label: 'OFF' },
];
const waterOpts = makeOptions(0.0, 10.0, 0.1);

export const skylightStItems = [
  {
    alias: 'M1',
    factorial: '換気温度',
    morningOpts: tempSkylightOpts,
    noonOpts: tempSkylightOpts,
    afternoonOpts: tempSkylightOpts,
    nightOpts: tempSkylightOpts,
    unit: '℃',
  },
  {
    alias: 'M2',
    factorial: '換気開度',
    morningOpts: rateSkylightOpts,
    noonOpts: rateSkylightOpts,
    afternoonOpts: rateSkylightOpts,
    nightOpts: rateSkylightOpts,
    unit: '%',
  },
];
export const heatTempStItems = [
  {
    alias: 'M3',
    factorial: '暖房温度',
    morningOpts: heatTempOpts,
    noonOpts: heatTempOpts,
    afternoonOpts: heatTempOpts,
    nightOpts: heatTempOpts,
    unit: '℃',
  },
];
export const dehStItems = [
  {
    alias: 'M4',
    factorial: '目標湿度',
    morningOpts: dehOpts,
    noonOpts: dehOpts,
    afternoonOpts: dehOpts,
    nightOpts: dehOpts,
    unit: '％',
  },
];
export const insTempItems = [
  {
    alias: 'M5',
    factorial: '保温温度',
    morningOpts: insTempOpts,
    noonOpts: insTempOpts,
    afternoonOpts: insTempOpts,
    nightOpts: insTempOpts,
    unit: '℃',
  },
  {
    alias: 'M6',
    factorial: '保温感度',
    morningOpts: senTempOpts,
    noonOpts: senTempOpts,
    afternoonOpts: senTempOpts,
    nightOpts: senTempOpts,
    unit: '℃ / 100%',
  },
];
export const co2StItems = [
  {
    alias: 'M7',
    factorial: 'CO2濃度設定',
    morningOpts: co2Opts,
    noonOpts: co2Opts,
    afternoonOpts: co2Opts,
    nightOpts: co2Opts,
    unit: 'ppm',
  },
];
export const timerStItems = [
  {
    alias: 'M8',
    factorial: '循環扇タイマー',
    morningOpts: timerOpts,
    noonOpts: timerOpts,
    afternoonOpts: timerOpts,
    nightOpts: timerOpts,
    unit: '',
  },
];
export const waterStItems = [
  {
    alias: 'M9',
    factorial: '1株あたりの1日かん水量',
    morningOpts: waterOpts,
    noonOpts: waterOpts,
    afternoonOpts: waterOpts,
    nightOpts: waterOpts,
    unit: 'L',
  },
];

const initEmptyAnswers = () => {
  const tmp: TCtrlAnswer[] = [];

  months.forEach((month) => {
    skylightStItems.forEach((it) => tmp.push({ alias: it.alias, factorial: it.factorial, unit: it.unit, month }));
    heatTempStItems.forEach((it) => tmp.push({ alias: it.alias, factorial: it.factorial, unit: it.unit, month }));
    dehStItems.forEach((it) => tmp.push({ alias: it.alias, factorial: it.factorial, unit: it.unit, month }));
    insTempItems.forEach((it) => tmp.push({ alias: it.alias, factorial: it.factorial, unit: it.unit, month }));
    co2StItems.forEach((it) => tmp.push({ alias: it.alias, factorial: it.factorial, unit: it.unit, month }));
    timerStItems.forEach((it) => tmp.push({ alias: it.alias, factorial: it.factorial, month }));
    waterStItems.forEach((it) => tmp.push({ alias: it.alias, factorial: it.factorial, unit: it.unit, month }));
  });

  return tmp;
};
export const emptyAnswers = initEmptyAnswers();
