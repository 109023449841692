export const DATA_SELECT = [
  {
    value: 'C1',
    label: '温度',
  },
  {
    value: 'C2',
    label: '湿度',
  },
  {
    value: 'C3',
    label: 'CO2',
  },
  {
    value: 'M1',
    label: '天窓 換気温度',
  },
  {
    value: 'M2',
    label: '天窓 換気開度',
  },
  {
    value: 'M3',
    label: '重油炊き暖房温度',
  },
  {
    value: 'M4',
    label: '湿度管理 目標湿度',
  },
  {
    value: 'M5',
    label: '保温カーテン　保温温度',
  },
  {
    value: 'M6',
    label: '保温カーテン　保温感度',
  },
  {
    value: 'M7',
    label: '炭酸ガス CO2濃度設定',
  },
  {
    value: 'M8',
    label: '循環扇　タイマー',
  },
  {
    value: 'M9',
    label: 'かん水 1株あたりの1日かん水量',
  },
];

export const DATA_TYPE_CORRECT_ANSWER = 'correctSimulations';
export const DATA_TYPE_STUDENT_ANSWER = 'studentSimulation';
export const DATA_TYPE_CALCULATED_ANSWER = 'calculatedData';

export const DESC_STUDENT_SETTINGS = '回答した制御設定';
export const DESC_CALCULATED_DATA = '環境シミュレーション結果';
export const DESC_CORRECT_ANSWERS = '模範データ';
export const MAX_SIZE_IMAGE = 5242880;
