import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { navStep } from 'constants/nav';
import EditTemplate from 'hook/EditTemplate';
import { EPath } from 'constants/routes';
import { TOptionsQuery } from 'types/common';
import { TTemplateState } from 'modules/template/template.reducer';
import { TGroupsClassDetail } from 'types/groupsClass';
import { TControlResultsDetail } from 'types/controlResults';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import useGetTemplateLs from 'hook/useGetTemplateLs';
import styles from './style.module.scss';

type Props = {
  getAllControlResults: (params: TOptionsQuery) => void;
  currentGroup: TGroupsClassDetail;
  controlResults: TControlResultsDetail[];
  updateTemplate: (
    data: { controlResult: number },
    id: number,
    groupId: number,
    buttonFooterNext: boolean,
    buttonFooterPre: boolean,
  ) => void;
  template: TTemplateState;
  saveTemplate: (data: TTemplateState) => void;
};
const AdmTempCultivateSimulation = ({
  getAllControlResults,
  updateTemplate,
  template,
  currentGroup,
  controlResults,
  saveTemplate,
}: Props) => {
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const [dataTemp] = useGetTemplateLs();

  EditTemplate();

  const handleNextPage = (buttonFooter: boolean = false) => {
    form
      .validateFields()
      .then(({ controlResult }: { controlResult: number }) => {
        if (currentGroup.id) {
          updateTemplate({ controlResult }, Number(id), currentGroup.id, buttonFooter, true);
        }
      })
      .catch(() => {});
  };

  const handleBackPage = (buttonFooter: boolean = false) => {
    form
      .validateFields()
      .then(({ controlResult }: { controlResult: number }) => {
        if (currentGroup.id) {
          updateTemplate({ controlResult }, Number(id), currentGroup.id, buttonFooter, true);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (currentGroup.id) {
      getAllControlResults({ limit: 10000, groupId: currentGroup.id });
    }
  }, [currentGroup]);

  useEffect(() => {
    if (template.controlResult) {
      form.setFieldValue('controlResult', template.controlResult);
    }
  }, [template.controlResult]);

  useEffect(() => {
    if (Object.keys(dataTemp).length > 0) {
      saveTemplate(dataTemp);
    }
  }, []);
  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
            </Row>
            <Row className={styles.assignmentsQuestionWrap__marginTop}>
              <Link to={EPath.A3}>
                <Button>テンプレート一覧に戻る</Button>
              </Link>
            </Row>
          </Col>
        </Row>
        <NavStep data={navStep} />
        <div className={styles.contentTitle}>模範データ選択</div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.form}>
              <Form form={form}>
                <p className={styles.title}>
                  研修の制御問題の採点処理に用いる模範データを選択します。
                  <br />
                  制御問題では、模範データ内の制御機器の設定値を正解とし、生徒が解答した設定値が適正範囲外の場合、採点結果が減点されます。
                </p>
                <Form.Item name="controlResult" rules={[{ required: true, message: '模範データを選択してください。' }]}>
                  <Select
                    options={controlResults.map((c) => ({ label: c.name ?? '', value: c.id }))}
                    placeholder=""
                    className={styles.select}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ButtonFooter handleClickNext={handleNextPage} handleClickPre={handleBackPage} />
    </>
  );
};

export default AdmTempCultivateSimulation;
