import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { replacePathParams } from 'libs/helpers/functions';
import { EPath } from 'constants/routes';
import AssignmentsRfp from './AssignmentsRfp';

const AssignmentsRfpContainer = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const handleBackPage = () => {
    history.push(replacePathParams(EPath.U5_1, { id }));
  };
  const handleNextPage = () => {
    history.push(replacePathParams(EPath.U6_1, { id }));
  };

  return <AssignmentsRfp handleBackPage={handleBackPage} handleNextPage={handleNextPage} />;
};

export default AssignmentsRfpContainer;
