import assignmentsAction from 'modules/assignments/assignments.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TOptionsQueryExtra } from 'types/common';
import { TTrainingDetail } from 'types/assignments';
import templateAction from 'modules/template/template.action';
import AssignmentsAdm from './AssignmentsAdm';

const AssignmentsAdmContainer = () => {
  const dispatch = useDispatch();
  const assignmentsState = useSelector((state: AppState) => state.pages.assignments);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const uiState = useSelector((state: AppState) => state.pages.ui);

  const defaultParamsGetTrainings = {
    sortBy: 'id:desc',
  };

  const getTrainings = (params: TOptionsQueryExtra<TTrainingDetail>) =>
    dispatch(
      assignmentsAction.getTrainings({
        ...defaultParamsGetTrainings,
        ...params,
      }),
    );
  const deleteTraining = (data: { id: number; groupId: number }) => {
    dispatch(assignmentsAction.deleteTraining(data));
  };
  const resetStateTemplate = () => dispatch(templateAction.clearTemplate());

  return (
    <AssignmentsAdm
      assignmentsState={assignmentsState}
      uiState={uiState}
      getTrainings={getTrainings}
      deleteTraining={deleteTraining}
      resetStateTemplate={resetStateTemplate}
      currentGroup={currentGroup}
    />
  );
};

export default AssignmentsAdmContainer;
