import React, { ReactNode } from 'react';
import { Modal, Space } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import styles from './style.module.scss';

const ConfirmModal: React.FC<{
  title: string;
  open: boolean;
  message: string | ReactNode;
  onClose: () => void;
  onConfirm?: () => void;
  textConfirm?: boolean;
  afterClose?: () => void;
}> = ({ open, onClose, message, title, onConfirm, textConfirm = false, afterClose }) => (
  <Modal
    title={title}
    centered
    open={open}
    onCancel={onClose}
    afterClose={afterClose}
    footer={
      <div>
        <Space align="center" className={styles.cancelButton}>
          <MainButton subButton text="いいえ" onClick={onClose} />
        </Space>
        {onConfirm && (
          <Space align="center">
            <MainButton
              text={textConfirm ? '確定' : 'はい'}
              onClick={() => {
                onConfirm();
                onClose();
              }}
            />
          </Space>
        )}
      </div>
    }
  >
    <div>{message}</div>
  </Modal>
);

export default ConfirmModal;
