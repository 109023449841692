import React, { useCallback, useEffect, useState } from 'react';
import uiAction from 'modules/ui/ui.action';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, matchPath } from 'react-router';
import { routers } from 'constants/router';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import userAction from 'modules/user/user.action';
import { AppState } from 'store';
import { getTitleRoleUser, parseSearchParams } from 'libs/helpers/functions';
import { ERole } from 'constants/enums/common';
import groupsClassAction from 'modules/groupsClass/groupsClass.action';
import { TOptionsQuery } from 'types/common';
import { TGroupsClassDetail } from 'types/groupsClass';
import { groupsClassModule } from 'modules/groupsClass/groupsClass.reducer';
import { getGroupFromLS, saveGroupToLS } from 'libs/helpers/localStorageFn';
import ModalGroups from 'components/CommonModal/ModalGroups';
import { EPath } from 'constants/routes';
import { TMainHeader } from './MainHeader.state';
import styles from './style.module.scss';

const MainHeader: React.FC<{
  location: any;
}> = ({ location }) => {
  const [mainHeader, setMainHeader] = useState<TMainHeader>({
    isShowLeftMenu: false,
  });
  const dispatch = useDispatch();
  const urlQueryParams: TOptionsQuery<any> = parseSearchParams(location.search);
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);
  const { groups, groupsModal, currentGroup, showModal, pagingModal, loading } = useSelector(
    (state: AppState) => state.pages.groupsClass,
  );
  const groupLs = getGroupFromLS();
  const history = useHistory();
  const getGroupsClass = (params: TOptionsQuery<TGroupsClassDetail>) => {
    dispatch(groupsClassAction.getGroupsClass(params));
  };
  const handleLogOut = () => {
    dispatch(userAction.logout());
    dispatch(groupsClassModule.actions.clearState({}));
  };

  const items1: MenuProps['items'] = [
    {
      key: 'change_password',
      label: <Link to="/password/change">パスワード変更</Link>,
    },
    {
      key: 'logout',
      label: (
        <span role="button" tabIndex={0} onClick={handleLogOut} onKeyDown={handleLogOut}>
          ログアウト
        </span>
      ),
    },
  ];

  useEffect(() => {
    const currentPath = Object.values(routers).find((router) =>
      matchPath(location.pathname, {
        path: router.path,
        exact: router.exact,
      }),
    );

    if (currentPath) {
      setMainHeader(currentPath);
    }
  }, [location.pathname]);

  const setShowLeftMenu = useCallback(() => {
    dispatch(uiAction.showLeftMenu());
  }, [dispatch]);

  const onClickLogo = () => {
    if (currentUser.role === ERole.Teacher || currentUser.role === ERole.Admin) {
      history.push(routers.A1.path);
    } else {
      history.push(routers.U3.path);
    }
  };
  const handleOpenModalGroups = () => {
    if (currentUser.role === ERole.Admin) dispatch(groupsClassModule.actions.setModal('groups'));
  };

  const handleCloseModal = () => {
    dispatch(groupsClassModule.actions.setModal(''));
  };
  // Call API get groups
  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0 && location.pathname !== EPath.login) {
      getGroupsClass({ ...urlQueryParams, sortBy: 'id:desc' });
    }
  }, [currentUser, location]);

  // Set default group
  useEffect(() => {
    if (!(Object.keys(groupLs).length > 0) && groups.length > 0) {
      saveGroupToLS(groups[0]);
      dispatch(groupsClassModule.actions.saveCurrentGroup(groups[0]));
    }
  }, [groups]);

  useEffect(() => {
    if (Object.keys(groupLs).length > 0) {
      dispatch(groupsClassModule.actions.saveCurrentGroup(groupLs));
    }
  }, []);

  useEffect(() => {
    if (location.pathname === EPath.login) {
      dispatch(groupsClassModule.actions.clearState({}));
      saveGroupToLS({});
    }
  }, []);
  return (
    <div className={styles.mainHeader}>
      <ModalGroups
        data={groupsModal}
        title="グループを選択する"
        loading={loading}
        pagination={pagingModal}
        onClose={handleCloseModal}
        open={showModal === 'groups'}
        currentUser={currentUser}
      />
      <div className={styles.mainHeader__left}>
        {mainHeader.isShowLeftMenu ? (
          <div
            className={styles.hamburgerLines}
            onClick={setShowLeftMenu}
            onKeyDown={setShowLeftMenu}
            role="button"
            tabIndex={0}
          >
            <span className={styles.line} />
            <span className={styles.line} />
            <span className={styles.line} />
          </div>
        ) : (
          ''
        )}
        <div role="button" tabIndex={0} onClick={onClickLogo} onKeyDown={onClickLogo}>
          <img className={styles.mainLogo} src="/images/logo.svg" alt="NSM_Logo_white 1" />
        </div>
        {(currentUser.role === ERole.Teacher || currentUser.role === ERole.Admin) && (
          <h2 className={styles.titleHeader}>管理画面</h2>
        )}
      </div>
      {mainHeader.isShowLeftMenu ? (
        <div className={styles.mainHeader__right}>
          {currentUser.role === ERole.Admin && (
            <div className={styles.groupsDownWrapper} onClick={handleOpenModalGroups}>
              <p>グループ名：{Object.keys(currentGroup).length > 0 ? currentGroup.name : ''}</p>
              <span className={styles.arrowDown} />
            </div>
          )}
          <div>
            <img src="/images/avatar-placeholder.svg" alt="avatar placeholder" />
          </div>
          <p>
            {currentUser.name} ({getTitleRoleUser(currentUser.role)})
          </p>
          <Dropdown
            overlayClassName={styles.dropDownUser}
            menu={{ items: items1 }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
            destroyPopupOnHide
            trigger={['click']}
          >
            <div className={styles.arrowDownWrapper}>
              <span className={styles.arrowDown} />
            </div>
          </Dropdown>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default withRouter(MainHeader);
