import React, { useEffect } from 'react';
import { Row } from 'antd';
import { TAnswersState } from 'modules/answers/answers.reducer';
import { saveAssignmentName } from 'libs/helpers/localStorageFn';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';

type Props = {
  handleNextPage: () => void;
  getTaskDetail: (groupId: number, id: string, isSaved: boolean) => void;
  answersState: TAnswersState;
  idAssignment: string;
  currentGroup: TGroupsClassDetail;
};
const AssignmentsRfpAboutTool = ({
  handleNextPage,
  getTaskDetail,
  answersState,
  idAssignment,
  currentGroup,
}: Props) => {
  useEffect(() => {
    if (currentGroup.id) {
      getTaskDetail(currentGroup.id, idAssignment, false);
    }
  }, []);
  const { detailTask } = answersState;
  useEffect(() => {
    if (detailTask.assignmentName) {
      saveAssignmentName(detailTask.assignmentName);
    }
  });
  return (
    <>
      <div className={styles.assignmentsRfpWrap}>
        <Row className={styles.titleWrapper}>
          <p>ツール説明</p>
          <p className={styles.titleTime}>{detailTask.assignmentName}</p>
        </Row>
        <div className={styles.contentWrap}>
          <h2>制御シミュレーションツールの操作方法等の説明です。</h2>
        </div>
      </div>
      <ButtonFooter handleClickNext={handleNextPage} />
    </>
  );
};

export default AssignmentsRfpAboutTool;
