import React from 'react';
import { Row, Card, Form, Input } from 'antd';
import { TForgotPasswordBody } from 'types/auth';
import MainButton from '../../components/MainButton/MainButton';
import styles from './style.module.scss';

type TProps = {
  forgotPassword: (data: TForgotPasswordBody) => void;
};

const ForgotPassword = ({ forgotPassword }: TProps) => {
  const [form] = Form.useForm();

  const handleForgotPassword = (values: TForgotPasswordBody) => {
    const { email } = values;
    forgotPassword({ email });
    form.resetFields();
  };

  return (
    <Row justify="center" align="middle" className={styles.rowWrap}>
      <Card type="inner" title="パスワード変更URL送信" className={styles.cardWrap}>
        <p className={styles.text}>
          パスワード再設定ページのURLを記載したメールを送信します。ログインIDとして利用中のメールアドレスを入力してください。
        </p>
        <div className={styles.formWarp}>
          <Form layout="vertical" form={form} onFinish={handleForgotPassword}>
            <Form.Item name="email" label="メールアドレス" className={styles.email}>
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item>
              <MainButton text="メール送信" htmlType="submit" />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Row>
  );
};

export default ForgotPassword;
