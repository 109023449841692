import React from 'react';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import { Button, Row, Col, Typography } from 'antd';
import { EPath } from 'constants/routes';
import { TResultAssignmentForOneStudent } from 'types/assignments';
import { replacePathParams } from 'libs/helpers/functions';
import { getEnabledEditMode } from 'libs/helpers/localStorageFn';
import { TArrayPathNavLinkU10 } from './AssignmentsResultStudentOverview.state';
import styles from './style.module.scss';
import './style.css';

type Props = {
  arrayPathU10: TArrayPathNavLinkU10[];
  resultAssignment: TResultAssignmentForOneStudent;
  assignmentId: string;
  onBackBasicTask: () => void;
};

const QUESTIONNAIRE_PATH =
  'https://docs.google.com/forms/d/e/1FAIpQLSeTfGLHfc0EZHrmHQmizaXoPIRUk7pBQoz5zdjlGvCtg5680w/viewform';

const AssignmentsResultStudentOverview = ({ arrayPathU10, resultAssignment, assignmentId, onBackBasicTask }: Props) => (
  <div className="groupsAdm">
    <div className={styles.groups}>
      <Row justify="space-between">
        <Col>
          <Row className={styles.groupsTitle}>
            <p>制御シミュレーション結果</p>
            <p>{resultAssignment.name}</p>
          </Row>
        </Col>
        <Col>
          <Link to={replacePathParams(EPath.U11, { id: assignmentId })}>
            <Typography.Text underline>栽培ルール確認</Typography.Text>
          </Link>
        </Col>
      </Row>

      <Row justify="space-between" className={styles.topWrapper}>
        <Col className={styles.questionnaireWrap}>
          <p className={styles.descText}>
            ※研修に参加いただきありがとうございます。
            <br />
            以下のボタンを押し、アンケート回答のご協力をお願いいたします。
          </p>
          <Button href={QUESTIONNAIRE_PATH} target="blank">
            アンケートに回答する
          </Button>
        </Col>
        <Col className={styles.groupLinkBtn}>
          <Link to={EPath.U3}>
            <Button className={styles.topButton}>TOPにもどる</Button>
          </Link>
          {getEnabledEditMode() && (
            <div className={styles.editModeBtn}>
              <Button className={styles.topButton} onClick={onBackBasicTask}>
                栽培問題の再回答
              </Button>
              <Link to={replacePathParams(EPath.U8_1_2, { id: assignmentId })}>
                <Button className={styles.topButton}>制御問題の再回答</Button>
              </Link>
            </div>
          )}
        </Col>
      </Row>

      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.navGroupButton}>
            {arrayPathU10.map((l) => (
              <NavLink key={l.to} to={`${l.to}`} className={styles.linkButton} activeClassName={styles.activeLink}>
                <div>{l.title}</div>
              </NavLink>
            ))}
          </div>
          <div className={styles.body}>
            <Switch>
              {arrayPathU10.map((r) => (
                <Route exact path={r.to} key={r.title}>
                  {r.component}
                </Route>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AssignmentsResultStudentOverview;
