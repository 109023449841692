import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { controlResultsServices } from 'services/controlResults';
import { AxiosResponse, TResponsePagination } from 'libs/axios';
import { messageModule } from 'modules/message/message.reducer';
import { uiModule } from 'modules/ui/ui.reducer';
import { push } from 'connected-react-router';
import { EPath } from 'constants/routes';
import { TControlResultsDetail } from 'types/controlResults';
import { controlResultsModule } from './controlResults.reducer';
import actions from './controlResults.action';

export const getControlResultsEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getControlResults),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: controlResultsModule.actions.loading(),
        asyncFns: from(controlResultsServices.getControlResults(payload)),
        next: (u: TResponsePagination<TControlResultsDetail>) => [
          controlResultsModule.actions.getListControlResults(u),
        ],
        complete: controlResultsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createControlResultsEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.createControlResults),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: controlResultsModule.actions.loading(),
        asyncFns: from(controlResultsServices.createControlResults(payload)),
        next: (u: any) => [push(EPath.A9_1), messageModule.actions.setSuccess('')],
        complete: controlResultsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateControlResultsEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateControlResults),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: controlResultsModule.actions.loading(),
        asyncFns: from(controlResultsServices.updateControlResults(payload)),
        next: () => [push(EPath.A9_1), messageModule.actions.setSuccess('')],
        complete: controlResultsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const deleteControlResultsEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.deleteControlResults),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: controlResultsModule.actions.loading(),
        asyncFns: from(controlResultsServices.deleteControlResults(payload)),
        next: (u: number) => [
          controlResultsModule.actions.removeControlResults(u),
          messageModule.actions.setSuccess(''),
        ],
        complete: controlResultsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => [controlResultsModule.actions.setError(true)],
      }),
    ),
  );

export const getControlResultsDetailEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getControlResultsDetail),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: controlResultsModule.actions.loading(),
        asyncFns: from(controlResultsServices.getControlResultsDetail(payload)),
        next: (u: TControlResultsDetail) => [controlResultsModule.actions.saveControlResultsDetail(u)],
        complete: controlResultsModule.actions.stopLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const uploadControlResultsFileEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.uploadControlResultsFile),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(controlResultsServices.uploadControlResultsFile(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: { fname: string; originalName: string }) => [controlResultsModule.actions.setDataFileUpload(u)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const downloadControlResultsEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.downloadControlResultsFile),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(controlResultsServices.downloadControlResultsFile(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: any) => {
          const url = window.URL.createObjectURL(new Blob([`\ufeff${u}`], { type: 'text/csv;charset=utf-8;' }));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          const currentTime = moment(Date.now()).format('YYYYMMDD');
          a.download = `exemplary_results_${currentTime}.csv`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          return [];
        },
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
