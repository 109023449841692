import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, Button, Form, Table, UploadFile, UploadProps, Select, Input } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import lodash from 'lodash';
import { EPath } from 'constants/routes';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { navStep } from 'constants/nav';
import { TNewTemplate, TStages } from 'types/template';
import EditTemplate from 'hook/EditTemplate';
import { TTemplateState } from 'modules/template/template.reducer';
import { getPublicUrlImage, getServerImage, validationDataStage } from 'libs/helpers/functions';
import { BASE_UPLOAD_URL } from 'constants/index';
import { RcFile } from 'antd/lib/upload';
import { useDispatch } from 'react-redux';
import templateAction from 'modules/template/template.action';
import { messageModule } from 'modules/message/message.reducer';
import { MESSAGE_ERROR_FILE_TOO_LARGE } from 'constants/notifications/message';
import { MAX_SIZE_IMAGE } from 'constants/common';
import { TGroupsClassDetail } from 'types/groupsClass';
import { TTemplateDetail } from 'types/templates';
import EditableRow from 'components/EditTableColRow/EditableRow';
import { setTemplateCopyToLs } from 'libs/helpers/localStorageFn';
import ModalUpload from './Modal/ModalUpload';
import { columns, data, TTableStage } from './TemplateInfo.state';
import styles from './style.module.scss';
import EditCell from './EditCell';

type TProps = {
  createTemplate: (fileList: UploadFile[], data: TNewTemplate) => void;
  createTemplateWithoutImage: (data: TNewTemplate) => void;
  updateTemplate: (
    fileList: UploadFile[],
    data: TNewTemplate,
    id: number,
    buttonFooterNext: boolean,
    image: string,
    groupId: number,
  ) => void;
  template: TTemplateState;
  updateImage: (id: number, data: FormData) => void;
  currentGroup: TGroupsClassDetail;
  templatesAll: TTemplateDetail[];
  getAllTemplates: (data: { groupId: number }) => void;
  getDetailTemplate: (data: { groupId: number; id: string }) => void;
  resetTemplate: () => void;
  setIsLinkedTraining: () => void;
};
const DEFAULT_UID = 'default-img';
const TemplateInfo = ({
  createTemplate,
  createTemplateWithoutImage,
  updateTemplate,
  template,
  updateImage,
  currentGroup,
  templatesAll,
  getAllTemplates,
  getDetailTemplate,
  resetTemplate,
  setIsLinkedTraining,
}: TProps) => {
  const [isShowModal, setShowModal] = useState(false);
  let previewImage = {
    uid: DEFAULT_UID,
    name: 'ハウス基本情報.png',
    url: getPublicUrlImage('HouseBasicInfo.png'),
    type: 'image/png',
  };
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    previewImage =
      template.step1?.imageName && id
        ? {
            uid: template.step1?.imageName,
            name: template.step1?.imageName,
            url: getServerImage(template.step1?.imageName),
            type: 'image/png',
          }
        : {
            uid: DEFAULT_UID,
            name: 'ハウス基本情報.png',
            url: getPublicUrlImage('HouseBasicInfo.png'),
            type: 'image/png',
          };
    if (isShowModal) {
      setFileList([previewImage]);
    }
  }, [isShowModal]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formSelectTemp] = Form.useForm();
  const [formDetail] = Form.useForm();
  const [formTable] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([previewImage]);

  const [image, setImage] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const [dataSource, setDataSource] = useState<TTableStage[]>(data);

  EditTemplate();
  const handleSave = (row: TStages) => {
    const updateData = dataSource.map((d) => (d.index === row.index ? row : d));
    setDataSource(updateData);
  };
  useEffect(() => {
    setTemplateCopyToLs({});
  }, []);
  const editColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    const idTempCopy = formSelectTemp.getFieldValue('temp');
    if (idTempCopy) {
      const copyTemp = lodash.omit(lodash.clone(template), 'id');
      setTemplateCopyToLs(copyTemp);
    }
    if (template.id && template.step1 && (id || idTempCopy)) {
      form.setFieldsValue({
        name: idTempCopy ? form.getFieldValue('name') : template.name,
      });
      formDetail.setFieldsValue({
        item1: template.step1?.itemName,
        method1: template.step1?.method,
      });
      if (template.step1.stages.length) {
        const dataStages = template.step1.stages.map((s) => ({
          index: s.index,
          name: s.name,
          startDate: s.startDate,
          endDate: s.endDate,
          feature: s.feature,
        }));
        setDataSource(dataStages);
      } else {
        setDataSource(data);
      }
      setImage(`${BASE_UPLOAD_URL}${template.step1.imageName ?? ''}`);
    }
  }, [template]);
  useEffect(() => {
    if (currentGroup && currentGroup.id) {
      dispatch(templateAction.getTemplateId({ id, groupId: currentGroup.id }));
    }
  }, [id, currentGroup]);
  useEffect(() => {
    if (currentGroup.id) {
      getAllTemplates({ groupId: currentGroup.id });
    }
  }, [currentGroup]);

  useEffect(() => {
    if (!template.id) {
      resetTemplate();
    }
  }, []);
  useEffect(() => {
    if (!id) {
      setIsLinkedTraining();
    }
  }, []);
  // Upload file
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const fileSize = fileList[fileList.length - 1]?.size;
    if (fileSize && fileSize > MAX_SIZE_IMAGE) {
      dispatch(messageModule.actions.setError(MESSAGE_ERROR_FILE_TOO_LARGE));
      setUploadSuccess(false);
      setFileList([]);
      return;
    }
    setFileList(newFileList);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setUploadSuccess(false);
    },
    beforeUpload: (file) => {
      setUploadSuccess(true);
      setFileList([...fileList, file]);
      return false;
    },
  };

  const handleBackPage = () => {
    history.push(EPath.A3);
  };

  const onClickNextStep = useCallback(
    (buttonFooterNext: boolean = true) => {
      let nameTemplate: { name: string };
      if (!validationDataStage(dataSource)) {
        return;
      }
      form
        .validateFields()
        .then((value: { name: string }) => {
          nameTemplate = value;
        })
        .catch(() => {});
      formDetail
        .validateFields()
        .then((value: { item1: string; method1: string }) => {
          const isUpload = fileList.some((f) => f.uid !== DEFAULT_UID);
          if (!id) {
            if (isUpload) {
              createTemplate(fileList, {
                name: nameTemplate.name,
                step1: {
                  itemName: value.item1,
                  method: value.method1,
                  stages: dataSource,
                },
              });
            } else {
              createTemplateWithoutImage({
                name: nameTemplate.name,
                step1: {
                  itemName: value.item1,
                  method: value.method1,
                  stages: dataSource,
                },
              });
            }
          } else {
            updateTemplate(
              fileList,
              {
                name: nameTemplate.name,
                step1: {
                  itemName: value.item1,
                  method: value.method1,
                  stages: dataSource,
                },
              },
              Number(id),
              buttonFooterNext,
              image,
              currentGroup.id ?? 0,
            );
            if (isUpload) {
              const formData = new FormData();

              fileList.forEach((file) => {
                formData.append('file', file.originFileObj as RcFile);
              });

              updateImage(template.id, formData);
            }
          }
        })
        .catch(() => {});
    },
    [fileList, form, formDetail, dataSource],
  );

  const handleCopyTemplate = ({ temp }: { temp: number }) => {
    if (currentGroup.id) getDetailTemplate({ groupId: currentGroup.id, id: String(temp) });
  };
  // EditableTable
  const components = {
    body: {
      cell: EditCell,
      row: EditableRow,
    },
  };

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
            </Row>
            <Row className={styles.assignmentsQuestionWrap__margin}>
              <Link to={EPath.A3}>
                <Button>テンプレート一覧に戻る</Button>
              </Link>
            </Row>
          </Col>
        </Row>
        <NavStep data={navStep} />
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <Card title="研修テンプレート名称入力" className={styles.contentWrap__top}>
              <Row>
                <Col xs={24} sm={12}>
                  <Form form={form} autoComplete="off" layout="vertical">
                    <span>研修テンプレート名</span>
                    <Form.Item name="name" rules={[{ required: true, message: '研修テンプレート名は必須項目です。' }]}>
                      <Input placeholder="example" />
                    </Form.Item>
                  </Form>
                  {!template.isLinkedWithTraining && (
                    <>
                      <p className={styles.labelSelect}>
                        既存のテンプレートの設定を読み込んで登録を進める場合は、
                        <br />
                        以下からテンプレートを選択し、「反映する」ボタンを押してください。
                      </p>
                      <Form form={formSelectTemp} autoComplete="off" layout="vertical" onFinish={handleCopyTemplate}>
                        <Form.Item
                          rules={[{ required: true, message: '既存のテンプレートを選択してください。' }]}
                          name="temp"
                        >
                          <Select
                            placeholder="既存テンプレートを選択"
                            className={styles.selectTemplate}
                            options={templatesAll.map((t) => ({ value: t.id, label: t.name }))}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button htmlType="submit" className={styles.btnFeedback}>
                            反映する
                          </Button>
                        </Form.Item>
                      </Form>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
            <Card title="栽培基本ルールの設定" className={styles.contentWrap__bottom}>
              <Row>
                <Col xs={24} sm={20}>
                  <Form form={formDetail} autoComplete="off" layout="horizontal">
                    <p>栽培基本ルールとは、出題問題に共通する栽培前提条件です。</p>
                    <p className={styles.contentWrap__margin}>品目・栽培方法・栽培ステージなどを設定します。</p>
                    <Row className={styles.subData}>
                      <Col span={3}>
                        <span>作物・品目 </span>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="item1" rules={[{ required: true, message: '作物・品目を入力してください。' }]}>
                          <Input placeholder="example" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className={styles.subData}>
                      <Col span={3}>
                        <span>栽培方法 </span>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="method1" rules={[{ required: true, message: '栽培方法を入力してください。' }]}>
                          <Input placeholder="example" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className={styles.contentWrap__flex}>
                      <h3 className={styles.contentWrap__title}>栽培ステージ</h3>
                      <Form form={formTable}>
                        <Table
                          className={styles.contentWrap__table}
                          components={components}
                          columns={editColumns}
                          dataSource={dataSource}
                          pagination={false}
                          bordered
                          rowClassName={() => 'editable-row'}
                          rowKey="key"
                        />
                      </Form>
                    </div>
                    <br />
                    <p>写真の設定</p>
                    <span>冒頭説明で画像を載せたい場合は、画像のアップロードをしてください。</span>
                    <br />
                    <span>（JPEG/GIF/PNG形式。添付可能なファイルサイズは最大で5MBです。）</span>
                  </Form>
                  <br />
                  <Button onClick={() => setShowModal(true)}>ファイルを選択</Button>
                  {!uploadSuccess && !image ? (
                    <span className={styles.contentWrap__marginLeft}>選択されていません。</span>
                  ) : (
                    <span className={styles.contentWrap__marginLeft}>{template.step1?.imageName ?? ''}</span>
                  )}
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
      <ModalUpload
        open={isShowModal}
        setModal={setShowModal}
        handleChange={handleChange}
        props={props}
        uploadSuccess={uploadSuccess}
        fileList={fileList}
      />

      <ButtonFooter handleClickNext={onClickNextStep} handleClickPre={handleBackPage} />
      <div className={styles.heightBottom} />
    </>
  );
};

export default TemplateInfo;
