import actionCreatorFactory from 'typescript-fsa';
import { TOptionsQuery } from 'types/common';
import { TAnswerSubmit, TControlAnswer } from 'types/assignments';

const ac = actionCreatorFactory();

export const answersAction = {
  getDetailTaskForStudent: ac<{ groupId: number; id: string; isSaved: boolean }>('getDetailTaskForStudent'),
  getQuestion: ac<{ id?: string; quesId?: string; type?: 'basic' | 'event'; groupId: number }>('getQuestion'),
  submitAnswerBasicTask: ac<TAnswerSubmit>('submitAnswerBasicTask'),
  getResultAssignmentOneStudent: ac<{ id: string; sid: string }>('getResultAssignment'),
  updateCommentAssignment: ac<{ id: number; sid: number; comment: string }>('updateCommentAssignment'),
  saveControlAnswers: ac<{ id: string; answers: TControlAnswer[] }>('saveControlAnswers'),
  submitAnswerControlTask: ac<{ id: string; simulation: boolean; answers: TControlAnswer[] }>(
    'submitAnswerControlTask',
  ),
};
