import React, { useEffect } from 'react';
import { Card, Row } from 'antd';
import { EPath } from 'constants/routes';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { ERole } from 'constants/enums/common';
import { groupsClassModule } from 'modules/groupsClass/groupsClass.reducer';
import Item from './Item';
import SubItem from './SubItem';
import styles from './style.module.scss';

const TopAdm = () => {
  const dispatch = useDispatch();
  const { currentGroup, groups } = useSelector((state: AppState) => state.pages.groupsClass);
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);
  useEffect(() => {
    if (currentGroup && !currentGroup.id && groups.length > 0 && currentUser.role === ERole.Teacher) {
      dispatch(groupsClassModule.actions.saveCurrentGroup(groups[0]));
    }
  }, []);
  return (
    <>
      <div className={styles.topAdm}>
        <Card
          className={styles.topAdmWarp}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>TOP</p>
            </div>
          }
        >
          <div>
            <p className={styles.title}>研修の準備の流れ</p>
            <hr />
            <Row gutter={[40, 40]} className={styles.topAdmRow} justify="space-around">
              <Item
                title="①受講者アカウントを登録します。"
                image="person-lines-fill"
                subTitle="受講者登録を行う"
                url={EPath.A2}
              />
              <Item
                title="②研修テンプレートを作成します。"
                image="terminal-plus"
                subTitle="テンプレートを作成する"
                url={EPath.A4_1_Create}
              />
              <Item
                title="③開催する研修を作成します。"
                image="pc-display-horizontal"
                subTitle="研修を作成する"
                url={EPath.A6_2}
              />
            </Row>
          </div>
          <div className={styles.topAdmSubItem}>
            <p className={styles.title}>各種設定</p>
            <hr />
            <div>
              <Row gutter={[40, 40]} className={styles.topAdmRowCustom}>
                <SubItem title="ユーザー管理" subTitle="受講者・講師の管理を行います。" url={EPath.A2} />
                <SubItem
                  title="研修テンプレート一覧"
                  subTitle="研修テンプレートの登録・編集・削除を行います。"
                  url={EPath.A3}
                />
                <SubItem
                  title="研修一覧"
                  subTitle="研修の管理を行います。 受講者の研修実施状況の確認ができます。"
                  url={EPath.A5}
                />
                <SubItem
                  title="模範データ管理"
                  subTitle="研修の制御問題の採点処理に用いる模範データの登録・編集・削除を行います。"
                  url={EPath.A9_1}
                />
              </Row>
            </div>
          </div>
          {currentUser.role === ERole.Admin && (
            <div className={styles.topAdmSubItem}>
              <p className={styles.title}>管理者設定</p>
              <hr />
              <div className={styles.topAdmRowCustom}>
                <Row gutter={[40, 40]} className={styles.topAdmRowCustom}>
                  <SubItem
                    title="グループ管理"
                    subTitle="グループの管理を行います。グループ別に使用するベースライブラリの設定もできます。"
                    url={EPath.S3_1}
                  />
                </Row>
              </div>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default TopAdm;
