import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Card, Radio, Space, RadioChangeEvent } from 'antd';
import { useSelector } from 'react-redux';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavQuestion from 'components/NavQuestion/NavQuestion';
import NotiModal from 'components/CommonModal/NotiModal';
import ConfirmModal from 'components/CommonModal/ConfirmModal';
import NavStage, { TDataStagesNav } from 'components/NavStage/NavStage';
import { TAnswersState } from 'modules/answers/answers.reducer';
import { Link, useHistory, useParams } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { TAnswerAQuestion, TAnswerSubmit } from 'types/assignments';
import {
  getAnswerFromLocalStorage,
  clearAnswerBasicTaskInLocalStorage,
  setStatusShowInsModal,
  getStatusShowInsModal,
  getAssignmentName,
  saveBasicAnswerToLocalStorage,
  saveEventAnswerToLocalStorage,
} from 'libs/helpers/localStorageFn';
import { mappingAnswerDemonstrationMode, replacePathParams } from 'libs/helpers/functions';
import { TGroupsClassDetail } from 'types/groupsClass';
import { AppState } from 'store';
import { data } from 'pages/TemplateInfo/TemplateInfo.state';
import { TQuesParams } from './AssignmentsEventQuestion.state';
import styles from './style.module.scss';

type Props = {
  answersState: TAnswersState;
  getQuestionList: (dataId: TQuesParams) => void;
  updateEventAnswer: (data: Array<TAnswerAQuestion>) => void;
  submitAnswerBasicTask: (data: TAnswerSubmit) => void;
  currentGroup: TGroupsClassDetail;
  getTaskDetail: (id: string, groupId: number, isSaved: boolean) => void;
  getResults: (id: string, sid: string) => void;
};
const AssignmentsEventQuestion = ({
  answersState,
  submitAnswerBasicTask,
  updateEventAnswer,
  getQuestionList,
  currentGroup,
  getTaskDetail,
  getResults,
}: Props) => {
  const history = useHistory();
  const { id, quesId }: { id: string; quesId: string } = useParams();
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);
  const [openNotice, setOpenNotice] = useState<'introduce' | 'warning' | 'confirm' | ''>('introduce');
  const [dataStage, setDataStage] = useState<TDataStagesNav[]>([]);
  const [stageName, setStageName] = useState<string>('');

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    const answerData = {
      stageName: currentQuestion.stageName,
      questionId: currentQuestion.id,
      selectedOption: e.target.value,
    };
    const updateListAnswer = listAnswerEventQues.map((q) => (q.questionId === quesId ? answerData : q));
    updateEventAnswer(updateListAnswer);
  };

  const { currentQuestion, basicAnswer, eventAnswer, enabledDemonstrationMode, detailTask, resultAssignment } =
    answersState;

  const listAnswerBasicQues = (getAnswerFromLocalStorage('basicAnswer') as TAnswerAQuestion[]) ?? basicAnswer;
  const listAnswerEventQues = (getAnswerFromLocalStorage('eventAnswer') as TAnswerAQuestion[]) ?? eventAnswer;
  const indexQuestion = listAnswerEventQues.findIndex((q) => q.questionId === currentQuestion.id);

  const [value, setValue] = useState<null | undefined | number>(0);

  useEffect(() => {
    if (
      resultAssignment.basicResults &&
      resultAssignment.basicResults.length &&
      detailTask.basicQuestions &&
      resultAssignment.eventResults &&
      detailTask.eventQuestions &&
      resultAssignment.eventResults.length &&
      detailTask.enabledDemonstrationMode
    ) {
      const basicAnswerMapping = mappingAnswerDemonstrationMode(
        resultAssignment.basicResults,
        detailTask.basicQuestions,
      );
      const eventAnswerMapping = mappingAnswerDemonstrationMode(
        resultAssignment.eventResults,
        detailTask.eventQuestions,
      );
      saveBasicAnswerToLocalStorage(basicAnswerMapping);
      saveEventAnswerToLocalStorage(eventAnswerMapping);
    }
  }, [resultAssignment, detailTask]);

  useEffect(() => {
    setValue(listAnswerEventQues.find((q) => q.questionId === quesId)?.selectedOption ?? 0);
    if (currentGroup.id) {
      getQuestionList({
        id,
        quesId,
        type: 'event',
        groupId: currentGroup.id,
      });
    }
  }, [quesId]);

  useEffect(() => {
    if (id && currentGroup.id) getTaskDetail(id, currentGroup.id, false);
  }, []);

  useEffect(() => {
    if (!detailTask.stages?.length) {
      setDataStage(data);
    } else
      setDataStage(
        detailTask.stages?.map((s) => ({
          index: s.index,
          label: s.name,
          name: s.name,
          link: s.index,
        })) ?? [],
      );
  }, [detailTask]);

  useEffect(() => {
    if (currentQuestion.stageName) {
      setStageName(currentQuestion.stageName);
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (currentUser.id && id) getResults(id, String(currentUser.id));
  }, []);

  const handleBackEventQuestion = () => {
    if (indexQuestion > 0) {
      history.push(
        replacePathParams(EPath.U7_EVENT, {
          id: id ?? '0',
          quesId: String(listAnswerEventQues[indexQuestion - 1].questionId),
        }),
      );
    } else {
      history.push(
        replacePathParams(EPath.U7_BASIC, {
          id: id ?? '0',
          quesId: String(listAnswerBasicQues[listAnswerBasicQues.length - 1].questionId),
        }),
      );
    }
  };
  const handleNextEventQuestion = () => {
    const answerData = {
      stageName: currentQuestion.stageName,
      questionId: currentQuestion.id,
      selectedOption: value,
    };
    const updateListAnswer = listAnswerEventQues.map((q) => (q.questionId === quesId ? answerData : q));
    updateEventAnswer(updateListAnswer);
    if (indexQuestion < listAnswerEventQues.length - 1) {
      history.push(
        replacePathParams(EPath.U7_EVENT, {
          id: id ?? '0',
          quesId: String(listAnswerEventQues[indexQuestion + 1].questionId),
        }),
      );
      return;
    }
    if (checkAnswer()) {
      setOpenNotice('confirm');
    } else {
      setOpenNotice('warning');
    }
  };
  const checkAnswer = () => {
    let check = true;
    listAnswerBasicQues.forEach((q) => {
      if (q.selectedOption === null || q.selectedOption === 0) {
        check = false;
      }
    });
    listAnswerEventQues.forEach((q) => {
      if (q.selectedOption === null || q.selectedOption === 0) {
        check = false;
      }
    });
    return check;
  };
  const handleSubmitAnswer = () => {
    if (id && currentGroup.id) {
      submitAnswerBasicTask({
        id,
        basicAnswers: listAnswerBasicQues,
        eventAnswers: listAnswerEventQues,
        groupId: currentGroup.id,
      });
      setStatusShowInsModal('basic', 'on');
      setStatusShowInsModal('event', 'on');
      if (enabledDemonstrationMode) {
        return;
      }
      clearAnswerBasicTaskInLocalStorage();
    }
  };

  const handleSubmitBtn = () => {
    if (checkAnswer()) {
      setOpenNotice('confirm');
    } else {
      setOpenNotice('warning');
    }
  };
  useEffect(() => {
    if (
      !listAnswerBasicQues ||
      listAnswerBasicQues.length === 0 ||
      !listAnswerEventQues ||
      listAnswerEventQues.length === 0
    ) {
      history.push(EPath.U3);
    }
  }, []);

  return (
    <>
      <NotiModal
        title="未回答問題があります。"
        isSubButton
        message={
          <p>
            未回答の問題があるため、回答を確定することができません。
            <br />
            未回答問題に回答してください。
          </p>
        }
        textButton="戻る"
        open={openNotice === 'warning'}
        openModal={() => setOpenNotice('')}
      />
      <NotiModal
        title="栽培イベント問題"
        message={
          <p>
            栽培イベント問題を回答していきましょう。
            <br /> 栽培イベント問題とは、突発的出来事への対応方法の問題です。
          </p>
        }
        textButton="はい"
        open={
          openNotice === 'introduce' && (!getStatusShowInsModal('event') || getStatusShowInsModal('event') === 'on')
        }
        openModal={() => {
          setStatusShowInsModal('event', 'off');
          setOpenNotice('');
        }}
      />
      <ConfirmModal
        title="確認"
        message={
          <p>
            回答を確定しますか？
            <br />
            回答を確定後回答を変更できません。
          </p>
        }
        open={openNotice === 'confirm'}
        onClose={() => setOpenNotice('')}
        onConfirm={handleSubmitAnswer}
      />
      <div className={styles.assignmentsQuestionWrap}>
        <Row className={styles.headWrap}>
          <Col span={12}>
            <Row className={styles.titleWrapper}>
              <p>栽培イベント問題</p>
              <p className={styles.title}>{getAssignmentName()}</p>
            </Row>
          </Col>
          <Col span={12}>
            <Row className={styles.contentRight}>
              <Link to={replacePathParams(EPath.U11, { id: id ?? '0' })}>
                <Typography.Text underline>栽培ルール確認</Typography.Text>
              </Link>
            </Row>
          </Col>
        </Row>
        <p className={styles.instrText}>
          回答済みの問題は緑色表示され、未回答状態のまま飛ばした問題は赤色表示されます。全ての問題に回答しましょう。
        </p>
        <Row align="middle" justify="space-between">
          <Col span={3}>
            <p className={styles.questionTitle}>基本問題回答状況</p>
          </Col>
          <Col span={21}>
            <NavQuestion dataQuestion={listAnswerBasicQues} id={id} type="basic" />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={3}>
            <p className={styles.questionTitle}>イベント問題回答状況</p>
          </Col>
          <Col span={21}>
            <NavQuestion dataQuestion={listAnswerEventQues} id={id} quesId={quesId} type="event" />
          </Col>
        </Row>
        <Row align="middle" className={styles.stageStep}>
          <Col span={2}>
            <p>栽培 ステージ</p>
          </Col>
          <Col span={22}>
            <NavStage stageName={stageName} data={dataStage} timeRange={detailTask.stages ?? []} />
          </Col>
        </Row>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <Row>
              <p>問題番号 1</p>
            </Row>
            <Row>
              <Card
                className={styles.questionCard}
                type="inner"
                title={<p className={styles.questionCardTitle}>問題</p>}
              >
                {currentQuestion.question}
              </Card>
            </Row>
            <Row>
              <Card
                className={styles.questionCard}
                type="inner"
                title={<p className={styles.questionCardTitle}>回答</p>}
              >
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="vertical">
                    {currentQuestion.options?.map((o) => (
                      <Radio key={o.index} value={o.index}>
                        {o.value}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Card>
            </Row>
          </div>
        </div>
      </div>
      <ButtonFooter
        handleClickPre={handleBackEventQuestion}
        handleClickNext={handleNextEventQuestion}
        textBtnFinish="回答確定"
        handleClickFinish={handleSubmitBtn}
      />
    </>
  );
};

export default AssignmentsEventQuestion;
