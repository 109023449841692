/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputRef } from 'antd';
import moment from 'moment';
import { logger } from 'libs/logger';
import { IndexedObject } from 'constants/types';
import { EditableContext } from 'components/EditTableColRow/EditableRow';
import styles from './style.module.scss';

const EditCell: React.FC<{
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: IndexedObject;
  title: string;
  handleSave: (record: IndexedObject) => void;
}> = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (form) form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async (dataIndex: string) => {
    try {
      const values = form && (await form.validateFields());
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      handleSave({ ...record, [dataIndex]: '' });
      logger.log('message', errInfo);
    }
  };
  function isValidDateFormat(dateString: string) {
    const formattedDate = dateString
      .split('/')
      .map((s) => s.padStart(2, '0'))
      .join('/');
    return moment(formattedDate, 'MM/DD', true).isValid();
  }
  let childNode = children;
  let inputNode = <></>;
  switch (dataIndex) {
    case 'name':
      inputNode = (
        <Form.Item
          className={styles.formItem}
          name={dataIndex}
          rules={[{ required: true, message: 'ステージ名を入力してください。' }]}
        >
          <Input.TextArea
            minLength={1}
            maxLength={20}
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
          />
        </Form.Item>
      );
      break;
    case 'startDate':
      inputNode = (
        <Form.Item
          className={styles.formItem}
          name={dataIndex}
          rules={[
            {
              validator: (_, value) => {
                if (isValidDateFormat(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('有効な日付を入力してください。'));
              },
            },
          ]}
        >
          <Input
            minLength={3}
            maxLength={5}
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
          />
        </Form.Item>
      );
      break;
    case 'endDate':
      inputNode = (
        <Form.Item
          className={styles.formItem}
          name={dataIndex}
          rules={[
            {
              validator: (_, value) => {
                if (isValidDateFormat(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('有効な日付を入力してください。'));
              },
            },
          ]}
        >
          <Input
            minLength={3}
            maxLength={5}
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            required
          />
        </Form.Item>
      );
      break;
    case 'feature':
      inputNode = (
        <Form.Item
          className={styles.formItem}
          name={dataIndex}
          rules={[{ required: true, message: '栽培ステージの特徴を入力してください。' }]}
        >
          <Input.TextArea
            className={styles.textAreaItem}
            autoSize={{ minRows: 3 }}
            minLength={1}
            maxLength={150}
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            required
          />
        </Form.Item>
      );
      break;
    default: {
      <Form.Item className={styles.formItem} name={dataIndex}>
        <Input.TextArea
          autoSize={{ minRows: 3 }}
          maxLength={150}
          className={styles.textAreaItem}
          ref={inputRef}
          onPressEnter={() => save(dataIndex)}
          onBlur={() => save(dataIndex)}
        />
      </Form.Item>;
    }
  }

  if (editable) {
    childNode = editing ? (
      inputNode
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit} onKeyDown={toggleEdit} role="button" tabIndex={0}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditCell;
