import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Select, Space } from 'antd';
import { useDispatch } from 'react-redux';
import MainButton from 'components/MainButton/MainButton';
// import CommonSelect from 'components/CommonSelect/CommonSelect';
import EditTable from 'components/EditTable/EditTable';
import { TGroupsState, groupsModule } from 'modules/groups/groups.reducer';
import { TUserDetail } from 'types/groups';
import { useHistory } from 'react-router-dom';
import { createQueryUrl } from 'libs/helpers/functions';
import { TOptionsQuery, TOptionsQueryExtra } from 'types/common';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { Location } from 'history';
import ConfirmModal from 'components/CommonModal/ConfirmModal';
import { IndexedObject } from 'constants/types';
import NotiModal from 'components/CommonModal/NotiModal';
import { TGroupsClassDetail } from 'types/groupsClass';
import AddMembersModal from './Modal/AddMembersModal';
import { EditableCellProps, isEditingUser, optionsValue, TOpenModal } from './GroupsAdm.state';
import styles from './style.module.scss';
import './style.css';

type Props = {
  getUsers: (params: TOptionsQueryExtra<TUserDetail>) => void;
  groupsState: TGroupsState;
  setUsers: (data: { user: TUserDetail; groupId: number }) => void;
  deleteUsers: (data: { id: number; groupId: number }) => void;
  location: Location;
  urlQueryParams: TOptionsQuery<TUserDetail>;
  currentGroup: TGroupsClassDetail;
};
const GroupsAdm = ({ getUsers, groupsState, setUsers, location, urlQueryParams, currentGroup, deleteUsers }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(0);
  const [openModal, setOpenModal] = useState<TOpenModal>('');
  const [userSelect, setUserSelect] = useState<number>(0);
  const { users, loading, pagination, showModalNoti } = groupsState;

  useEffect(() => {
    if (currentGroup && currentGroup.id) {
      getUsers({ ...urlQueryParams, groupId: currentGroup.id });
    }
  }, [location, currentGroup]);

  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  const handleDeleteUser = () => {
    deleteUsers({ id: userSelect, groupId: currentGroup.id ?? 0 });
  };
  const editableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, inputType, children, ...restProps }) => {
    const inputNode =
      inputType === 'select' ? (
        <Select options={optionsValue.map((o) => ({ label: o.name, value: o.value }))} />
      ) : (
        <Input />
      );
    let rulesValidate: IndexedObject[] = [];
    if (dataIndex === 'name') {
      rulesValidate = [{ required: true }];
    }
    if (dataIndex === 'email') {
      rulesValidate = [{ required: true, whitespace: true }, { type: 'email' }];
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rulesValidate}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record: TUserDetail) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id ?? 0);
  };

  const save = async (id: number) => {
    const row = (await form.validateFields()) as TUserDetail;
    if (row) {
      setUsers({ user: { ...row, id }, groupId: currentGroup.id ?? 0 });
      setEditingKey(0);
    }
  };

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
      editable: false,
    },
    {
      key: 'email',
      title: 'メールアドレス',
      dataIndex: 'email',
      width: '25%',
      editable: true,
    },
    {
      key: 'name',
      title: '氏名',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      key: 'role',
      title: '区分',
      dataIndex: 'role',
      width: '25%',
      editable: true,
      render: (_: any, record: TUserDetail) => {
        const value = optionsValue.find((option) => option.value === record.role);
        return value ? value.name : '';
      },
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'operation',
      render: (_: any, record: TUserDetail) => {
        const editable = isEditingUser(record, editingKey);
        return editable ? (
          <Space align="center">
            <MainButton text="編集終了" onClick={() => save(record.id ?? 0)} />
          </Space>
        ) : (
          <Space align="center">
            <MainButton text="編集" onClick={() => edit(record)} />
            <MainButton
              subButton
              text="削除"
              onClick={() => {
                setUserSelect(record.id ?? 0);
                setOpenModal('delete');
              }}
            />
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TUserDetail) => ({
        record,
        inputType: col.dataIndex === 'role' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingUser(record, editingKey),
      }),
    };
  });

  return (
    <div className="groupsAdm">
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>ユーザー管理</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Button className={styles.modalAddUserButton} onClick={() => setOpenModal('add')}>
                ユーザーを追加する
              </Button>
              <Form form={form} component={false}>
                <EditTable
                  loading={loading}
                  form={form}
                  data={users}
                  editableCell={editableCell}
                  mergedColumns={mergedColumns}
                />
              </Form>
            </div>
            <div className={styles.bottom}>
              <PaginationCustom
                current={pagination.page}
                total={pagination.totalResults}
                pageSize={pagination.limit}
                disabled={loading}
                onChange={onChangePage}
              />
            </div>
          </div>
        </Card>
        <AddMembersModal open={openModal === 'add'} onClose={() => setOpenModal('')} />
        <ConfirmModal
          title="確認"
          message={
            <p>
              ユーザーを削除してもよろしいでしょうか？
              <br />
              削除するとユーザーの解答結果も削除されます。
            </p>
          }
          open={openModal === 'delete'}
          onClose={() => setOpenModal('')}
          onConfirm={handleDeleteUser}
        />
        <NotiModal
          title="確認"
          message="ユーザーを追加しました。"
          open={showModalNoti === 'create'}
          openModal={() => dispatch(groupsModule.actions.clearSuccess())}
          textButton="OK"
        />
        <NotiModal
          title="確認"
          message="ユーザーを編集しました。"
          open={showModalNoti === 'update'}
          openModal={() => dispatch(groupsModule.actions.clearSuccess())}
          textButton="OK"
        />
        <NotiModal
          title="確認"
          message="ユーザーを削除しました。"
          open={showModalNoti === 'delete'}
          openModal={() => dispatch(groupsModule.actions.clearSuccess())}
          textButton="OK"
        />
      </div>
    </div>
  );
};

export default GroupsAdm;
