import React from 'react';
import RightAnswerIcon from 'components/Icons/RightAnswerIcon';
import WrongAnswerIcon from 'components/Icons/WrongAnswerIcon';
import { Divider, Row } from 'antd';
import { TResultBasicTask } from 'types/assignments';
import styles from './style.module.scss';

type Props = {
  dataTable: Array<TResultBasicTask>;
  title1?: string;
  title2?: string;
};

const TableResultDetail = ({ dataTable, title1, title2 }: Props) => (
  <>
    <Row>
      <p className={styles.title}>{title1}</p>
      <p className={`${styles.title} ${styles.titleTable}`}>{title2}</p>
    </Row>
    <Divider className={styles.divider} />
    <table className={styles.tableResultAnswer}>
      <thead>
        <tr>
          <th> </th>
          <th>正誤</th>
          <th>栽培ステージ</th>
          <th>問題</th>
          <th>選択した回答</th>
          <th>正解</th>
          <th>解説</th>
        </tr>
      </thead>
      <tbody>
        {dataTable.map((d) => (
          <tr
            key={d.key}
            className={`${styles.tableRow} ${d.selectedAnswer !== d.correctAnswer ? styles.wrongAnswer : ''}`}
          >
            <td className={styles.cellJustify}>{Number(d.key) + 1} </td>
            <td className={styles.cellJustify}>
              {d.selectedAnswer === d.correctAnswer ? <RightAnswerIcon size={20} /> : <WrongAnswerIcon />}
            </td>
            <td className={styles.cellJustify}>{d.stageName}</td>
            <td className={styles.cellStart}>{d.question}</td>
            <td className={styles.cellStart}>{d.selectedAnswer}</td>
            <td className={styles.cellStart}>{d.correctAnswer}</td>
            <td className={styles.cellStart}>{d.comment}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

export default TableResultDetail;
