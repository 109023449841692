import React from 'react';
import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import moment from 'moment';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { forkJoin, from } from 'rxjs';
import { AxiosResponse } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { templateServices } from 'services/template';
import { uploadServices } from 'services/upload';
import { push } from 'connected-react-router';
import { EPath } from 'constants/routes';
import { replacePathParams } from 'libs/helpers/functions';
import { TStep2 } from 'types/template';
import actions from './template.action';
import { templateModule, TTemplateState } from './template.reducer';

type TNextPage = {
  createTemplate: TTemplateState;
  name: string;
};

export const getTemplateByIdEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTemplateId),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.getTemplateById(payload)),
        next: (v: TTemplateState) => [templateModule.actions.getTemplate(v)],
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getTemplateAndSaveIdEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTemplateAndSaveId),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.getTemplateById(payload)),
        next: (v: TTemplateState) => [templateModule.actions.saveIdTemplate(v.id)],
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createTemplateEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.createTemplate),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.createTemplate({ ...payload.data, groupId: payload.groupId })).pipe(
          concatMap((resCreate) =>
            from(uploadServices.uploadImage(resCreate.id, payload.formData)).pipe(
              concatMap((resUpload) =>
                forkJoin({
                  createTemplate: [resCreate],
                  name: [resUpload.name],
                }),
              ),
            ),
          ),
        ),
        next: (v: TNextPage) => {
          if (v.createTemplate.id) {
            return [
              push(replacePathParams(EPath.A4_2, { id: String(v.createTemplate.id) })),
              templateModule.actions.saveTemplate(v.createTemplate),
            ];
          }
          return [templateModule.actions.saveTemplate(v.createTemplate)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createTemplateWithoutImageEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.createTemplateWithoutImage),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.createTemplate({ ...payload.data, groupId: payload.groupId })),
        next: (v: TTemplateState) => {
          const convertData = {
            createTemplate: v,
            name: undefined,
          };
          if (convertData.createTemplate.id) {
            return [
              push(replacePathParams(EPath.A4_2, { id: String(convertData.createTemplate.id) })),
              templateModule.actions.saveTemplate(convertData.createTemplate),
            ];
          }
          return [templateModule.actions.saveTemplate(convertData.createTemplate)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep1: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep1),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate(payload.data, payload.id, payload.groupId)),
        next: (v: TTemplateState) => {
          if (payload.buttonFooterNext) {
            return [
              push(replacePathParams(EPath.A4_2, { id: String(payload.id) })),
              templateModule.actions.saveTemplate(v),
            ];
          }
          return [templateModule.actions.saveTemplate(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
export const updateImageStep1: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep1),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate(payload.data, payload.id, payload.groupId)),
        next: (v: TTemplateState) => {
          if (payload.buttonFooterNext) {
            return [
              push(replacePathParams(EPath.A4_2, { id: String(payload.id) })),
              templateModule.actions.saveTemplate(v),
            ];
          }
          return [templateModule.actions.saveTemplate(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
export const updateTemplateStep2: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep2),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate2(payload.data, payload.id, payload.groupId)),
        next: (v: TTemplateState) => {
          if (payload.buttonFooterNext) {
            return [
              push(replacePathParams(EPath.A4_3, { id: String(payload.id) })),
              templateModule.actions.updateStep2(v),
            ];
          }
          if (payload.buttonFooterPre) {
            return [
              push(replacePathParams(EPath.A4_1, { id: String(payload.id) })),
              templateModule.actions.updateStep2(v),
            ];
          }
          return [templateModule.actions.updateStep2(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep3: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep3),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate3(payload.data, payload.id, payload.groupId)),
        next: (v: TTemplateState) => {
          if (payload.buttonFooterNext) {
            return [
              push(replacePathParams(EPath.A4_4, { id: String(payload.id) })),
              templateModule.actions.updateStep3(v),
            ];
          }
          if (payload.buttonFooterPre) {
            return [
              push(replacePathParams(EPath.A4_2, { id: String(payload.id) })),
              templateModule.actions.updateStep3(v),
            ];
          }
          return [templateModule.actions.updateStep3(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
export const updateTemplateStep4: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep4),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate4(payload.data, payload.id, payload.groupId)),
        next: (v: TTemplateState) => {
          if (payload.buttonFooterNext) {
            return [
              templateModule.actions.updateStep4(v),
              push(replacePathParams(EPath.A4_5, { id: String(payload.id) })),
            ];
          }
          if (payload.buttonFooterPre) {
            return [
              templateModule.actions.updateStep4(v),
              push(replacePathParams(EPath.A4_3, { id: String(payload.id) })),
            ];
          }
          return [templateModule.actions.updateStep4(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep5: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep5),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate5(payload.data, payload.id, payload.groupId)),
        next: (v: TTemplateState) => {
          if (payload.buttonFooterNext) {
            return [
              push(replacePathParams(EPath.A4_6_NEW, { id: String(payload.id) })),
              templateModule.actions.updateStep5(v),
            ];
          }
          if (payload.buttonFooterPre) {
            return [
              push(replacePathParams(EPath.A4_4, { id: String(payload.id) })),
              templateModule.actions.updateStep5(v),
            ];
          }
          return [templateModule.actions.updateStep4(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep6: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep6),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate6(payload.data, `${payload.id}`, payload.groupId)),
        next: (v: TTemplateState) => {
          if (payload.buttonFooterNext) {
            return [push(EPath.A3), templateModule.actions.updateStep6(v)];
          }
          if (payload.buttonFooterPre) {
            return [
              templateModule.actions.updateStep6(v),
              push(replacePathParams(EPath.A4_6_NEW, { id: String(payload.id) })),
            ];
          }
          return [templateModule.actions.updateStep6(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStepChooseControlResult: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (
  action$,
) =>
  action$.pipe(
    ofAction(actions.updateTemplateStepChooseControlResult),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(
          templateServices.updateTemplateChooseControlResult(payload.data, String(payload.id), payload.groupId),
        ),
        next: (v: TTemplateState) => {
          if (payload.buttonFooterNext) {
            return [
              push(replacePathParams(EPath.A4_6, { id: String(payload.id) })),
              templateModule.actions.updateStepChooseControlResult(v),
            ];
          }
          if (payload.buttonFooterPre) {
            return [
              push(replacePathParams(EPath.A4_5, { id: String(payload.id) })),
              templateModule.actions.updateStepChooseControlResult(v),
            ];
          }
          return [templateModule.actions.updateStepChooseControlResult(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
export const clearTemplate: Epic<AnyAction, WrapAction<any>> = (action$) =>
  action$.pipe(
    ofAction(actions.clearTemplate),
    mergeMap(() => [templateModule.actions.clearState()]),
  );

export const uploadCsvQuestionFileEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.uploadCsvQuestionFile),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.uploadCsvQuestionFile(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u) => {
          const addKey = u.map((x: TStep2, i: number) => ({
            ...x,
            id: i + x.question,
          }));

          return [
            payload.step === 2
              ? templateModule.actions.updateQuestionStep2(addKey)
              : templateModule.actions.updateQuestionStep3(addKey),
          ];
        },
        error: (err: AxiosResponse) => {
          const data: string[] = err.data.message.split('・');
          return messageModule.actions.setSpecialErrorMsg(data);
        },
      }),
    ),
  );

export const downloadCsvQuestionFileEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.downloadCsvQuestionFile),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.downloadCsvQuestionFile(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: any) => {
          const url = window.URL.createObjectURL(new Blob([`\ufeff${u}`], { type: 'text/csv;charset=utf-8;' }));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          const fileName = payload.step === 2 ? 'basic' : 'event';
          const currentTime = moment(Date.now()).format('YYYYMMDD');
          a.download = `${fileName}-question_${currentTime}.csv`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          return [];
        },
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
