import { Form, Table } from 'antd';
import React from 'react';
import { TUserDetail } from 'types/groups';
import styles from './style.module.scss';
import { columnsTableUsers } from './AssignmentDetailAdm.state';

type Props = {
  selectedUsers: TUserDetail[];
};

const PreviewUsersTraining = ({ selectedUsers }: Props) => {
  if (!selectedUsers.length) return null;

  return (
    <Form.Item>
      <div className={styles.noteWrap}>
        <p>※選択中の受講者一覧（上で選択したユーザーの一覧です）</p>
      </div>
      <Table
        dataSource={selectedUsers}
        columns={columnsTableUsers}
        rowKey="id"
        pagination={false}
        scroll={{ y: 160 }}
      />
    </Form.Item>
  );
};

export default PreviewUsersTraining;
