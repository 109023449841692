import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { AxiosResponse, TResponsePagination } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { TTemplateDetail } from 'types/templates';
import { templatesServices } from 'services/templates';
import { templatesModule } from './templates.reducer';
import actions from './templates.action';

export const getTemplatesEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTemplates),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templatesServices.getTemplates(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TResponsePagination<TTemplateDetail>) => templatesModule.actions.saveTemplates(t),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const deleteTemplateEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.deleteTemplate),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templatesServices.deleteTemplate(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: TTemplateDetail) => [templatesModule.actions.deleteTemplate(payload.templateId)],
        error: (err: AxiosResponse) => [templatesModule.actions.setError(true)],
      }),
    ),
  );

export const getAllTemplatesEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getAllTemplates),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templatesServices.getAllTemplates(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TTemplateDetail[]) => templatesModule.actions.saveTemplatesAll(t),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
