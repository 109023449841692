import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { navStep } from 'constants/nav';
import EditableCell from 'components/EditTableColRow/EditableCellProps';
import EditableRow from 'components/EditTableColRow/EditableRow';
import { TTemplateState } from 'modules/template/template.reducer';
import { ColumnTypes } from 'types/template';
import EditTemplate from 'hook/EditTemplate';
import { Link } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { TGroupsClassDetail } from 'types/groupsClass';
import useGetTemplateLs from 'hook/useGetTemplateLs';
import {
  defaultColumns,
  data,
  DataType,
  TStep5State,
  aliasData,
  correctionValue,
  ALIAS,
} from './AdmTempControlSimulation.state';
import styles from './style.module.scss';

type TProps = {
  template: TTemplateState;
  updateTemplate: (
    data: TStep5State,
    id: number,
    groupId: number,
    buttonFooterNext?: boolean,
    buttonFooterPre?: boolean,
  ) => void;
  currentGroup: TGroupsClassDetail;
  saveTemplate: (data: TTemplateState) => void;
};

const AdmTempControlSimulation = ({ updateTemplate, template, currentGroup, saveTemplate }: TProps) => {
  const [dataSource, setDataSource] = useState<DataType[] | []>([]);
  const [dataTemp] = useGetTemplateLs();

  const dataStep5 = dataSource.map((item, index) => ({
    alias: aliasData[index],
    effectName: item.effectName,
    factor: item.factor,
  }));

  EditTemplate();

  useEffect(() => {
    const newData = [...data];

    if (template.id && template.step5 && template.step5.length > 0) {
      const newDataStep5 = template.step5.map((item, index) => ({
        correctionValue: correctionValue[index],
        alias: ALIAS[index],
        effectName: item.effectName,
        factor: item.factor,
      }));
      setDataSource([...newDataStep5]);
    } else {
      setDataSource(newData);
    }
  }, [template]);

  useEffect(() => {
    if (Object.keys(dataTemp).length > 0) {
      saveTemplate(dataTemp);
    }
  }, []);
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];

    const index = newData.findIndex((item) => row.correctionValue === item.correctionValue);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setDataSource(newData);
  };

  const components = {
    body: {
      cell: EditableCell,
      row: EditableRow,
    },
  };

  const onClickNextStep = (buttonFooter: boolean = false) => {
    const data: TStep5State = {
      step5: dataStep5,
    };
    updateTemplate(data, template.id, currentGroup.id ?? 0, buttonFooter, true);
  };

  const onClickPreStep = (buttonFooter: boolean = false) => {
    const data: TStep5State = {
      step5: dataStep5,
    };
    updateTemplate(data, template.id, currentGroup.id ?? 0, buttonFooter, true);
  };

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
            </Row>
            <Row className={styles.assignmentsQuestionWrap__marginTop}>
              <Link to={EPath.A3}>
                <Button>テンプレート一覧に戻る</Button>
              </Link>
            </Row>
          </Col>
        </Row>
        <NavStep data={navStep} />
        <div className={styles.contentTitle}>制御シミュレーション補正値の設定</div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.form}>
              <p>制御シミュレーションで使用する計算式の補正値を設定できます</p>
              <br />
              <Table
                className={styles.contentWrap__table}
                components={components}
                columns={columns as ColumnTypes}
                dataSource={dataSource}
                pagination={false}
                rowKey="correctionValue"
              />
              <br />
            </div>
          </div>
        </div>
      </div>
      <ButtonFooter handleClickNext={onClickNextStep} handleClickPre={onClickPreStep} />
    </>
  );
};

export default AdmTempControlSimulation;
