import React from 'react';
import { Form, Input, Modal, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MainButton from 'components/MainButton/MainButton';
import { IndexedObject } from 'constants/types';
import groupsAction from 'modules/groups/groups.action';
import { defaultValidateMessages } from 'constants/validate/message';
import { AppState } from 'store';
import { optionsValue } from '../GroupsAdm.state';
import styles from '../style.module.scss';

const AddMembersModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const onCreateUser = (values: IndexedObject) => {
    dispatch(groupsAction.createUsers({ ...values, groupId: currentGroup.id ?? 0 }));
    form.resetFields();
    onClose();
  };
  return (
    <Modal
      title={<p className={styles.modalAddUserTitle}>ユーザーを追加する</p>}
      centered
      open={open}
      footer={null}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      width={440}
      closable={false}
      bodyStyle={{
        padding: '16px 40px',
      }}
    >
      <Form layout="vertical" onFinish={onCreateUser} form={form}>
        <Form.Item
          name="email"
          label="メールアドレス"
          rules={[
            { required: true, whitespace: true },
            { type: 'email', message: defaultValidateMessages.types.email },
          ]}
          className={styles.formAddUserItem}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          label="氏名"
          name="name"
          className={styles.formAddUserItem}
          rules={[{ required: true, message: defaultValidateMessages.required }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="password"
          label="初期パスワード"
          className={styles.formAddUserItem}
          rules={[{ required: true, message: defaultValidateMessages.required }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item name="role" rules={[{ required: true, message: defaultValidateMessages.required }]}>
          <Radio.Group>
            {optionsValue &&
              optionsValue.map((option) => (
                <Radio key={option.name} value={option.value}>
                  {option.name}
                </Radio>
              ))}
          </Radio.Group>
        </Form.Item>
        <p className={styles.descRegis}>
          ※ユーザーの追加後、上のメールアドレスあてにIDとパスワードを記載した招待メールが送信されます。
        </p>
        <Form.Item>
          <MainButton text="追加する" htmlType="submit" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddMembersModal;
