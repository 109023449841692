import React from 'react';
import { Upload, Modal, Space } from 'antd';
import { UploadChangeParam, UploadFile, UploadListType, UploadProps } from 'antd/lib/upload/interface';
import MainButton from 'components/MainButton/MainButton';
import styles from './style.module.scss';

const ModalUpload: React.FC<{
  open: boolean;
  setModal: (active: boolean) => void;
  handleChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
  props: UploadProps<any>;
  uploadSuccess: boolean;
  fileList?: UploadFile[];
}> = ({ open, setModal, handleChange, uploadSuccess, props, fileList }) => (
  <Modal
    title="ファイルのアップロード"
    centered
    open={open}
    onOk={() => setModal(false)}
    onCancel={() => setModal(false)}
    footer={null}
  >
    <Upload.Dragger
      maxCount={1}
      {...props}
      onChange={handleChange}
      listType={'picture' as UploadListType}
      multiple={false}
      fileList={fileList}
    >
      <p className="ant-upload-drag-icon">
        <img src="/images/icon-wrapper-h.png" alt="icon-wrapper-h" />
      </p>
      <p className="ant-upload-text">ここにファイルをドラッグ＆ドロップ</p>
      <p className="ant-upload-hint">またはここをクリックし、ファイルを指定してください。</p>
    </Upload.Dragger>
    <div className={styles.buttonUpload}>
      <Space>
        <MainButton text="このファイルをアップロード" onClick={() => setModal(false)} disabled={!uploadSuccess} />
      </Space>
    </div>
  </Modal>
);

export default ModalUpload;
