import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getTrainings: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'assignments',
};

const getTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'assignments/:id',
};

const updateTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'assignments/:id',
};

const createTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'assignments',
};

const deleteTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'assignments/:id',
};

const getDetailTaskStudentDoes: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'assignments/:id/todo',
};
const getResultsAssignment: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/assignments/:id/results',
};

const submitAnswerBasicTask: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/assignments/:id/basic-tasks',
};

const getResultAssignmentOneStudent: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/assignments/:id/results/:sid',
};

const updateCommentAssignment: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/assignments/:id/results/:sid',
};

const submitAnswerControlTask: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/assignments/:id/control-task',
};

export const assignmentsRepositories = {
  getTrainings: apiClient.getFn(getTrainings),
  getTraining: apiClient.getFn(getTraining),
  createTraining: apiClient.postFn(createTraining),
  updateTraining: apiClient.patchFn(updateTraining),
  deleteTraining: apiClient.deleteFn(deleteTraining),
  getDetailTaskStudentDoes: apiClient.getFn(getDetailTaskStudentDoes),
  getResultsAssignment: apiClient.getFn(getResultsAssignment),
  submitAnswerBasicTask: apiClient.postFn(submitAnswerBasicTask),
  getResultAssignmentOneStudent: apiClient.getFn(getResultAssignmentOneStudent),
  updateCommentAssignment: apiClient.patchFn(updateCommentAssignment),
  submitAnswerControlTask: apiClient.postFn(submitAnswerControlTask),
};
