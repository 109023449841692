import { TAnswerAQuestion, TControlAnswer } from 'types/assignments';
import { TDataSimulations } from 'types/results';
import { TGroupsClassDetail } from 'types/groupsClass';
import { IndexedObject } from 'constants/types';

export const saveBasicAnswerToLocalStorage = (data: Array<TAnswerAQuestion>) => {
  localStorage.setItem('basicAnswer', JSON.stringify(data));
};

export const saveEventAnswerToLocalStorage = (data: Array<TAnswerAQuestion>) => {
  localStorage.setItem('eventAnswer', JSON.stringify(data));
};

export const getAnswerFromLocalStorage = (key: 'basicAnswer' | 'eventAnswer') =>
  JSON.parse(localStorage.getItem(key) ?? '[]');

export const saveControlAnswersToLocalStorage = (data: { id: string; answers: TControlAnswer[] }) => {
  localStorage.setItem(`controlAnswers_${data.id}`, JSON.stringify(data.answers));
};

export const getControlAnswersFromLS = (id: string | number) =>
  JSON.parse(localStorage.getItem(`controlAnswers_${id}`) ?? '[]');

export const clearAnswerBasicTaskInLocalStorage = () => {
  localStorage.removeItem('basicAnswer');
  localStorage.removeItem('eventAnswer');
};

export const saveDataSimulationsToLocalStorage = (data: TDataSimulations) => {
  localStorage.setItem('dataSimulations', JSON.stringify(data));
};

export const getDataSimulationsFromLocalStorage = () => JSON.parse(localStorage.getItem(`dataSimulations`) ?? '{}');

export const getStatusShowInsModal = (type: 'basic' | 'event' | 'control') => localStorage.getItem(type) ?? null;
export const setStatusShowInsModal = (type: 'basic' | 'event' | 'control', value: 'on' | 'off') =>
  localStorage.setItem(type, value);

export const saveAssignmentName = (name: string) => {
  localStorage.setItem('assignmentName', name);
};

export const getAssignmentName = (): string => localStorage.getItem('assignmentName') ?? '';

export const saveGroupToLS = (group: TGroupsClassDetail) => localStorage.setItem('group', JSON.stringify(group));
export const getGroupFromLS = () => JSON.parse(localStorage.getItem('group') ?? '{}');

export const setTemplateCopyToLs = (template: IndexedObject) =>
  localStorage.setItem('template', JSON.stringify(template));
export const getTemplateCopyFromLs = () => JSON.parse(localStorage.getItem('template') ?? '{}');

export const setEnabledEditMode = (data: boolean) => localStorage.setItem('editMode', JSON.stringify(data));
export const getEnabledEditMode = () => JSON.parse(localStorage.getItem('editMode') ?? 'false');
