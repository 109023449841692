import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { convertDataAnswerGraph, replacePathParams } from 'libs/helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { EPath } from 'constants/routes';
import { answersAction } from 'modules/answers/answers.action';
import { TAnswerControlSubmit, TControlAnswer } from 'types/assignments';
import { AppState } from 'store';
import { getControlAnswersFromLS } from 'libs/helpers/localStorageFn';
import AssignmentsControlGraph from './AssignmentsControlGraph';

const AssignmentsControlGraphContainer = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmitAnswerControlTask = useCallback(
    (data: TAnswerControlSubmit) => {
      dispatch(answersAction.submitAnswerControlTask(data));
    },
    [dispatch],
  );
  const answersState = getControlAnswersFromLS(id);
  const dataStudentAnswer = useSelector((state: AppState) => state.pages.answers.dataSimulations?.studentSimulation);

  const handleClickPre = () => history.push(replacePathParams(EPath.U8_1_2, { id }));

  const answersStateConvert: TControlAnswer[] = answersState.map((it: TControlAnswer) => {
    const dataAnswer = {
      alias: it.alias,
      factorial: it.factorial,
      morningData: it.morningData ? parseFloat(it.morningData.toString() || '0') : 0,
      afternoonData: it.beforeNightData ? parseFloat(it.beforeNightData.toString() || '0') : 0,
      beforeNightData: it.afternoonData ? parseFloat(it.afternoonData.toString() || '0') : 0,
      midnightData: it.midnightData ? parseFloat(it.midnightData.toString() || '0') : 0,
      unit: it.unit,
      month: it.month,
    };
    return dataAnswer;
  });

  const studentAnswerCheckNotDoing = convertDataAnswerGraph(answersState);

  return (
    <AssignmentsControlGraph
      handleSubmitAnswerControlTask={handleSubmitAnswerControlTask}
      handleClickPre={handleClickPre}
      answersState={answersStateConvert}
      assignmentId={id}
      dataStudentAnswer={dataStudentAnswer}
      studentAnswerCheckNotDoing={studentAnswerCheckNotDoing}
    />
  );
};

export default AssignmentsControlGraphContainer;
