import { DEFAULT_LIMIT_PAGINATION } from 'constants/pagination';
import { groupsRepositories } from 'repositories/groups';
import { TOptionsQuery } from 'types/common';
import { TUserDetail } from 'types/groups';

const DEFAULT_GROUPS_LIMIT = DEFAULT_LIMIT_PAGINATION;
const DEFAULT_GROUPS_PAGE = 1;

const getUsers = async (params: TOptionsQuery<TUserDetail>) => {
  const res = await groupsRepositories.getUsers({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_GROUPS_LIMIT,
      page: params.page ?? DEFAULT_GROUPS_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const createUsers = async (data: TUserDetail & { groupId: number }) => {
  const { groupId, ...body } = data;
  const res = await groupsRepositories.createUsers({
    body,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateUsers = async (data: { user: TUserDetail; groupId: number }) => {
  const {
    user: { id, ...body },
    groupId,
  } = data;
  const res = await groupsRepositories.updateUsers({
    body,
    pathParameters: { id },
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const deleteUsers = async (data: { id: number; groupId: number }) => {
  const res = await groupsRepositories.deleteUsers({
    pathParameters: { id: data.id },
    condition: {
      groupId: data.groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }
  return data.id;
};
export const groupsServices = {
  getUsers,
  createUsers,
  updateUsers,
  deleteUsers,
};
