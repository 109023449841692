import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Space } from 'antd';
import { defaultValidateMessages } from 'constants/validate/message';
import { Link } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { TControlResultsState } from 'modules/controlResults/controlResults.reducer';
import { TGroupsClassDetail } from 'types/groupsClass';
import ModalUploadControlRs from './ModalUploadControlRs';
import styles from './style.module.scss';

type Props = {
  id: string;
  currentGroup: TGroupsClassDetail;
  controlResultsState: TControlResultsState;
  uploadFileControlResults: (file: FormData) => void;
  createControlResults: (data: { name?: string; fname?: string; groupId: number }) => void;
  getDetailControlResults: (data: { ctrRsId: number; groupId: number }) => void;
  updateControlResults: (data: { ctrRsId: number; name?: string; fname?: string; groupId: number }) => void;
  updateDataFileUpload: () => void;
};
const AdmControlResultsDetails = ({
  id,
  currentGroup,
  controlResultsState,
  createControlResults,
  getDetailControlResults,
  updateControlResults,
  uploadFileControlResults,
  updateDataFileUpload,
}: Props) => {
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleSubmit = (values: { name: string }) => {
    const { fname } = controlResultsState.dataFileUpload;

    if (!id && fname && currentGroup.id) {
      createControlResults({ ...values, fname, groupId: currentGroup.id });
      return;
    }
    if (id && currentGroup.id) {
      const dataUpdate = fname ? { ...values, fname, ctrRsId: Number(id) } : { ...values, ctrRsId: Number(id) };
      updateControlResults({ ...dataUpdate, groupId: currentGroup.id });
    }
  };
  useEffect(() => {
    updateDataFileUpload();
  }, []);

  useEffect(() => {
    if (id && currentGroup.id) {
      getDetailControlResults({ ctrRsId: Number(id), groupId: currentGroup.id });
    }
  }, [id, currentGroup.id]);

  useEffect(() => {
    if (id) {
      form.setFieldValue('name', controlResultsState.controlResultsDetail.name);
    }
  }, [controlResultsState.controlResultsDetail, id]);
  return (
    <>
      <ModalUploadControlRs
        uploadFileControlResults={uploadFileControlResults}
        open={openModal}
        setModal={setOpenModal}
        clearStateUpload={() => {}}
      />
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>模範データ登録</p>
            </div>
          }
          loading={false}
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.topButtonGroup}>
                <Button className={styles.topButton}>
                  <Link to={EPath.A9_1}>模範データ管理に戻る</Link>
                </Button>
              </Space>
              <Form
                autoComplete="off"
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                validateMessages={defaultValidateMessages}
                disabled={false}
              >
                <Form.Item>
                  <Space direction="vertical" align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>模範データ名</p>
                    <Form.Item name="name" rules={[{ required: true, whitespace: true }]} noStyle>
                      <Input className={styles.assignmentItemWidth} placeholder="模範データ名を入力してください" />
                    </Form.Item>
                  </Space>
                </Form.Item>

                <div className={styles.body}>
                  <p>
                    模範データのファイルをアップロードしてください。 <br />
                    （CSV形式。添付可能な最大サイズは500KBです。）
                  </p>
                  <div className={styles.uploadGroup}>
                    <Button className={styles.topButton} onClick={() => setOpenModal(true)}>
                      ファイルを選択
                    </Button>
                    <p>
                      {controlResultsState.dataFileUpload.originalName
                        ? controlResultsState.dataFileUpload.originalName
                        : '選択されていません。'}
                    </p>
                  </div>
                </div>
                <Form.Item noStyle>
                  <button disabled={false} type="submit" className={styles.confirmButton}>
                    保存する
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AdmControlResultsDetails;
