import React from 'react';
import { Select } from 'antd';
import { aliasValue } from 'constants/date';
import { TableSettingItem, TCtrlAnswer } from './AssignmentsControlQuestion.state';
import styles from './style.module.scss';

type TProps = {
  title: string;
  settingItems: TableSettingItem[];
  answers: TCtrlAnswer[];
  onChange: (value: string, alias: string, key: number) => any;
};

const getByAlias = (alias: string, answers: TCtrlAnswer[]) => answers.find((it) => it.alias === alias);

const ControlQuestionTable = ({ title, settingItems, answers, onChange }: TProps) => (
  <>
    <div className={styles.contentTitle}>{title}</div>
    <div className={styles.content}>
      <div className={styles.contentWrap}>
        <div className={styles.form}>
          <table className={styles.contentWrap__table}>
            <thead>
              <tr>
                <th className={styles.table__factorial}>設定項目</th>
                <th className={styles.table__unit}>単位</th>
                <th>早朝</th>
                <th>日中</th>
                <th>前夜半</th>
                <th>夜間</th>
              </tr>
            </thead>
            <tbody>
              {settingItems.map((item) => {
                const answer = getByAlias(item.alias, answers);
                const convertValue = (value?: string | null) => {
                  if (answer?.alias === aliasValue.M8) {
                    if (value === null || value === undefined) {
                      return null;
                    }
                    return Number(value) === 1 ? 'ON' : 'OFF';
                  }
                  return value;
                };
                return (
                  <tr key={item.factorial}>
                    <td>{item.factorial}</td>
                    <td>{item.unit}</td>
                    <td>
                      <Select
                        className={styles.widthSelectTable}
                        value={convertValue(answer?.morningData)}
                        options={item.morningOpts}
                        onChange={(v: string) => onChange(v, item.alias, 0)}
                      />
                    </td>
                    <td>
                      <Select
                        className={styles.widthSelectTable}
                        value={convertValue(answer?.noonData)}
                        options={item.noonOpts}
                        onChange={(v: string) => onChange(v, item.alias, 1)}
                      />
                    </td>
                    <td>
                      <Select
                        className={styles.widthSelectTable}
                        value={convertValue(answer?.afternoonData)}
                        options={item.afternoonOpts}
                        onChange={(v: string) => onChange(v, item.alias, 2)}
                      />
                    </td>
                    <td>
                      <Select
                        className={styles.widthSelectTable}
                        value={convertValue(answer?.nightData)}
                        options={item.nightOpts}
                        onChange={(v: string) => onChange(v, item.alias, 3)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </>
);

export default ControlQuestionTable;
