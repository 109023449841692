import React from 'react';
import { Table } from 'antd';
import { IndexedObject } from 'constants/types';
import styles from './style.module.scss';

const Task: React.FC<{
  title: string;
  columns: Array<IndexedObject>;
  data: Array<IndexedObject>;
}> = ({ title, columns, data }) => (
  <div className={styles.taskWarp}>
    <p className={styles.taskTitle}>{title}</p>
    <Table
      className={styles.taskTable}
      size="small"
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ y: 180, x: 'auto' }}
    />
  </div>
);
export default Task;
