import React, { useMemo } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { TNavStep, TNavSteps } from 'types/common';
import styles from './style.module.scss';

const NavStep: React.FC<{ data: TNavSteps; linkDisabled?: boolean; onClick?: () => void }> = ({
  data,
  linkDisabled = false,
  onClick,
}) => {
  const { id } = useParams<{ id: string }>();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      if (linkDisabled) e.preventDefault();
      onClick();
    }
  };

  const pathUrl = useHistory();
  const pathName = useMemo(() => pathUrl.location.pathname, [pathUrl]);

  const makeNavLink = (step: TNavStep) => {
    let navLink = '';
    if (id && step.link) navLink = step.link.replace(':id', id);
    else if (step.link) navLink = step.link || navLink;

    return navLink;
  };

  return (
    <div className={styles.stepContainer}>
      <ul className={styles.stepWrap}>
        {data &&
          data.map((step) => (
            <NavLink
              key={step.link}
              to={makeNavLink(step)}
              className={() =>
                pathName.indexOf((step.link as string).replace('/:id', '')) !== -1
                  ? classnames(styles.linkActive, styles.step)
                  : styles.step
              }
              onClick={handleClick}
            >
              <p>
                {step.name}
                <span>{step.label}</span>
              </p>
            </NavLink>
          ))}
      </ul>
    </div>
  );
};

export default NavStep;
