import { getMaxScoreBasic } from 'libs/helpers/functions';
import React from 'react';
import { TMaxScore } from 'types/assignments';
import styles from './style.module.scss';

type Props = {
  maxScore?: TMaxScore;
  basicScore?: number;
  eventScore?: number;
};

const TableResultBasicTask = ({ maxScore, basicScore = 0, eventScore = 0 }: Props) => (
  <table className={styles.tableResult}>
    <thead>
      <tr>
        <th>合計</th>
        <th>基本問題</th>
        <th>イベント問題</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{getMaxScoreBasic(maxScore)}点満点</th>
        <th>{maxScore?.basic ?? 0}点満点</th>
        <th>{maxScore?.event ?? 0}点満点</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{basicScore + eventScore}</td>
        <td>{basicScore}</td>
        <td>{eventScore}</td>
      </tr>
    </tbody>
  </table>
);

export default TableResultBasicTask;
