export type DataType = {
  index: number;
  name: string;
  startDate: string;
  endDate: string;
  feature: string;
};

export const columns: any = [
  {
    title: 'ステージ名',
    dataIndex: 'name',
    editable: true,
  },
  {
    title: '開始日',
    dataIndex: 'startDate',
    editable: true,
  },
  {
    title: '終了日',
    dataIndex: 'endDate',
    editable: true,
  },
  {
    title: '特徴',
    dataIndex: 'feature',
    editable: true,
  },
];

export const data: DataType[] = [
  {
    index: 1,
    name: '定植期～主枝摘心期（10月）',
    startDate: '1/10',
    endDate: '8/11',
    feature:
      '定植期・活着期（10/1～10/10）、根張り期（10/15～25）、主枝摘心期（10/28～11/8）。11月以降の着果に備えて作物体を育てていく重要な時期。',
  },
  {
    index: 2,
    name: '収穫開始期・前期（11・12月）',
    startDate: '9/11',
    endDate: '31/12',
    feature:
      '11月はハウス密閉後、暖房稼働時間が少ない時期であり、ハウス内が多湿になる傾向がある。寒くなるにつれて、日中天窓・側窓の開放率が減少する。11月果実が肥大化し、12月以降雌花率・収穫量が増加していく。',
  },
  {
    index: 3,
    name: '収穫中期（1・2月）',
    startDate: '1/1',
    endDate: '28/2',
    feature:
      '厳寒期。暖房稼働によりハウス内が乾燥しやすい傾向がある。日射量は徐々に増加していく。雌花率・収穫量も増加していく。',
  },
  {
    index: 4,
    name: '収穫後期（3・4月）',
    startDate: '1/3',
    endDate: '30/4',
    feature:
      '３月はハウス密閉後、暖房稼働時間が少ない時期であり、夜間ハウス内が多湿になる傾向がある。また日中は天窓・側窓開放率増加していき乾燥傾向になる。日射量は増加していき収穫量も増加していく。',
  },
];
