import React, { ReactNode } from 'react';
import { Button } from 'antd';
import styles from './style.module.scss';

const MainButton: React.FC<{
  text: string | ReactNode;
  subButton?: boolean;
  onClick?: () => void;
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}> = ({ text, subButton, onClick, htmlType, disabled = false }) => (
  <Button
    type="primary"
    size="large"
    className={subButton ? styles.drakButton : styles.mainButton}
    onClick={onClick}
    htmlType={htmlType ?? 'button'}
    disabled={disabled}
  >
    {text}
  </Button>
);

export default MainButton;
