import React, { useState } from 'react';
import { Row, Card, Form, Input } from 'antd';
import { TResetPasswordBody } from 'types/auth';
import { customValidateMessages, defaultValidateMessages } from 'constants/validate/message';
import { checkPassword, checkRePassword } from 'constants/validate';
import { parseSearchParams } from 'libs/helpers/functions';
import { useHistory, useLocation } from 'react-router-dom';
import { EPath } from 'constants/routes';
import MainButton from '../../components/MainButton/MainButton';
import styles from './style.module.scss';

type TFormChangePassWord = {
  password: string;
  rePassword: string;
};

type TProps = {
  resetPassword: (data: TResetPasswordBody) => void;
};

const ResetPassword = ({ resetPassword }: TProps) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [passwordState, setPasswordState] = useState('');
  const { key } = parseSearchParams(location.search);

  const onChangeValues = (values: TFormChangePassWord) => {
    const { password } = values;
    if (password) {
      setPasswordState(password);
    }
  };
  const handleChangePassword = (values: TFormChangePassWord) => {
    const { password } = values;
    const data: TResetPasswordBody = {
      token: key,
      password,
    };
    resetPassword(data);
    form.resetFields();
  };

  if (!key) {
    history.push(EPath.forgotPassword);
  }

  return (
    <Row justify="center" align="middle" className={styles.rowWrap}>
      <Card type="inner" title="パスワードの再設定" className={styles.cardWrap}>
        <p className={styles.subTitle}>新しいパスワードを入力して、パスワードを再設定して下さい。</p>
        <div className={styles.formWarp}>
          <Form
            layout="vertical"
            name="reset_password_form"
            className="update_user_form"
            onFinish={handleChangePassword}
            form={form}
            validateMessages={defaultValidateMessages}
            onValuesChange={onChangeValues}
          >
            <Form.Item
              name="password"
              label="新しいパスワード"
              rules={[
                { required: true, whitespace: true },
                { pattern: checkPassword, message: customValidateMessages.validatePassword },
              ]}
            >
              <Input.Password type="password" placeholder="New password" />
            </Form.Item>
            <Form.Item
              name="rePassword"
              label="新しいパスワード（確認用）"
              rules={[
                { required: true, whitespace: true },
                { pattern: checkRePassword(passwordState), message: customValidateMessages.rePasswordNotMatch },
              ]}
            >
              <Input.Password type="password" placeholder="Renew password" />
            </Form.Item>
            <Form.Item>
              <MainButton text="変更" htmlType="submit" />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Row>
  );
};

export default ResetPassword;
