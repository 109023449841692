import { IndexedObject } from 'constants/types';

export const DATA_SELECT_USER = [
  {
    value: 'M1',
    label: '天窓　換気温度',
  },
  {
    value: 'M2',
    label: '天窓　換気開度',
  },
  {
    value: 'M3',
    label: '重油炊き暖房温度',
  },
  {
    value: 'M4',
    label: '湿度管理 目標湿度',
  },
  {
    value: 'M5',
    label: '保温カーテン　保温温度',
  },
  {
    value: 'M6',
    label: '保温カーテン　保温感度',
  },
  {
    value: 'M7',
    label: 'CO2濃度設定',
  },
  {
    value: 'M8',
    label: '循環扇　タイマー',
  },
  {
    value: 'M9',
    label: 'かん水 1株あたりの1日かん水量',
  },
];
export const DATA_SELECT_SIMULATION = [
  {
    value: 'C1',
    label: '温度',
  },
  {
    value: 'C2',
    label: '湿度',
  },
  {
    value: 'C3',
    label: 'CO2濃度',
  },
];

export const getChartConfig = (data: any, unanswered: string[]) => ({
  data,
  xField: 'Date',
  yField: 'scales',
  appendPadding: 0,
  seriesField: 'type',
  legend: false,
  color: (type: IndexedObject) => (type.type === 'studentSimulation' ? 'blue' : 'red'),
  xAxis: {
    tickCount: 28,
    label: {
      style: (month: string) => {
        if (unanswered.some((item) => item === month)) {
          return {
            fill: 'red',
            fontSize: 16,
            fontWeight: 500,
            textAlign: 'center',
          };
        }
        return {
          fontSize: 16,
          fontWeight: 500,
          textAlign: 'center',
        };
      },
    },
  },

  slider: {
    start: 0,
    end: 0.5,
  },
  point: {
    shape: 'breath-point-u8-3',
  },
});
