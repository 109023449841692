import React, { useEffect, useState } from 'react';
import { Button, Card, Space, Table } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import './style.css';
import { TOptionsQuery, TOptionsQueryExtra } from 'types/common';
import { TTrainingDetail } from 'types/assignments';
import { TAssignmentsState } from 'modules/assignments/assignments.reducer';
import { TUiState } from 'modules/ui/ui.reducer';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ConfirmModal from 'components/CommonModal/ConfirmModal';
import NotiModal from 'components/CommonModal/NotiModal';
import { createQueryUrl, parseSearchParams, replacePathParams } from 'libs/helpers/functions';
import { TGroupsClassDetail } from 'types/groupsClass';
import { EPath } from 'constants/routes';
import styles from './style.module.scss';

type Props = {
  getTrainings: (params: TOptionsQueryExtra<TTrainingDetail>) => void;
  assignmentsState: TAssignmentsState;
  uiState: TUiState;
  deleteTraining: (data: { id: number; groupId: number }) => void;
  resetStateTemplate: () => void;
  currentGroup: TGroupsClassDetail;
};
type TModal = 'delete' | 'noti' | '';
const DEFAULT_TIMEOUT = 1;
const AssignmentsAdm = ({
  getTrainings,
  assignmentsState,
  uiState,
  deleteTraining,
  resetStateTemplate,
  currentGroup,
}: Props) => {
  const history = useHistory();
  const { trainings, pagination } = assignmentsState;
  const { isShowLoading } = uiState;
  const location = useLocation();
  const urlQueryParams: TOptionsQuery<TTrainingDetail> = parseSearchParams(location.search);
  const [openModal, setOpenModal] = useState<TModal>('');
  const [trainingId, setTrainingId] = useState<number>(0);
  useEffect(() => {
    if (getTrainings && currentGroup.id) {
      getTrainings({ ...urlQueryParams, groupId: currentGroup.id });
    }
  }, [location, currentGroup.id]);

  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: '研修名',
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: '受講者数',
      width: '10%',
      render: (_: any, record: TTrainingDetail) => record.studentIds?.length,
    },
    {
      title: '最終更新日',
      width: '30%',
      render: (_: any, record: TTrainingDetail) => `${record.startDate} - ${record.endDate}`,
    },
    {
      title: '',
      width: '20%',
      render: (_: any, record: TTrainingDetail) => (
        <Space align="center" className={styles.groupButton}>
          <Link to={replacePathParams(EPath.A6_1, { id: record.id?.toString() ?? '' })}>
            <MainButton subButton text="詳細・編集" />
          </Link>
          <Link to={replacePathParams(EPath.A7, { id: record.id?.toString() ?? '' })}>
            <MainButton text="結果" />
          </Link>
          <MainButton
            text="削除"
            subButton
            onClick={() => {
              setOpenModal('delete');
              setTrainingId(record.id ?? 0);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="groupsAdm">
      <ConfirmModal
        title="確認"
        message={
          <span>
            研修を削除してもよろしいでしょうか？
            <br />
            削除すると研修結果も削除されます。
          </span>
        }
        open={openModal === 'delete'}
        onClose={() => setOpenModal('')}
        onConfirm={() => {
          deleteTraining({ id: trainingId, groupId: currentGroup.id ?? 0 });
          setTimeout(() => {
            setOpenModal('noti');
          }, DEFAULT_TIMEOUT);
        }}
      />
      <NotiModal
        title="確認"
        message="研修を削除しました。"
        open={openModal === 'noti'}
        textButton="OK"
        openModal={() => setOpenModal('')}
      />
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>研修一覧</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.topButtonGroup}>
                <Button>
                  <Link to={EPath.A1}>TOPに戻る</Link>
                </Button>
                <Button onClick={() => resetStateTemplate()}>
                  <Link to={EPath.A6_2}>研修を登録する</Link>
                </Button>
              </Space>
              <Table
                bordered
                columns={columns}
                dataSource={trainings}
                pagination={false}
                loading={isShowLoading}
                rowKey="id"
              />
            </div>
            <div className={styles.bottom}>
              <PaginationCustom
                current={pagination.page}
                total={pagination.totalResults}
                pageSize={pagination.limit}
                disabled={isShowLoading}
                onChange={onChangePage}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AssignmentsAdm;
