import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { answersAction } from 'modules/answers/answers.action';
import { AppState } from 'store';
import AssignmentsAnswerOverview from './AssignmentsAnswerOverview';

const AssignmentsAnswerOverviewContainer = () => {
  const dispatch = useDispatch();
  const getResultAssignment = (data: { id: string; sid: string }) => {
    dispatch(answersAction.getResultAssignmentOneStudent(data));
  };
  const getTaskDetail = (id: string, groupId: number, isSaved: boolean) => {
    dispatch(answersAction.getDetailTaskForStudent({ id, isSaved, groupId }));
  };
  const answersState = useSelector((state: AppState) => state.pages.answers);
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  return (
    <AssignmentsAnswerOverview
      currentUser={currentUser}
      answersState={answersState}
      getResultAssignment={getResultAssignment}
      getTaskDetail={getTaskDetail}
      currentGroup={currentGroup}
    />
  );
};

export default AssignmentsAnswerOverviewContainer;
