/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, InputRef } from 'antd';
import { logger } from 'libs/logger';
import { IndexedObject } from 'constants/types';
import { EditableContext } from './EditableRow';
import styles from './style.module.scss';

const EditableCell: React.FC<{
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: IndexedObject;
  title: string;
  handleSave: (record: IndexedObject) => void;
}> = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const inputNumberRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      inputNumberRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (form) form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = form && (await form.validateFields());

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      logger.log('message', errInfo);
    }
  };

  let childNode = children;
  let inputNode = <></>;

  switch (dataIndex) {
    case 'sunnyDay':
    case 'rainyDay':
    case 'cloudyDay':
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <InputNumber
            min={0}
            max={30}
            defaultValue={10}
            step={1}
            ref={inputNumberRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      );
      break;
    case 'factor':
      switch (record.correctionValue) {
        case '日中前夜半時間帯温度':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={0}
                max={5}
                defaultValue={3}
                step={1}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case '換気感度効果':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={0}
                max={5}
                defaultValue={0.2}
                step={0.1}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case '空気循環効果':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={0}
                max={5}
                defaultValue={1}
                step={1}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case '湿度上限　早朝時間帯':
        case '湿度上限　日中時間帯':
        case '湿度上限 前夜半時間帯':
        case '湿度上限　夜間時間帯':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={90}
                max={100}
                defaultValue={100}
                step={5}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case '湿度下限 前夜半時間帯':
        case '湿度下限　早朝時間帯':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={0}
                max={90}
                defaultValue={60}
                step={5}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case '湿度下限　日中時間帯':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={0}
                max={90}
                defaultValue={40}
                step={5}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case '湿度下限　夜間時間帯':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={0}
                max={90}
                defaultValue={80}
                step={5}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case 'CO2濃度上限 夜間時間帯':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={400}
                max={2500}
                defaultValue={2500}
                step={50}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case 'CO2濃度上限　早朝時間帯':
        case 'CO2濃度上限　日中時間帯':
        case 'CO2濃度上限　転流時間帯':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={1000}
                max={2500}
                defaultValue={1500}
                step={50}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case 'CO2濃度下限 早朝時間帯':
        case 'CO2濃度下限 日中時間帯':
        case 'CO2濃度下限 前夜半時間帯':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={0}
                max={400}
                defaultValue={250}
                step={50}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        case 'CO2濃度下限 夜間時間帯':
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={0}
                max={1000}
                defaultValue={800}
                step={50}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
        default:
          inputNode = (
            <Form.Item className={styles.formItem} name={dataIndex}>
              <InputNumber
                min={1}
                max={3}
                defaultValue={1}
                step={1}
                ref={inputNumberRef}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );
          break;
      }
      break;
    case 'question':
    case 'stageName':
    case 'comment':
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 10 }} ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      );
      break;

    default:
      inputNode = (
        <Form.Item className={styles.formItem} name={dataIndex}>
          <InputNumber
            min={-20}
            max={50}
            defaultValue={25.3}
            step={0.1}
            ref={inputNumberRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      );
      break;
  }

  if (editable) {
    childNode = editing ? (
      inputNode
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit} onKeyDown={toggleEdit} role="button" tabIndex={0}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
