import React, { useEffect, useState } from 'react';
import { Button, Card, Space, Table } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { EPath } from 'constants/routes';
import MainButton from 'components/MainButton/MainButton';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import ConfirmModal from 'components/CommonModal/ConfirmModal';
import { TOptionsQuery } from 'types/common';
import { createQueryUrl, parseSearchParams } from 'libs/helpers/functions';
import { TGroupsClassState } from 'modules/groupsClass/groupsClass.reducer';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';

type TModal = '' | 'delete';
type Props = {
  groupsClassState: TGroupsClassState;
  deleteGroupsClass: (id: number) => void;
  goGroupsDetailPage: (id: number) => void;
  getGroupsClass: (params: TOptionsQuery<TGroupsClassDetail>) => void;
};
const GroupsManage = ({ groupsClassState, deleteGroupsClass, goGroupsDetailPage, getGroupsClass }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [groupId, setGroupId] = useState<number>(0);
  const urlQueryParams: TOptionsQuery<any> = parseSearchParams(location.search);
  const [openModal, setOpenModal] = useState<TModal>('');
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'グループ名',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: '講師数',
      dataIndex: 'teacherNumber',
      width: '10%',
    },
    {
      title: '生徒数',
      dataIndex: 'studentNumber',
      width: '10%',
    },
    {
      title: '登録日',
      dataIndex: 'createdAt',
      width: '15%',
    },
    {
      title: '',
      width: '15%',
      render: (_: any, groups: TGroupsClassDetail) => (
        <Space align="center" className={styles.groupButton}>
          <MainButton text="詳細・編集" onClick={() => goGroupsDetailPage(groups.id ?? 0)} />
          <MainButton
            subButton
            text="削除"
            onClick={() => {
              setOpenModal('delete');
              setGroupId(groups.id ?? 0);
            }}
          />
        </Space>
      ),
    },
  ];
  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  useEffect(() => {
    getGroupsClass({ ...urlQueryParams, sortBy: 'id:desc' });
  }, [location]);
  return (
    <>
      <ConfirmModal
        title="確認"
        message="グループを削除してもよろしいでしょうか？"
        open={openModal === 'delete'}
        onClose={() => setOpenModal('')}
        onConfirm={() => {
          deleteGroupsClass(groupId);
          setOpenModal('');
        }}
      />
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.menuTitle}>
              <p>グループ管理</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.topButtonGroup}>
                <Button>
                  <Link to={EPath.A1}>TOPに戻る</Link>
                </Button>
                <Button>
                  <Link to={EPath.S3_2}>グループを追加する</Link>
                </Button>
              </Space>
              <div className={styles.tableWrapper}>
                <Table
                  bordered
                  columns={columns}
                  dataSource={groupsClassState.groups}
                  pagination={false}
                  loading={groupsClassState.loading}
                  rowKey="id"
                />
              </div>
            </div>
            <div className={styles.bottom}>
              <PaginationCustom
                current={groupsClassState.pagination.page}
                total={groupsClassState.pagination.totalResults}
                pageSize={groupsClassState.pagination.limit}
                disabled={groupsClassState.loading}
                onChange={onChangePage}
                showQuickJumper
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default GroupsManage;
