import { TMonthStep4, TStep4 } from 'types/template';

export const questions = [
  '定植期～主枝摘心期の栽培基本問題解説と気象情報を参考にし10月の適切な制御設定を行ってください。',
  '収穫開始期・前期の栽培基本問題解説と気象情報を参考にし11月の適切な制御設定を行ってください。',
  '収穫開始期・前期の栽培基本問題解説と気象情報を参考にし12月の適切な制御設定を行ってください。',
  '収穫中期の栽培基本問題解説と気象情報を参考にし1月の適切な制御設定を行ってください。',
  '収穫中期の栽培基本問題解説と気象情報を参考にし2月の適切な制御設定を行ってください。',
  '収穫後期の栽培問題解説と気象情報を参考にし3月の適切な制御設定を行ってください。',
  '収穫後期の栽培問題解説と気象情報を参考にし4月の適切な制御設定を行ってください。',
];

export const data: TStep4[] = [
  {
    month: 10 as TMonthStep4,
    sunnyDay: 18,
    rainyDay: 6,
    cloudyDay: 7,
    avgTemp: 25.8,
    maxTemp: 20.8,
    minTemp: 16.5,
    question: questions[0],
  },
  {
    month: 11 as TMonthStep4,
    sunnyDay: 20,
    rainyDay: 4,
    cloudyDay: 6,
    avgTemp: 19.9,
    maxTemp: 13.8,
    minTemp: 8.9,
    question: questions[1],
  },
  {
    month: 12 as TMonthStep4,
    sunnyDay: 24,
    rainyDay: 1,
    cloudyDay: 6,
    avgTemp: 14.6,
    maxTemp: 8.8,
    minTemp: 3.8,
    question: questions[2],
  },
  {
    month: 1 as TMonthStep4,
    sunnyDay: 21,
    rainyDay: 3,
    cloudyDay: 7,
    avgTemp: 12.3,
    maxTemp: 6.5,
    minTemp: 1.8,
    question: questions[3],
  },
  {
    month: 2 as TMonthStep4,
    sunnyDay: 17,
    rainyDay: 3,
    cloudyDay: 8,
    avgTemp: 11.8,
    maxTemp: 6.4,
    minTemp: 1.6,
    question: questions[4],
  },
  {
    month: 3 as TMonthStep4,
    sunnyDay: 14,
    rainyDay: 7,
    cloudyDay: 10,
    avgTemp: 18,
    maxTemp: 12.8,
    minTemp: 8.2,
    question: questions[5],
  },
  {
    month: 4 as TMonthStep4,
    sunnyDay: 16,
    rainyDay: 6,
    cloudyDay: 8,
    avgTemp: 22.1,
    maxTemp: 17.1,
    minTemp: 12.9,
    question: questions[6],
  },
];

export type TStep4State = {
  step4: TStep4[];
};
