import React from 'react';
import { Divider } from 'antd';
import TableResultDetail from 'components/TableResultDetail/TableResultDetail';
import TableResultBasicTask from 'components/TableResultBasicTask/TableResultBasicTask';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import styles from './style.module.scss';
import './style.css';

const AssignmentsResultCultivate = () => {
  const { resultAssignment } = useSelector((state: AppState) => state.pages.answers);
  const basicScore = resultAssignment.basicResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const eventScore = resultAssignment.eventResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const basicTaskResults = resultAssignment.basicResults?.map((e, i) => ({
    ...e,
    key: i,
  }));
  const eventTaskResults = resultAssignment.eventResults?.map((e, i) => ({
    ...e,
    key: i,
  }));
  return (
    <div className="groupsAdm">
      <p className={styles.bodySection}>採点結果</p>
      <Divider className={styles.divider} />
      <TableResultBasicTask maxScore={resultAssignment.maxScore} basicScore={basicScore} eventScore={eventScore} />
      <TableResultDetail dataTable={basicTaskResults ?? []} title1="解答解説" title2="基本問題" />
      <TableResultDetail dataTable={eventTaskResults ?? []} title1="解答解説" title2="イベント問題" />
    </div>
  );
};

export default AssignmentsResultCultivate;
