import React from 'react';
import { Col } from 'antd';
import { useHistory } from 'react-router-dom';
import styles from './style.module.scss';

const Item: React.FC<{
  title: string;
  image: string;
  subTitle: string;
  url?: string;
}> = ({ title, image, subTitle, url }) => {
  const history = useHistory();

  const handelClick = () => {
    if (url) {
      history.push(url);
    }
  };
  return (
    <Col span={8} className={styles.item} onClick={handelClick}>
      <div className={styles.item__top}>
        <p>{title}</p>
        <img src={`/images/${image}.svg`} alt={image} />
      </div>
      <div className={styles.borderItem}>
        <p>{subTitle}</p>
      </div>
    </Col>
  );
};

export default Item;
