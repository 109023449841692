import templateAction from 'modules/template/template.action';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { templateModule, TTemplateState } from 'modules/template/template.reducer';
import AdmTempBasic from './AdmTempBasic';
import { TStep2State } from './AdmTempBasic.state';

const AdmTempBasicContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const updateTemplate = useCallback(
    (
      data: TStep2State,
      id: number,
      groupId: number,
      buttonFooterNext: boolean = false,
      buttonFooterPre: boolean = false,
    ) => {
      dispatch(templateAction.updateTemplateStep2({ data, id, groupId, buttonFooterNext, buttonFooterPre }));
    },
    [],
  );

  const saveTemplate = (data: TTemplateState) => {
    dispatch(templateModule.actions.saveTemplate(data));
  };

  const uploadCsvQuestionFile = (data: { file: FormData; id: number; groupId: number; step: number }) => {
    dispatch(templateAction.uploadCsvQuestionFile(data));
  };

  const downloadCsvQuestionFile = (data: { id: number; groupId: number; step: number }) => {
    dispatch(templateAction.downloadCsvQuestionFile(data));
  };
  return (
    <AdmTempBasic
      updateTemplate={updateTemplate}
      template={template}
      currentGroup={currentGroup}
      saveTemplate={saveTemplate}
      uploadCsvQuestionFile={uploadCsvQuestionFile}
      downloadCsvQuestionFile={downloadCsvQuestionFile}
    />
  );
};

export default AdmTempBasicContainer;
