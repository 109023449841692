import actionCreatorFactory from 'typescript-fsa';
import { TOptionsQueryExtra } from 'types/common';
import { TTemplateDetail } from 'types/templates';

const ac = actionCreatorFactory();

export default {
  getTemplates: ac<TOptionsQueryExtra<TTemplateDetail>>('getTemplates'),
  getAllTemplates: ac<TTemplateDetail & { groupId: number }>('getAllTemplates'),
  deleteTemplate: ac<{ templateId: string; groupId: number }>('deleteTemplate'),
};
