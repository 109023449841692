import React from 'react';
import { Table } from 'antd';
import { ColumnTypes, TStep2 } from 'types/template';
import { IndexedObject } from 'constants/types';

type TProps = {
  columns: IndexedObject;
  data: TStep2[];
  components: object;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
};

const DragTable: React.FC<TProps> = ({ columns, data, components, moveRow }) => (
  <>
    <Table
      columns={columns as ColumnTypes}
      dataSource={data}
      components={components}
      pagination={false}
      onRow={(_, index) => {
        const attr = {
          index,
          moveRow,
        };
        return attr as React.HTMLAttributes<any>;
      }}
      rowClassName={() => 'editable-row'}
      rowKey="id"
    />
  </>
);

export default DragTable;
