import React from 'react';
import { G2 } from '@ant-design/plots';
import { IndexedObject } from 'constants/types';
import _ from 'lodash';
import { TChartData } from 'types/results';
import { DATA_TYPE_CALCULATED_ANSWER, DATA_TYPE_CORRECT_ANSWER, DATA_TYPE_STUDENT_ANSWER } from 'constants/common';

export const configLineChart = (dataChart: {
  dataLine: Array<TChartData>;
  minData?: Array<TChartData>;
  maxData?: Array<TChartData>;
  check?: boolean;
}) => {
  const { dataLine, minData, maxData, check = false } = dataChart;
  let dataLineMain = dataLine;
  let allowableLine: any = [];
  G2.registerShape('point', 'breath-point-U9', {
    draw(cfg, container) {
      const data = cfg.data as IndexedObject;
      const point = {
        x: cfg.x as number,
        y: cfg.y as number,
      };
      const group = container.addGroup();

      if (check && data?.scales === 0 && data?.Date === DATA_TYPE_STUDENT_ANSWER) {
        const decorator1 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        const decorator2 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        const decorator3 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        decorator1.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
          },
        );
        decorator2.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
            delay: 600,
          },
        );
        decorator3.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
            delay: 1200,
          },
        );
        group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 6,
            fill: cfg.color,
            opacity: 0.7,
          },
        });
        group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 1.5,
            fill: cfg.color,
          },
        });
      }
      if (check && data?.scales === 0 && data?.type === DATA_TYPE_CORRECT_ANSWER) {
        const decorator1 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        const decorator2 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        const decorator3 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        decorator1.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
          },
        );
        decorator2.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
            delay: 600,
          },
        );
        decorator3.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
            delay: 1200,
          },
        );
        group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 6,
            fill: cfg.color,
            opacity: 0.7,
          },
        });
        group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 1.5,
            fill: cfg.color,
          },
        });
      }
      if (data?.type === DATA_TYPE_STUDENT_ANSWER) {
        if (
          data.scales < Number(minData?.find((item: any) => item.Date === data.Date)?.scales) ||
          data.scales > Number(maxData?.find((item: any) => item.Date === data.Date)?.scales)
        ) {
          const decorator1 = group.addShape('circle', {
            attrs: {
              x: point.x,
              y: point.y,
              r: 5,
              fill: '#FF8E8E',
              opacity: 0.5,
            },
          });
          const decorator2 = group.addShape('circle', {
            attrs: {
              x: point.x,
              y: point.y,
              r: 5,
              fill: '#FF8E8E',
              opacity: 0.5,
            },
          });
          const decorator3 = group.addShape('circle', {
            attrs: {
              x: point.x,
              y: point.y,
              r: 5,
              fill: '#FF8E8E',
              opacity: 0.5,
            },
          });
          decorator1.animate(
            {
              r: 15,
              opacity: 0,
            },
            {
              duration: 1800,
              easing: 'easeLinear',
              repeat: true,
            },
          );
          decorator2.animate(
            {
              r: 15,
              opacity: 0,
            },
            {
              duration: 1800,
              easing: 'easeLinear',
              repeat: true,
              delay: 600,
            },
          );
          decorator3.animate(
            {
              r: 15,
              opacity: 0,
            },
            {
              duration: 1800,
              easing: 'easeLinear',
              repeat: true,
              delay: 1200,
            },
          );
          group.addShape('circle', {
            attrs: {
              x: point.x,
              y: point.y,
              r: 5,
              fill: '#FF8E8E',
              opacity: 0.7,
            },
          });
          group.addShape('circle', {
            attrs: {
              x: point.x,
              y: point.y,
              r: 1.5,
              fill: '#FF8E8E',
            },
          });
        }
      }
      return group;
    },
  });
  if (minData && maxData && minData.length > 0 && maxData.length > 0) {
    let nums: number[] = [];
    if (maxData[0].scales - minData[0].scales > 100) {
      nums = _.range(0, maxData[0].scales - minData[0].scales, 10);
    } else if (maxData[0].scales - minData[0].scales <= 1) {
      nums = _.range(0, maxData[0].scales - minData[0].scales, 0.02);
    } else {
      nums = _.range(0, maxData[0].scales - minData[0].scales, 0.1);
    }
    nums.forEach((num) => {
      const newData: any = minData.map((item: any) => ({
        type: `${num}`,
        Date: item.Date,
        scales: item.scales + num,
      }));
      allowableLine = [...allowableLine, ...newData];
    });

    dataLineMain = [...dataLine, ...allowableLine, ...minData, ...maxData];
  }

  return {
    data: dataLineMain,
    xField: 'Date',
    yField: 'scales',
    appendPadding: 0,
    seriesField: 'type',
    legend: false,
    color: (type: IndexedObject) => {
      switch (type.type) {
        case DATA_TYPE_CALCULATED_ANSWER:
        case DATA_TYPE_STUDENT_ANSWER:
          return 'blue';
        case DATA_TYPE_CORRECT_ANSWER:
          return 'red';
        default:
          return 'yellow';
      }
    },
    xAxis: {
      tickCount: 28,
      label: {
        style: {
          fontSize: 16,
          fontWeight: 500,
          textAlign: 'center',
        },
      },
    },
    lineStyle: ({ type }: IndexedObject) => {
      switch (type) {
        case DATA_TYPE_STUDENT_ANSWER:
        case DATA_TYPE_CORRECT_ANSWER:
        case DATA_TYPE_CALCULATED_ANSWER:
          return {
            strokeOpacity: 1,
          };
        default:
          return {
            strokeOpacity: 0.2,
          };
      }
    },
    slider: {
      start: 0,
      end: 0.5,
    },
    point: {
      shape: 'breath-point-U9',
    },
    tooltip: {
      showMarkers: false,
      customContent: (title: any, items: any) => {
        const mappingKey = items.map((e: any, i: any) => ({
          ...e,
          key: i,
        }));
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {mappingKey?.map((item: any, index: any) => {
                const { name, value, color, key } = item;
                if (
                  name === DATA_TYPE_STUDENT_ANSWER ||
                  name === DATA_TYPE_CORRECT_ANSWER ||
                  name === DATA_TYPE_CALCULATED_ANSWER
                )
                  return (
                    <li
                      key={key}
                      className="g2-tooltip-list-item"
                      data-index={index}
                      style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                    >
                      <span className="g2-tooltip-marker" style={{ backgroundColor: color }}>
                        {' '}
                      </span>
                      <span style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}>
                        <span style={{ marginRight: 16 }}>{name}:</span>
                        <span className="g2-tooltip-list-item-value">{value}</span>
                      </span>
                    </li>
                  );
                return null;
              })}
            </ul>
          </>
        );
      },
    },
  };
};
