import { answersAction } from 'modules/answers/answers.action';
import { answersModule } from 'modules/answers/answers.reducer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TAnswerAQuestion, TAnswerSubmit } from 'types/assignments';
import AssignmentsEventQuestion from './AssignmentsEventQuestion';
import { TQuesParams } from './AssignmentsEventQuestion.state';

const AssignmentsEventQuestionContainer = () => {
  const dispatch = useDispatch();
  const getQuestionList = (dataId: TQuesParams) => {
    dispatch(answersAction.getQuestion(dataId));
  };
  const getTaskDetail = (id: string, groupId: number, isSaved: boolean) => {
    dispatch(answersAction.getDetailTaskForStudent({ id, isSaved, groupId }));
  };
  const updateEventAnswer = (data: Array<TAnswerAQuestion>) => {
    dispatch(answersModule.actions.saveEventAnswer(data));
  };
  const submitAnswerBasicTask = (data: TAnswerSubmit) => {
    dispatch(answersAction.submitAnswerBasicTask(data));
  };
  const answersState = useSelector((state: AppState) => state.pages.answers);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const getResults = (id: string, sid: string) => {
    dispatch(answersAction.getResultAssignmentOneStudent({ id, sid }));
  };
  return (
    <AssignmentsEventQuestion
      updateEventAnswer={updateEventAnswer}
      getQuestionList={getQuestionList}
      submitAnswerBasicTask={submitAnswerBasicTask}
      answersState={answersState}
      currentGroup={currentGroup}
      getTaskDetail={getTaskDetail}
      getResults={getResults}
    />
  );
};

export default AssignmentsEventQuestionContainer;
