import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import controlResultsAction from 'modules/controlResults/controlResults.action';
import { controlResultsModule } from 'modules/controlResults/controlResults.reducer';
import { AppState } from 'store';
import { TOptionsQuery } from 'types/common';
import AdmControlResult from './AdmControlResult';

const AdmControlResultContainer = () => {
  const dispatch = useDispatch();
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);
  const controlResultsState = useSelector((state: AppState) => state.pages.controlResults);
  const getControlResults = (params: TOptionsQuery) => {
    dispatch(controlResultsAction.getControlResults({ ...params, sortBy: 'id:desc' }));
  };

  const deleteControlResults = (data: { ctrRsId: number; groupId: number }) => {
    dispatch(controlResultsAction.deleteControlResults(data));
  };

  const clearErr = () => {
    dispatch(controlResultsModule.actions.setError(false));
  };

  const downloadControlResults = (data: { ctrRsId: number; groupId: number }) => {
    dispatch(controlResultsAction.downloadControlResultsFile(data));
  };
  return (
    <AdmControlResult
      getControlResults={getControlResults}
      currentGroup={currentGroup}
      controlResultsState={controlResultsState}
      deleteControlResults={deleteControlResults}
      clearErr={clearErr}
      downloadControlResults={downloadControlResults}
    />
  );
};

export default AdmControlResultContainer;
