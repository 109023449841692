import templateAction from 'modules/template/template.action';
import { templateModule, TTemplateState } from 'modules/template/template.reducer';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import AdmTempCultivateSimulation from './AdmTempCultivateSimulation';
import { TStep6State } from './AdmTempCultivateSimulation.state';

const AdmTempCultivateSimulationContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const updateTemplate = useCallback(
    (
      data: TStep6State,
      id: number,
      groupId: number,
      buttonFooterNext: boolean = false,
      buttonFooterPre: boolean = false,
    ) => {
      dispatch(templateAction.updateTemplateStep6({ data, id, groupId, buttonFooterNext, buttonFooterPre }));
    },
    [],
  );
  const saveTemplate = (data: TTemplateState) => {
    dispatch(templateModule.actions.saveTemplate(data));
  };

  const clearTemplateState = () => {
    dispatch(templateModule.actions.clearState());
  };

  return (
    <AdmTempCultivateSimulation
      template={template}
      saveTemplate={saveTemplate}
      updateTemplate={updateTemplate}
      currentGroup={currentGroup}
      clearTemplateState={clearTemplateState}
    />
  );
};

export default AdmTempCultivateSimulationContainer;
