import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { replacePathParams } from 'libs/helpers/functions';
import { assignmentsServices } from 'services/assignments';
import { TAnswerAQuestion, TDetailTask } from 'types/assignments';
import { AxiosResponse } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { push } from 'react-router-redux';
import { EPath } from 'constants/routes';
import { getAnswerFromLocalStorage } from 'libs/helpers/localStorageFn';
import { answersModule } from './answers.reducer';
import { answersAction } from './answers.action';

export const getDetailTask: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(answersAction.getDetailTaskForStudent),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.getDetailTaskForStudentDoes(payload.id, payload.groupId)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TDetailTask) => {
          const listAnswerBasic = t.basicQuestions?.map((q) => ({
            stageName: q.stageName,
            questionId: q.id,
            selectedOption: null,
          }));
          const listAnswerEvent = t.eventQuestions?.map((q) => ({
            stageName: q.stageName,
            questionId: q.id,
            selectedOption: null,
          }));
          if (payload.isSaved) {
            return [
              answersModule.actions.saveTaskDetail(t),
              answersModule.actions.saveBasicAnswer(
                t.enabledDemonstrationMode && (getAnswerFromLocalStorage('basicAnswer') as TAnswerAQuestion[]).length
                  ? (getAnswerFromLocalStorage('basicAnswer') as TAnswerAQuestion[]) ?? listAnswerBasic
                  : listAnswerBasic ?? [],
              ),
              answersModule.actions.saveEventAnswer(
                t.enabledDemonstrationMode && (getAnswerFromLocalStorage('eventAnswer') as TAnswerAQuestion[]).length
                  ? (getAnswerFromLocalStorage('eventAnswer') as TAnswerAQuestion[]) ?? listAnswerEvent
                  : listAnswerEvent ?? [],
              ),
            ];
          }
          return [answersModule.actions.saveTaskDetail(t)];
        },
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getQuestion: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(answersAction.getQuestion),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.getDetailTaskForStudentDoes(payload.id ?? '0', payload.groupId)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TDetailTask) => {
          if (payload.type === 'basic') {
            const quesBasicData = t.basicQuestions?.find((q) => q.id === payload.quesId);

            return [
              answersModule.actions.saveCurrentQuestion(quesBasicData ?? {}),
              answersModule.actions.setEnabledDemonstrationMode(t.enabledDemonstrationMode ?? false),
            ];
          }
          const quesEventData = t.eventQuestions?.find((q) => q.id === payload.quesId);
          return [
            answersModule.actions.saveCurrentQuestion(quesEventData ?? {}),
            answersModule.actions.setEnabledDemonstrationMode(t.enabledDemonstrationMode ?? false),
          ];
        },
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const submitStudentAnswerBasicTask: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(answersAction.submitAnswerBasicTask),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.submitAnswerBasicTask(payload)),
        complete: uiModule.actions.hideLoading(),
        next: () => [
          push(replacePathParams(EPath.U7_RESULTS, { id: payload.id })),
          answersModule.actions.clearDataBasicAnswer(),
        ],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getResultAssignment: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(answersAction.getResultAssignmentOneStudent),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.getResultsAssignmentOneStudent(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (t) => [answersModule.actions.saveResultAssignment(t)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const saveControlAnswers: Epic<AnyAction, any> = (action$) =>
  action$.pipe(
    ofAction(answersAction.saveControlAnswers),
    map(({ payload }) => answersModule.actions.saveControlAnswers(payload)),
  );

export const updateCommentAssignmentEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(answersAction.updateCommentAssignment),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.updateCommentAssignment(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (t: string) => [answersModule.actions.saveCommentAssignment(t)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const submitStudentAnswerControlTask: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(answersAction.submitAnswerControlTask),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.submitAnswerControlTask(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (e) => {
          if (!payload.simulation) {
            return [
              answersModule.actions.saveDataSimulations(e),
              push(replacePathParams(EPath.U9, { id: payload.id })),
            ];
          }
          return [answersModule.actions.saveDataSimulations(e)];
        },
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
