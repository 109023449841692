import React, { Fragment } from 'react';
import { Row } from 'antd';
import classnames from 'classnames';
import { TStages } from 'types/template';
import styles from './style.module.scss';

export type TDataStagesNav = {
  index?: number;
  label?: string;
  name?: string;
  value?: string;
  link?: number;
  isActive?: boolean;
};
const NavStage: React.FC<{
  stageName?: string;
  data: TDataStagesNav[];
  timeRange: TStages[];
  linkDisabled?: boolean;
}> = ({ data, linkDisabled = false, stageName, timeRange }) => {
  const timeStage = [...timeRange.map((s) => s.startDate), timeRange[timeRange.length - 1]?.endDate];
  return (
    <Row>
      <ul className={styles.stepWrap}>
        {data &&
          data.map((step: TDataStagesNav) => (
            <Fragment key={step.index}>
              {linkDisabled ? (
                <div className={step?.isActive ? classnames(styles.linkActive, styles.step) : styles.step}>
                  <p>{step.label}</p>
                </div>
              ) : (
                <div className={stageName === step.name ? classnames(styles.linkActive, styles.step) : styles.step}>
                  <p>{step.name}</p>
                </div>
              )}
            </Fragment>
          ))}
        <div className={styles.timeWrap}>
          {timeStage.map((d) => (
            <span className={styles.time} key={d}>
              {d}
            </span>
          ))}
        </div>
      </ul>
    </Row>
  );
};

export default NavStage;
