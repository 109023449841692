import { TChangePasswordBody, TForgotPasswordBody, TResetPasswordBody, TUser } from 'types/auth';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  login: ac<TUser>('login'),
  logout: ac('logout'),
  changePassword: ac<TChangePasswordBody>('changePassword'),
  forgotPassword: ac<TForgotPasswordBody>('forgotPassword'),
  resetPassword: ac<TResetPasswordBody>('resetPassword'),
  getAuth: ac<string>('getAuth'),
};
