import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, message, Row } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { navStep } from 'constants/nav';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragTable from 'components/DragTable/DragTable';
import { DndProvider } from 'react-dnd';
import { TTemplateState } from 'modules/template/template.reducer';
import update from 'immutability-helper';
import { ColumnTypes, TStages, TStep2 } from 'types/template';
import { IndexedObject } from 'constants/types';
import DraggableBodyRow from 'components/EditTableColRow/DragTableRow';
import EditTemplate from 'hook/EditTemplate';
import { omit } from 'lodash';
import { MESSAGE_ERROR_ADD_QUESTION_EVENT } from 'constants/notifications/message';
import { MinusCircleFilled, PlusCircleFilled, SwapOutlined } from '@ant-design/icons';
import { EPath } from 'constants/routes';
import { TGroupsClassDetail } from 'types/groupsClass';
import ConfirmModal from 'components/CommonModal/ConfirmModal';
import UploadCsvModal from 'components/CommonModal/UploadCsvModal';
import { Link } from 'react-router-dom';
import CustomDivider from 'components/CustomDivider/CustomDivider';
import useGetTemplateLs from 'hook/useGetTemplateLs';
import EditableCellStep3 from './EditTableCellStep3';
import { INITIAL_DATA, TStep3State } from './AdmTempEvent.state';
import styles from './style.module.scss';

type TProps = {
  template: TTemplateState;
  currentGroup: TGroupsClassDetail;
  updateTemplate: (
    data: TStep3State,
    id: number,
    groupId: number,
    buttonFooterNext?: boolean,
    buttonFooterPre?: boolean,
  ) => void;
  saveTemplate: (data: TTemplateState) => void;
  uploadCsvQuestionFile: (data: { file: FormData; id: number; groupId: number; step: number }) => void;
  downloadCsvQuestionFile: (data: { id: number; groupId: number; step: number }) => void;
};

const AdmTempEvent = ({
  template,
  updateTemplate,
  currentGroup,
  uploadCsvQuestionFile,
  saveTemplate,
  downloadCsvQuestionFile,
}: TProps) => {
  const [data, setData] = useState<TStep2[]>(INITIAL_DATA);
  const [openModal, setOpenModal] = useState<'confirm' | 'upload' | ''>('');
  const [dataRow, setDataRow] = useState<IndexedObject>({});
  const [stateSelect, setStateSelect] = useState<TStages[]>([]);
  const [dataTemp] = useGetTemplateLs();

  EditTemplate();

  useEffect(() => {
    if (template.id && template.step3 && template.step3.length > 0) {
      setData(template.step3);
    }
    if (template.id && template.step3 && template.step3.length === 0) {
      const updateInitData = INITIAL_DATA.map((d, index) => {
        if (index < 3) {
          return {
            ...d,
            stageName: template.step1?.stages[0].name ?? '',
          };
        }
        if (index < 5) {
          return {
            ...d,
            stageName: template.step1?.stages[1].name ?? '',
          };
        }
        return {
          ...d,
          stageName: template.step1?.stages[2].name ?? '',
        };
      });
      setData(updateInitData);
    }
    setStateSelect(template.step1?.stages ?? []);
  }, [template]);

  useEffect(() => {
    if (Object.keys(dataTemp).length > 0) {
      saveTemplate(dataTemp);
    }
  }, []);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: '',
      dataIndex: 'swap',
      width: '1%',
      render: () => (!template.isLinkedWithTraining ? <SwapOutlined rotate={90} /> : <></>),
    },
    {
      title: '',
      dataIndex: 'ordinal',
      align: 'center',
      width: '1%',
      render: (_: any, record: TStep2) => <>{data.findIndex((item) => item.id === record.id) + 1}</>,
    },
    {
      title: '栽培ステージ',
      dataIndex: 'stageName',
      width: '10%',
      editable: true,
    },
    {
      title: '問題',
      dataIndex: 'question',
      width: '10%',
      editable: true,
    },
    {
      title: '選択肢1',
      dataIndex: 'option1',
      width: '10%',
      render: (_: any, record: TStep2) => record.options[0]?.value,
      editable: true,
    },
    {
      title: '選択肢2',
      dataIndex: 'option2',
      width: '10%',
      render: (_: any, record: TStep2) => record.options[1]?.value,
      editable: true,
    },
    {
      title: '選択肢3',
      dataIndex: 'option3',
      width: '10%',
      render: (_: any, record: TStep2) => record.options[2]?.value,
      editable: true,
    },
    {
      title: '選択肢4',
      dataIndex: 'option4',
      width: '10%',
      render: (_: any, record: TStep2) => record.options[3]?.value,
      editable: true,
    },
    {
      title: '正解',
      dataIndex: 'correctAnswer',
      width: '1%',
      editable: true,
      render: (_: any, record: TStep2) => record.options.find((item) => item.isCorrect)?.index,
    },
    {
      title: '配点',
      dataIndex: 'score',
      width: '1%',
      editable: true,
    },
    {
      title: '解説',
      dataIndex: 'comment',
      width: '35%',
      editable: true,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '1%',
      render: (_: any, record: IndexedObject) =>
        data.length > 1 && !template.isLinkedWithTraining ? (
          <div
            className={styles.deleteRowButton}
            onClick={() => {
              setDataRow(record);
              setOpenModal('confirm');
            }}
          >
            <MinusCircleFilled className={styles.iconRemove} />
          </div>
        ) : (
          <></>
        ),
    },
  ];

  const handleMoveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!template.isLinkedWithTraining) {
        const dragRow = data[dragIndex];
        setData(
          update(data, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          }),
        );
      }
    },
    [data],
  );
  const handleDelete = () => {
    const newData = data.filter((item) => item.id !== dataRow.id);
    setData(newData);
  };

  const handleAdd = () => {
    const newData: TStep2 = {
      id: `${data.length + 1}`,
      stageName: template.step1?.stages[0].name ?? '',
      question: 'Please enter a question!',
      options: [
        {
          index: 1,
          value: 'Answer',
          isCorrect: false,
        },
        {
          index: 2,
          value: 'Answer',
          isCorrect: false,
        },
        {
          index: 3,
          value: 'Answer',
          isCorrect: true,
        },
        {
          index: 4,
          value: 'Answer',
          isCorrect: false,
        },
      ],
      comment: 'comment',
      score: 5,
    };
    if (data.length < 12) {
      setData([...data, newData]);
    } else {
      message.error(MESSAGE_ERROR_ADD_QUESTION_EVENT);
    }
  };

  const handleSave = (row: TStep2) => {
    const newData = data.map((data) => (data.id === row.id ? row : data));

    setData(newData);
  };

  const components = {
    body: {
      row: DraggableBodyRow,
      cell: (props: any) => <EditableCellStep3 {...props} stages={stateSelect} />,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TStep2) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleNextPage = (buttonFooter: boolean = false) => {
    const newStep3 = data.map((data) => omit(data, ['id']));

    const newData: TStep3State = {
      step3: newStep3,
    };

    updateTemplate(newData, template.id, currentGroup.id ?? 0, buttonFooter, true);
  };

  const handleBackPage = (buttonFooter: boolean = false) => {
    const newStep3 = data.map((data) => omit(data, ['id']));
    const newData: TStep3State = {
      step3: newStep3,
    };
    updateTemplate(newData, template.id, currentGroup.id ?? 0, buttonFooter, true);
  };

  const handleDownloadFile = () => {
    if (currentGroup.id) downloadCsvQuestionFile({ groupId: currentGroup.id, id: template.id, step: 3 });
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <ConfirmModal
        open={openModal === 'confirm'}
        onClose={() => setOpenModal('')}
        title="確認"
        message="問題を削除してよろしいでしょうか？"
        onConfirm={handleDelete}
      />
      <UploadCsvModal
        uploadFile={uploadCsvQuestionFile}
        open={openModal === 'upload'}
        closeModal={() => setOpenModal('')}
        groupId={currentGroup.id ?? 0}
        id={template.id}
        step={3}
      />
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
            </Row>
            <Row className={styles.assignmentsQuestionWrap__marginTop}>
              <Link to={EPath.A3}>
                <Button>テンプレート一覧に戻る</Button>
              </Link>
            </Row>
          </Col>
        </Row>
        <NavStep data={navStep} />
        <div className={styles.contentTitle}>栽培イベント問題の設定</div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.form}>
              <p>栽培イベント問題とは、環境制御に関する突発的な出来事に対する問題です。</p>
              <p>栽培ステージ・問題文・選択肢・正解番号・配点解説を設定できます。</p>
              <p>栽培イベント問題を最大12個まで登録できます。</p>
              <br />
              <p className={styles.pForm}>基礎問題設定 基本問題</p>
              <CustomDivider />
              <Row gutter={[20, 20]} className={styles.csvBtn}>
                {!template.isLinkedWithTraining && (
                  <Col>
                    <Button onClick={() => setOpenModal('upload')}>CSV一括入力</Button>
                  </Col>
                )}
                <Col>
                  <Button onClick={handleDownloadFile}>CSV一括出力</Button>
                </Col>
              </Row>
              <DragTable data={data} moveRow={handleMoveRow} columns={columns} components={components} />
              {data.length < 12 && !template.isLinkedWithTraining && (
                <Button type="dashed" onClick={handleAdd} className={styles.buttonAdd}>
                  <PlusCircleFilled className={styles.iconAdd} />
                </Button>
              )}
            </div>
            <div className={styles.heightBottom} />
          </div>
        </div>
      </div>
      <ButtonFooter handleClickPre={handleBackPage} handleClickNext={handleNextPage} />
    </DndProvider>
  );
};

export default AdmTempEvent;
