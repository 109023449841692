/* eslint-disable complexity */
import * as dotenv from 'dotenv';
import moment from 'moment';
import { aliasValue } from 'constants/date';
import { IndexedObject } from 'constants/types';
import { Location } from 'history';
import queryString from 'query-string';
import { TRoleUser } from 'types/auth';
import { TUserDetail } from 'types/groups';
import { TChartData, TCorrectSimulations } from 'types/results';
import { TStages } from 'types/template';
import { TControlAnswer, TMaxScore, TQuestion, TResultBasicTask, TStudentAnswerControl } from 'types/assignments';
import { TCtrlAnswer } from 'pages/AssignmentsControlQuestion/AssignmentsControlQuestion.state';

dotenv.config();
export const replacePathParams = (path: string, params: IndexedObject<string>): string =>
  path.replace(/:([^/]+)/g, (_, p1) => encodeURIComponent(params[p1] ? params[p1] : ''));

export const isEmptyObject = (obj: IndexedObject) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const parseFloatNum = (str: string | (string | null)[] | null | number) => {
  const trimmed = str && typeof str === 'string' ? str.trim() : null;
  if (!trimmed) {
    return null;
  }
  const num = parseFloat(trimmed);
  const isFullyParsedNum = num.toString() === trimmed;
  return isFullyParsedNum ? num : null;
};
export const parseSearchParams = (search: string) => {
  const params = queryString.parse(search);
  return Object.keys(params).reduce((result: IndexedObject, key: string) => {
    const newResult = { ...result };
    const val = params[key];
    if (val === 'true') {
      newResult[key] = true;
    } else if (val === 'false') {
      newResult[key] = false;
    } else {
      const num = parseFloatNum(val);
      newResult[key] = num === null ? val : num;
    }
    return newResult;
  }, {});
};

export const createQueryUrl = (location: Location, params: IndexedObject) => {
  const { pathname } = location;
  if (isEmptyObject(params)) return pathname;
  const query = queryString.stringify(params);
  return `${pathname}?${query}`;
};

export const getTitleRoleUser = (role?: TRoleUser) => {
  switch (role) {
    case 'student':
      return '生徒';
    case 'teacher':
      return '教師';
    default:
      return '管理者';
  }
};

export const numberWithCommas = (x: number | string) => `¥${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const mapUsersByUserId = (users: TUserDetail[], userIds: Array<number | undefined>) =>
  users.filter((user) => userIds.includes(user.id));

export const convertArrayStringToNumber = (array?: Array<number | string>) => array?.map((item) => Number(item)) ?? [];

export const getListUserDetailByIds = (
  ids: Array<string | number | undefined> | undefined = [],
  users: TUserDetail[] = [],
): TUserDetail[] => users.filter((u) => ids.includes(u.id));

export const getMaxScoreControl = (maxScore?: TMaxScore) =>
  Number(maxScore?.morning ?? 0) +
  Number(maxScore?.afternoon ?? 0) +
  Number(maxScore?.beforeNight ?? 0) +
  Number(maxScore?.midnight ?? 0);

export const getMaxScoreBasic = (maxScore?: TMaxScore) => Number(maxScore?.basic ?? 0) + Number(maxScore?.event ?? 0);

export const splitNameAndTime = (infoString: string) => {
  const index = infoString.lastIndexOf(' ');
  const updatedAt = infoString.slice(index + 2, infoString.length - 1);
  const name = infoString.slice(0, index);
  return {
    name,
    updatedAt,
  };
};

export const getPublicUrlImage = (image: string) => `${process.env.PUBLIC_URL}/images/${image}`;

export const convertRangeData = (data: TCorrectSimulations, alias: string, type: string) => {
  const findDataType = data.find((d) => d.alias === alias);
  const chartData: Array<TChartData> = [];
  findDataType?.data?.forEach((e) => {
    const arrayData = Object.entries(e);
    const month = arrayData[0][1];
    const dataMapping = arrayData.slice(1);
    dataMapping.forEach((e: any) => {
      let time;
      switch (e[0]) {
        case 'morningData':
          time = '月-早朝';
          break;
        case 'afternoonData':
          time = '月-日中';
          break;
        case 'beforeNightData':
          time = '月-前夜半';
          break;
        case 'midnightData':
          time = '月-夜間';
          break;
        default:
          break;
      }
      chartData.push({
        type,
        Date: `${month} ${time}`,
        scales: e[1][`${type}Value`],
      });
    });
  });
  return chartData;
};

export const convertData = (dataChart: any, alias: string, isCalculate: boolean, nameType: string) => {
  const findDataType = dataChart.find((d: any) => d.alias === alias);
  const chartData: Array<TChartData> = [];
  findDataType?.data.forEach((d: any) => {
    const arrayData = Object.entries(d);
    const month = arrayData[0][1];
    const dataMapping = arrayData.slice(1);

    dataMapping.forEach((e) => {
      let time;
      let check = true;
      switch (e[0]) {
        case isCalculate ? 'morning' : 'morningData':
          time = '月-早朝';
          break;
        case isCalculate ? 'afternoon' : 'afternoonData':
          time = '月-日中';
          break;
        case isCalculate ? 'beforeNight' : 'beforeNightData':
          time = '月-前夜半';
          break;
        case isCalculate ? 'midnight' : 'midnightData':
          time = '月-夜間';
          break;
        default:
          break;
      }
      switch (e[1]) {
        case Number.isNaN:
        case null:
          // case 0:
          check = false;
          break;
        case 0:
          check = true;
          break;
        default:
          check = true;
          break;
      }
      chartData.push({
        type: nameType,
        Date: `${month} ${time}`,
        scales: e[1] ? (e[1] as number) : 0,
        check,
      });
    });
  });

  return chartData;
};

export const convertCorrectData = (dataCorrect: TCorrectSimulations, alias: string) => {
  const findDataType = dataCorrect.find((d) => d.alias === alias);
  const chartData: Array<TChartData> = [];
  findDataType?.data?.forEach((e) => {
    const arrayData = Object.entries(e);
    const month = arrayData[0][1];
    const dataMapping = arrayData.slice(1);
    dataMapping.forEach((e: any) => {
      let time;
      switch (e[0]) {
        case 'morningData':
          time = '月-早朝';
          break;
        case 'afternoonData':
          time = '月-日中';
          break;
        case 'beforeNightData':
          time = '月-前夜半';
          break;
        case 'midnightData':
          time = '月-夜間';
          break;
        default:
          break;
      }
      chartData.push({
        type: 'correctSimulations',
        Date: `${month} ${time}`,
        scales: e[1].correctValue,
      });
    });
  });

  return chartData;
};

export const checkUnitLineChart = (alias?: string) => {
  let unitData;
  switch (alias) {
    case aliasValue.C1:
      unitData = {
        name: '温度',
        value: '（℃）',
      };
      break;
    case aliasValue.M1:
    case aliasValue.M3:
    case aliasValue.M4:
    case aliasValue.M5:
      unitData = {
        name: '',
        value: '℃',
      };
      break;
    case aliasValue.C2:
      unitData = {
        name: '湿度',
        value: '（％）',
      };
      break;
    case aliasValue.M2:
      unitData = {
        name: '',
        value: '％',
      };
      break;
    case aliasValue.C3:
      unitData = {
        name: 'CO2',
        value: '（ppm）',
      };
      break;
    case aliasValue.M7:
      unitData = {
        name: '',
        value: 'ppm',
      };
      break;
    case aliasValue.M6:
      unitData = {
        name: '',
        value: '℃ / 100%',
      };
      break;
    case aliasValue.M9:
      unitData = {
        name: '',
        value: 'L',
      };
      break;
    default:
      unitData = {
        name: '',
        value: '',
      };
  }
  return unitData;
};

export const getServerImage = (image?: string) => `${process.env.REACT_APP_BASE_UPLOAD_URL}${image}`;

export const validationDataStage = (data: TStages[]) => {
  let valid = true;
  for (let i = 0; i < data.length; i += 1) {
    const check = Object.values(data[i]).every((i) => i !== '');
    if (!check) {
      valid = false;
      break;
    }
  }
  return valid;
};

export const getStageByMonth = (convertData: IndexedObject[], month: number) => {
  let daysCount = 0;
  let stageTarget;
  const listStageMatchMonth = convertData?.filter((e) => e[month]);
  listStageMatchMonth?.forEach((e) => {
    if (e[month] > daysCount) {
      daysCount = e[month];
      stageTarget = e.stage;
    }
  });
  return stageTarget;
};

export const convertDaysEachStages = (stages: TStages[]) => {
  const convertDays = stages?.map((e) => {
    const startDate = moment(e.startDate, 'MM/DD');
    const endDate = moment(e.endDate, 'MM/DD');
    const getMonthStart = Number(e.startDate.split('/')[0]);
    const getMonthEnd = Number(e.endDate.split('/')[0]);
    const objCount: IndexedObject = {};
    if (getMonthStart <= getMonthEnd) {
      const startMonth = startDate.month();
      const endMonth = endDate.month();

      for (let month = startMonth; month <= endMonth; month += 1) {
        const daysInMonth = moment([startDate.year(), month]).daysInMonth();
        if (month === startMonth || month === endMonth) {
          const startOfMonth = month === startMonth ? startDate : moment([startDate.year(), month, 1]);
          const endOfMonth = month === endMonth ? endDate : moment([endDate.year(), month, daysInMonth]);
          const diffInDays = moment.duration(endOfMonth.diff(startOfMonth)).asDays() + 1;
          objCount[month + 1] = diffInDays;
        } else {
          objCount[month + 1] = daysInMonth;
        }
      }
    } else {
      const endOfYear = moment([endDate.year(), 11, 31]);
      const diffInDaysUntilEndOfYear = moment.duration(endOfYear.diff(startDate)).asDays() + 1;
      objCount[startDate.month() + 1] = diffInDaysUntilEndOfYear;

      const diffInDaysFromStartOfYear = moment.duration(endDate.diff(moment([endDate.year(), 0, 1]))).asDays() + 1;
      objCount[endDate.month() + 1] = diffInDaysFromStartOfYear;

      for (let month = 0; month < 12; month += 1) {
        if (month >= startDate.month() && month <= endDate.month()) {
          objCount[month + 1] = moment([startDate.year(), month]).daysInMonth();
        }
      }
    }
    return {
      stage: e.name,
      ...objCount,
    };
  });
  return convertDays;
};

export const convertDataAnswerGraph = (answerStudent: TControlAnswer[]) => {
  const findAnsByAlias = (alias: string) => answerStudent.filter((e: TControlAnswer) => e.alias === alias);
  const listAnsM1 = findAnsByAlias('M1');
  const listAnsM2 = findAnsByAlias('M2');
  const listAnsM3 = findAnsByAlias('M3');
  const listAnsM4 = findAnsByAlias('M4');
  const listAnsM5 = findAnsByAlias('M5');
  const listAnsM6 = findAnsByAlias('M6');
  const listAnsM7 = findAnsByAlias('M7');
  const listAnsM8 = findAnsByAlias('M8');
  const listAnsM9 = findAnsByAlias('M9');

  const convertAnswerByAlias = (listAns: TControlAnswer[]) => {
    const aliasValue = listAns[0].alias;
    const factorialValue = listAns[0].factorial;
    const data = listAns.reduce(
      (acc: any, current: TControlAnswer) => [
        ...acc,
        {
          month: current.month,
          morningData: current.morningData,
          afternoonData: current.afternoonData,
          beforeNightData: current.beforeNightData,
          midnightData: current.midnightData,
        },
      ],
      [],
    );
    return {
      alias: aliasValue,
      factorial: factorialValue,
      data,
    };
  };
  return [
    convertAnswerByAlias(listAnsM1),
    convertAnswerByAlias(listAnsM2),
    convertAnswerByAlias(listAnsM3),
    convertAnswerByAlias(listAnsM4),
    convertAnswerByAlias(listAnsM5),
    convertAnswerByAlias(listAnsM6),
    convertAnswerByAlias(listAnsM7),
    convertAnswerByAlias(listAnsM8),
    convertAnswerByAlias(listAnsM9),
  ];
};

export const mappingAnswerDemonstrationMode = (tasksResult: TResultBasicTask[], questionList: TQuestion[]) => {
  const mappingAnswer = questionList.map((ques, i: number) => {
    if (tasksResult.length > 0) {
      const selectAnswer = tasksResult[i].selectedAnswer;
      const indexSelectAnswer =
        ques.options?.findIndex((e) => e.value === selectAnswer) !== undefined &&
        ques.options?.findIndex((e) => e.value === selectAnswer) >= 0
          ? ques.options?.findIndex((e) => e.value === selectAnswer)
          : 0;
      return {
        questionId: ques.id,
        selectedOption: indexSelectAnswer + 1,
        stageName: ques.stageName,
      };
    }
    return {};
  });
  return mappingAnswer;
};

export const mapAliasWithUnit = (alias: string) => {
  let unit: string;
  switch (alias) {
    case 'M1':
      unit = '℃';
      break;
    case 'M2':
      unit = '%';
      break;
    case 'M3':
      unit = '℃';
      break;
    case 'M4':
      unit = '%';
      break;
    case 'M5':
      unit = '℃';
      break;
    case 'M6':
      unit = '℃ / 100%';
      break;
    case 'M7':
      unit = 'ppm';
      break;
    case 'M9':
      unit = 'L';
      break;
    default:
      unit = '';
      break;
  }
  return unit;
};
export const convertControlAnswerByAlias = (studentAnswer: TStudentAnswerControl[], alias: string) => {
  const answerWithAlias = studentAnswer.find((e) => e.alias === alias);
  const convertAnswerWithMonth = answerWithAlias?.data?.map((e) => {
    const item: {
      alias: string;
      factorial: string;
      month: number;
      morningData: number;
      midnightData: number;
      beforeNightData: number;
      afternoonData: number;
      unit?: string;
    } = {
      alias,
      factorial: answerWithAlias.factorial ?? '',
      month: e.month ?? 0,
      morningData: Number(e.morningData),
      midnightData: Number(e.midnightData),
      afternoonData: Number(e.beforeNightData),
      beforeNightData: Number(e.afternoonData),
      unit: mapAliasWithUnit(alias),
    };
    if (alias === 'M8') {
      delete item.unit;
    }
    return item;
  });
  return convertAnswerWithMonth;
};

export const combineControlAnswerByAlias = (studentAnswer: TStudentAnswerControl[]) => {
  const controlStudentAnswer: TControlAnswer[] = [
    ...(convertControlAnswerByAlias(studentAnswer, 'M1') ?? []),
    ...(convertControlAnswerByAlias(studentAnswer, 'M2') ?? []),
    ...(convertControlAnswerByAlias(studentAnswer, 'M3') ?? []),
    ...(convertControlAnswerByAlias(studentAnswer, 'M4') ?? []),
    ...(convertControlAnswerByAlias(studentAnswer, 'M5') ?? []),
    ...(convertControlAnswerByAlias(studentAnswer, 'M6') ?? []),
    ...(convertControlAnswerByAlias(studentAnswer, 'M7') ?? []),
    ...(convertControlAnswerByAlias(studentAnswer, 'M9') ?? []),
    ...(convertControlAnswerByAlias(studentAnswer, 'M8') ?? []),
  ];
  return controlStudentAnswer;
};
