import assignmentsAction from 'modules/assignments/assignments.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import AssignmentsResultAdm from './AssignmentsResultAdm';

const AssignmentsResultAdmContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const uiState = useSelector((state: AppState) => state.pages.ui);
  const assignmentsState = useSelector((state: AppState) => state.pages.assignments);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupsClass);

  const getResultsAssignment = (data: { id: string; groupId: number }) =>
    dispatch(assignmentsAction.getResultsAssignment(data));

  return (
    <AssignmentsResultAdm
      uiState={uiState}
      assignmentsState={assignmentsState}
      trainingId={id}
      getResultsAssignment={getResultsAssignment}
      currentGroup={currentGroup}
    />
  );
};

export default AssignmentsResultAdmContainer;
