import { Button, Card, Form, Input, Space } from 'antd';
import { EPath } from 'constants/routes';
import { defaultValidateMessages } from 'constants/validate/message';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TGroupsClassDetail } from 'types/groupsClass';
import { TGroupsClassState, groupsClassModule } from 'modules/groupsClass/groupsClass.reducer';
import styles from './style.module.scss';

type Props = {
  handleSubmit: (data: TGroupsClassDetail) => void;
  getDetailgroup: () => void;
  idGroup: string;
  groupsClassState: TGroupsClassState;
};
const GroupsDetails = ({ handleSubmit, getDetailgroup, idGroup, groupsClassState }: Props) => {
  const [form] = Form.useForm();
  const { groupDetail } = groupsClassState;
  useEffect(() => {
    getDetailgroup();
  }, [idGroup]);

  useEffect(() => {
    if (idGroup) {
      form.setFieldValue('name', groupDetail.name);
    }
  }, [groupsClassState.groupDetail]);

  useEffect(() => {
    groupsClassModule.actions.saveDetailGroups({});
  }, []);
  return (
    <div className={styles.groups}>
      <Card
        className={styles.groups__wrap}
        title={
          <div className={styles.assignmentsMenuTitle}>
            <p>グループ登録</p>
          </div>
        }
        loading={false}
      >
        <div className={styles.content}>
          <div className={styles.top}>
            <Space className={styles.topButtonGroup}>
              <Button className={styles.topButton}>
                <Link to={EPath.S3_1}>グループ管理に戻る</Link>
              </Button>
            </Space>
            <Form
              autoComplete="off"
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              validateMessages={defaultValidateMessages}
              disabled={false}
            >
              <Form.Item>
                <Space direction="vertical" align="start" className={styles.assignmentItem}>
                  <p className={styles.assignmentTitle}>グループ名</p>
                  <Form.Item name="name" rules={[{ required: true, whitespace: true }]} noStyle>
                    <Input className={styles.assignmentItemWidth} placeholder="グループ名を入力してください" />
                  </Form.Item>
                </Space>
              </Form.Item>

              <Form.Item noStyle>
                <button disabled={false} type="submit" className={styles.confirmButton}>
                  保存する
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default GroupsDetails;
