import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Table } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NotiModal from 'components/CommonModal/NotiModal';
import NavStage from 'components/NavStage/NavStage';
import NavQuestionStage from 'components/NavQuestionStage/NavQuestion';
import {
  combineControlAnswerByAlias,
  convertDaysEachStages,
  getStageByMonth,
  replacePathParams,
} from 'libs/helpers/functions';
import { EPath } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { IndexedObject } from 'constants/types';
import { setStatusShowInsModal, getStatusShowInsModal } from 'libs/helpers/localStorageFn';
import { TAnswersState } from 'modules/answers/answers.reducer';
import { TGroupsClassDetail } from 'types/groupsClass';
import { TUserDetail } from 'types/groups';
import {
  co2StItems,
  columnTemperature,
  columnTime,
  columnWeather,
  dehStItems,
  emptyAnswers,
  heatTempStItems,
  InitialDataType,
  insTempItems,
  skylightStItems,
  stages,
  TCtrlAnswer,
  timeMappedByMonth,
  timerStItems,
  timeSample,
  waterStItems,
  firstMonth,
  lastMonth,
  convertToTCtrlAnswer,
} from './AssignmentsControlQuestion.state';
import ControlQuestionTable from './AssignmentsControlQuestion.Table';
import styles from './style.module.scss';

type Props = {
  assignmentId: string;
  getTaskDetail: (id: string, groupId: number) => void;
  answersState: TAnswersState;
  saveCtrlAnswers: (answers: TCtrlAnswer[]) => void;
  targetMonth: IndexedObject;
  currentGroup: TGroupsClassDetail;
  getResults: (id: string, sid: string) => void;
  currentUser: TUserDetail;
};

const AssignmentsControlQuestion = ({
  assignmentId,
  getTaskDetail,
  answersState,
  saveCtrlAnswers,
  targetMonth,
  currentGroup,
  getResults,
  currentUser,
}: Props) => {
  const { detailTask, controlAnswers, resultAssignment } = answersState;
  const history = useHistory();
  const [month, setMonth] = useState(firstMonth);
  const [stageLst, setStageLst] = useState(stages);
  const [openNotice, setOpenNotice] = useState(true);
  const [initialData, setInitialData] = useState<InitialDataType>({
    weatherData: [],
    tempData: [],
    question: '',
    timeData: [],
  });

  const [answers, setAnswers] = useState<TCtrlAnswer[]>(emptyAnswers);

  useEffect(() => {
    if (currentUser.id && assignmentId) getResults(assignmentId, String(currentUser.id));
  }, []);

  useEffect(() => {
    if (
      resultAssignment.controlResult &&
      resultAssignment.controlResult.studentAnswers &&
      resultAssignment.controlResult.studentAnswers?.length > 0
    ) {
      const answerControlConverted = combineControlAnswerByAlias(resultAssignment.controlResult.studentAnswers ?? []);
      setAnswers(convertToTCtrlAnswer(answerControlConverted));
    }
  }, [resultAssignment]);

  useEffect(() => {
    if (!detailTask.stages?.length) {
      setStageLst(stages);
    } else
      setStageLst(
        detailTask.stages?.map((s, i) => ({
          label: s.name,
          name: s.name,
          isActive: !i,
          index: i,
        })) ?? [],
      );
  }, [detailTask]);

  useEffect(() => {
    if (currentGroup.id) {
      getTaskDetail(assignmentId, currentGroup.id);
    }
  }, [assignmentId]);

  useEffect(() => {
    if (controlAnswers && controlAnswers.length > 0) {
      setAnswers(convertToTCtrlAnswer(controlAnswers));
    }
  }, [detailTask]);

  useEffect(() => {
    if (detailTask.initialControlData) {
      const monthlyRawData = detailTask.initialControlData.find((item) => item.month === month);
      setInitialData({
        weatherData: [
          {
            key: month,
            weather: '日数',
            sunny: monthlyRawData?.sunnyDay || 0,
            cloudy: monthlyRawData?.cloudyDay || 0,
            rain: monthlyRawData?.rainyDay || 0,
          },
        ],
        tempData: [
          {
            key: month,
            kind: '月平均気温',
            highest: monthlyRawData?.maxTemp || 0,
            average: monthlyRawData?.avgTemp || 0,
            lowest: monthlyRawData?.minTemp || 0,
          },
        ],
        question: monthlyRawData?.question || '',
        timeData: [timeMappedByMonth.get(month) || timeSample],
      });
    }
  }, [month, detailTask]);

  const changeMonth = (month: number): void => {
    const dataDayConverted = convertDaysEachStages(detailTask.stages ?? []);
    const stages = stageLst.map((it) => {
      const stg = { ...it };
      stg.isActive = stg.name === getStageByMonth(dataDayConverted, month);
      return stg;
    });
    setMonth(month);
    setStageLst(stages);
  };

  const handleNextPage = () => {
    let nextMonth = month + 1;
    nextMonth = nextMonth > 12 ? nextMonth - 12 : nextMonth;

    changeMonth(nextMonth);
    if (month === lastMonth) {
      saveCtrlAnswers(answers);
      toSimulationPage();
    }
  };

  const handleBackPage = () => {
    let nextMonth = month - 1;
    nextMonth = nextMonth === 0 ? 12 : nextMonth;

    changeMonth(nextMonth);
    if (nextMonth < firstMonth && nextMonth > lastMonth) backBasicResultPage();
  };

  const onSelectAnswer = (value: string, alias: string, key: number) => {
    const updatedAnswers = answers.map((ans) => {
      if (ans.alias === alias && ans.month === month) {
        const tmp = { ...ans };
        switch (key) {
          case 0:
            tmp.morningData = value;
            break;
          case 1:
            tmp.noonData = value;
            break;
          case 2:
            tmp.afternoonData = value;
            break;
          case 3:
            tmp.nightData = value;
            break;
          default:
            break;
        }
        return tmp;
      }

      return ans;
    });

    setAnswers(updatedAnswers);
  };

  const copyLastMonthData = (e: any) => {
    e.preventDefault();

    if (month !== firstMonth) {
      let lastMonth = month - 1;
      lastMonth = lastMonth === 0 ? 12 : lastMonth;

      const copiedData = answers
        .filter((ans) => ans.month === lastMonth)
        .map((ans) => {
          const tmp = { ...ans };
          tmp.month = month;
          return tmp;
        });
      const updatedAnswers = answers.filter((ans) => ans.month !== month); // remove answers of current month
      setAnswers(updatedAnswers.concat(copiedData));
    }
  };

  const backBasicResultPage = () => {
    saveCtrlAnswers(answers);
    history.push({
      pathname: EPath.U7_RESULTS.replace(':id', assignmentId),
      state: {
        isBack: true,
      },
    });
  };
  const toSimulationPage = () => {
    saveCtrlAnswers(answers);
    history.push(EPath.U8_3.replace(':id', assignmentId));
  };
  const filterByMonthAndAlias = (data: TCtrlAnswer[], aliases: string[]) =>
    data.filter((it) => it.month === month && aliases.includes(it.alias));
  if (targetMonth.month) {
    useEffect(() => {
      setMonth(targetMonth.month);
    }, [targetMonth.month]);
  }
  const onGoToInstructionPage = () => {
    saveCtrlAnswers(answers);
    history.push(replacePathParams(EPath.U11, { id: assignmentId }));
  };

  const setPropsButtonFooter = () => {
    if (month === firstMonth) {
      return {
        textBtnNext: '翌月に進む',
        handleClickNext: handleNextPage,
        textBtnFinish: '制御結果プレビュー',
        handleClickFinish: toSimulationPage,
      };
    }
    if (month === lastMonth) {
      return {
        textBtnPrev: '先月に戻る',
        handleClickPre: handleBackPage,
        textBtnFinish: '制御結果プレビュー',
        handleClickFinish: toSimulationPage,
      };
    }
    return {
      textBtnPrev: '先月に戻る',
      textBtnNext: '翌月に進む',
      textBtnFinish: '制御結果プレビュー',
      handleClickPre: handleBackPage,
      handleClickNext: handleNextPage,
      handleClickFinish: toSimulationPage,
    };
  };

  return (
    <>
      {/* ================= NOTI MODAL AREA ================= */}
      <NotiModal
        title="制御問題"
        message={
          <p>
            設定項目をプルダウンで選択後、<Button className={styles.buttonModalConfirm}>進む</Button>{' '}
            ボタンをクリックしていきます。
            <br />
            また、<Button className={styles.buttonModalPre}>制御結果</Button>{' '}
            ボタンをクリックすることで、制御シミュレーション結果を確認できます。
          </p>
        }
        isButtonPre
        textButtonPre="制御結果プレビュー"
        textButton="進む"
        open={openNotice && (!getStatusShowInsModal('control') || getStatusShowInsModal('control') === 'on')}
        openModal={() => {
          setStatusShowInsModal('control', 'off');
          setOpenNotice(false);
        }}
      />

      {/* ================= MAIN AREA ================= */}
      <div className={styles.assignmentsQuestionWrap}>
        {/* ================= ASSIGNMENT NAME AREA ================= */}
        <Row className={styles.assignmentsQuestionWrap__marginBottom}>
          <Col span={12}>
            <Row>
              <p>制御問題</p>
              <p className={styles.title}>{detailTask.assignmentName}</p>
            </Row>
          </Col>
          <Col span={12}>
            <Row className={styles.contentRight}>
              <Typography.Text onClick={onGoToInstructionPage} underline>
                栽培ルール確認
              </Typography.Text>
            </Row>
            <Row className={styles.contentRightButton}>
              <Button className={styles.buttonLeft} onClick={backBasicResultPage}>
                栽培問題課題回答確認
              </Button>
            </Row>
          </Col>
        </Row>

        {/* ================= CULTIVATION STAGES AREA ================= */}
        <Row align="middle" className={styles.stageStep}>
          <Col span={2}>
            <p>
              栽培 <br /> ステージ
            </p>
          </Col>
          <Col span={22}>
            <NavStage data={stageLst} linkDisabled timeRange={detailTask.stages ?? []} />
          </Col>
        </Row>
        <Row className={styles.fixedNavMonth}>
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <NavQuestionStage
              currentMonth={month}
              onClick={changeMonth}
              monthData={detailTask.initialControlData?.map((i) => i.month ?? 0) ?? []}
            />
          </Col>
        </Row>
        <Row align="middle" className={styles.stageStep}>
          <Col span={2}>
            <p>設問</p>
          </Col>
          <Col className={styles.maxWidthTutorial} span={22}>
            <div>{initialData.question}</div>
          </Col>
        </Row>
        <Row align="middle" className={styles.stageStep}>
          <Col span={2}>
            <p>天気</p>
          </Col>
          <Col className={styles.maxWidthTable} span={22}>
            <Table
              className={styles.tableInfo}
              dataSource={initialData.weatherData}
              columns={columnWeather}
              pagination={false}
            />
          </Col>
        </Row>
        <Row align="middle" className={styles.stageStep}>
          <Col span={2}>
            <p>気温</p>
          </Col>
          <Col className={styles.maxWidthTable} span={22}>
            <Table
              className={styles.tableInfo}
              dataSource={initialData.tempData}
              columns={columnTemperature}
              pagination={false}
            />
          </Col>
        </Row>
        <Row align="middle" className={styles.stageStep}>
          <Col span={2}>
            <p>時間帯</p>
          </Col>
          <Col className={styles.maxWidthTable} span={22}>
            <Table
              className={`${styles.tableInfo} ${styles.max}`}
              dataSource={initialData.timeData}
              columns={columnTime}
              pagination={false}
            />
          </Col>
        </Row>

        {/* ================= CULTIVATION FORM AREA ================= */}
        <div className={styles.firstTable}>
          <Row>
            <Col span={2}> </Col>
            <Col>
              <Button className={styles.buttonCopy} onClick={(e) => copyLastMonthData(e)}>
                先月の設定をコピーする
              </Button>
            </Col>
          </Row>
          <ControlQuestionTable
            title="天窓　設定"
            settingItems={skylightStItems}
            answers={filterByMonthAndAlias(answers, ['M1', 'M2'])}
            onChange={onSelectAnswer}
          />
        </div>

        <ControlQuestionTable
          title="重油炊き暖房　設定"
          settingItems={heatTempStItems}
          answers={filterByMonthAndAlias(answers, ['M3'])}
          onChange={onSelectAnswer}
        />
        <ControlQuestionTable
          title="湿度管理　設定"
          settingItems={dehStItems}
          answers={filterByMonthAndAlias(answers, ['M4'])}
          onChange={onSelectAnswer}
        />
        <ControlQuestionTable
          title="保温カーテン　設定"
          settingItems={insTempItems}
          answers={filterByMonthAndAlias(answers, ['M5', 'M6'])}
          onChange={onSelectAnswer}
        />
        <ControlQuestionTable
          title="炭酸ガス　設定"
          settingItems={co2StItems}
          answers={filterByMonthAndAlias(answers, ['M7'])}
          onChange={onSelectAnswer}
        />
        <ControlQuestionTable
          title="循環線（ダクトファン）　設定"
          settingItems={timerStItems}
          answers={filterByMonthAndAlias(answers, ['M8'])}
          onChange={onSelectAnswer}
        />
        <ControlQuestionTable
          title="灌水　設定"
          settingItems={waterStItems}
          answers={filterByMonthAndAlias(answers, ['M9'])}
          onChange={onSelectAnswer}
        />
      </div>

      <ButtonFooter {...setPropsButtonFooter()} />
    </>
  );
};

export default AssignmentsControlQuestion;
