import React from 'react';
import { Button, Dropdown, Space } from 'antd';
import ArrowDownIcon from 'components/Icons/ArrowDownIcon';
import styles from './style.module.scss';

const PullDown: React.FC<{
  title?: string;
  menuProps?: any;
}> = ({ title, menuProps }) => (
  <Dropdown menu={menuProps} overlayClassName={styles.wrapper} trigger={['click']}>
    <Button className={styles.mainButton}>
      <Space className={styles.buttonTittle}>
        {title} <ArrowDownIcon />
      </Space>
    </Button>
  </Dropdown>
);

export default PullDown;
