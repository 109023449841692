import React, { useEffect, useState } from 'react';
import { Row, Col, Divider, Table, Image } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import { TAnswersState } from 'modules/answers/answers.reducer';
import { EPath } from 'constants/routes';
import { getPublicUrlImage, getServerImage, replacePathParams } from 'libs/helpers/functions';
import { getAnswerFromLocalStorage } from 'libs/helpers/localStorageFn';
import { TAnswerAQuestion } from 'types/assignments';
import { TGroupsClassDetail } from 'types/groupsClass';
import { TStages } from 'types/template';
import { columns, data } from './AssignmentsStart.state';
import styles from './style.module.scss';
import './style.css';

type Props = {
  getTaskDetail: (id: string, groupId: number, isSaved: boolean) => void;
  answersState: TAnswersState;
  currentGroup: TGroupsClassDetail;
  clearResult: () => void;
};
const AssignmentsStart = ({ getTaskDetail, answersState, currentGroup, clearResult }: Props) => {
  const { detailTask } = answersState;

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [dataSource, setDataSource] = useState<TStages[]>(data);
  const checkAnswered = () => {
    const listAnswerBasicQues = getAnswerFromLocalStorage('basicAnswer') as TAnswerAQuestion[];
    const listAnswerEventQues = getAnswerFromLocalStorage('eventAnswer') as TAnswerAQuestion[];
    const isAnsweredBasic = listAnswerBasicQues?.some((q) => q.selectedOption !== null);
    const isAnsweredEvent = listAnswerEventQues?.some((q) => q.selectedOption !== null);
    return isAnsweredBasic || isAnsweredEvent;
  };

  useEffect(() => {
    if (currentGroup.id) getTaskDetail(id, currentGroup.id, !checkAnswered());
  }, []);
  useEffect(() => {
    clearResult();
  }, []);
  useEffect(() => {
    if (detailTask?.stages && detailTask.stages.length > 0) {
      const dataTable = detailTask.stages.map((s) => ({
        index: s.index,
        name: s.name,
        startDate: s.startDate,
        endDate: s.endDate,
        feature: s.feature,
      }));
      setDataSource(dataTable);
    }
  }, [detailTask]);
  const handleNextAnswerQuestionBasic = () => {
    if (detailTask.basicQuestions && detailTask.basicQuestions[0].id) {
      history.push(replacePathParams(EPath.U7_BASIC, { id, quesId: String(detailTask.basicQuestions[0].id) }));
    }
  };
  const handleBackAnswerQuestionBasic = () => {
    history.push(replacePathParams(EPath.U6_1, { id }));
  };

  return (
    <>
      <div className={styles.assignmentsRfpWrap}>
        <Row className={styles.titleBig}>
          <Col span={12}>
            <Row>
              <p>栽培ルール</p>
              <p className={styles.title}>{detailTask.assignmentName}</p>
            </Row>
            <Row>
              <p className={styles.subTitle}>栽培基本情報</p>
            </Row>
          </Col>
        </Row>
        <Divider className={styles.divider} />
        <Row className={styles.titleContent}>
          <p>今回の研修では、以下の条件で制御シミュレーションを行います。</p>
        </Row>
        <Row>
          <Col span={6}>
            <p>作物・品目</p>
          </Col>
          <Col span={18}>
            <p>{detailTask.itemName}</p>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <p>栽培方法</p>
          </Col>
          <Col span={18}>
            <p>{detailTask.method}</p>
          </Col>
        </Row>
        <Row className={styles.tableInfo}>
          <Col span={6}>
            <p>栽培ステージ</p>
          </Col>
          <Col span={18}>
            <Table columns={columns} dataSource={dataSource} pagination={false} />
          </Col>
        </Row>
        <Row className={styles.titleBig}>
          <p>ハウス基本情報</p>
        </Row>
        <Divider className={styles.divider} />
        <Image
          src={detailTask.imageName ? getServerImage(detailTask.imageName) : getPublicUrlImage('HouseBasicInfo.png')}
        />
      </div>
      <ButtonFooter handleClickPre={handleBackAnswerQuestionBasic} handleClickNext={handleNextAnswerQuestionBasic} />
    </>
  );
};

export default AssignmentsStart;
