import React, { useEffect } from 'react';
import { Divider, Row } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import { useHistory, useParams } from 'react-router-dom';
import { EPath } from 'constants/routes';
import { TAnswersState } from 'modules/answers/answers.reducer';
import { saveAssignmentName } from 'libs/helpers/localStorageFn';
import { replacePathParams } from 'libs/helpers/functions';
import { TUserDetail } from 'types/groups';
import { TGroupsClassDetail } from 'types/groupsClass';
import TableResultDetail from 'components/TableResultDetail/TableResultDetail';
import TableResultBasicTask from 'components/TableResultBasicTask/TableResultBasicTask';
import styles from './style.module.scss';

type Props = {
  getResultAssignment: (data: { id: string; sid: string }) => void;
  answersState: TAnswersState;
  currentUser: TUserDetail;
  getTaskDetail: (id: string, groupId: number, isSaved: boolean) => void;
  currentGroup: TGroupsClassDetail;
};
const AssignmentsAnswerOverview = ({
  getResultAssignment,
  answersState,
  currentUser,
  currentGroup,
  getTaskDetail,
}: Props) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { resultAssignment } = answersState;

  const basicScore = resultAssignment.basicResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const eventScore = resultAssignment.eventResults?.reduce((score, current) => score + (current.score ?? 0), 0);
  const basicTaskResults = resultAssignment.basicResults?.map((e, i) => ({
    ...e,
    key: i,
  }));
  const eventTaskResults = resultAssignment.eventResults?.map((e, i) => ({
    ...e,
    key: i,
  }));

  useEffect(() => {
    if (currentUser.id) {
      getResultAssignment({
        id,
        sid: currentUser.id.toString(),
      });
    }
  }, []);

  useEffect(() => {
    if (currentGroup.id) getTaskDetail(id, currentGroup.id, false);
  }, []);

  const handelClickNext = () => {
    history.push(replacePathParams(EPath.U8_1_2, { id }));
  };
  useEffect(() => {
    if (resultAssignment.name) {
      saveAssignmentName(resultAssignment.name);
    }
  });

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <div className={styles.title}>
          <Row>
            <p>栽培問題</p>
            <p className={styles.titleTime}>{resultAssignment.name}</p>
          </Row>
          <Row>
            <p className={styles.titleResult}>採点結果と回答解説</p>
          </Row>
        </div>

        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <Row>
              <Divider className={styles.divider} />
              <TableResultBasicTask
                maxScore={resultAssignment.maxScore}
                basicScore={basicScore}
                eventScore={eventScore}
              />
            </Row>
            <Row>
              <TableResultDetail dataTable={basicTaskResults ?? []} title1="解答解説" title2="基本問題" />
            </Row>
            <Row>
              <TableResultDetail dataTable={eventTaskResults ?? []} title1="解答解説" title2="イベント問題" />
            </Row>
          </div>
        </div>
      </div>
      <ButtonFooter handleClickNext={handelClickNext} />
    </>
  );
};

export default AssignmentsAnswerOverview;
